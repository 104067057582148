import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable({
  	providedIn: 'root'
})
export class AuthService {
	
    private __loginUrl = `${environment.apiUrl}admin/authenaticate`;

  	constructor( private _router: Router, private _http: HttpClient) { }

	loginAdmin(admin: any) {
        return this._http.post<any>(this.__loginUrl,admin);
    }

    loggedIn(){
        return !!localStorage.getItem('inkingi_auth_token');
    }

    getToken(){
        return localStorage.getItem('inkingi_auth_token');
    }

    logout(){
        localStorage.removeItem('inkingi_auth_token');
        this._router.navigate(['/adminlogin']);
    }
    
}
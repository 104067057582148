<div id="entire_customization_page">

    <div *ngIf="isSuccess" class="success_message">
        <div class="msg_box">
            <p>{{ successMsg }}</p>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-.997-4L6.76 11.757l1.414-1.414 2.829 2.829 5.656-5.657 1.415 1.414L11.003 16z" fill="rgba(255,255,255,1)"/></svg>
        </div>
    </div>

    <div class="image_viewer" *ngIf="viewingPicMedia">
        <svg (click)="closeAllMediaView()" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path fill="#ffffff" d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-9.414l2.828-2.829 1.415 1.415L13.414 12l2.829 2.828-1.415 1.415L12 13.414l-2.828 2.829-1.415-1.415L10.586 12 7.757 9.172l1.415-1.415L12 10.586z"/></svg>
        <img [src]="currentImageBeingViewed" alt="">
    </div>

    <div class="video_viewer" *ngIf="viewingVidMedia">
        <svg (click)="closeAllMediaView()" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path fill="#ffffff" d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-9.414l2.828-2.829 1.415 1.415L13.414 12l2.829 2.828-1.415 1.415L12 13.414l-2.828 2.829-1.415-1.415L10.586 12 7.757 9.172l1.415-1.415L12 10.586z"/></svg>
        <video [src]="currentVideoBeingViewed" muted autoplay controls></video>
    </div>

    <div class="header col-mob-12 col-tab-12 col-large-12">
        <p>Customize your website</p>
    </div>
    <div class="content col-mob-12 col-tab-12 col-large-12">
        <div class="form col-mob-12 col-tab-12 col-large-12">
            <form>
                <div *ngIf="hasErrors" class="alert_container">
                    <p *ngIf="hasErrors">{{errors}}</p>
                </div>

                <div class="form_group">
                    <p>Dashboard products' title</p>
                    <input type="text" #title_dash (input)="getProdDashTitle(title_dash.value)" required maxlength="60" [value]="currentCustomizations.dashboardProductsTitle" [ngClass]="{'--red_bordered': dashTitleRequired}" placeholder="Dashboard products' title">
                </div>

                <div class="form_group">
                    <p>Dashboard products' description</p>
                    <!-- <input type="text" placeholder="Product description"> -->
                    <textarea #descript_dash (input)="getProdDashDescript(descript_dash.value)" required maxlength="150" [value]="currentCustomizations.dashboardProductsDescription" [ngClass]="{'--red_bordered': dashDescriptionRequired}" placeholder="Dashboard products' description"></textarea>
                </div>

                <div class="form_group">
                    <p>Dashboard slideshow images</p>
                    <div class="drag_zone">
                        <div *ngIf="slideshowDone" class="images_selected">
                            <img *ngFor="let img of slideShowImagesBlobs; let i = index" [src]="img" alt="">
                        </div>
                        <input type="file" id="slideshow_images" accept=".png,.jpg,.jpeg" (input)="getSlideshowImages($event)" multiple required>
                        <svg *ngIf="!slideshowDone" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="64" height="64"><path fill="none" d="M0 0h24v24H0z"/><path d="M21 15v3h3v2h-3v3h-2v-3h-3v-2h3v-3h2zm.008-12c.548 0 .992.445.992.993V13h-2V5H4v13.999L14 9l3 3v2.829l-3-3L6.827 19H14v2H2.992A.993.993 0 0 1 2 20.007V3.993A1 1 0 0 1 2.992 3h18.016zM8 7a2 2 0 1 1 0 4 2 2 0 0 1 0-4z"/></svg>
                        <p *ngIf="!slideshowDone">Drag and drop files or click to upload</p>
                    </div>
                    <span>maximum number of these images is <strong>10</strong> of similar size.</span>
                </div>

                <div class="form_group video_shots">
                    <p>Dashboard video shots</p>
                    <div class="drag_zone">
                        <div *ngIf="videoShotsDone" class="images_selected">
                            <video *ngFor="let shot of videoShotsBlobs; let i = index" [src]="shot"></video>
                        </div>
                        <input type="file" id="video_shots" accept=".avi,.wmv,.mov,.mpeg,.flv,.3gp,.mp4" (input)="getVideoShots($event)" multiple required>
                        <svg *ngIf="!videoShotsDone" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="64" height="64"><path fill="none" d="M0 0H24V24H0z"/><path d="M16 4c.552 0 1 .448 1 1v4.2l5.213-3.65c.226-.158.538-.103.697.124.058.084.09.184.09.286v12.08c0 .276-.224.5-.5.5-.103 0-.203-.032-.287-.09L17 14.8V19c0 .552-.448 1-1 1H2c-.552 0-1-.448-1-1V5c0-.552.448-1 1-1h14zm-1 2H3v12h12V6zM8 8h2v3h3v2H9.999L10 16H8l-.001-3H5v-2h3V8zm13 .841l-4 2.8v.718l4 2.8V8.84z"/></svg>
                        <p *ngIf="!videoShotsDone">Drag and drop files or click to upload</p>
                    </div>
                    <span>maximum number of these videos is <strong>10</strong></span>
                </div>

                <div class="submit_form col-mob-12 col-tab-12 col-large-12" id="submit_container">
                    <button (click)="cancelCustomizations()" class="col-mob-12 col-tab-5 col-large-5">Cancel now</button>
                    <button (click)="saveCustomizations()" class="sub_btn col-mob-12 col-tab-5 col-large-5">
                        <svg *ngIf="hasSubmitted" class="spinner_loading" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M18.364 5.636L16.95 7.05A7 7 0 1 0 19 12h2a9 9 0 1 1-2.636-6.364z" fill="rgba(255,255,255,1)"/></svg>
                        <span *ngIf="!hasSubmitted">Save customization</span>
                    </button>
                </div>
            </form>
        </div>
        <div class="current_content col-mob-12 col-tab-12 col-large-12">
            <div class="current">
                <div class="form_group">
                    <p>Current active customizations</p>
                    <span>Dashboard slideshow images</span>
                    <div class="slideshow_images">
                        <div *ngIf="loadingData" class="isLoadingData">
                            <svg class="spinner_loading" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path fill="#000000" d="M18.364 5.636L16.95 7.05A7 7 0 1 0 19 12h2a9 9 0 1 1-2.636-6.364z"/></svg>
                            <p>Loading . . .</p>
                        </div>
                        <div *ngFor="let img of currentCustomizations.mainSlideImages; let i = index">
                            <img (click)="viewPicMedia(img)" [src]="img" alt="">
                        </div>
                    </div>
                    
                    <span>Dashboard video shots</span>
                    <div class="slideshow_images">
                        <div *ngIf="loadingData" class="isLoadingData">
                            <svg class="spinner_loading" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path fill="#000000" d="M18.364 5.636L16.95 7.05A7 7 0 1 0 19 12h2a9 9 0 1 1-2.636-6.364z"/></svg>
                            <p>Loading . . .</p>
                        </div>
                        <div *ngFor="let vid of currentCustomizations.videoShots; let i = index">
                            <video (click)="viewVidMedia(vid)" [src]="vid"></video>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    </div>
</div>
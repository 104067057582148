<div id="entire_clientoutlet__component">

    <div class="currency_container">
        <div class="flag_container">
            <div>
                <img style="max-height: 100%; border-radius: 50%;" *ngIf="currency == 'rwf'" src="../../assets/app/rw.png" alt="">
                <img *ngIf="currency == 'usd'" src="../../assets/app/usa.png" alt="">
                <img *ngIf="currency == 'gbp'" src="../../assets/app/gbp.png" alt="">
            </div>
        </div>
        <div class="all_accepted_currencies">
            <p (click)="changeCurrency('rwf')" [ngClass]="{'active': currency == 'rwf'}">RWF (RF)</p>
            <p (click)="changeCurrency('usd')" [ngClass]="{'active': currency == 'usd'}">USD ($)</p>
            <p (click)="changeCurrency('gbp')" [ngClass]="{'active': currency == 'gbp'}">POUND (&pound;)</p>
        </div>
    </div>

    <div *ngIf="isSuccess || hasErrorAlert" class="success_message">
        <div class="msg_box" [ngClass]="{'isFailed': hasErrorAlert}">
            <p *ngIf="isSuccess">{{ successMsg }}</p>
            <p *ngIf="hasErrorAlert">{{ hasErrorMsg }}</p>
            <svg *ngIf="isSuccess" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-.997-4L6.76 11.757l1.414-1.414 2.829 2.829 5.656-5.657 1.415 1.414L11.003 16z" fill="rgba(255,255,255,1)"/></svg>
            <svg *ngIf="hasErrorAlert" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-9.414l2.828-2.829 1.415 1.415L13.414 12l2.829 2.828-1.415 1.415L12 13.414l-2.828 2.829-1.415-1.415L10.586 12 7.757 9.172l1.415-1.415L12 10.586z" fill="rgba(255,255,255,1)"/></svg>
        </div>
    </div>

    <div *ngIf="searchToggled" class="search_container" (click)="toggleSearch($event)">
        <div class="search">
            <h2>What are you looking for?</h2>
            <input type="text" #key (input)="getSearchKey(key.value)" placeholder="Search on inkingi . . ." maxlength="30">
            <button (click)="searchOnInkingi()" [disabled]="loadingSearch">
                <svg [ngClass]="{'--loading_search': loadingSearch}" id="searchicon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="22" height="22"><path fill="none" d="M0 0h24v24H0z"/><path fill="#fff" d="M18.031 16.617l4.283 4.282-1.415 1.415-4.282-4.283A8.96 8.96 0 0 1 11 20c-4.968 0-9-4.032-9-9s4.032-9 9-9 9 4.032 9 9a8.96 8.96 0 0 1-1.969 5.617zm-2.006-.742A6.977 6.977 0 0 0 18 11c0-3.868-3.133-7-7-7-3.868 0-7 3.132-7 7 0 3.867 3.132 7 7 7a6.977 6.977 0 0 0 4.875-1.975l.15-.15z"/></svg>
            </button>
            <svg id="closesearch" (click)="toggleSearch($event)" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z"/></svg>
        </div>
    </div>

    <div [ngClass]="{'isNavbarDownClass': isNavbarDown}" class="navbar_section">

        <div *ngIf="expandedMenu" class="link_container hide_on_desktop">
            <div class="search">
                <input type="text" #key (input)="getSearchKey(key.value)" (keyup.enter)="searchOnInkingi()" placeholder="Search on inkingi . . .">
                <svg (click)="searchOnInkingi()" id="searchicon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20"><path fill="none" d="M0 0h24v24H0z"/><path fill="#000" d="M18.031 16.617l4.283 4.282-1.415 1.415-4.282-4.283A8.96 8.96 0 0 1 11 20c-4.968 0-9-4.032-9-9s4.032-9 9-9 9 4.032 9 9a8.96 8.96 0 0 1-1.969 5.617zm-2.006-.742A6.977 6.977 0 0 0 18 11c0-3.868-3.133-7-7-7-3.868 0-7 3.132-7 7 0 3.867 3.132 7 7 7a6.977 6.977 0 0 0 4.875-1.975l.15-.15z"/></svg>
            </div>
            <ul>
                <li><a (click)="expandMenu()" [routerLinkActiveOptions]="{ exact: true }" routerLinkActive = "active" [routerLink]="['']">HOME</a></li>
                <li><a (click)="expandMenu()" routerLinkActive = "active" [routerLink]="['men']">MEN</a></li>
                <li><a (click)="expandMenu()" routerLinkActive = "active" [routerLink]="['women']">WOMEN</a></li>
                <li><a (click)="expandMenu()" routerLinkActive = "active" [routerLink]="['shop']">SHOP</a></li>
                <li><a (click)="expandMenu()" routerLinkActive = "active" [routerLink]="['collections']">COLLECTIONS</a></li>
                <li><a (click)="expandMenu()" routerLinkActive = "active" [routerLink]="['contact']">CONTACT</a></li>
            </ul>
        </div>

        <div class="menu_toggle">
            <svg (click)="expandMenu()" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="22" height="22"><path fill="none" d="M0 0h24v24H0z"/><path class="navbar_icons" [ngClass]="{'--not_on_home_page_icons': !onTheHomePage}" fill="#ffffff" d="M3 4h18v2H3V4zm6 7h12v2H9v-2zm-6 7h18v2H3v-2z"/></svg>
        </div>

        <div class="link_container hide_mobile" [ngClass]="{'--not_on_home_page': !onTheHomePage}">
            <ul>
                <li><a [routerLinkActiveOptions]="{ exact: true }" routerLinkActive = "active" [routerLink]="['']">HOME</a></li>
                <li><a routerLinkActive = "active" [routerLink]="['men']">MEN</a></li>
                <li><a routerLinkActive = "active" [routerLink]="['women']">WOMEN</a></li>
                <li><a routerLinkActive = "active" [routerLink]="['shop']">SHOP</a></li>
                <li><a routerLinkActive = "active" [routerLink]="['contact']">CONTACT</a></li>
            </ul>
        </div>

        <div class="logo_container">
            <a *ngIf="!onTheHomePage || isNavbarDown" [routerLink]="['']">
                <img src="../../assets/app/logo.png" alt="">
            </a>
            <a *ngIf="onTheHomePage && !isNavbarDown" [routerLink]="['']">
                <img src="../../assets/app/light_logo.png" alt="">
            </a>
        </div>

        <div class="cart_container">

            <div class="collections hide_in_mobile" [ngClass]="{'--not_on_home_page': !onTheHomePage}">
                <ul>
                    <li><a (click)="expandMenu()" routerLinkActive = "active" [routerLink]="['collections']">COLLECTIONS</a></li>
                </ul>
            </div>

            <div class="cart">
                <a (click)="expandMenuFalsey()" [routerLink]="['cart']">
                    <div class="items_in_cart">
                        <p>{{ itemsInCart }}</p>
                    </div>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="22" height="22"><path fill="none" d="M0 0h24v24H0z"/><path class="navbar_icons" [ngClass]="{'--not_on_home_page_icons': !onTheHomePage}" fill="#ffffff"  d="M6.5 2h11a1 1 0 0 1 .8.4L21 6v15a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V6l2.7-3.6a1 1 0 0 1 .8-.4zM19 8H5v12h14V8zm-.5-2L17 4H7L5.5 6h13zM9 10v2a3 3 0 0 0 6 0v-2h2v2a5 5 0 0 1-10 0v-2h2z"/></svg>
                    <!-- <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="22" height="22"><path fill="none" d="M0 0h24v24H0z"/><path class="navbar_icons" [ngClass]="{'--not_on_home_page_icons': !onTheHomePage}" fill="#ffffff" d="M4 6.414L.757 3.172l1.415-1.415L5.414 5h15.242a1 1 0 0 1 .958 1.287l-2.4 8a1 1 0 0 1-.958.713H6v2h11v2H5a1 1 0 0 1-1-1V6.414zM6 7v6h11.512l1.8-6H6zm-.5 16a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm12 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z"/></svg> -->
                </a>
            </div>

            <div class="search">
                <svg (click)="toggleSearch($event)" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="22" height="22"><path fill="none" d="M0 0h24v24H0z"/><path class="navbar_icons" [ngClass]="{'--not_on_home_page_icons': !onTheHomePage}" fill="#ffffff" d="M18.031 16.617l4.283 4.282-1.415 1.415-4.282-4.283A8.96 8.96 0 0 1 11 20c-4.968 0-9-4.032-9-9s4.032-9 9-9 9 4.032 9 9a8.96 8.96 0 0 1-1.969 5.617zm-2.006-.742A6.977 6.977 0 0 0 18 11c0-3.868-3.133-7-7-7-3.868 0-7 3.132-7 7 0 3.867 3.132 7 7 7a6.977 6.977 0 0 0 4.875-1.975l.15-.15z"/></svg>
            </div>
            
        </div>
    </div>
    <div class="body_content">
        <router-outlet></router-outlet>
    </div>
    <div class="footer col-12 col-mob-12 col-tab-12 col-large-12">
        <div class="main_footer_container col-12 col-tab-12 col-large-12 col-mob-12">
            <div class="footer_content first_col col-3 col-tab-6 col-large-6 col-mob-12">
                <h4 class="important_links">Our quoting</h4>
                <p class="footer_p_descript left_margin">
                    Inkingi fashion design is helping you to look smart with professional made clothings by trained excepts.
                    With all means for you to reach them easily by shipping them for you.
                </p>
                <img src="../../assets/app/logo.png" alt="logo">
            </div>
            <div class="footer_content col-3 col-tab-6 col-large-6 col-mob-12">
                <h4 class="important_links">Important Links</h4>
                <a routerLinkActive = "active" [routerLinkActiveOptions]="{ exact: true }" [routerLink]="['']">Home page</a>
                <a routerLinkActive = "active" [routerLink]="['shop']">Shop</a>
                <a routerLinkActive = "active" [routerLink]="['men']">Men collections</a>
                <a routerLinkActive = "active" [routerLink]="['women']">Women collections</a>
                <a routerLinkActive = "active" [routerLink]="['contact']">Contact</a>
            </div>
            <div class="footer_content contact_us col-3 col-tab-6 col-large-12 col-mob-12">
                <h4>Contact Us</h4>
                <p> KG 623 St, Kimihurura</p>
                <p>Kimihurura, Gasabo - Kigali</p>
                <p> Republic of Rwanda</p> 
                <p><strong>Phone:</strong> +250 789 428 757</p>
                <p><strong>Email:</strong> info@maisoninkingi.com</p>
            </div>
            <div class="footer_content news_letter col-3 col-tab-6 col-large-6 col-mob-12">
                <h4>Inkingi Newsletter</h4>
                <h3 class="left_margin">If you feel like you want always get our updates then make 
                    sure to subscribe below.</h3>
                <form>
                    <input id="email" type="email" name="email" [value]="subscriberEmail" #mail (input)="getSubscriberEmail(mail.value)" required placeholder="Enter email" maxlength="80">
                    <button (click)="subscriptionActivity()">
                        <svg *ngIf="hasSubmitted" class="spinner_loading" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M18.364 5.636L16.95 7.05A7 7 0 1 0 19 12h2a9 9 0 1 1-2.636-6.364z" fill="rgba(255,255,255,1)"/></svg>
                        <span *ngIf="!hasSubmitted">Subscribe now</span>
                    </button>
                </form>
            </div>
        </div>
        <div class="social_medias col-12">
            <div class="social_icon_container">
                <div class="icon">
                    <a target="_blank" href="https://www.instagram.com/inkingi.designs/">    
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M12 9a3 3 0 1 0 0 6 3 3 0 0 0 0-6zm0-2a5 5 0 1 1 0 10 5 5 0 0 1 0-10zm6.5-.25a1.25 1.25 0 0 1-2.5 0 1.25 1.25 0 0 1 2.5 0zM12 4c-2.474 0-2.878.007-4.029.058-.784.037-1.31.142-1.798.332-.434.168-.747.369-1.08.703a2.89 2.89 0 0 0-.704 1.08c-.19.49-.295 1.015-.331 1.798C4.006 9.075 4 9.461 4 12c0 2.474.007 2.878.058 4.029.037.783.142 1.31.331 1.797.17.435.37.748.702 1.08.337.336.65.537 1.08.703.494.191 1.02.297 1.8.333C9.075 19.994 9.461 20 12 20c2.474 0 2.878-.007 4.029-.058.782-.037 1.309-.142 1.797-.331.433-.169.748-.37 1.08-.702.337-.337.538-.65.704-1.08.19-.493.296-1.02.332-1.8.052-1.104.058-1.49.058-4.029 0-2.474-.007-2.878-.058-4.029-.037-.782-.142-1.31-.332-1.798a2.911 2.911 0 0 0-.703-1.08 2.884 2.884 0 0 0-1.08-.704c-.49-.19-1.016-.295-1.798-.331C14.925 4.006 14.539 4 12 4zm0-2c2.717 0 3.056.01 4.122.06 1.065.05 1.79.217 2.428.465.66.254 1.216.598 1.772 1.153a4.908 4.908 0 0 1 1.153 1.772c.247.637.415 1.363.465 2.428.047 1.066.06 1.405.06 4.122 0 2.717-.01 3.056-.06 4.122-.05 1.065-.218 1.79-.465 2.428a4.883 4.883 0 0 1-1.153 1.772 4.915 4.915 0 0 1-1.772 1.153c-.637.247-1.363.415-2.428.465-1.066.047-1.405.06-4.122.06-2.717 0-3.056-.01-4.122-.06-1.065-.05-1.79-.218-2.428-.465a4.89 4.89 0 0 1-1.772-1.153 4.904 4.904 0 0 1-1.153-1.772c-.248-.637-.415-1.363-.465-2.428C2.013 15.056 2 14.717 2 12c0-2.717.01-3.056.06-4.122.05-1.066.217-1.79.465-2.428a4.88 4.88 0 0 1 1.153-1.772A4.897 4.897 0 0 1 5.45 2.525c.638-.248 1.362-.415 2.428-.465C8.944 2.013 9.283 2 12 2z"/></svg>
                    </a>
                </div>
                <div class="icon">
                    <a target="_blank" href="">    
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M15.3 5.55a2.9 2.9 0 0 0-2.9 2.847l-.028 1.575a.6.6 0 0 1-.68.583l-1.561-.212c-2.054-.28-4.022-1.226-5.91-2.799-.598 3.31.57 5.603 3.383 7.372l1.747 1.098a.6.6 0 0 1 .034.993L7.793 18.17c.947.059 1.846.017 2.592-.131 4.718-.942 7.855-4.492 7.855-10.348 0-.478-1.012-2.141-2.94-2.141zm-4.9 2.81a4.9 4.9 0 0 1 8.385-3.355c.711-.005 1.316.175 2.669-.645-.335 1.64-.5 2.352-1.214 3.331 0 7.642-4.697 11.358-9.463 12.309-3.268.652-8.02-.419-9.382-1.841.694-.054 3.514-.357 5.144-1.55C5.16 15.7-.329 12.47 3.278 3.786c1.693 1.977 3.41 3.323 5.15 4.037 1.158.475 1.442.465 1.973.538z"/></svg>
                    </a>
                </div>
                <div class="icon">
                    <a target="_blank" href="mailto:info@maisoninkingi.com">    
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M22 20.007a1 1 0 0 1-.992.993H2.992A.993.993 0 0 1 2 20.007V19h18V7.3l-8 7.2-10-9V4a1 1 0 0 1 1-1h18a1 1 0 0 1 1 1v16.007zM4.434 5L12 11.81 19.566 5H4.434zM0 15h8v2H0v-2zm0-5h5v2H0v-2z"/></svg>
                    </a>
                </div>
                <div class="icon">
                    <a target="_blank" href=""> 
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M13 19.938A8.001 8.001 0 0 0 12 4a8 8 0 0 0-1 15.938V14H9v-2h2v-1.654c0-1.337.14-1.822.4-2.311A2.726 2.726 0 0 1 12.536 6.9c.382-.205.857-.328 1.687-.381.329-.021.755.005 1.278.08v1.9H15c-.917 0-1.296.043-1.522.164a.727.727 0 0 0-.314.314c-.12.226-.164.45-.164 1.368V12h2.5l-.5 2h-2v5.938zM12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10z"/></svg>
                    </a>
                </div>
                <div class="icon">
                    <a target="_blank" href=""> 
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M15.396 10.58l.02-.249a32.392 32.392 0 0 0 .083-2.326c0-.87-.294-1.486-.914-2.063-.66-.614-1.459-.942-2.59-.942-1.137 0-1.958.335-2.51.888-.696.695-.958 1.218-.958 2.1 0 .521.061 1.994.096 2.618a2 2 0 0 1-.469 1.402c.055.098.105.204.153.317.3.771.198 1.543-.152 2.271-.392.818-.731 1.393-1.41 2.154a7.973 7.973 0 0 1-.642.643 1.999 1.999 0 0 1 .412.565 5.886 5.886 0 0 1 1.585.074c.81.146 1.324.434 2.194 1.061l.016.011.213.152c.619.44.877.546 1.473.546.609 0 .91-.121 1.523-.552l.207-.146c.876-.632 1.407-.928 2.231-1.076a6.664 6.664 0 0 1 1.559-.074 1.999 1.999 0 0 1 .417-.567 8.409 8.409 0 0 1-.616-.616 9.235 9.235 0 0 1-1.447-2.16c-.363-.749-.47-1.54-.137-2.321.04-.098.085-.19.132-.276a2 2 0 0 1-.469-1.435zm-10.315-.102c.419 0 .6.305 1.219.305.157 0 .26-.035.326-.066-.009-.156-.099-1.986-.099-2.729 0-1.688.72-2.69 1.543-3.514C8.893 3.65 10.175 3 11.996 3c1.82 0 3.066.653 3.952 1.478.886.825 1.551 1.93 1.551 3.528 0 1.555-.099 2.594-.108 2.716a.59.59 0 0 0 .279.065c.63 0 .63-.31 1.33-.31.685 0 .983.57.983.823 0 .621-.833.967-1.33 1.126-.369.117-.931.291-1.075.635-.074.174-.043.4.092.678.003.008 1.26 2.883 3.93 3.326.235.035.391.241.391.483 0 .332-.37.617-.726.782-.443.2-1.091.37-1.952.505-.043.078-.134.485-.235.887-.135.542-.801.366-.991.326A4.997 4.997 0 0 0 16.291 20c-.482.087-.913.378-1.395.726-.713.504-1.465 1.076-2.9 1.076-1.436 0-2.144-.572-2.857-1.076-.482-.348-.905-.637-1.396-.726-.898-.163-1.57.036-1.795.057-.226.02-.842.244-.996-.327-.045-.166-.191-.808-.235-.895-.856-.135-1.508-.313-1.952-.513-.365-.165-.726-.443-.726-.779 0-.235.158-.44.391-.482 2.644-.483 3.766-3.005 3.922-3.33.132-.276.161-.5.091-.679-.143-.343-.704-.513-1.073-.635-.105-.034-1.336-.373-1.336-1.117 0-.24.205-.573.582-.73a1.36 1.36 0 0 1 .465-.092z"/></svg>
                    </a>
                </div>
                <div class="icon">
                    <a target="_blank" href=""> 
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M7.253 18.494l.724.423A7.953 7.953 0 0 0 12 20a8 8 0 1 0-8-8c0 1.436.377 2.813 1.084 4.024l.422.724-.653 2.401 2.4-.655zM2.004 22l1.352-4.968A9.954 9.954 0 0 1 2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10a9.954 9.954 0 0 1-5.03-1.355L2.004 22zM8.391 7.308c.134-.01.269-.01.403-.004.054.004.108.01.162.016.159.018.334.115.393.249.298.676.588 1.357.868 2.04.062.152.025.347-.093.537a4.38 4.38 0 0 1-.263.372c-.113.145-.356.411-.356.411s-.099.118-.061.265c.014.056.06.137.102.205l.059.095c.256.427.6.86 1.02 1.268.12.116.237.235.363.346.468.413.998.75 1.57 1l.005.002c.085.037.128.057.252.11.062.026.126.049.191.066a.35.35 0 0 0 .367-.13c.724-.877.79-.934.796-.934v.002a.482.482 0 0 1 .378-.127c.06.004.121.015.177.04.531.243 1.4.622 1.4.622l.582.261c.098.047.187.158.19.265.004.067.01.175-.013.373-.032.259-.11.57-.188.733a1.155 1.155 0 0 1-.21.302 2.378 2.378 0 0 1-.33.288 3.71 3.71 0 0 1-.125.09 5.024 5.024 0 0 1-.383.22 1.99 1.99 0 0 1-.833.23c-.185.01-.37.024-.556.014-.008 0-.568-.087-.568-.087a9.448 9.448 0 0 1-3.84-2.046c-.226-.199-.435-.413-.649-.626-.89-.885-1.562-1.84-1.97-2.742A3.47 3.47 0 0 1 6.9 9.62a2.729 2.729 0 0 1 .564-1.68c.073-.094.142-.192.261-.305.127-.12.207-.184.294-.228a.961.961 0 0 1 .371-.1z"/></svg>
                    </a>
                </div>
            </div>
        </div>
        <div class="copyright_section col-12">
            <p>&copy; Copyright {{ year }} <strong> <span> Inkingi Fashion Design</span></strong>. All Rights Reserved</p>
        </div>
    </div>
</div>
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  	providedIn: 'root'
})

export class CollectionsService {

	constructor( private _http: HttpClient ) { }

	registerNewCollection(data: any) {
		return this._http.post<any>(`${environment.apiUrl}collections/addCollection`, data);
	}
	
	updateCollection(value: any, data: any) {
		return this._http.put<any>(`${environment.apiUrl}collections/updateCollection/${value}`, data);
	}

	getAllCollections() {
		return this._http.get<any>(`${environment.apiUrl}collections/getAllCollections`);
	}

	getCollectionById(value: any) {
		return this._http.get<any>(`${environment.apiUrl}collections/getCollectionById/${value}`);
	}

	deactivateCollection(value: any) {
		return this._http.get<any>(`${environment.apiUrl}collections/deactivateCollection/${value}`);
	}
	
	reactiveDisabledCollection(value: any) {
		return this._http.get<any>(`${environment.apiUrl}collections/reactiveDisabledCollection/${value}`);
	}
	
}
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { AdminService } from '../services/admin.service';
import { CommunicationService } from '../services/communication.service';
import { NewsletterserviceService } from '../services/newsletterservice.service';
import { environment } from 'src/environments/environment';
import { SwPush } from '@angular/service-worker';

@Component({
	selector: 'app-adminoutlet',
	templateUrl: './adminoutlet.component.html',
	styleUrls: ['./adminoutlet.component.css']
})
export class AdminoutletComponent implements OnInit {

	constructor( 
		private __authService: AuthService, 
		private __adminService: AdminService, 
		private _router: Router,
		private __communService: CommunicationService,
		private swPush: SwPush,
		private __newsletterService: NewsletterserviceService
	) { }

	readonly VAPID_PUBLIC_KEY = environment.webpush_public_key;

	subscribeToNotifications() {
        this.swPush.requestSubscription({
            serverPublicKey: this.VAPID_PUBLIC_KEY
        })
        .then(sub => 
			this.__newsletterService.addPushSubscriber(sub).subscribe()
		)
        .catch(err => console.error("Could not subscribe to notifications", err));
    }

	apiPublic: string = environment.apiPublic;

	adminData: any = [];
	loading: boolean = false;

	ngOnInit(): void {
		let auth_token: any = localStorage.getItem('inkingi_auth_token');

		if (!auth_token || auth_token == null || auth_token == undefined) {
			this._router.navigate(['/adminlogin'])
		}

		let auth_token_data: any = 'Bearer '+auth_token;

		this.__adminService.check_auth_token(auth_token_data).subscribe(
			res => { console.warn('Processing admin . . .') },
			err => {
				localStorage.removeItem('inkingi_auth_token');
				this._router.navigate(['/adminlogin']);
			}
		)

		this.__adminService.getAdminData().subscribe(
			res => { console.trace('Processing admin . . .') },
			err => {
				localStorage.removeItem('inkingi_auth_token')
				this._router.navigate(['/adminlogin']);
			}
		)

		if (!this.__authService.loggedIn()) {
			this._router.navigate(['/adminlogin']);
		}

		if (this.__authService.loggedIn()) {
			this.getAdminData();
			this.getNotifications();
			this.loading = true;
			//allow admin to receive constant notifications
			this.subscribeToNotifications();
		}

	}

	getAdminData() {
		this.__adminService.getAdminData().subscribe(
			res => {
				this.loading = false;
				this.adminData = res.data;
				
				this.username = this.adminData.username;
				this.email = this.adminData.email;
			},
			err => {
				this.getAdminData();
			}
		)
	}

	allNotifications: any = [];
	hasErrorAlert: boolean = false;
	hasErrorMsg: string = '';

	noNotifications: boolean = false;
	notificationNumber: number = 0;

	getNotifications() {
		this.loadingData = true;
		this.__communService.getAllNotification().subscribe(
			res => {
				this.notificationNumber = 0;
				this.loadingData = false;
				this.allNotifications.push(res.data);

				this.allNotifications[0].forEach((element:any) => {
					if (!element.notificationRead) {
						this.notificationNumber++;
					}	
				});

				if (this.allNotifications[0].length == 0) {
					this.noNotifications = true;
				}
			},	
			err => {
				this.loadingData = false;
				this.hasErrorMsg = 'Notifications failed';
				this.hasErrorAlert = true;
				
				let count = 0;

				let successInterval = setInterval(() => {
					if (count > 0) {
						this.hasErrorAlert = false;
						clearInterval(successInterval);
					}

					count = count + 1;
				}, 2000)
			}
		)
	}

	clearAllNotifications() {
		this.allNotifications = [];
		this.loadingData = true;
		this.__communService.clearAllNotifications().subscribe(
			res => {
				this.getNotifications();
				this.loadingData = true;
				this.successMsg = 'Notifications cleared successfully';
				this.isSuccess = true;
				
				let count = 0;

				let successInterval = setInterval(() => {
					if (count > 0) {
						this.isSuccess = false;
						clearInterval(successInterval);
					}

					count = count + 1;
				}, 2000)
			},
			err => {
				this.hasErrorMsg = 'Clearing failed!';
				this.hasErrorAlert = true;
				
				let count = 0;

				let successInterval = setInterval(() => {
					if (count > 0) {
						this.hasErrorAlert = false;
						clearInterval(successInterval);
					}

					count = count + 1;
				}, 2000)
			}
		)
	}

	loadingData: boolean = false;

	markRead(value: any) {
		this.allNotifications = [];
		this.loadingData = true;
		this.__communService.markNotificationRead(value).subscribe(
			res => {
				this.getNotifications();
				this.loadingData = true;
				this.successMsg = 'Notifications read status changed';
				this.isSuccess = true;
				
				let count = 0;

				let successInterval = setInterval(() => {
					if (count > 0) {
						this.isSuccess = false;
						clearInterval(successInterval);
					}

					count = count + 1;
				}, 2000)
			},
			err => {
				this.hasErrorMsg = 'Marking read failed!';
				this.hasErrorAlert = true;
				
				let count = 0;

				let successInterval = setInterval(() => {
					if (count > 0) {
						this.hasErrorAlert = false;
						clearInterval(successInterval);
					}

					count = count + 1;
				}, 2000)
			}
		)
	}

	menutoggled: boolean = false;
	profileOpen: boolean = false;
	notificationsOpen: boolean = false;
	expanded_sidebar: boolean = false;

	toggleMenu() {
		this.menutoggled = !this.menutoggled;
	}

	toggleDestopMenu() {
		this.expanded_sidebar = !this.expanded_sidebar;
	}

	toggleProfile(event: any) {
		if (event.target !== event.currentTarget) return;
		this.profileOpen = !this.profileOpen;
	}

	toggleNotification(event: any) {
		if (event.target !== event.currentTarget) return;
		this.notificationsOpen = !this.notificationsOpen;
	}

	logout() {
		this.__authService.logout();
		this._router.navigate(['/adminlogin']);
	}

	//updating logic

	username: string = '';
	email: string = '';
	currentpassword: string = '';
	newpassword: string = '';
	hasErrors: boolean = false;
	errors: string = '';
	regularExpression = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

	gettingUsername(value: string) {
		this.username = value;

		if (this.username.length > 3) {
			this.hasErrors = false;
		}
	}

	gettingEmail(value: string) {
		this.email = value;

		if (this.hasErrors) {
			const valid = this.regularExpression.test(String(value).toLowerCase());
			if (!valid) {
				this.hasErrors = false;
			}
		}
		
		if (this.email.length > 5) {
			this.hasErrors = false;
		}
	}

	gettingCurrentpassword(value: string) {
		this.currentpassword = value;
	}

	gettingNewpassword(value: string) {
		this.newpassword = value;
	}

	fileName: string = '';
	profilePicture: any;
	hasSubmitted: boolean = false;
	successMsg: string = '';
	isSuccess: boolean = false;

	detectFileSelected(event: any) {
		const profilePicture: any = event.target.files[0];
		
		if (profilePicture) {
			this.fileName = profilePicture.name;
			this.profilePicture = profilePicture;
		}
	}

	updateAdminProfile() {

		if (this.username == '') {
			this.hasErrors = true;
			return this.errors = "Username can not be empty!";
		}
		
		if (this.username.length < 3) {
			this.hasErrors = true;
			return this.errors = "Username should be atleast 3 characters long!";
		}	

		if (this.email.length < 5) {
			this.hasErrors = true;
			return this.errors = "Email should be atleast 5 characters long!";
		}

		if (this.email == '') {
			this.hasErrors = true;
			return this.errors = "Email address can not be empty!";
		}

		if (this.email != "") {
			const valid = this.regularExpression.test(String(this.email).toLowerCase());
			if (!valid) {
				this.errors = "Invalid email address!"
				return this.hasErrors = true;
			}
		}

		if (this.currentpassword != '' || this.newpassword != '') {
			if (this.newpassword !== this.currentpassword) {
				this.hasErrors = true;
				return this.errors = "Passwords are not matching!";
			}

			if (this.newpassword.length < 6) {
				this.hasErrors = true;
				return this.errors = "Password must be atleat 6 characters long.";
			}
		}

		this.hasErrors = false;
		this.hasSubmitted = true;

		let formData: FormData = new FormData()
		formData.append('username', this.username);
		formData.append('email', this.email);
		formData.append('password', this.newpassword);
		formData.append('profilePicture', this.profilePicture);
		
		this.__adminService.updateAdmin(formData).subscribe(
			res => {
				this.getAdminData();
				this.hasSubmitted = false;
				this.profileOpen = false;
				this.successMsg = 'Updated profile successfully';
				this.isSuccess = true;
				
				let count = 0;

				let successInterval = setInterval(() => {
					if (count > 0) {
						this.isSuccess = false;
						clearInterval(successInterval);
					}

					count = count + 1;
				}, 2000)
			},
			err => {

				this.hasErrors = true;
				this.hasSubmitted = false;
				this.errors = err.error.message;
			}
		)
	}

}
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  	name: 'ordersproductnamefiltering'
})
export class OrdersproductnamefilteringPipe implements PipeTransform {

	transform(value: any) {
		let product_numbers:number = 0;

		value.forEach((element: any) => {
			product_numbers = element.number + product_numbers;
		});

		return product_numbers;
	}

}
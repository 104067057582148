import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  	name: 'ordersizedefiner'
})
export class OrdersizedefinerPipe implements PipeTransform {

	transform(value: any) {
		let product_sizes:number = 0;
		let sizes: any = [];

		value.forEach((element: any) => {
			if (!sizes.includes(element.size)) {
				product_sizes = product_sizes + 1;
			}
			sizes.push(element.sizes)
		});

		return product_sizes;
	}
}
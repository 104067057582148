<div id="entire_unsubscribe_page">

    <div *ngIf="loading" class="loader">
        <app-loader></app-loader>
    </div>

    <div *ngIf="isSuccess || hasErrorAlert" class="success_message">
        <div class="msg_box" [ngClass]="{'isFailed': hasErrorAlert}">
            <p *ngIf="isSuccess">{{ successMsg }}</p>
            <p *ngIf="hasErrorAlert">{{ hasErrorMsg }}</p>
            <svg *ngIf="isSuccess" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-.997-4L6.76 11.757l1.414-1.414 2.829 2.829 5.656-5.657 1.415 1.414L11.003 16z" fill="rgba(255,255,255,1)"/></svg>
            <svg *ngIf="hasErrorAlert" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-9.414l2.828-2.829 1.415 1.415L13.414 12l2.829 2.828-1.415 1.415L12 13.414l-2.828 2.829-1.415-1.415L10.586 12 7.757 9.172l1.415-1.415L12 10.586z" fill="rgba(255,255,255,1)"/></svg>
        </div>
    </div>

    <div class="content_box col-mob-12">
        <img (click)="navigatetohome()" class="logo" src="../../../assets/app/light_logo.png" alt="">
      
        <img class="payment_image" src="../../../assets/app/payment_success.png" alt="">
        <!-- <img class="payment_image" src="https://ouch-cdn2.icons8.com/_l5wAFPq8mZFD5kynk3kZLwpa-vMeO0EW_WPH1NoSsU/rs:fit:912:912/czM6Ly9pY29uczgu/b3VjaC1wcm9kLmFz/c2V0cy9zdmcvNzA0/LzQ5ZDg3NTdiLTdm/MTgtNDI4MC1iNjFi/LWQ5NjVmYzhhYTUy/ZS5zdmc.png" alt=""> -->
        <!-- <img src="https://ouch-cdn2.icons8.com/8cqe15SqSjNzAc-rn6TkpTCmdcwzoJLmlSrpUEhHi1I/rs:fit:1216:912/czM6Ly9pY29uczgu/b3VjaC1wcm9kLmFz/c2V0cy9wbmcvODIv/MjkxMGNiNzUtZWJi/Mi00ZGQxLWFjNzQt/Yzg3NTE5YzEyNDM0/LnBuZw.png"/>     -->

        <h4>Payment completed successfully</h4>
        <p>Consider getting your receipt via you email address specified during checkout.</p>
        <button (click)="navigatetoshop()">
            Go Back To Shop
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="18" height="18"><path fill="none" d="M0 0h24v24H0z"/><path class="navbar_icons" fill="#ffffff" d="M4 6.414L.757 3.172l1.415-1.415L5.414 5h15.242a1 1 0 0 1 .958 1.287l-2.4 8a1 1 0 0 1-.958.713H6v2h11v2H5a1 1 0 0 1-1-1V6.414zM6 7v6h11.512l1.8-6H6zm-.5 16a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm12 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z"/></svg>
        </button>
    </div>

</div>
<div id="entire_application__single_product_component">
    <div *ngIf="loading" class="loader">
        <app-loader></app-loader>
    </div>
    <div class="cookies_disabled" *ngIf="cookies_disabled">
        <div class="cookie_modal_container col-mob-11">
            <div class="image">
                <img src="../../../assets/app/cookies.png" alt="">
            </div>
            <div class="message">
                <h1>You must enable cookies</h1>
                <p>Inkingi fashion design shop uses browser cookies and yours have been disabled please enable them to continue shopping.</p>
                <button (click)="enableBrowserCookies()">Edit settings by <strong>&nbsp;ALT + F&nbsp;</strong> then <strong>&nbsp;S&nbsp;</strong></button>
            </div>
        </div>
    </div>

    <div *ngIf="isSuccess || hasErrorAlert" class="success_message">
        <div class="msg_box" [ngClass]="{'isFailed': hasErrorAlert}">
            <p *ngIf="isSuccess">{{ successMsg }}</p>
            <p *ngIf="hasErrorAlert">{{ hasErrorMsg }}</p>
            <svg *ngIf="isSuccess" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-.997-4L6.76 11.757l1.414-1.414 2.829 2.829 5.656-5.657 1.415 1.414L11.003 16z" fill="rgba(255,255,255,1)"/></svg>
            <svg *ngIf="hasErrorAlert" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-9.414l2.828-2.829 1.415 1.415L13.414 12l2.829 2.828-1.415 1.415L12 13.414l-2.828 2.829-1.415-1.415L10.586 12 7.757 9.172l1.415-1.415L12 10.586z" fill="rgba(255,255,255,1)"/></svg>
        </div>
    </div>

    <div class="single_product__container">

        <div class="product_samples_details_container">
            <div class="product_samples col-mob-12 col-tab-12 col-large-12">
                <div class="sample_slide">
                    <div class="samples_container col-mob-12">
                        <div class="sample" (click)="changeMainImage(productdata.productMainImage)" [ngClass]="{'--currentCloth': productMainImage == productdata.productMainImage}">
                            <img [src]="productdata.productMainImage" alt="">
                        </div>
                        <div *ngFor="let productImg of productdata.productSubImages; let i = index" [ngClass]="{'--currentCloth': productMainImage == productImg}" (click)="changeMainImage(productImg)" class="sample">
                            <img [src]="productImg" alt="">
                        </div>
                    </div>
                </div>
                <div class="sample_current_slide col-mob-12">
                    <div class="current_sample col-mob-12">
                        <img id="current_image" [src]="productMainImage" alt="">
                    </div>
                </div>
            </div>
            <div class="product_details col-mob-12 col-tab-12 col-large-12">
                <div class="product_descript">
                    <h1>{{ productdata.productName }}</h1>
                </div>
                <div class="reviews">
                    <p>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="18" height="18"><path fill="none" d="M0 0H24V24H0z"/><path d="M12.001 4.529c2.349-2.109 5.979-2.039 8.242.228 2.262 2.268 2.34 5.88.236 8.236l-8.48 8.492-8.478-8.492c-2.104-2.356-2.025-5.974.236-8.236 2.265-2.264 5.888-2.34 8.244-.228z" fill="rgba(255,56,52,1)"/></svg>
                        ( {{ productdata.productLikes }} Customers liked this )
                    </p>
                </div>
                <div class="prices">
                    <h3 *ngIf="!loadingPrice"> 
                        <strong *ngIf="currency == 'usd'">$ </strong> 
                        <strong *ngIf="currency == 'gbp'">&pound; </strong> 
                        {{ productdata.productPrice | currencies:currency:exchangeValue | number }} 
                        <span *ngIf="currency == 'rwf'">RWF</span>
                    </h3>
                    <h3 *ngIf="loadingPrice"><span>calculating price . . .</span></h3>
                </div>
                <div class="more">
                    <p>{{ productdata.productDescription }}</p>
                </div>
                <div class="sizes_colors">
                    <div class="colors col-mob-12">
                        <div *ngFor="let productColor of productdata.productColors; let i = index" (click)="changeMainImageColor(productColor[0], productColor[1])" [ngClass]="{'--currentColor': productMainImage == productColor[0]}" class="color">
                            <div class="color_value" [style.background]="productColor[1]" style="width: 90%; height: 90%; border-radius: 50%; display: flex; align-items: center; justify-content: center;">
                                <svg *ngIf="productMainImage == productColor[0]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z" fill="rgba(255,255,255,1)"/></svg>         
                            </div>
                        </div>
                        <p style="font-size: 0.76em; opacity: 0.3;" *ngIf="productdata.productColors.length == 0">No colors specified</p>
                    </div>
                    <div class="sizes  col-mob-12">
                        <div [ngClass]="{'active': clothSize == 's'}" (click)="changeClothSize('s')" class="size s">S</div>
                        <div [ngClass]="{'active': clothSize == 'm'}" (click)="changeClothSize('m')" class="size m">M</div>
                        <div [ngClass]="{'active': clothSize == 'l'}" (click)="changeClothSize('l')" class="size l">L</div>
                        <div [ngClass]="{'active': clothSize == 'xl'}" (click)="changeClothSize('xl')" class="size xl">XL</div>
                        <div [ngClass]="{'active': clothSize == 'xxl'}" (click)="changeClothSize('xxl')" class="size xxl">XXL</div>
                    </div>
                </div>
                <div class="add_to_cart" [ngClass]="{'out_of_stock': productdata.quantityInStock <= 0}">
                    <input #num (input)="getNumberOfItems(num.value)" type="number" value="1" min="1">
                    <button [disabled]="addingToChat || productdata.quantityInStock <= 0" (click)="addToCart(productdata._id, productdata.quantityInStock, productdata.productDeleted)">
                        <span *ngIf="!addingToChat && productdata.quantityInStock > 0">Add to shopping cart</span> 
                        <span *ngIf="addingToChat">
                            <svg class="spinner_loading" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path fill="#ffffff" d="M18.364 5.636L16.95 7.05A7 7 0 1 0 19 12h2a9 9 0 1 1-2.636-6.364z"/></svg>
                            &nbsp;Adding . . .
                        </span>   
                        <span *ngIf="productdata.quantityInStock <= 0">
                            Product sold out
                        </span>
                    </button>
                </div>
                <div class="all_details">
                    <p>Product: <span>{{ productdata.productName }}</span></p>
                    <p>Cateogry: <span>{{ productdata.productCategory }}</span></p>
                    <p>Tag: 
                        <span *ngIf="productdata.productTag == 'full'" >Full body</span>
                        <span *ngIf="productdata.productTag == 'upper'">Upper body</span>
                        <span *ngIf="productdata.productTag == 'lower'">Lower body</span>
                        <span *ngIf="productdata.productTag == 'head'">Head wear</span>
                        <span *ngIf="productdata.productTag == 'foot'">Foot wear</span>
                    </p>
                </div>
            </div>
        </div>

        <div class="tips">
            <div class="tips_details col-mob-12">
                <h2>Details & Care Tips</h2>
                <ul>
                    <li *ngFor="let productTip of productdata.productTips; let i = index" [hidden]="productTip == ''" [ngClass]="{'--ng_do_not_tample_384_bc_gn': productTip == ''}">
                        {{ productTip | trimtips }}
                    </li>
                    <span *ngIf="productdata.productTips.length <= 1" style="opacity: 0.5; font-size: 0.8em; margin-top: 2em;">No tips specified to this product</span>
                </ul>
            </div>
            <div class="shipping_tips col-mob-12">
                <h2>Shipping, Delivery & Return Procedures</h2>
                <p>Your order will be shipped to the addresses specified by you 
                    during the order process. The delivery takes up to 15 calendar 
                    days. Inkingi is not responsible for any loss of the products 
                    following shipment from premises to your delivery address. We 
                    encourage you to immediately indicate when you receive a
                    damaged package or refuse it altogether. Learn More Please note 
                    that you may be required to pay import duties and taxes, which
                    are levied once a shipment reaches your destination.
                </p>
            </div>
        </div>

    </div>
</div>
import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  	providedIn: 'root'
})
export class ProductsService {

  	constructor( private _http: HttpClient ) { }

	registerNewProduct(data: any) {
		return this._http.post<any>(`${environment.apiUrl}products/addProduct`, data);
	}

	getAllProducts() {
		return this._http.get<any>(`${environment.apiUrl}products/getAllProducts`);
	}

	getAllProductsLimitedToANumber() {
		return this._http.get<any>(`${environment.apiUrl}products/getAllProductsLimitedToANumber`);
	}

	getAllProductsRespectToDates() {
		return this._http.get<any>(`${environment.apiUrl}products/getAllProductsRespectToDates`);
	}

	getMenProducts() {
		return this._http.get<any>(`${environment.apiUrl}products/getMenProducts`);
	}

	getWomenProducts() {
		return this._http.get<any>(`${environment.apiUrl}products/getWomenProducts`);
	}

	getProductById(value: any) {
		return this._http.get<any>(`${environment.apiUrl}products/getProductById/${value}`);
	}

	updateProductById(data: any, product_id: any) { 
		return this._http.put<any>(`${environment.apiUrl}products/updateProduct/${product_id}`, data);
	}

	reduceQuantityInStock(value: any, data: any) {
		return this._http.post<any>(`${environment.apiUrl}products/reduceQuantityInStock/${value}`, data);
	}

	increaseQuantityInStock(value: any, data: any) {
		return this._http.post<any>(`${environment.apiUrl}products/increaseQuantityInStock/${value}`, data);
	}

	likeProduct(value: any) {
		return this._http.get<any>(`${environment.apiUrl}products/likeProduct/${value}`);
	}

	dislikeProduct(value: any) {
		return this._http.get<any>(`${environment.apiUrl}products/dislikeProduct/${value}`);
	}

	deleteProduct(value: any) {
		return this._http.delete<any>(`${environment.apiUrl}products/deleteProduct/${value}`);
	}

	deleteDisableProduct(value: any) {
		return this._http.get<any>(`${environment.apiUrl}products/deleteDisableProduct/${value}`);
	}

	reactiveDisabledProduct(value: any) {
		return this._http.get<any>(`${environment.apiUrl}products/reactiveDisabledProduct/${value}`);
	}

	//currencies conversions
	public currentCurrency: string = 'rwf';

	updateExchangeRates: any = new EventEmitter();

	setCurrentCurrency(value: string) {
		document.cookie = `_currencynw=${value}; path=/; max-age=` + 2147483647;
		this.updateExchangeRates.emit(this.getCurrentCurrency);
		return this.currentCurrency = value;
	}

	getCurrentCurrency() {
		var cookieName = "_currencynw";
		var matchCookie = document.cookie.match(new RegExp('(^| )' + cookieName + '=([^;]+)'));

		if (matchCookie) {
			this.currentCurrency = matchCookie[2];
		}

		if (!matchCookie) {
			document.cookie = `_currencynw=rwf; path=/; max-age=` + 2147483647;
			this.currentCurrency = 'rwf';
		}

		return this.currentCurrency;
	}

	getCurrencies() {
		return this._http.get<any>(`${environment.apiUrl}currencies/getCurrencies`);
	}

}
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CommunicationService } from 'src/app/services/communication.service';
import { CollectionsService } from '../../services/collections.service'

@Component({
	selector: 'app-adminaddcollection',
	templateUrl: './adminaddcollection.component.html',
	styleUrls: ['./adminaddcollection.component.css']
})
export class AdminaddcollectionComponent implements OnInit {

	constructor(
		private __collectionsService: CollectionsService, 
		private _router: Router,
		private __communService: CommunicationService
	) { }

	ngOnInit(): void {
	}

  	hasSubmitted: boolean = false;
	isSuccess: boolean = false;
	successMsg: string = '';
	hasErrors: boolean = false;
	errors: string = '';

	collectionName: string = '';
	collectionDescription: string = '';
	mainCollectionImage: any;
	otherCollectionImages: any = [];

	mainCollectionImageBlob: any;
	otherCollectionimagesBlobs: any = [];

	//status
	mainCollectionImageDone: boolean = false;
	otherCollectionimagesDone: boolean = false;

	nameRequired: boolean = false;
	descriptionRequired: boolean = false;

	formData: FormData = new FormData();

	getMainCollectionPicture(event: any) {
		if (this.mainCollectionImage != null) {
			this.formData.delete('mainCollectionImage');
		}
		this.mainCollectionImageBlob = null;
		this.mainCollectionImage = null;
		this.mainCollectionImageDone = false;

		const mainCollectionImage: any = event.target.files[0];

		if (mainCollectionImage) {
			
			this.mainCollectionImage = mainCollectionImage;
			this.formData.append('mainCollectionImage', this.mainCollectionImage);
			
			this.addMainCollectionImageBlobs();
			
		}
	}

	addMainCollectionImageBlobs() {
		let fileReader = new FileReader();
		fileReader.addEventListener('load', () => {
			this.mainCollectionImageBlob = fileReader.result;
		})
		fileReader.readAsDataURL(this.mainCollectionImage);	

		this.mainCollectionImageDone = true;
	}

	getOtherCollectionPictures(event: any) {
		if (this.otherCollectionImages.length > 0) {
			this.formData.delete('otherCollectionImages');
		}
		this.otherCollectionimagesBlobs = [];
		this.otherCollectionImages = [];
		this.otherCollectionimagesDone = false;

		const othercollectionimages: any = Array.from(event.target.files);

		if (othercollectionimages) {
			
			this.otherCollectionImages.push(othercollectionimages);
			Array.from(othercollectionimages).forEach((item: any) => {
				this.formData.append('otherCollectionImages', item);
			})
			this.addOtherCollectionImagesBlobs();
			
		}
	}

	addOtherCollectionImagesBlobs() {
		for(let i= 0; i < this.otherCollectionImages[0].length; i++) {
			let fileReader = new FileReader();
			fileReader.addEventListener('load', () => {
				this.otherCollectionimagesBlobs.push(fileReader.result);
			})
			fileReader.readAsDataURL(this.otherCollectionImages[0][i]);		
		}

		this.otherCollectionimagesDone = true;
	}

	getCollectionName(value: any) {
		this.nameRequired = false;

		this.collectionName = value;
	}

	getCollectionDescription(value: any) {
		this.descriptionRequired = false;

		this.collectionDescription = value;
	}

	createCollection() {
	
		this.hasErrors = false;
		this.descriptionRequired = false;
		this.nameRequired = false;
		
		if (this.mainCollectionImage == null || this.mainCollectionImage == undefined) {
			this.hasErrors = true;
			this.errors = 'Main collection image is required!';
			window.scrollTo(0,0);
			return;
		} 

		if (this.otherCollectionImages.length == 0 || this.otherCollectionImages.length < 1) {
			this.hasErrors = true;
			this.errors = 'Other collection images are required!';
			window.scrollTo(0,0);
			return;
		}

		if (this.collectionName == '') {
			this.hasErrors = true;
			this.nameRequired = true;
			this.errors = 'Collection name is required!';
			window.scrollTo(0,0);
			return;
		}

		if (this.collectionDescription == '') {
			this.hasErrors = true;
			this.descriptionRequired = true;
			this.errors = 'Collection description is required!';
			window.scrollTo(0,0);
			return;
		}

		this.hasSubmitted = true;
		this.formData.set("collectionName", this.collectionName);
		this.formData.set("collectionDescription", this.collectionDescription);

		this.__collectionsService.registerNewCollection(this.formData).subscribe(
			res => {
				this.hasSubmitted = false;
				this.isSuccess = true;
				this.successMsg = 'Collection added successfully';
				this.showThisNewsToSubscribers(res.data._id);

				let count = 0;
				
				let successInterval = setInterval(() => {
					if (count > 0) {
						this.isSuccess = false;						
						clearInterval(successInterval);
						this._router.navigate(['/admin/collections']);
						var name: any = document.getElementById('name');
						var descript: any = document.getElementById('descript');
						name.value = '';
						descript.value = '';

						this.formData.delete;
					}

					count = count + 1;
				}, 2000)

			},
			err => {
				this.hasSubmitted = false;
				this.hasErrors = true;
				
				if (err.error.message) {
					this.errors = err.error.message;
				} else if (err.message) {
					this.errors = err.message;
				} else if (err.error.text) {
					this.errors = err.error.text;
				} else {
					this.errors = "Something went wrong. Try again!"
				}
				window.scroll(0,0)
			}
		)

	}

	showThisNewsToSubscribers(value: any) {
		let news_data: any = {
			"category": 'new_collection',
			"collection_id": `${value}`
		}

		this.__communService.emailAboutNewCollection(news_data).subscribe(
			res => {},
			err => {}
		)
	}

	cancelSubmissionHere() {
		this._router.navigate(['/admin/collections']);
	}

}
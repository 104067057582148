<div id="entire_admin_orders">
    <div *ngIf="isSuccess || hasErrorAlert" class="success_message">
        <div class="msg_box" [ngClass]="{'isFailed': hasErrorAlert}">
            <p *ngIf="isSuccess">{{ successMsg }}</p>
            <p *ngIf="hasErrorAlert">{{ hasErrorMsg }}</p>
            <svg *ngIf="isSuccess" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-.997-4L6.76 11.757l1.414-1.414 2.829 2.829 5.656-5.657 1.415 1.414L11.003 16z" fill="rgba(255,255,255,1)"/></svg>
            <svg *ngIf="hasErrorAlert" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-9.414l2.828-2.829 1.415 1.415L13.414 12l2.829 2.828-1.415 1.415L12 13.414l-2.828 2.829-1.415-1.415L10.586 12 7.757 9.172l1.415-1.415L12 10.586z" fill="rgba(255,255,255,1)"/></svg>
        </div>
    </div>

    <div class="header">
        <div class="title col-mob-12">
            <p>Inkingi client sessions</p>
        </div>
        <div class="sort col-mob-12">
            <input type="text" placeholder="search session" #keyword (keyup)="searchAlgorithmActivity(keyword.value)">
        </div>
    </div>
    <div class="content">

        <div [ngClass]="{'isNavbarDownClassTables': pageScrollToNavbarDownPosition}" class="table_heading">
            <div class="count">
                <p>#</p>
            </div>
            <div class="id">
                <p>Location</p>
            </div>
            <div class="id">
                <p>Browser</p>
            </div>
            <div class="names">
                <p>Ip address</p>
            </div>
            <div class="status">
                <!-- <p>Mac address</p> -->
                <p>Lng and Lat</p>
            </div>
            <div class="amount">
                <p>Operating system</p>
            </div>
            <div class="time">
                <p>Time</p>
            </div>
        </div>

        <div *ngIf="loadingData" class="isLoadingData">
            <svg class="spinner_loading" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path fill="#000000" d="M18.364 5.636L16.95 7.05A7 7 0 1 0 19 12h2a9 9 0 1 1-2.636-6.364z"/></svg>
            <p>Loading . . .</p>
        </div>
        
        <div *ngIf="searching_loading && !loadingData" class="isLoadingData">
            <svg class="spinner_loading" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path fill="#000000" d="M18.364 5.636L16.95 7.05A7 7 0 1 0 19 12h2a9 9 0 1 1-2.636-6.364z"/></svg>
            <p>Searching . . .</p>
        </div>

        <p *ngIf="noSessionStatus" style="font-size: 0.83em; opacity: 0.7; margin: 7em auto; text-align: center;">No sessions yet</p>
        <p *ngIf="searchTermNotFound && !noSessionStatus" style="font-size: 0.83em; opacity: 0.7; margin: 7em auto; text-align: center;">Oops! No such sessions available</p>

        <div class="table_content" *ngFor="let session of sessions[0]; let i = index" (click)="currentRowView(session._id)" [ngClass]="{'current_viewed': session._id==currentViewedRow}">
            <div class="count">
                <p>{{ i+1 }}</p>
            </div>
            <div class="id">
                <p>{{ session.location}}</p>
            </div>
            <div class="names">
                <p>{{ session.browser }}</p>
            </div>
            <div class="status">
                <p>{{ session.ip_address }}</p>
            </div>
            <div class="amount">
                <p>{{ session.lng_lat | lnglattrim}}</p>
            </div>
            <div class="time">
                <p>{{ session.operating_system }}</p>
            </div>
            <div class="time">
                <p>{{ session.time | timetrim }}</p>
            </div>
        </div>

    </div>
</div>

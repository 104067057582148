import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  	name: 'timetrim'
})
export class TimetrimPipe implements PipeTransform {

	transform(value: string) {
		let date: any = value.split('T')[0];
		let time: any = value.split('T')[1].split('.')[0]
		let return_time: any = date+' - '+time

		return return_time;
	}

}
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { ClientoutletComponent } from './clientoutlet/clientoutlet.component';
import { HomeComponent } from './home/home.component';
import { CartComponent } from './components/cart/cart.component';
import { ClothingsComponent } from './components/clothings/clothings.component';
import { ContactComponent } from './components/contact/contact.component';
import { MencollectionsComponent } from './components/mencollections/mencollections.component';
import { SearchComponent } from './components/search/search.component';
import { ShopComponent } from './components/shop/shop.component';
import { WomencollectionsComponent } from './components/womencollections/womencollections.component';
import { SingleproductComponent } from './components/singleproduct/singleproduct.component';
import { CheckoutComponent } from './components/checkout/checkout.component';
import { ShippingComponent } from './components/shipping/shipping.component';
import { EditshippingComponent } from './components/editshipping/editshipping.component';
import { PaymentComponent } from './components/payment/payment.component';
import { UnsubscribeComponent } from './components/unsubscribe/unsubscribe.component';
import { SinglecollectionComponent } from './components/singlecollection/singlecollection.component';
import { SuccessfullpaymentComponent } from './components/successfullpayment/successfullpayment.component';
import { PaymentfailedComponent } from './components/paymentfailed/paymentfailed.component';

import { AdminoutletComponent } from './adminoutlet/adminoutlet.component';
import { AdmindashboardComponent } from './components/admindashboard/admindashboard.component';
import { AdminallordersComponent } from './components/adminallorders/adminallorders.component';
import { AdmincompleteordersComponent } from './components/admincompleteorders/admincompleteorders.component';
import { AdminallproductsComponent } from './components/adminallproducts/adminallproducts.component';
import { AdminsubscribersComponent } from './components/adminsubscribers/adminsubscribers.component';
import { AdmincontactedComponent } from './components/admincontacted/admincontacted.component';
import { AdminaddproductComponent } from './components/adminaddproduct/adminaddproduct.component';
import { AdmineditproductComponent } from './components/admineditproduct/admineditproduct.component'
import { AdminauthComponent } from './components/adminauth/adminauth.component';
import { AdmincollectionsComponent } from './components/admincollections/admincollections.component';
import { AdmincartsComponent } from './components/admincarts/admincarts.component';
import { AdmincustomizationComponent } from './components/admincustomization/admincustomization.component';
import { AdminsessionsComponent } from './components/adminsessions/adminsessions.component';
import { AdmintransitionsComponent } from './components/admintransitions/admintransitions.component';
import { AdminaddcollectionComponent } from './components/adminaddcollection/adminaddcollection.component';
import { AdmineditcollectionComponent } from './components/admineditcollection/admineditcollection.component';
import { AdminpendingordersComponent } from './components/adminpendingorders/adminpendingorders.component';

import { AuthGuard } from './common/auth.guard';

const routes: Routes = [

	{
		path: '',
		component: ClientoutletComponent,
		children: [

			{
				path: '',
				component: HomeComponent
			},
			{
				path: 'cart',
				component: CartComponent
			},
			{
				path: 'men',
				component: MencollectionsComponent
			},
			{
				path: 'collections',
				component: ClothingsComponent
			},
			{
				path: 'women',
				component: WomencollectionsComponent
			},
			{
				path: 'shop',
				component: ShopComponent
			},
			{
				path: 'search/:term',
				component: SearchComponent
			},
			{
				path: 'contact',
				component: ContactComponent
			},
			{
				path: 'product/:prodid',
				component: SingleproductComponent
			},
			{
				path: 'checkout',
				component: CheckoutComponent
			},
			{
				path: 'shipping',
				component: ShippingComponent
			},
			{
				path: 'editshipping',
				component: EditshippingComponent
			},
			{
				path: 'payment',
				component: PaymentComponent
			},
			{
				path: 'collection/:collectionname',
				component: SinglecollectionComponent
			},
			{
				path: '',
				redirectTo: '',
				pathMatch: 'full'
			}

		]
	},
	{
		path: 'admin',
		component: AdminoutletComponent,
		canActivate: [AuthGuard],
		children: [

			{
				path: 'dashboard',
				component: AdmindashboardComponent 
			},
			{
				path: 'allorders',
				component: AdminallordersComponent
			},
			{
				path: 'pendingorders',
				component: AdminpendingordersComponent
			},
			{
				path: 'completeorders',
				component: AdmincompleteordersComponent
			},
			{
				path: 'products',
				component: AdminallproductsComponent
			},
			{
				path: 'subscribers',
				component: AdminsubscribersComponent
			},
			{
				path: 'contacted',
				component: AdmincontactedComponent
			},
			{
				path: 'addproduct',
				component: AdminaddproductComponent
			},
			{
				path: 'editproduct/:productId',
				component: AdmineditproductComponent
			},
			{
				path: 'sessions',
				component: AdminsessionsComponent
			},
			{
				path: 'transactions',
				component: AdmintransitionsComponent
			},
			{
				path: 'customization',
				component: AdmincustomizationComponent
			},
			{
				path: 'collections',
				component: AdmincollectionsComponent
			},
			{
				path: 'editcollection/:collectionId',
				component: AdmineditcollectionComponent
			},
			{
				path: 'addcollection',
				component: AdminaddcollectionComponent
			},
			{
				path: 'carts',
				component: AdmincartsComponent
			},
			{
				path: '',
				redirectTo: 'dashboard',
				pathMatch: 'full'
			}

		]
	},
	{
		path: 'adminlogin',
		component: AdminauthComponent
	},
	{
		path: 'unsubscribe/:id',
		component: UnsubscribeComponent
	},
	{
		path: 'successpayment/:status/:tx_ref/:transaction_id',
		component: SuccessfullpaymentComponent
	},
	{
		path: 'paymentfailed/:status',
		component: PaymentfailedComponent
	},
	{
		path: '',
		redirectTo: '',
		pathMatch: 'full'
	},
	{
		path: '**',
		component: PageNotFoundComponent
	}

];

@NgModule({
	imports: [RouterModule.forRoot(routes,{scrollPositionRestoration: 'enabled'})],
	exports: [RouterModule]
})
export class AppRoutingModule { }
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CartService } from '../../services/cart.service';
import { ProductsService } from '../../services/products.service';
import { environment } from 'src/environments/environment';
import { CustomsService } from 'src/app/services/customs.service';
import { OrdersService } from '../../services/orders.service';
import { PaymentsService } from 'src/app/services/payments.service';

@Component({
	selector: 'app-shipping',
	templateUrl: './shipping.component.html',
	styleUrls: ['./shipping.component.css']
})
export class ShippingComponent implements OnInit {

	constructor( 
		private __cartService: CartService, 
		private __customsService: CustomsService, 
		private _router: Router, 
		private __productsService: ProductsService,
		private __ordersService: OrdersService,
		private __paymentsServices: PaymentsService
	) { }

	apiPublic: string = environment.apiPublic;
	loading: boolean = true;

	ngOnInit(): void {

		const checkoutToken:any = localStorage.getItem('__checkout_credt_id');

		if (checkoutToken == null) {
			this._router.navigate(['/checkout']);
		}

		const paymentToken: any = localStorage.getItem('__payment_token');

		if (paymentToken != null) {
			this._router.navigate(['/payment']);
		}

		this.getMyOrder();
		this.getCurrentCurrency();
		this.getAllCurrencies();

		this.__customsService.updatePageStatus(false);

		this.__productsService.updateExchangeRates.subscribe(
			(res:any) => {
				this.currency = this.__productsService.getCurrentCurrency();
			}, (err:any) => console.log(err) 
		);

		this.__cartService.getMyShoppingCart().then(data => {
			this.shouldCreateCart = data;
		
			if (this.shouldCreateCart) {
				this.createNewCart();
			} 
			
			if (!this.shouldCreateCart) {
				var cookieid: any = document.cookie.match(new RegExp('(^| )' + '_mycart' + '=([^;]+)'));
				this.getMyShoppingCart(cookieid[2]);
			}
	
		});
	}

	myorderdata: any;

	getMyOrder() {
		var token: any = localStorage.getItem('__checkout_credt_id');
		this.__ordersService.getSpecificOrder(token).subscribe(
			res => {
				this.loading = false;
				if (res.data.pickupmethod == 'pick') {
					this._router.navigate(['/payment']);
				} else {
					this.myorderdata = res.data;
				}
			},
			err => {
				this.getMyOrder()
			}
		)
	}

	
	currency: any = 'rwf';
	exchangeValue: any = 0;
	exchangeRates: any;
	loadingPrice: boolean = true;
	reprocessingcurrencies: number = 0;

	getCurrentCurrency() {
		this.currency = this.__productsService.getCurrentCurrency();
	}

	getAllCurrencies() {

		this.__productsService.getCurrencies().subscribe(
			res => {
				this.exchangeRates = res.data;
				this.exchangeValue = this.exchangeRates;
				this.loadingPrice = false;
			},
			err => {

				if (this.reprocessingcurrencies <= 2) {
					this.getAllCurrencies();
					this.reprocessingcurrencies++;
				} else {
					alert('Having trouble in processing currencies! Reprocessing . . .');
					location.reload();
				}

			})

	}

	shippingMethodPickup: string = 'pick';

	changeShippingMethod(value: string) {
		this.shippingMethodPickup = value;
	}

	myCart: any = [];
	shouldCreateCart: any;

	isSuccess: boolean = false;
	successMsg: string = '';
	hasSubmitted: boolean = false;
	hasErrorAlert: boolean = false;
	hasErrorMsg: string = '';
	
	loadingData: boolean = true;

	getMyShoppingCart(value: any) {

		this.myCart = [];
		this.finalProductsInCart = [];
		this.thisItem = [];
		this.firstlevelItems = [];
		this.firstlevelProds = [];

		let data = value;

		this.__cartService.getSpecificCart(data).subscribe(
			res => {
				this.myCart = res.data;
				
				if (this.myCart.length != 0) {
					this.getEachCartItem();
				}

				if (this.myCart.products.length == 0) {
					this.hasErrorAlert = true;
					this.hasErrorMsg = "You cart is empty put some items!"
					
					let count = 0;
	
					let successInterval = setInterval(() => {
						if (count > 0) {
							this.hasErrorAlert = false;
							clearInterval(successInterval);
							this._router.navigate(['/shop']);				
						}
						count = count + 1;
					}, 3000)
				}

			},
			err => {
				// this.loading = false;
				this.hasErrorAlert = true;
				this.hasErrorMsg = "You cart is not found!"
				
				let count = 0;

				let successInterval = setInterval(() => {
					if (count > 0) {
						this.hasErrorAlert = false;
						clearInterval(successInterval);
						this.createAlert()					
					}
					count = count + 1;
				}, 3000)
			}
		)
	}

	createAlert() {
		this.isSuccess = true;
		this.successMsg = "Don't worry we are creating a new one for you"
		
		let countTwo = 0;

		let successIntervalTwo = setInterval(() => {
			if (countTwo > 0) {
				this.isSuccess = false;
				clearInterval(successIntervalTwo);
				this.createNewCart();
			}
			countTwo = countTwo + 1;
		}, 3000)
	}

	createNewCart() {
		let newCartData = {
			"products": []
		};

		this.__cartService.createNewCart(newCartData).subscribe(
			res => {
				document.cookie = `_mycart=${res.data._id}; path=/; max-age=` + 2147483647;
				this.getMyShoppingCart(res.data._id)
			},
			err => {
				this.createNewCart();
			}
		)
	}


	finalProductsInCart: any = [];

	thisItem: any = [];
	firstlevelItems: any = [];
	firstlevelProds: any = [];
	
	async getEachCartItem() {

		const promises = this.myCart.products.map(async (element: any) => {
			this.thisItem = [
				element.size, 
				element.number, 
				element.singlePrice, 
				element.color,
				element.productUniqueness
			];
			
			this.firstlevelItems = [
				...this.firstlevelItems,
				this.thisItem
			]

			await this.__productsService.getProductById(element.productId).subscribe(
				res => {

					this.firstlevelProds = [
						...this.firstlevelProds,
						res.data
					]

					this.updateFinals();
										
				},
				err => {
					return;
				}
			)
				
			this.thisItem = [];	
		});

		await Promise.all(promises);

	}

	updateFinals() {
	
		if (this.firstlevelProds.length == this.firstlevelItems.length) {
			let count = 0;

			this.firstlevelItems.forEach((element: any) => {
				element = [
					...element,
					this.firstlevelProds[count]
				];
				let item = element;
				this.finalProductsInCart.push(item);
				count++;
			});

			if (this.finalProductsInCart.length == 0) {
				this.hasErrorAlert = true;
				this.hasErrorMsg = "First add items to your cart!"
				
				let count = 0;

				let successInterval = setInterval(() => {
					if (count > 0) {
						this.hasErrorAlert = false;
						clearInterval(successInterval);
						this._router.navigate(['/cart']);
						this.loading = false;				
					}	
					count = count + 1;
				}, 3000)
			}

			this.loadingData = false;
		}

	}

	async proceedToPayment() {
		this.isSuccess = false;
        this.hasErrorAlert = false;
		var checkout_token: any = localStorage.getItem('__checkout_credt_id');

		if (checkout_token == null) {
			return this._router.navigate(['/checkout'])
		}

		this.__paymentsServices.authenaticatePayment(checkout_token).subscribe(
			res => {
				this.hasSubmitted = true;
				this.isSuccess = true;
				this.successMsg = "Proceeding to payment . . .";
				
				localStorage.setItem('__payment_token', res.data.token);

				let count = 0;

				let successInterval = setInterval(() => {
					if (count > 0) {
						this.isSuccess = false;
						clearInterval(successInterval);
						this._router.navigate(['/payment'])				
					}
					count = count + 1;
				}, 3000)
			},
			err => {
				this.hasSubmitted = true;
				this.hasErrorAlert = true;
				this.hasErrorMsg = 'Something went wrong! Try again';
				
				let count = 0;

				let successInterval = setInterval(() => {
					if (count > 0) {
						this.hasErrorAlert = false;
						clearInterval(successInterval);				
					}
					count = count + 1;
				}, 3000)
			}
		)

	}
	
	deletingOrder: boolean = false;
	cancelCheck() {
		this.isSuccess = false;
        this.hasErrorAlert = false;
		this.deletingOrder = true;
		var orderId : any = localStorage.getItem('__checkout_credt_id');
		this.__ordersService.deleteOrder(orderId).subscribe(
			res => {
				this.isSuccess = true;
				this.successMsg = "Terminated order successfully"
				
				let count = 0;
		
				let successInterval = setInterval(() => {
					if (count > 0) {
						this.hasErrorAlert = false;
						this.deletingOrder = false;
						localStorage.removeItem('__checkout_credt_id');
						clearInterval(successInterval);
						this._router.navigate(['/cart'])				
					}
		
					count = count + 1;
				}, 3000)
			},
			err => {
				this.hasErrorAlert = true;
				if (err.error.message) {
					this.hasErrorMsg = err.error.message;
				} else {
					this.hasErrorMsg = "Oops! Something went wrong!"
				}

				let count = 0;
		
				let successInterval = setInterval(() => {
					if (count > 0) {
						this.hasErrorAlert = false;
						this.deletingOrder = false;
						clearInterval(successInterval);
						this._router.navigate(['/cart'])				
					}
					count = count + 1;
				}, 3000)
			}
		)
	}

}
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CartService } from '../../services/cart.service';
import { ProductsService } from '../../services/products.service';
import { environment } from 'src/environments/environment';
import { CustomsService } from 'src/app/services/customs.service';

@Component({
	selector: 'app-cart',
	templateUrl: './cart.component.html',
	styleUrls: ['./cart.component.css']
})
export class CartComponent implements OnInit {

	constructor( 
		private __cartService: CartService, 
		private __customsService: CustomsService, 
		private _router: Router, 
		private __productsService: ProductsService 
	) {
		this.checkOutCookieStatus();
	}

	checkOutCookieStatus() {
		if (!navigator.cookieEnabled) {
			this.cookies_disabled = true;
		}
	}

	cookies_disabled: boolean = false;

	enableBrowserCookies() {
		alert("Press ALT + F and then S to edit your browser settings")
	}

	apiPublic: string = environment.apiPublic;

	loading: boolean = false;
	loadingData: boolean = true;

	myCart: any = [];
	shouldCreateCart: any;

	isSuccess: boolean = false;
	successMsg: string = '';
	hasSubmitted: boolean = false;
	hasErrorAlert: boolean = false;
	hasErrorMsg: string = '';

	ngOnInit(): void {
		this.loading = true;
		this.getCurrentCurrency();
		this.getAllCurrencies();

		this.checkOutCookieStatus();
		this.__customsService.updatePageStatus(false);

		this.__productsService.updateExchangeRates.subscribe(
			(res:any) => {
				this.currency = this.__productsService.getCurrentCurrency();
			}, (err:any) => console.log(err) 
		);
		
		this.__cartService.getMyShoppingCart().then(data => {
			this.shouldCreateCart = data;
		
			if (this.shouldCreateCart) {
				this.createNewCart();
			} 
			
			if (!this.shouldCreateCart) {
				var cookieid: any = document.cookie.match(new RegExp('(^| )' + '_mycart' + '=([^;]+)'));
				this.getMyShoppingCart(cookieid[2]);
			}
	
		});
	}
	
	currency: any = 'rwf';
	exchangeValue: any = 0;
	exchangeRates: any;
	loadingPrice: boolean = true;
	reprocessingcurrencies: number = 0;

	getCurrentCurrency() {
		this.currency = this.__productsService.getCurrentCurrency();
	}

	getAllCurrencies() {

		this.__productsService.getCurrencies().subscribe(
			res => {
				this.exchangeRates = res.data;
				this.exchangeValue = this.exchangeRates;
				this.loadingPrice = false;
			},
			err => {

				if (this.reprocessingcurrencies <= 2) {
					this.getAllCurrencies();
					this.reprocessingcurrencies++;
				} else {
					alert('Having trouble in processing currencies! Reprocessing . . .');
					location.reload();
				}

			})

	}
	
	getMyShoppingCart(value: any) {
		this.loadingData = true;
		this.checkOutCookieStatus();

		this.myCart = [];
		this.finalProductsInCart = [];
		this.thisItem = [];
		this.firstlevelItems = [];
		this.firstlevelProds = [];
		this.updatingcart = false;

		let data = value;
		this.loading = true;

		this.__cartService.getSpecificCart(data).subscribe(
			res => {
				this.loading = false;
				this.myCart = res.data;
				
				if (this.myCart.length != 0) {
					this.getEachCartItem();
				}

			},
			err => {
				// this.loading = false;
				this.hasErrorAlert = true;
				this.hasErrorMsg = "You cart has expired!"
				
				let count = 0;

				let successInterval = setInterval(() => {
					if (count > 0) {
						this.hasErrorAlert = false;
						clearInterval(successInterval);
						this.createAlert()					
					}
					count = count + 1;
				}, 3000)
			}
		)
	}

	createAlert() {
		this.isSuccess = true;
		this.successMsg = "Don't worry we are creating a new one for you"
		
		let countTwo = 0;

		let successIntervalTwo = setInterval(() => {
			if (countTwo > 0) {
				this.isSuccess = false;
				clearInterval(successIntervalTwo);
				this.createNewCart();
			}
			countTwo = countTwo + 1;
		}, 3000)
	}


	createNewCart() {
		this.checkOutCookieStatus();
		let newCartData = {
			"products": []
		};

		this.__cartService.createNewCart(newCartData).subscribe(
			res => {
				document.cookie = `_mycart=${res.data._id}; path=/; max-age=` + 2147483647;
				this.getMyShoppingCart(res.data._id)
			},
			err => {
				this.createNewCart();
			}
		)
	}

	finalProductsInCart: any = [];

	thisItem: any = [];
	firstlevelItems: any = [];
	firstlevelProds: any = [];
	
	async getEachCartItem() {

		const promises = await this.myCart.products.map(async (element: any) => {

			this.thisItem = [
				element.size, 
				element.number, 
				element.singlePrice, 
				element.color,
				element.productUniqueness
			];
			
			this.firstlevelItems = [
				...this.firstlevelItems,
				this.thisItem
			]

			await this.__productsService.getProductById(element.productId).subscribe(
				res => {

					this.firstlevelProds = [
						...this.firstlevelProds,
						res.data
					]

					this.updateFinals();
										
				},
				err => {
					return;
				}
			)
				
			this.thisItem = [];	
		});

		await Promise.all(promises);
	}

	async updateFinals() {
	
		if (this.firstlevelProds.length == this.firstlevelItems.length) {
			let count = 0;

			await this.firstlevelItems.forEach((element: any) => {
				element = [
					...element,
					this.firstlevelProds[count]
				];
				let item = element;
				this.finalProductsInCart.push(item);
				count++;
			});

			this.loadingData = false;
		}
	
	}

	removedSomething: boolean = false;
	removingFromCartSubmission: boolean = false; 
	removingCartSubmissionId: string = '';

	removeItemFromCart(value: any, prod_id: any, number: any) {
		this.removingCartSubmissionId = value;
		this.removingFromCartSubmission = true;

		if (number <= 0) {
			this.isSuccess = false;
			this.hasErrorAlert = true;
			this.hasErrorMsg = "Invalid product quantity!"
					
			let count = 0;
			
			let successIntervalTwo = setInterval(() => {
				if (count > 0) {
					this.hasErrorAlert = false;
					clearInterval(successIntervalTwo);
				}
				count = count + 1;
			}, 2000)

			return;
		}

		if (number > 0) {
			let quantity_increase: any = {
				"quantity": number
			}
			
			this.__productsService.increaseQuantityInStock(prod_id, quantity_increase).subscribe(
				res => {
					var cookieid: any = document.cookie.match(new RegExp('(^| )' + '_mycart' + '=([^;]+)'));
					const id:any = cookieid[2]; 

					let data: any = {
						"productId": value
					}

					this.__cartService.removeItemFromCart(id, data).subscribe(
						res => {
							this.isSuccess = true;
							this.successMsg = "Removed from cart successfully"
							this.removingCartSubmissionId = value;
							this.removingFromCartSubmission = true;
							
							this.getMyShoppingCart(id);
							this.updateCartNumber(-number);

							let count = 0;
							
							let successInterval = setInterval(() => {
								if (count > 0) {
									this.isSuccess = false;
									clearInterval(successInterval);
								}
				
								count = count + 1;
							}, 2000)
						},
						err => {
							let quantity_reduce: any = {
								"quantity": number
							}

							this.reduceQuantityInStock(prod_id, quantity_reduce);
							this.removingCartSubmissionId = value;
							this.removingFromCartSubmission = true;
					
							this.hasErrorAlert = true;
							if (err.message) {
								this.hasErrorMsg = err.message;
							} else {
								this.hasErrorMsg = "Oops! Something went wrong!"
							}
							
							let count = 0;

							let successInterval = setInterval(() => {
								if (count > 0) {
									this.hasErrorAlert = false;
									clearInterval(successInterval);				
								}
				
								count = count + 1;
							}, 2000)
						}
					)
				},
				err => {
		
					this.hasErrorAlert = true;
					this.hasErrorMsg = err.error.message;
					this.removingCartSubmissionId = '';
					this.removingFromCartSubmission = false;

					if (!err.error.message) {
						this.hasErrorMsg = "Something went wrong! Try again."
					}
					
					let count = 0;
					
					let successIntervalTwo = setInterval(() => {
						if (count > 0) {
							this.hasErrorAlert = false;
							clearInterval(successIntervalTwo);
						}
						count = count + 1;
					}, 2000);

				}
			)
		}
	}

	reduceQuantityInStock(value: any, data: any) {
		this.__productsService.reduceQuantityInStock(value, data).subscribe(
			res => {},
			err => {}
		)
	}

	updateCartNumber(value: number) {
		this.__cartService.updateCartNumber(value);
	}

	disableInputs: boolean = false;
	updateThisItem(id: any, prod_id: any, quantity_current_in_cart: any, prod_quantity: any, number: any, arr_id: any) {
		this.isSuccess = false;
		this.hasErrorAlert = false;
		this.hasErrorMsg = '';
		this.successMsg = '';

		// if (quantity_current_in_cart == number) {
		// 	this.hasErrorAlert = true;
		// 	this.hasErrorMsg = 'Similar quantity entered!';
			
		// 	let count = 0;

		// 	let successInterval = setInterval(() => {
		// 		if (count > 0) {
		// 			this.hasErrorAlert = false;
		// 			clearInterval(successInterval);				
		// 		}

		// 		count = count + 1;
		// 	}, 500)
		// 	return;
		// }

		if (number == '' || number == undefined || number == null) {
			this.hasErrorAlert = true;
			this.hasErrorMsg = 'Invalid number';
			
			let count = 0;

			let successInterval = setInterval(() => {
				if (count > 0) {
					this.hasErrorAlert = false;
					this.disableInputs = false;
					clearInterval(successInterval);				
				}
				count = count + 1;
			}, 500)
			return;
		}
		
		if (number == 0 || number < 0) {
			this.hasErrorAlert = true;
			this.hasErrorMsg = 'Quantity cannot be 0 or less!';
			
			let count = 0;

			let successInterval_3 = setInterval(() => {
				if (count > 0) {
					this.hasErrorAlert = false;
					clearInterval(successInterval_3);
					location.reload();			
				}

				count = count + 1;
			}, 2000)
			return;
		}

		this.disableInputs = true;

		var sum_quantity: any = quantity_current_in_cart + prod_quantity;
		var quantity_to_be_increase: number = 0;
		var quantity_to_be_reduce: number = 0;
		if (number > quantity_current_in_cart) {
			quantity_to_be_increase = number - quantity_current_in_cart;
		}

		if (number < quantity_current_in_cart) {
			quantity_to_be_reduce = quantity_current_in_cart - number;
		}

		if (prod_quantity < quantity_to_be_increase) {
			this.hasErrorAlert = true;
			this.hasErrorMsg = 'Not enough products in stock!';
			
			let count = 0;

			let successInterval = setInterval(() => {
				if (count > 0) {
					this.hasErrorAlert = false;
					this.disableInputs = false;
					clearInterval(successInterval);	
					location.reload();			
				}
				count = count + 1;
			}, 2000);
			
			return;
		}

		var increase_quantity_num: number = 0;
		var reduce_quantity_num: number = 0;

		if (number > quantity_current_in_cart) {
			increase_quantity_num = number - quantity_current_in_cart;
		}

		if (number < quantity_current_in_cart) {
			reduce_quantity_num = quantity_current_in_cart - number;
		}

		if (number == quantity_current_in_cart) {
			if (quantity_current_in_cart > number) {
				increase_quantity_num = number - quantity_current_in_cart;
			}

			if (quantity_current_in_cart < number) {
				reduce_quantity_num = quantity_current_in_cart - number;;
			}
		}

		var cookieid: any = document.cookie.match(new RegExp('(^| )' + '_mycart' + '=([^;]+)'));
		const cartId:any = cookieid[2]; 

		let productId: any = id;

		let productData: any = {
			"number": parseInt(number),
		}

		if (increase_quantity_num > 0) {
			let quantity_reduce: any = {
				"quantity": increase_quantity_num
			}

			this.__productsService.reduceQuantityInStock(prod_id, quantity_reduce).subscribe(
				res => {
					this.__cartService.updateCartItemInCart(cartId ,productId, productData).subscribe(
						res => {
							this.isSuccess = true;
							this.successMsg = 'Updated cart item';
							this.finalProductsInCart[arr_id][1] = number;
							this.finalProductsInCart[arr_id][5].quantityInStock = prod_quantity - increase_quantity_num;
							this.updateCartNumber(increase_quantity_num);
							
							let count = 0;
			
							let successInterval = setInterval(() => {
								if (count > 0) {
									this.isSuccess = false;
									this.disableInputs = false;
									clearInterval(successInterval);				
								}
								count = count + 1;
							}, 1000)
						},
						err => {
							let quantity_increase: any = {
								"quantity": increase_quantity_num
							}

							this.increaseQuantityInStock(prod_id, quantity_increase);

							this.hasErrorAlert = true;
							if (err.message) {
								this.hasErrorMsg = err.message;
							} else {
								this.hasErrorMsg = "Oops! Something went wrong!"
							}
							
							let count = 0;
			
							let successInterval = setInterval(() => {
								if (count > 0) {
									this.hasErrorAlert = false;
									this.disableInputs = false;
									clearInterval(successInterval);				
								}
								count = count + 1;
							}, 2000)
						}
					)
				},
				err => {
					this.hasErrorAlert = true;
					this.hasErrorMsg = err.error.message;
					if (!err.error.message) {
						this.hasErrorMsg = "Something went wrong. Try again!"
					}
					
					let count = 0;
					
					let successIntervalTwo = setInterval(() => {
						if (count > 0) {
							this.hasErrorAlert = false;
							this.disableInputs = false;
							clearInterval(successIntervalTwo);
						}
						count = count + 1;
					}, 2000)
				}
			)
		}

		if (reduce_quantity_num > 0) {

			let quantity_increase: any = {
				"quantity": reduce_quantity_num
			}

			this.__productsService.increaseQuantityInStock(prod_id, quantity_increase).subscribe(
				res => {
					this.__cartService.updateCartItemInCart(cartId ,productId, productData).subscribe(
						res => {
							this.isSuccess = true;
							this.successMsg = 'Updated cart item';
							this.finalProductsInCart[arr_id][1] = number;
							this.finalProductsInCart[arr_id][5].quantityInStock = prod_quantity + reduce_quantity_num;
							this.updateCartNumber(-reduce_quantity_num);
							
							let count = 0;
			
							let successInterval = setInterval(() => {
								if (count > 0) {

									this.isSuccess = false;
									this.disableInputs = false;
									clearInterval(successInterval);				
								}
								count = count + 1;
							}, 1000)
						},
						err => {
							let quantity_increase: any = {
								"quantity": reduce_quantity_num
							}

							this.reduceQuantityInStock(prod_id, quantity_increase);

							this.hasErrorAlert = true;
							if (err.message) {
								this.hasErrorMsg = err.message;
							} else {
								this.hasErrorMsg = "Oops! Something went wrong!"
							}
							
							let count = 0;
			
							let successInterval = setInterval(() => {
								if (count > 0) {
									this.hasErrorAlert = false;
									this.disableInputs = false;
									clearInterval(successInterval);				
								}
								count = count + 1;
							}, 2000)
						}
					)
				},
				err => {
					this.hasErrorAlert = true;

					this.hasErrorMsg = err.error.message;
					if (!err.error.message) {
						this.hasErrorMsg = "Something went wrong. Try again!"
					}
					
					let count = 0;
					
					let successIntervalTwo = setInterval(() => {
						if (count > 0) {
							this.hasErrorAlert = false;
							this.disableInputs = false;
							clearInterval(successIntervalTwo);
						}
						count = count + 1;
					}, 2000)
				}
			)
		}
		
	}

	increaseQuantityInStock(value: any, data: any) {
		this.__productsService.increaseQuantityInStock(value, data).subscribe(
			res => {},
			err => {}
		)
	}

	updatingcart: boolean = false;
	updateCartSync() {
		this.updatingcart = true;
		this.loading = true;
		window.location.reload()
	}

	proceedToCheck(price: any) {
		if (price == 0) {
			this.hasErrorAlert = true;
				this.hasErrorMsg = 'Please first add items to your cart!';
				
				let count = 0;

				let successInterval = setInterval(() => {
					if (count > 0) {
						this.hasErrorAlert = false;
						clearInterval(successInterval);				
					}
					count = count + 1;
				}, 2000)
			return;
		}

		this._router.navigate(['/checkout']);
	}

	goToShop() {
		this._router.navigate(['/shop']);
	}

	seeMoreOneThisProduct(value: any) {
		this._router.navigate(['/product/', value]);
	}

}
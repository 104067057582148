import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  	providedIn: 'root'
})
export class TransactionsService {

	constructor( 
		private _http: HttpClient, 
	) { }

	addTransaction(data: any) {
		return this._http.post<any>(`${environment.apiUrl}transactions/addTransaction`, data);
	}

	getSpecificTransaction(value: string) {
		return this._http.get<any>(`${environment.apiUrl}transactions/getSpecificTransaction/${value}`);
	}

	getAllTransactions() {
		return this._http.get<any>(`${environment.apiUrl}transactions/getAllTransactions`);
	}

}
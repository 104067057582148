<div id="entire_application__single_collection_component">

    <div *ngIf="loading" class="loader">
        <app-loader></app-loader>
    </div>
    
    <div class="image_viewer" *ngIf="viewingPicMedia">
        <svg (click)="closeAllMediaView()" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path fill="#ffffff" d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-9.414l2.828-2.829 1.415 1.415L13.414 12l2.829 2.828-1.415 1.415L12 13.414l-2.828 2.829-1.415-1.415L10.586 12 7.757 9.172l1.415-1.415L12 10.586z"/></svg>
        <img [src]="currentImageBeingViewed" alt="">
    </div>
    
    <div *ngIf="isSuccess || hasErrorAlert" class="success_message">
        <div class="msg_box" [ngClass]="{'isFailed': hasErrorAlert}">
            <p *ngIf="isSuccess">{{ successMsg }}</p>
            <p *ngIf="hasErrorAlert">{{ hasErrorMsg }}</p>
            <svg *ngIf="isSuccess" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-.997-4L6.76 11.757l1.414-1.414 2.829 2.829 5.656-5.657 1.415 1.414L11.003 16z" fill="rgba(255,255,255,1)"/></svg>
            <svg *ngIf="hasErrorAlert" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-9.414l2.828-2.829 1.415 1.415L13.414 12l2.829 2.828-1.415 1.415L12 13.414l-2.828 2.829-1.415-1.415L10.586 12 7.757 9.172l1.415-1.415L12 10.586z" fill="rgba(255,255,255,1)"/></svg>
        </div>
    </div>

    <div class="single_collection__container">

        <div class="collection_content_container">
            <div class="collection_main_image_container col-mob-12 col-tab-12 col-large-12">
                <img (click)="viewPicMedia(collectiondata.mainCollectionImage)" [src]="collectiondata.mainCollectionImage" alt="">
            </div>
            <div class="collection_description col-mob-12 col-tab-12 col-large-12">
                <div class="collection_title">
                    <h1>{{ collectiondata.collectionName }}</h1>
                </div>
                <div class="collection_upload_date">
                    <p><span>Presented On:</span> {{ collectiondata.createdAt | date:'medium' }}</p>
                </div>
                <div class="collection_descript">
                    <p>{{ collectiondata.collectionDescription }}</p>
                </div>
                <div class="actions">
                    <a [routerLink]="['/collections']">
                        Return to collections
                    </a>
                    <a class="more_of_this" [routerLink]="['/shop']">
                        Shop one of these
                    </a>
                </div>
            </div>
        </div>

        <div class="more_of_this_collection">
            <div *ngFor="let img of collectiondata.otherCollectionImages; let i = index" class="collection_picture col-mob-12 col-tab-14 col-large-14">
                <img (click)="viewPicMedia(img)" [src]="img" alt="">
            </div>
        </div>

    </div>
</div>
<div id="entire_add_collections_page">

    <div *ngIf="isSuccess" class="success_message">
        <div class="msg_box">
            <p>{{ successMsg }}</p>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-.997-4L6.76 11.757l1.414-1.414 2.829 2.829 5.656-5.657 1.415 1.414L11.003 16z" fill="rgba(255,255,255,1)"/></svg>
        </div>
    </div>

    <div class="header col-mob-12 col-tab-12 col-large-12">
        <p>Add a new collection</p>
    </div>
    <div class="content col-mob-12 col-tab-12 col-large-12">
        <div *ngIf="hasErrors" class="alert_container">
            <p *ngIf="hasErrors">{{errors}}</p>
        </div>
        <div class="form col-mob-12 col-tab-12 col-large-12">
            <form>
                <div class="form_group">
                    <p>Main collection image</p>
                    <div class="drag_zone">
                        <div *ngIf="mainCollectionImageDone" class="images_selected main_image">
                            <img [src]="mainCollectionImageBlob" alt="">
                        </div>
                        <input type="file" id="maincollectionImage" accept=".png,.jpg,.jpeg" (input)="getMainCollectionPicture($event)" required>
                        <svg *ngIf="!mainCollectionImageDone" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="64" height="64"><path fill="none" d="M0 0h24v24H0z"/><path d="M21 15v3h3v2h-3v3h-2v-3h-3v-2h3v-3h2zm.008-12c.548 0 .992.445.992.993V13h-2V5H4v13.999L14 9l3 3v2.829l-3-3L6.827 19H14v2H2.992A.993.993 0 0 1 2 20.007V3.993A1 1 0 0 1 2.992 3h18.016zM8 7a2 2 0 1 1 0 4 2 2 0 0 1 0-4z"/></svg>
                        <p *ngIf="!mainCollectionImageDone" >Drag and drop files or click to upload</p>
                    </div>
                </div>                
            </form>
        </div>

        <div class="other_side_form col-mob-12 col-tab-12 col-large-12">
            <div class="form_group">
                <p>Collection name</p>
                <input type="text" id="name" placeholder="Collection name" minlength="2" maxlength="100" [ngClass]="{'--red_bordered': nameRequired}" #name (input)="getCollectionName(name.value)" required>
            </div>
            
            <div class="form_group">
                <p>Collection description</p>
                <textarea id="descript" placeholder="Collection description" minlength="5" maxlength="800" [ngClass]="{'--red_bordered': descriptionRequired}" #descript (input)="getCollectionDescription(descript.value)"></textarea>
            </div>
        </div>

        <div class="all_remaining_shots">
            <div class="form_group">
                <p>All other collection shots</p>
                <div class="drag_zone">
                    <div *ngIf="otherCollectionimagesDone" class="images_selected other_images_selected">
                        <img *ngFor="let img of otherCollectionimagesBlobs; let i = index" [src]="img" alt="">
                    </div>
                    <input type="file" id="otherCollectionImages" accept=".png,.jpg,.jpeg" (input)="getOtherCollectionPictures($event)" multiple required>
                    <svg *ngIf="!otherCollectionimagesDone" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="64" height="64"><path fill="none" d="M0 0h24v24H0z"/><path d="M21 15v3h3v2h-3v3h-2v-3h-3v-2h3v-3h2zm.008-12c.548 0 .992.445.992.993V13h-2V5H4v13.999L14 9l3 3v2.829l-3-3L6.827 19H14v2H2.992A.993.993 0 0 1 2 20.007V3.993A1 1 0 0 1 2.992 3h18.016zM8 7a2 2 0 1 1 0 4 2 2 0 0 1 0-4z"/></svg>
                    <p *ngIf="!otherCollectionimagesDone" >Drag and drop files or click to upload</p>
                </div>
            </div>
        </div>

        <div class="submit_form col-mob-12 col-tab-12 col-large-12" id="submit_container">
            <button class="col-mob-12 col-tab-5 col-large-5" (click)="cancelSubmissionHere()">Cancel now</button>
            <button (click)="createCollection()" class="sub_btn col-mob-12 col-tab-5 col-large-5">
                <svg *ngIf="hasSubmitted" class="spinner_loading" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M18.364 5.636L16.95 7.05A7 7 0 1 0 19 12h2a9 9 0 1 1-2.636-6.364z" fill="rgba(255,255,255,1)"/></svg>
                <span *ngIf="!hasSubmitted">Save collection</span>
            </button>
        </div>
    </div>
</div>
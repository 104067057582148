import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommunicationService } from '../../services/communication.service';

@Component({
	selector: 'app-unsubscribe',
	templateUrl: './unsubscribe.component.html',
	styleUrls: ['./unsubscribe.component.css']
})
export class UnsubscribeComponent implements OnInit {

	constructor( private __communService: CommunicationService, 
		private _router: Router, 
		private activatedRoute: ActivatedRoute
	) {	}

	_id: string = '';
	loading: boolean = false;

	ngOnInit(): void {
		this.loading = true;
		let userId: any = this.activatedRoute.snapshot.paramMap.get('id');
		this._id = userId;
		this.unsubscribe()
	}

	unsubscribe() {
		this.__communService.unsubscribe(this._id).subscribe(
			res => {
				this.loading = false;
				this.successMsg = 'Unsubscribed inkingi successfully';
				this.isSuccess = true;
				
				let count = 0;
				
				let successInterval = setInterval(() => {
					if (count > 0) {
						this.isSuccess = false;
						clearInterval(successInterval);
					}
					count = count + 1;
				}, 2000)


			},
			err => {
				this.loading = false;
				this.hasErrorAlert = true;				
				this.hasErrorMsg = "Something isn't right! Try again";

				let count = 0;
				
				let successInterval = setInterval(() => {
					if (count > 0) {
						this.hasErrorAlert = false;
						clearInterval(successInterval);
						this._router.navigate(['/']);
					}
					count = count + 1;
				}, 2000)
			}
		)
	}

	isSuccess: boolean = false;
	successMsg: string = '';
	hasSubmitted: boolean = false;
	hasErrorAlert: boolean = false;
	hasErrorMsg: string = '';
	subscriberEmail: string = '';
	regularExpression = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

	
	getSubscriberEmail(value: string) {
		this.subscriberEmail = value;
	}
	
	subscriptionActivity() {
		this.hasErrorAlert = false;
		this.isSuccess = false;
		this.successMsg = '';
		this.hasErrorMsg = '';

		if (this.subscriberEmail.length < 5) {
			this.hasErrorAlert = true;
			this.hasErrorMsg = "Your email isn't long enough 5 (min)";

			let count = 0;

			let successInterval = setInterval(() => {
				if (count > 0) {
					this.hasErrorAlert = false;
					clearInterval(successInterval);
				}
				count = count + 1;
			}, 2000)

			return;
		}
	
		if (this.subscriberEmail.length > 80) {
			this.hasErrorAlert = true;
			this.hasErrorMsg = "Your email is too long 80 (max)";

			let count = 0;

			let successInterval = setInterval(() => {
				if (count > 0) {
					this.hasErrorAlert = false;
					clearInterval(successInterval);
				}
				count = count + 1;
			}, 2000)

			return;
		}

		if (this.subscriberEmail != "") {
			const valid = this.regularExpression.test(String(this.subscriberEmail).toLowerCase());
			if (!valid) {
				this.hasErrorAlert = true;
				this.hasErrorMsg = "Invalid email address!"
				
				let count = 0;

				let successInterval = setInterval(() => {
					if (count > 0) {
						this.hasErrorAlert = false;
						clearInterval(successInterval);
					}
					count = count + 1;
				}, 2000)
	
				return;
			}
		} else {
			return;
		}

		this.hasSubmitted = true;
		let formData = { "subscriberEmail": this.subscriberEmail };
		
		this.isSuccess = false;
		this.hasErrorAlert = false;
		this.__communService.subscribeNow(formData).subscribe(
			res => {
				this.sendNotificaton();
				this.hasSubmitted = false;
				this.subscriberEmail = '';
				var email: any = document.getElementById('email');
				email.value = '';
				
				this.successMsg = 'Subscribed inkingi successfully';
				this.isSuccess = true;
				
				let count = 0;
				
				let successInterval = setInterval(() => {
					if (count > 0) {
						this.isSuccess = false;
						clearInterval(successInterval);
						this._router.navigate(['/']);
					}
					count = count + 1;
				}, 2000)


			},
			err => {
				this.hasSubmitted = false;
				this.hasErrorAlert = true;
				if (err.message && err.error) {
					if (err.error.message) {
						this.hasErrorMsg = err.error.message;
					} else if (err.error) {
						this.hasErrorMsg = err.error;
					} else {
						this.hasErrorMsg = "Oops! Something went wrong!"
					}
				} else if (err.message) {
					this.hasErrorMsg = err.message;
				} else if (err.error) {
					this.hasErrorMsg = err.error;
				} else {
					this.hasErrorMsg = "Oops! Something went wrong!"
				}

				let count = 0;

				let successInterval = setInterval(() => {
					if (count > 0) {
						this.hasErrorAlert = false;
						clearInterval(successInterval);
					}
					count = count + 1;
				}, 2000)
			}
		)
	}

	sendNotificaton() {

		let noteData = {
			
			"notificationTitle": "New inkingi subscriber",
			"notificationDescription": `A new email ${this.subscriberEmail} has been subscribed to inkingi.`,
			"notificationCategory": "newsubscriber"
			
		}

		this.__communService.sendNotification(noteData).subscribe(
			res => {
				if (res) return;
			}, err => {
				if (err) return;
			}
		)
	}

}
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  	name: 'trimtips'
})
export class TrimtipsPipe implements PipeTransform {

	transform(value: string) {
		let return_tip: any = value;

		if (return_tip.split('')[0] == ',') {
			return_tip = return_tip.substring(1);
		}

		return return_tip;
	}

}
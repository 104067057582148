<div id="entire_application__men_component">

    <div *ngIf="isSuccess || hasErrorAlert" class="success_message">
        <div class="msg_box" [ngClass]="{'isFailed': hasErrorAlert}">
            <p *ngIf="isSuccess">{{ successMsg }}</p>
            <p *ngIf="hasErrorAlert">{{ hasErrorMsg }}</p>
            <svg *ngIf="isSuccess" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-.997-4L6.76 11.757l1.414-1.414 2.829 2.829 5.656-5.657 1.415 1.414L11.003 16z" fill="rgba(255,255,255,1)"/></svg>
            <svg *ngIf="hasErrorAlert" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-9.414l2.828-2.829 1.415 1.415L13.414 12l2.829 2.828-1.415 1.415L12 13.414l-2.828 2.829-1.415-1.415L10.586 12 7.757 9.172l1.415-1.415L12 10.586z" fill="rgba(255,255,255,1)"/></svg>
        </div>
    </div>

    <div class="collections_container">
        <div class="header">
            <div class="descript col-mob-12 col-tab-12 col-large-12">
                <p>All Inkingi products and designs.</p>
            </div>
            <div class="categorise col-mob-12 col-tab-12 col-large-12">
                <select #sort_algorithm (input)="getSortingAlgorithm(sort_algorithm.value)">
                    <option value="latest">Latest products</option>
                    <option value="popular">Most popular</option>
                    <option value="low_high">Sort by price: low to high</option>
                    <option value="high_low">Sort by price: high to low</option>
                </select>
                <button (click)="renderAddProduct()">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20"><path fill="none" d="M0 0h24v24H0z"/><path d="M11 11V7h2v4h4v2h-4v4h-2v-4H7v-2h4zm1 11C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16z" fill="rgba(255,255,255,1)"/></svg>
                    Add product
                </button>
            </div>
        </div>
        <div class="product_items">

            <div *ngIf="loadingData" class="isLoadingData">
                <svg class="spinner_loading" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path fill="#000000" d="M18.364 5.636L16.95 7.05A7 7 0 1 0 19 12h2a9 9 0 1 1-2.636-6.364z"/></svg>
                <p>Loading . . .</p>
            </div>

            <p *ngIf="noProductStatus" style="font-size: 0.83em; opacity: 0.7; margin: 7em auto;">No product in stock</p>

            <div class="product col-mob-11 col-tab-13 col-large-14" *ngFor="let product of productdata; let i = index" >
                <div class="image_design edit_relative_parent">
                    <span class="edit_span" (click)="editProductNavigation(product._id)">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="15" height="15"><path fill="none" d="M0 0h24v24H0z"/><path d="M15.728 9.686l-1.414-1.414L5 17.586V19h1.414l9.314-9.314zm1.414-1.414l1.414-1.414-1.414-1.414-1.414 1.414 1.414 1.414zM7.242 21H3v-4.243L16.435 3.322a1 1 0 0 1 1.414 0l2.829 2.829a1 1 0 0 1 0 1.414L7.243 21z" fill="rgba(18,25,35,1)"/></svg>
                        edit
                    </span>
                    <img  (click)="viewProduct(product._id)" [src]="product.productMainImage" alt="">
                </div>
                <div class="image_descript">
                    <svg *ngIf="proccessingThisProd && product._id == proccessingThisProdId" class="spinner_loading" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path fill="#000000" d="M18.364 5.636L16.95 7.05A7 7 0 1 0 19 12h2a9 9 0 1 1-2.636-6.364z"/></svg>
                    <svg *ngIf="product.productDeleted == false && product._id != proccessingThisProdId" (click)="deleteThisProduct(product._id)" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="22" height="22"><path fill="none" d="M0 0h24v24H0z"/><path fill="#f82b27" d="M17 6h5v2h-2v13a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V8H2V6h5V3a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1v3zm1 2H6v12h12V8zm-9 3h2v6H9v-6zm4 0h2v6h-2v-6zM9 4v2h6V4H9z"/></svg>
                    <svg *ngIf="product.productDeleted == true && product._id != proccessingThisProdId" (click)="reactiveProduct(product._id)" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0H24V24H0z"/><path d="M19.562 12.097l1.531 2.653c.967 1.674.393 3.815-1.28 4.781-.533.307-1.136.469-1.75.469H16v2.5L11 19l5-3.5V18h2.062c.263 0 .522-.07.75-.201.718-.414.963-1.332.55-2.049l-1.532-2.653 1.732-1zM7.304 9.134l.53 6.08-2.164-1.25-1.031 1.786c-.132.228-.201.487-.201.75 0 .828.671 1.5 1.5 1.5H9v2H5.938c-1.933 0-3.5-1.567-3.5-3.5 0-.614.162-1.218.469-1.75l1.03-1.787-2.164-1.249 5.53-2.58zm6.446-6.165c.532.307.974.749 1.281 1.281l1.03 1.785 2.166-1.25-.53 6.081-5.532-2.58 2.165-1.25-1.031-1.786c-.132-.228-.321-.417-.549-.549-.717-.414-1.635-.168-2.049.549L9.169 7.903l-1.732-1L8.97 4.25c.966-1.674 3.107-2.248 4.781-1.281z" fill="rgba(25,188,155,1)"/></svg>
                    <p (click)="viewProduct(product._id)">{{ product.productName }}</p>
                    <h3>{{ product.productPrice | number }} <span>RWF</span></h3>
                    <a *ngIf="product.productDeleted == false" [routerLink]="[ '/admin/editproduct', product._id ]">
                        Edit product
                    </a>
                    <a (click)="reactiveProduct(product._id)" *ngIf="product.productDeleted == true">
                        Reactivate product
                    </a>
                </div>
            </div>

        </div>
    </div>
</div>
import { Directive, HostBinding, Input } from '@angular/core';

@Directive({
  	selector: '[appGeneral]'
})
export class GeneralDirective {

	public _elementClass: string[] = [];

	_config: any;

	@HostBinding()
	get appGeneral(): string {
		return this._elementClass.join(' ');
	}

	@Input()
	set(val: string, config: any) {
		this._config = config;
		if (this._config == true) {
			this._elementClass = val.split(' ');
		}
	}
  
	constructor() {
		this._elementClass.push('--ng--ser--8934--2nsdn_jsdncd');
		this._elementClass.push('--do_touch_ng_direct_mover');
	}
}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomsService } from 'src/app/services/customs.service';
import { CollectionsService } from '../../services/collections.service'

@Component({
	selector: 'app-admineditcollection',
	templateUrl: './admineditcollection.component.html',
	styleUrls: ['./admineditcollection.component.css']
})
export class AdmineditcollectionComponent implements OnInit {

	constructor(
		private __customsService: CustomsService,
		private __collectionsService: CollectionsService,
		private activatedRoute: ActivatedRoute, 
		private _router: Router
	) { }

	loadingCollectionData: boolean = true;
	collection_id: any;

	collectiondata: any;
	
	ngOnInit(): void {
		let collectionId: any = this.activatedRoute.snapshot.paramMap.get('collectionId');
		this.collection_id = collectionId;

		this.getCollectionData(this.collection_id);
	}

	getCollectionData(value: any) {
		this.isSuccess = false;
        this.hasErrorAlert = false;
		this.__collectionsService.getCollectionById(value).subscribe(
			res => {
				this.loadingCollectionData = false;
				this.collectiondata = res.data;
			},
			err => {
				this.hasErrorAlert = true;
				if (err.status == 404) {
					this.hasErrorMsg = "Collection not found!"
				} else {
					this.hasErrorMsg = "Oops! Something went wrong."
				}
				
				let count = 0;

				let successInterval = setInterval(() => {
					if (count > 0) {
						this.hasErrorAlert = false;
						clearInterval(successInterval);	
						this._router.navigate(['/admin/collections']);
					}
					count = count + 1;
				}, 3000)
			}
		)
	}

	viewingPicMedia: boolean = false;
	currentImageBeingViewed: string = '';

	closeAllMediaView() {
		this.viewingPicMedia = false;

		this.currentImageBeingViewed = '';
	}

	viewPicMedia(value: string) {
		this.viewingPicMedia = !this.viewingPicMedia;
		// this.currentImageBeingViewed = this.apiPublic+value;
		this.currentImageBeingViewed = value;
	}

	setOfImagesToDelete: any = [];
	allPreviousImagesDeleted: boolean = false;

	getDeleteImageData(image_value: any, image_id: any) {

		this.collectiondata.otherCollectionImages.splice(image_id, 1);
		this.collectiondata.otherCollectionImagesCloudIds.splice(image_id, 1);

		if (this.collectiondata.otherCollectionImages.length <= 0) {
			this.allPreviousImagesDeleted = true;
		}

		let thisImageSet: any = [];

		if (image_id != undefined && image_value != '' && image_value != undefined) {
			thisImageSet.push(image_id);
			thisImageSet.push(image_value);
			this.setOfImagesToDelete.push(thisImageSet);
		}
		
	}

  	hasSubmitted: boolean = false;
	isSuccess: boolean = false;
	successMsg: string = '';
	hasErrorAlert: boolean = false;
	hasErrorMsg: string = '';
	hasErrors: boolean = false;
	errors: string = '';

	collectionName: string = '';
	collectionDescription: string = '';
	mainCollectionImage: any;
	otherCollectionImages: any = [];

	mainCollectionImageBlob: any;
	otherCollectionimagesBlobs: any = [];

	//status
	mainCollectionImageDone: boolean = false;
	otherCollectionimagesDone: boolean = false;

	nameRequired: boolean = false;
	descriptionRequired: boolean = false;

	formData: FormData = new FormData();

	getMainCollectionPicture(event: any) {
		if (this.mainCollectionImage != null) {
			this.formData.delete('mainCollectionImage');
		}
		this.mainCollectionImageBlob = null;
		this.mainCollectionImage = null;
		this.mainCollectionImageDone = false;

		const mainCollectionImage: any = event.target.files[0];

		if (mainCollectionImage) {
			
			this.mainCollectionImage = mainCollectionImage;
			this.formData.append('mainCollectionImage', this.mainCollectionImage);
			
			this.addMainCollectionImageBlobs();
			
		}
	}

	addMainCollectionImageBlobs() {
		let fileReader = new FileReader();
		fileReader.addEventListener('load', () => {
			this.mainCollectionImageBlob = fileReader.result;
		})
		fileReader.readAsDataURL(this.mainCollectionImage);	

		this.mainCollectionImageDone = true;
	}

	getOtherCollectionPictures(event: any) {
		if (this.otherCollectionImages.length > 0) {
			this.formData.delete('otherCollectionImages');
		}
		this.otherCollectionimagesBlobs = [];
		this.otherCollectionImages = [];
		this.otherCollectionimagesDone = false;

		const othercollectionimages: any = Array.from(event.target.files);

		if (othercollectionimages) {
			
			this.otherCollectionImages.push(othercollectionimages);
			Array.from(othercollectionimages).forEach((item: any) => {
				this.formData.append('otherCollectionImages', item);
			})
			this.addOtherCollectionImagesBlobs();
			
		}
	}

	addOtherCollectionImagesBlobs() {
		for(let i= 0; i < this.otherCollectionImages[0].length; i++) {
			let fileReader = new FileReader();
			fileReader.addEventListener('load', () => {
				this.otherCollectionimagesBlobs.push(fileReader.result);
			})
			fileReader.readAsDataURL(this.otherCollectionImages[0][i]);		
		}

		this.otherCollectionimagesDone = true;
	}

	getCollectionName(value: any) {
		this.nameRequired = false;

		this.collectionName = value;
	}

	getCollectionDescription(value: any) {
		this.descriptionRequired = false;

		this.collectionDescription = value;
	}

	createCollection(value: any) {
		this.hasErrors = false;
		this.descriptionRequired = false;
		this.nameRequired = false;

		if (this.collectionName == '') {
			this.collectionName = this.collectiondata.collectionName;
		}

		if (this.collectionDescription == '') {
			this.collectionDescription = this.collectiondata.collectionDescription;
		}

		if (this.collectiondata.otherCollectionImages.length <= 0) {
			if (this.otherCollectionImages.length <= 0) {
				this.hasErrors = true;
				this.errors = 'A collection should atleast have one image.';
				window.scrollTo(0,0);
				return;
			}
		}

		this.hasSubmitted = true;
		this.formData.set("collectionName", this.collectionName);
		this.formData.set("collectionDescription", this.collectionDescription);
		this.formData.set("filteredOtherImageUrls", JSON.stringify(this.collectiondata.otherCollectionImages));
		this.formData.set("filteredOtherImageCloudIds", JSON.stringify(this.collectiondata.otherCollectionImagesCloudIds));
		this.formData.set("collectionDescription", this.collectionDescription);
		this.formData.set("imagesToDelete", JSON.stringify(this.setOfImagesToDelete));

		this.__collectionsService.updateCollection(value, this.formData).subscribe(
			res => {

				this.hasSubmitted = false;
				this.isSuccess = true;
				this.successMsg = 'Collection updated successfully';

				let count = 0;
				
				let successInterval = setInterval(() => {
					if (count > 0) {
						this.isSuccess = false;						
						clearInterval(successInterval);
						this._router.navigate(['/admin/collections']);
						this.formData.delete;
					}
					count = count + 1;
				}, 2000)

			},
			err => {
				
				this.hasSubmitted = false;
				this.hasErrors = true;
				
				if (err.error.message) {
					this.errors = err.error.message;
				} else if (err.error) {
					this.errors = err.error;
				} else if (err.message) {
					this.errors = err.message;
				} else if (err.error.text) {
					this.errors = err.error.text;
				} else {
					this.errors = "Something went wrong. Try again!"
				}
				window.scroll(0,0)
			}
		)

	}

	cancelSubmissionHere() {
		this._router.navigate(['/admin/collections']);
	}

}
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  	providedIn: 'root'
})
export class SearchService {

	constructor( private _http: HttpClient ) { }

	inkingiSearch(data: any) {
		return this._http.post<any>(`${environment.apiUrl}search/inkingiSearch`, data);
	}
	
}
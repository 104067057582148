import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CommunicationService } from 'src/app/services/communication.service';
import { ProductsService } from 'src/app/services/products.service';

@Component({
	selector: 'app-adminaddproduct',
	templateUrl: './adminaddproduct.component.html',
	styleUrls: ['./adminaddproduct.component.css']
})
export class AdminaddproductComponent implements OnInit {

	constructor( 
		private _router: Router, 
		private __productsService: ProductsService,
		private __communService: CommunicationService
	) { }

	ngOnInit(): void {}

	hasSubmitted: boolean = false;
	isSuccess: boolean = false;
	successMsg: string = '';
	hasErrors: boolean = false;
	errors: string = '';
	prodSampleDone: boolean = false;

	margin: number = 3;

	//product details 
	productName: string = '';
	productDescription: string = '';
	productPrice: string = '';
	quantityInStock: string = '';
	productCategory: string = 'men';
	productTag: string = 'full';
	productTips: any = [];
	productMainImage: any;
	productSubImages: any = [];
	productColors: any = [];
	// productWeight: string = '';
	// productBoxQuantity: string = '';
	// productShippingBoxVolume: string = '';
	
	productCurrentTip: string = '';
	productCurrentColor: string = '';
	productCurrentColorImage: any;
	
	productColorsDisplay: any = [];
	productCurrentColorDisplay: string = ''
	productCurrentColorImageBlobs: any;
	productMainImageBlobs: any;

	formData: FormData = new FormData();

	getProductName(value: string) {
		this.productName = value;
	}

	getProductDescription(value: string) {
		this.productDescription = value;
	}

	getProductPrice(value: any) {
		this.productPrice = value;
	}

	getQuantityInStock(value: any) {
		this.quantityInStock = value;
	}

	getProductCategory(value: any) {
		this.productCategory = value;
	}

	getProductTag(value: any) {
		this.productTag = value;
	}

	getProductTips(value: any) {
		this.productCurrentTip = value;
	}

	getCurrentTip() {
		var btnContainer:any = document.getElementById('submit_container');
		btnContainer.style.marginTop = this.margin + 1 + 'em';

		if (this.productCurrentTip.length > 75) {
			this.hasErrors = true;
			this.errors = 'Product care tip must be 75 characters or less!';
			window.scrollTo(0,0);
			return;
		}

		if (this.productCurrentTip.length < 3) {
			this.hasErrors = true;
			this.errors = 'Product care tip must be 3 characters or more!';
			window.scrollTo(0,0);
			return;
		}
		this.errors = '';
		this.hasErrors = false;

		this.productTips.push(this.productCurrentTip+'*');
		this.productCurrentTip = '';
	}

	getProductMainImage(event: any) {
		const productMainImage: any = event.target.files[0];
		
		if (productMainImage) {
			this.productMainImage = productMainImage;
			
			let fileReader = new FileReader();
			fileReader.addEventListener('load', () => {
				this.productMainImageBlobs = fileReader.result
			})
			fileReader.readAsDataURL(productMainImage);
			this.prodSampleDone = true;
		}
	}

	getProductSubImages(event: any) {
		if (this.productSubImages.length > 0) {
			this.formData.delete('productSubImages');
			this.productSubImages = [];
		}

		const productSubImages: any = Array.from(event.target.files)
		
		if (productSubImages) {
			if (productSubImages.length >= 5) {
				this.hasErrors = true;
				this.errors = 'You should upload atmost 4 sub-product images';
				let subImages:any = document.getElementById('sub_images');
				subImages.value = '';
				window.scrollTo(0 , 0);
				return;
			}

			this.productSubImages.push(productSubImages);
			Array.from(productSubImages).forEach((item: any) => {
				this.formData.append('productSubImages', item);
			})
			
		}
	}

	getProductColors(value: any) {
		this.productCurrentColor = value;
		this.productCurrentColorDisplay = value;
	}

	getProductColorImage(event: any) {
		const productCurrentColorImage: any = event.target.files[0];
		
		if (productCurrentColorImage) {
			this.productCurrentColorImage = productCurrentColorImage;
			
			let fileReader = new FileReader();
			fileReader.addEventListener('load', () => {
				this.productCurrentColorImageBlobs = fileReader.result
			})
			fileReader.readAsDataURL(productCurrentColorImage)
		
		}
	}

	addColorProductItem() {
		if (this.productCurrentColor != '') {
			this.hasErrors = false;
			this.errors = '';
			if(this.productCurrentColorImage != null) {
				if (this.productColors.length >= 7) {
					this.hasErrors = true;
					this.errors = 'You have reached the maximum number of colors';
					window.scrollTo(0 , 0);
					return;
				}
				this.hasErrors = false;
				this.errors = '';

				let arr: any = [];
				arr.push(this.productCurrentColor);
				arr.push(this.productCurrentColorImage);
				this.productCurrentColor = '';
				this.productCurrentColorImage = null;
				
				this.productColors.push(arr);
				arr = []

				let disarr: any = [];
				disarr.push(this.productCurrentColorDisplay);
				disarr.push(this.productCurrentColorImageBlobs);
				this.productCurrentColorDisplay = '';
				this.productCurrentColorImageBlobs = null;
				
				this.productColorsDisplay.push(disarr);
				disarr = []
			} else {
				this.hasErrors = true;
				this.errors = 'A corresponding image to the select color must be choosen'
				window.scrollTo(0,0);
			}
		} else {
			window.scrollTo(0,0);
			this.hasErrors = true;
			this.errors = 'You must choose a color value to add!';
		}
	}

	// getProductBoxQuantity(value: any) {
	// 	this.productBoxQuantity = value;
	// }

	// getShippingBoxVol(value: any) {
	// 	this.productShippingBoxVolume = value;
	// }

	// getProductWeight(value: any) {
	// 	this.productWeight = value;
	// }
	
	getProductDataFinal() {

		if (this.hasSubmitted) {
			this.isSuccess = true;
			this.successMsg = 'Wait for the response please!'
			
			let count = 0;
			
			let successInterval = setInterval(() => {
				if (count > 0) {
					this.isSuccess = false;
					clearInterval(successInterval);
				}

				count = count + 1;
			}, 2000)
		}

		if (this.productMainImage == undefined || this.productMainImage == null) {
			this.hasErrors = true;
			this.errors = 'The main image of the product is required!';
			window.scrollTo(0,0);
			return;
		} 

		if (this.productColors.length > 6) {
			this.hasErrors = true;
			this.errors = 'Product colors should not exceed 6 in number!';
			window.scrollTo(0,0);
			return;
		}

		if (this.productName == '') {
			this.hasErrors = true;
			this.errors = 'Product name is required!';
			window.scrollTo(0,0);
			return;
		}

		if (this.productName.length < 2) {
			this.hasErrors = true;
			this.errors = 'Product name must be atleast 2 characters long!';
			window.scrollTo(0,0);
			return;
		}

		if (this.productName.length > 30) {
			this.hasErrors = true;
			this.errors = 'Product name must be atmost 30 characters long!';
			window.scrollTo(0,0);
			return;
		}

		if (this.productPrice == '') {
			this.hasErrors = true;
			this.errors = 'Product price is required!';
			window.scrollTo(0,0);
			return;
		}

		// if (this.productBoxQuantity == '') {
		// 	this.hasErrors = true;
		// 	this.errors = 'Product box quantity is required!';
		// 	window.scrollTo(0,0);
		// 	return;
		// }

		// if (this.productWeight == '') {
		// 	this.hasErrors = true;
		// 	this.errors = 'Product weight is required!';
		// 	window.scrollTo(0,0);
		// 	return;
		// }

		// if (this.productShippingBoxVolume == '' || this.productShippingBoxVolume == '...') {
		// 	this.hasErrors = true;
		// 	this.errors = 'Product shipping box volumen is required!';
		// 	window.scrollTo(0,0);
		// 	return;
		// }

		if (this.quantityInStock == '') {
			this.hasErrors = true;
			this.errors = 'Product stock quantity is required!';
			window.scrollTo(0,0);
			return;
		}

		if (this.productDescription == '') {
			this.hasErrors = true;
			this.errors = 'Product description is required!';
			window.scrollTo(0,0);
			return;
		}

		if (this.productDescription.length > 400) {
			this.hasErrors = true;
			this.errors = 'Produce description must be atmost 400 characters long!';
			window.scrollTo(0,0);
			return;
		}

		if (this.productDescription.length < 5) {
			this.hasErrors = true;
			this.errors = 'Product description must be atleast 5 characters long!';
			window.scrollTo(0,0);
			return;
		}

		if (this.productPrice == undefined || this.productPrice == null) {
			this.hasErrors = true;
			this.errors = 'Product price is required!';
			window.scrollTo(0,0);
			return;
		}

		if (this.quantityInStock == undefined || this.quantityInStock == null) {
			this.hasErrors = true;
			this.errors = 'Product stock quantity is required!';
			window.scrollTo(0,0);
			return;
		}

		this.errors = '';
		this.hasErrors = false;
		this.hasSubmitted = true;

		if (this.productColors.length > 0) {

			let colorImagesArr = [];
			let colorsArr: any = [];
	
			for(let index = 0; index < this.productColors.length; index++) {
				colorsArr.push(this.productColors[index][0]);
				colorImagesArr.push(this.productColors[index][1]);
			}
	
			Array.from(colorImagesArr).forEach((item: any) => {
				this.formData.append('productColorImages', item);
			})
			
			this.formData.append("productColors", colorsArr);
		
		}

		this.formData.append("productName", this.productName);
		this.formData.append("productDescription", this.productDescription);
		this.formData.append("productPrice", this.productPrice);
		this.formData.append("quantityInStock", this.quantityInStock);
		this.formData.append("productCategory", this.productCategory);
		this.formData.append("productTag", this.productTag);
		this.formData.append("productTips", this.productTips);
		this.formData.append("productMainImage", this.productMainImage);
		// this.formData.append("productWeight", this.productWeight);
		// this.formData.append("productBoxQuantity", this.productBoxQuantity);
		// this.formData.append("productShippingBoxVolume", this.productShippingBoxVolume);
		
		this.__productsService.registerNewProduct(this.formData).subscribe(
			res => {
				this.showThisNewsToSubscribers(res.data._id);
				this.hasSubmitted = false;
				this.isSuccess = true;
				this.successMsg = 'Added product successfully'
				window.scrollTo(0 , 0);
				
				this._router.navigate(['/admin/addproduct']);

				this.resetFormNow();
				
				let count = 0;
				
				let successInterval = setInterval(() => {
					if (count > 0) {
						this.isSuccess = false;
						clearInterval(successInterval);
					}

					count = count + 1;
				}, 2000)
			},
			err => {
				this.clearFormFields();
				window.scrollTo(0 , 0);
				this.hasErrors = true;
				this.hasSubmitted = false;
				if (err.error.message) {
					this.errors = err.error.message;
				} else if (err.error.text) {
					this.errors = err.error.text;
				} else {
					this.errors = "Something went wrong. Try again!"
				}
			}
		)
	}

	clearFormFields() {
		this.formData.delete('productName');
		this.formData.delete("productDescription");
		this.formData.delete("productPrice");
		this.formData.delete("quantityInStock");
		this.formData.delete("productCategory");
		this.formData.delete("productTag");
		this.formData.delete("productTips");
		this.formData.delete("productMainImage");
		// this.formData.delete("productWeight");
		// this.formData.delete("productBoxQuantity");
		// this.formData.delete("productShippingBoxVolume");

		if (this.productColors.length > 0) {
			this.formData.delete("productColors");
			this.formData.delete('productColorImages');
		}
		
		if (this.productSubImages.length > 0) {
			this.formData.delete('productSubImages');
		}
	}

	resetFormNow() {

		this.clearFormFields();
		this.productName = '';
		this.productDescription = '';
		this.productPrice = '';
		this.quantityInStock = '';
		this.productCategory = 'men';
		this.productTag = 'full';
		this.productTips = [];
		this.productMainImage = null;
		this.productSubImages = [];
		this.productColors = [];
		// this.productWeight = '';
		// this.productBoxQuantity = '';
		// this.productShippingBoxVolume = '';
		
		this.productCurrentTip = '';
		this.productCurrentColor = '';
		this.productCurrentColorImage = null;

		this.productColorsDisplay = [];
		this.productCurrentColorDisplay = '';
		this.productCurrentColorImageBlobs = null;
		this.productMainImageBlobs = null;

		let productName: any = document.getElementById('productName');
		let productDescription: any = document.getElementById('productDescription');
		let productMainImage: any = document.getElementById('productMainImage');
		let productSubImages: any = document.getElementById('sub_images');
		let productCategory: any = document.getElementById('productCategory')
		let productTag: any = document.getElementById('productTag')
		let productPrice: any = document.getElementById('productPrice');
		let productStockStatus: any = document.getElementById('productStockStatus');
		let productTips: any = document.getElementById('productTips');
		let productColors: any = document.getElementById('productColors');
		let productColorImages: any = document.getElementById('productColorImages');
		// let productWeight: any = document.getElementById('productWeight');
		// let productBoxQuantity: any = document.getElementById('productBoxQuantity');
		// let shipping_box_vol: any = document.getElementById('shipping_box_vol');

		this.productMainImageBlobs = null;
		this.prodSampleDone = false;

		productCategory.value = 'men';
		productTag.value = 'full';

		productDescription.value = 'Product description . . .'
		// shipping_box_vol.value = '...'
		if (productSubImages != null) {
			productSubImages.value = '';
		}
		
		if (productTips != null) {
			productTips.value = null;
			this.productTips = [];
		}
		
		if (productColors != null) {
			productColors.value = '#000000';
			this.productColorsDisplay = [];
		}

		if (productColorImages != null) {
			productColorImages.value = null;
		}

		let arr = [productName, productMainImage, productPrice, productStockStatus];
		for (let i = 0; i < arr.length; i++) {
			arr[i].value = '';
		}

	}

	showThisNewsToSubscribers(value: any) {
		let news_data: any = {
			"category": 'new_product',
			"product_id": `${value}`
		}

		this.__communService.emailAboutNewCollection(news_data).subscribe(
			res => {},
			err => {}
		)
	}

	cancelProductUpload() {
		this._router.navigate(['/admin/products']);
	}

	getProductData(data: any) {}

}
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  	name: 'ordercolordefiner'
})
export class OrdercolordefinerPipe implements PipeTransform {

	transform(value: any) {
		let product_colors:number = 0;
		let color: any = [];

		value.forEach((element: any) => {
			if (!color.includes(element.color)) {
				product_colors = product_colors + 1;
			}
			color.push(element.color)
		});

		return product_colors;
	}

}
<div id="entire_add_product">
    
    <div *ngIf="isSuccess" class="success_message">
        <div class="msg_box">
            <p>{{ successMsg }}</p>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-.997-4L6.76 11.757l1.414-1.414 2.829 2.829 5.656-5.657 1.415 1.414L11.003 16z" fill="rgba(255,255,255,1)"/></svg>
        </div>
    </div>
    
    <div class="header">
        <h2>Add new product</h2>
    </div>
    <div class="form">
        <form #formData = "ngForm" (ngSubmit) = "getProductData(formData.value)" (keydown.enter)="$event.preventDefault()">
            <div *ngIf="hasErrors" class="alert_container">
                <p *ngIf="hasErrors">{{errors}}</p>
            </div>
            <div class="group col-mob-12 col-tab-12 col-large-12">
                <div class="form_group">
                    <p>Main product image</p>
                    <div class="input_drag_drop">
                        <img *ngIf="prodSampleDone" [src]="productMainImageBlobs" alt="">
                        <svg *ngIf="!prodSampleDone" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="64" height="64"><path fill="none" d="M0 0h24v24H0z"/><path d="M21 15v3h3v2h-3v3h-2v-3h-3v-2h3v-3h2zm.008-12c.548 0 .992.445.992.993V13h-2V5H4v13.999L14 9l3 3v2.829l-3-3L6.827 19H14v2H2.992A.993.993 0 0 1 2 20.007V3.993A1 1 0 0 1 2.992 3h18.016zM8 7a2 2 0 1 1 0 4 2 2 0 0 1 0-4z"/></svg>
                        <p *ngIf="!prodSampleDone">Add product image</p>
                        <input id="productMainImage" type="file" (input)="getProductMainImage($event)" accept=".png,.jpg,.jpeg" required>
                    </div>
                    <span>This is the main image of the product</span>
                </div>
                <div class="form_group">
                    <p>Sub images of the product (4 max)</p>
                    <div class="sub_input_drag_drop">
                        <input type="file" id="sub_images" (input)="getProductSubImages($event)" accept=".png,.jpg,.jpeg" multiple>
                    </div>
                </div>
                <div class="form_group color_cloth">
                    <p>Available product colors</p>
                    <input type="color" id="productColors" #prodColor (change)="getProductColors(prodColor.value)" placeholder="Enter color">
                    <input type="file" id="productColorImages" (input)="getProductColorImage($event)" accept=".png,.jpg,.jpeg">
                    <span>Corresponding image to the color above</span>
                    <button (click)="addColorProductItem()">Add color to product</button>

                    <div class="color_items_picked">
                        <ol *ngFor="let color of productColorsDisplay.slice().reverse(); let i=index">
                            <li>
                                <img [src]="color[1]" alt="">
                                <div class="color" [style.background]="color[0]" ></div>
                            </li>
                        </ol>
                    </div>

                </div>
                <br>
                <!-- <div class="form_group">
                    <p>Shipping box volumes</p>
                    <select id="shipping_box_vol" #shippingBoxVol (change)="getShippingBoxVol(shippingBoxVol.value)">
                        <option value="..." selected>L x B x H</option>
                        <option value="35x25x3">35 x 25 x 3 cm</option>
                        <option value="60x30x15">60 x 30 x 15 cm</option>
                        <option value="L+B+H=90">L + B + H = 90 cm !> 60 cm</option>
                        <option value="120x60x60">120 x 60 x 60 cm</option>
                    </select>
                </div>
                <div class="form_group">
                    <p>How many items can the box above hold</p>
                    <input type="number" id="productBoxQuantity" placeholder="How many can the above box hold" name="productBoxQuantity" ngModel #productBoxQuantity="ngModel" #prodBoxQuantity (keyup)="getProductBoxQuantity(prodBoxQuantity.value)" required min="1">
                    <span class="error_input" *ngIf="productBoxQuantity.invalid && productBoxQuantity.touched">Box quantity is required.</span> 
                </div> -->

            </div>

            <div class="group col-mob-12 col-tab-12 col-large-12">
                <div class="form_group">
                    <p>Product details</p>
                    <input type="text" id="productName" placeholder="Product name" ngModel #productName="ngModel" #prodName (keyup)="getProductName(prodName.value)" name="productName" required>
                    <span class="error_input" *ngIf="productName.invalid && productName.touched">Product name is required.</span>
                    
                    <textarea name="productDescription" id="productDescription" #prodDescript (keyup)="getProductDescription(prodDescript.value)">Product description</textarea>
                    
                    <input type="number" id="productPrice" placeholder="Product price in Rwf" name="productPrice" ngModel #productPrice="ngModel" #prodPrice (keyup)="getProductPrice(prodPrice.value)" min="0" required>
                    <span class="error_input" *ngIf="productPrice.invalid && productPrice.touched">Product price is required.</span> 
                    
                    <input type="number" id="productStockStatus" placeholder="Product quantity in stock" name="quantityInStock" ngModel #quantityInStock="ngModel" #stockStatus (keyup)="getQuantityInStock(stockStatus.value)" min="0" required>
                    <span class="error_input" *ngIf="quantityInStock.invalid && quantityInStock.touched">Product stock quantity is required.</span>
                </div>

                <div class="form_group">
                    <p>Product category</p>
                    <select #prodCate id="productCategory" (change)="getProductCategory(prodCate.value)">
                        <option value="men" selected>Men</option>
                        <option value="women">Women</option>
                        <option value="all">For all</option>
                    </select>
                </div> 
                <div class="form_group">
                    <p>Product tags</p>
                    <select #prodTag id="productTag" (change)="getProductTag(prodTag.value)">
                        <option value="full" selected>Full body</option>
                        <option value="upper">Upper body</option>
                        <option value="lower">Lower body</option>
                        <option value="head">Head wear</option>
                        <option value="foot">Foot wear</option>
                    </select>
                </div> 
                <!-- <div class="form_group">
                    <p>Weight per product (kg)</p>
                    <input type="number" id="productWeight" placeholder="Weight per product in kg" name="productWeight" ngModel #productWeight="ngModel" #prodWeight (keyup)="getProductWeight(prodWeight.value)" required min="0">
                    <span class="error_input" *ngIf="productWeight.invalid && productWeight.touched">Weight per product is required.</span> 
                </div> -->
               
                <div class="form_group tips">
                    <p>Product care tips</p>
                    <input id="productTips" #prodTip (keyup)="getProductTips(prodTip.value)" type="text" [value]="productCurrentTip" placeholder="Enter tip one" maxlength="60">
                    <button (click)="getCurrentTip()">Add tip</button>

                    <ul>
                        <li *ngFor="let tip of productTips.slice().reverse(); let i=index">{{ tip }}</li>
                    </ul>
                </div>

            </div>
            <div class="submit_form col-mob-12 col-tab-12 col-large-12" id="submit_container">
                <button (click)="cancelProductUpload()" class="col-mob-12 col-tab-5 col-large-5">Cancel and return</button>
                <button (click) = "getProductDataFinal()" class="sub_btn col-mob-12 col-tab-5 col-large-5">
                    <svg *ngIf="hasSubmitted" class="spinner_loading" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M18.364 5.636L16.95 7.05A7 7 0 1 0 19 12h2a9 9 0 1 1-2.636-6.364z" fill="rgba(255,255,255,1)"/></svg>
                    <span *ngIf="!hasSubmitted">Save product now</span>
                </button>
            </div>
        </form>
    </div>
</div>
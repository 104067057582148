import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OrdersService } from 'src/app/services/orders.service';
import { ProductsService } from 'src/app/services/products.service';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-admincompleteorders',
	templateUrl: './admincompleteorders.component.html',
	styleUrls: ['./admincompleteorders.component.css']
})
export class AdmincompleteordersComponent implements OnInit {

	constructor(
		private __ordersService: OrdersService,
		private __productsService: ProductsService,
		private _router: Router	
	) { 
		this.checkOutCookieStatus();
	}

	checkOutCookieStatus() {
		if (!navigator.cookieEnabled) {
			this.cookies_disabled = true;
		}
	}

	cookies_disabled: boolean = false;

	enableBrowserCookies() {
		alert("Press ALT + F and then S to edit your browser settings")
	}
	
	allOrders: any = [];

	apiPublic: any = environment.apiPublic;

	ngOnInit(): void {
		this.getCompletedOrders();
	}

	isSuccess: boolean = false;
	successMsg: string = '';
	hasErrorAlert: boolean = false;
	hasErrorMsg: string = '';

	loadingData: boolean = true;
	processingSubmissionId: string = '';
	hasSubmitted: boolean = false;

	noItemsInPending: boolean = false;

	getCompletedOrders() {
		this.__ordersService.getCompletedOrders().subscribe(
			res => {
				this.allOrders.push(res.data);
				this.loadingData = false;

				if (this.allOrders[0].length == 0) {
					this.noItemsInPending = true;
				}
			},
			err => {
				this.hasErrorAlert = true;
				this.hasErrorMsg = 'Something went wrong';

				let count = 0;

				let successInterval = setInterval(() => {
					if (count > 0) {
						this.hasErrorAlert = false;
						this.allOrders = [];
						this.getCompletedOrders();
						clearInterval(successInterval);
					}
	
					count = count + 1;
				}, 3000)
			}
		)
	}

	markOrderCompleted(value: any) {
		this.processingSubmissionId = value;
		this.hasSubmitted = true;
		this.__ordersService.markOrderCompleted(value).subscribe(
			res => {
				this.isSuccess = true;
				this.successMsg = 'Completed order successfully';

				let count = 0;

				let successInterval = setInterval(() => {
					if (count > 0) {
						this.hasSubmitted = false;
						this.processingSubmissionId = '';
						this.isSuccess = false;
						this.allOrders = [];
						this.getCompletedOrders();
						clearInterval(successInterval);
					}
	
					count = count + 1;
				}, 3000)
			},
			err => {
				this.hasErrorAlert = true;
				this.hasErrorMsg = err.error.message;

				if (!err.error.message) {
					this.hasErrorMsg = err.error;
				}
				
				let count = 0;

				let successInterval = setInterval(() => {
					if (count > 0) {
						this.hasSubmitted = false;
						this.processingSubmissionId = '';
						this.hasErrorAlert = false;
						clearInterval(successInterval);
					}
	
					count = count + 1;
				}, 3000)
			}
		)
	}

	isOrderDetailsOpen: boolean = false;
	orderBeingViewInDetails: any;
	itemsInCurrentOrder: any = [];
	toggleOrderDetails(event: any) {
		if (event.target !== event.currentTarget) return;
		this.isOrderDetailsOpen = false;
	}

	loadingItems: boolean = true;

	showOrderDetails(data: any) {
		this.itemsInCurrentOrder = [];
		this.orderBeingViewInDetails = data;
		this.isOrderDetailsOpen = true;

		data.cartDetails.products.forEach((element:any) => {
			this.loadingItems = true;
			this.__productsService.getProductById(element.productId).subscribe(
				res => {
					let product: any = res.data;
					let item:any = [
						element.color,
						element.number,
						element.productId,
						element.singlePrice,
						element.size,
						element.productUniqueness,
						product
					]
					this.itemsInCurrentOrder.push(item);
					this.loadingItems = false;
				},
				err => {
					this.hasErrorAlert = true;
					this.hasErrorMsg = 'Failed loading items. Reprocessing . . .'
					let count = 0;

					let successInterval = setInterval(() => {
						if (count > 0) {
							this.hasErrorAlert = false;
							clearInterval(successInterval);
						}
		
						count = count + 1;
					}, 3000)
				}
			)
		});
	}

	getItem(value: any) {
		const url:any = this._router.createUrlTree(['/product', value]);
		window.open(url.toString(), '_blank');
	}

	getSortingPickup(value: any) {
		if (value == 'ship') {
			this.allOrders[0].sort(function(a:any, b:any) {
				return (b.pickupmethod < a.pickupmethod) ? -1 : (b.pickupmethod > a.pickupmethod) ? 1 : 0;
			});
			let arr = this.allOrders[0];
			this.allOrders = [];
			this.allOrders.push(arr);
		}
		
		if (value == 'pick') {
			this.allOrders[0].sort(function(a:any, b:any) {
				return (a.pickupmethod < b.pickupmethod) ? -1 : (a.pickupmethod > b.pickupmethod) ? 1 : 0;
			});
			let arr = this.allOrders[0];
			this.allOrders = [];
			this.allOrders.push(arr);
		}
		
	}
	
	getSortingPrice(value: any) {
		if (value == 'low_high') {
			this.allOrders[0].sort(function(a:any, b:any) {
				return a.orderPrice - b.orderPrice;
			});
			let arr = this.allOrders[0];
			this.allOrders = [];
			this.allOrders.push(arr);
		}
		
		if (value == 'high_low') {
			this.allOrders[0].sort(function(a:any, b:any) {
				return b.orderPrice - a.orderPrice;
			});
			let arr = this.allOrders[0];
			this.allOrders = [];
			this.allOrders.push(arr);
		}
	}

}
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { CollectionsService } from '../../services/collections.service'

@Component({
	selector: 'app-admincollections',
	templateUrl: './admincollections.component.html',
	styleUrls: ['./admincollections.component.css']
})
export class AdmincollectionsComponent implements OnInit {

	constructor(private _router: Router, private __collectionsService: CollectionsService) { }

	publicUrl: string = environment.apiPublic;

	ngOnInit(): void {
		this.getAllCollections();
	}

	isSuccess: boolean = false;
	successMsg: string = '';
	hasErrorAlert: boolean = false;
	hasErrorMsg: string = '';
	
	collectiondata: any = [];
	
	loadingData: boolean = true;
	noCollectionStatus: boolean = false;
	
	getAllCollections() {
		this.__collectionsService.getAllCollections().subscribe(
			res => {
				this.collectiondata = res.data;
				
				if (this.collectiondata.length == 0) {
					this.noCollectionStatus = true;
				}
				
				this.loadingData = false;
			},
			err => {
				this.getAllCollections()
			}
		)
	}

	proccessingThisCollection: boolean = false;
	proccessingThisCollectionId: string = '';

	reactiveCollection(value: any) {
		this.proccessingThisCollection = true;
		this.proccessingThisCollectionId = value;
		this.__collectionsService.reactiveDisabledCollection(value).subscribe(
			res => {
			
				this.successMsg = 'Reactived collection successfully';
				this.isSuccess = true;
				
				let count = 0;

				let successInterval = setInterval(() => {
					if (count > 0) {
						this.isSuccess = false;
						this.proccessingThisCollection = false;
						this.proccessingThisCollectionId = '';
						clearInterval(successInterval);
						this.getAllCollections();
					}

					count = count + 1;
				}, 2000)

			},
			err => {
				this.hasErrorAlert = true;
				this.hasErrorMsg = 'Something went wrong try again!'

				let count = 0;

				let successInterval = setInterval(() => {
					if (count > 0) {
						this.proccessingThisCollection = false;
						this.proccessingThisCollectionId = '';
						this.hasErrorAlert = false;
						clearInterval(successInterval);
					}
	
					count = count + 1;
				}, 2000)
			}
		)
	}

	deleteCollection(value: any) {
		this.proccessingThisCollection = true;
		this.proccessingThisCollectionId = value;
		this.__collectionsService.deactivateCollection(value).subscribe(
			res => {
			
				this.successMsg = 'Deactivated collection successfully';
				this.isSuccess = true;
				
				let count = 0;

				let successInterval = setInterval(() => {
					if (count > 0) {
						this.proccessingThisCollection = false;
						this.proccessingThisCollectionId = '';
						this.isSuccess = false;
						clearInterval(successInterval);
						this.getAllCollections();
					}

					count = count + 1;
				}, 2000)

			},
			err => {
				this.hasErrorAlert = true;
				this.hasErrorMsg = 'Something went wrong try again!'

				let count = 0;

				let successInterval = setInterval(() => {
					if (count > 0) {
						this.proccessingThisCollection = false;
						this.proccessingThisCollectionId = '';
						this.hasErrorAlert = false;
						clearInterval(successInterval);
					}
	
					count = count + 1;
				}, 2000)
			}
		)
	}

	seeThisCollection(status: any, id: any) {
		if (status == true) {
			this.hasErrorAlert = true;
				this.hasErrorMsg = 'This collection is deactivated!'

				let count = 0;

				let successInterval = setInterval(() => {
					if (count > 0) {
						this.hasErrorAlert = false;
						clearInterval(successInterval);
					}
	
					count = count + 1;
				}, 2000)
		} else {
			const url:any = this._router.createUrlTree(['/collection', id]);
			window.open(url.toString(), '_blank');
		}
	}

	addCollection() {
		this._router.navigate(['/admin/addcollection']);
	}

	editCollectionNavigation(value: any) {
		this._router.navigate(['/admin/editcollection/', value]);
	}

}
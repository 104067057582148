import { Component, OnInit } from '@angular/core';
import { AdminsubscriptionsService } from '../../services/adminsubscriptions.service'

@Component({
	selector: 'app-admincontacted',
	templateUrl: './admincontacted.component.html',
	styleUrls: ['./admincontacted.component.css']
})
export class AdmincontactedComponent implements OnInit {

	constructor( private __adminContacts: AdminsubscriptionsService) { }

	ngOnInit(): void {
		this.getAllContacts();
	}

	contacts: any = [];
	loadingData: boolean = true;
	currentPicked: string = '';

	isSuccess: boolean = false;
	successMsg: string = '';
	hasSubmitted: boolean = false;
	hasErrorAlert: boolean = false;
	hasErrorMsg: string = '';

	getAllContacts() {
		this.__adminContacts.getAllContacts().subscribe(
			res => {
				this.contacts = res.data;
				this.loadingData = false;
				this.currentPicked = this.contacts[0]._id;
				this.answerNames = this.contacts[0].contactNames;
				this.answerEmail = this.contacts[0].contactEmail;

				let emailfield:any = document.getElementById('emailField');
				emailfield.value = this.contacts[0].contactEmail;
			},
			err => {
				this.getAllContacts();
			}
		)
	}

	pickMessage(Idvalue: any, Emailvalue: any, Namesvalue: any) {
		this.currentPicked = Idvalue;
	
		let message: any = document.getElementById('messageField');
		let heading: any = document.getElementById('headingField');

		message.value = '';
		heading.value = '';

		let emailfield:any = document.getElementById('emailField');
		emailfield.value = Emailvalue;
		this.answerEmail = Emailvalue;

		this.answerNames = Namesvalue;
	}

	deleteContact(value: any) {
		this.__adminContacts.deleteContact(value).subscribe(
			res => {
				this.getAllContacts();
				this.successMsg = 'Deleted message successfully!';
				this.isSuccess = true;
				
				let count = 0;
				
				let successInterval = setInterval(() => {
					if (count > 0) {
						this.isSuccess = false;
						clearInterval(successInterval);
					}

					count = count + 1;
				}, 2000)

			},
			err => {
				this.hasErrorAlert = true;
				if (err.message && err.error) {
					if (err.error.message) {
						this.hasErrorMsg = err.error.message;
					} else if (err.error) {
						this.hasErrorMsg = err.error;
					} else {
						this.hasErrorMsg = "Oops! Something went wrong!"
					}
				} else if (err.message) {
					this.hasErrorMsg = err.message;
				} else if (err.error) {
					this.hasErrorMsg = err.error;
				} else {
					this.hasErrorMsg = "Oops! Something went wrong!"
				}

				let count = 0;

				let successInterval = setInterval(() => {
					if (count > 0) {
						this.hasErrorAlert = false;
						clearInterval(successInterval);
					}
	
					count = count + 1;
				}, 2000)
			}
		)
	}

	answerheading: string = ''; 
	answermessage: string = '';
	answerNames: string = '';
	answerEmail: string = '';

	getHeading(value: any) { 
		this.answerheading = value;
	}

	getMessage(value: any) {
		this.answermessage = value;
	}

	answerClient() {
		var answerdata = {
			"answerId": `${this.currentPicked}`,
			"contactNames": `${this.answerNames}`,
			"contactHeading": `${this.answerheading}`,
			"contactEmail": `${this.answerEmail}`,
			"contactMessage": `${this.answermessage}`
		}


		this.hasSubmitted = true;

		this.__adminContacts.answer(answerdata).subscribe(
			res => {
				this.getAllContacts();
				this.successMsg = 'Replied successfully!';
				this.isSuccess = true;
				this.hasSubmitted = false;

				this.markAnswered(this.currentPicked);
				
				let message: any = document.getElementById('messageField');
				let heading: any = document.getElementById('headingField');

				message.value = '';
				heading.value = '';

				
				let count = 0;
				
				let successInterval = setInterval(() => {
					if (count > 0) {
						this.isSuccess = false;
						clearInterval(successInterval);
					}

					count = count + 1;
				}, 2000)


			},
			err => {
				this.hasSubmitted = false;
				this.hasErrorAlert = true;
				if (err.message && err.error) {
					if (err.error.message) {
						this.hasErrorMsg = err.error.message;
					} else if (err.error) {
						this.hasErrorMsg = err.error;
					} else {
						this.hasErrorMsg = "Oops! Something went wrong!"
					}
				} else if (err.message) {
					this.hasErrorMsg = err.message;
				} else if (err.error) {
					this.hasErrorMsg = err.error;
				} else {
					this.hasErrorMsg = "Oops! Something went wrong!"
				}

				let count = 0;

				let successInterval = setInterval(() => {
					if (count > 0) {
						this.hasErrorAlert = false;
						clearInterval(successInterval);
					}
	
					count = count + 1;
				}, 2000)
			}
		)
	}

	markAnswered(value: string) {
		this.__adminContacts.markAnswered(value).subscribe(
			res => {
				this.getAllContacts();
				return;
			},
			err => {
				return;
			}
		)
	}
	
}
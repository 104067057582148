import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ProductsService } from '../../services/products.service';
import { CartService } from '../../services/cart.service';
import { environment } from 'src/environments/environment';
import { CustomsService } from 'src/app/services/customs.service';

@Component({
  selector: 'app-mencollections',
  templateUrl: './mencollections.component.html',
  styleUrls: ['./mencollections.component.css']
})
export class MencollectionsComponent implements OnInit {
 
	constructor( 
		private __productsService: ProductsService, 
		private __customsService: CustomsService, 
		private _router: Router, 
		private __cartService: CartService 
	) {
		this.checkOutCookieStatus();
	}

	checkOutCookieStatus() {
		if (!navigator.cookieEnabled) {
			this.cookies_disabled = true;
		}
	}

	cookies_disabled: boolean = false;

	enableBrowserCookies() {
		alert("Press ALT + F and then S to edit your browser settings")
	}

	apiPublic: string = environment.apiPublic;

	myCart: any = [];
	shouldCreateCart: any;

	isSuccess: boolean = false;
	successMsg: string = '';
	hasSubmitted: boolean = false;
	hasErrorAlert: boolean = false;
	hasErrorMsg: string = '';

	ngOnInit(): void {
		this.checkOutCookieStatus();
		this.getAllProducts();
		this.getCurrentCurrency();
		this.getAllCurrencies();

		this.__customsService.updatePageStatus(false);

		this.__productsService.updateExchangeRates.subscribe(
			(res:any) => {
				this.currency = this.__productsService.getCurrentCurrency();
			}, (err:any) => console.log(err) 
		);
	
		this.__cartService.getMyShoppingCart().then(data => {
			this.shouldCreateCart = data;
		
			if (this.shouldCreateCart) {
				this.createNewCart();
			} 
			
			if (!this.shouldCreateCart) {
				var cookieid: any = document.cookie.match(new RegExp('(^| )' + '_mycart' + '=([^;]+)'));
				this.getMyShoppingCart(cookieid[2]);
			}
	
		});

	}

	currency: any = 'rwf';
	exchangeValue: any = 0;
	exchangeRates: any;
	loadingPrice: boolean = true;
	reprocessingcurrencies: number = 0;

	getCurrentCurrency() {
		this.currency = this.__productsService.getCurrentCurrency();
	}

	getAllCurrencies() {

		this.__productsService.getCurrencies().subscribe(
			res => {
				this.exchangeRates = res.data;
				this.exchangeValue = this.exchangeRates;

				this.loadingPrice = false;
			},
			err => {

				if (this.reprocessingcurrencies <= 2) {
					this.getAllCurrencies();
					this.reprocessingcurrencies++;
				} else {
					alert('Having trouble in processing currencies! Reprocessing . . .');
					location.reload();
				}
			})

	}

	getMyShoppingCart(value: any) {
		this.checkOutCookieStatus();
		let data = value;

		this.__cartService.getSpecificCart(data).subscribe(
			res => {
				this.myCart = res.data;
				this.getItemIdToFilterShopping(this.myCart);
			},
			err => { this.createNewCart() }
		)
	}

	createNewCart() {
		this.checkOutCookieStatus();
		let newCartData = {
			"products": []
		};

		this.__cartService.createNewCart(newCartData).subscribe(
			res => {
				document.cookie = `_mycart=${res.data._id}; path=/; max-age=` + 2147483647;
				this.getMyShoppingCart(res.data._id)
			},
			err => {
				this.createNewCart();
			}
		)
	}

	productdata: any;

	loadingData: boolean = true;
	noProductStatus: boolean = false;

	likesArr: any = [];

	getAllProducts() {
		this.__productsService.getMenProducts().subscribe(
			res => {
				this.loadingData = false;
				this.productdata = res.data;

				let cookiename: string = '_prod_likes'
				var cookiematch: any = document.cookie.match(new RegExp('(^| )' + cookiename + '=([^;]+)'));

				if (cookiematch) {
					this.likesArr = JSON.parse(cookiematch[2]);
				}

				if (this.productdata.length <= 0) {
					this.noProductStatus = true; 
				} else {
					let countDel: number = 0;
					let countAct: number = 0;
					
					this.productdata.forEach((element:any )=> {
						if (element.productDeleted == true) {
							countDel++;
						} else {
							countAct++;
						}
					});

					if (countDel > 0 && countAct > 0) {
						this.noProductStatus = false;
					} else if (countDel > 0 && countAct == 0) {
						this.noProductStatus = true;
					} else if (countDel == 0 && countAct > 0) {
						this.noProductStatus = false;
					} else {
						this.noProductStatus = false;
					}
					
				}

			},
			err => {
				this.getAllProducts()
			}
		)
	}

	getProductPage(value: any) {
		this._router.navigate(['/product/', value]);
	}

	likeProduct(value: any) {
		this.likingProductsubmissionId = value;
		this.likingProductsubmission = true;

		let cookiename: string = '_prod_likes'
		var cookiematch: any = document.cookie.match(new RegExp('(^| )' + cookiename + '=([^;]+)'));
		
		if (cookiematch) {
			let recentLikes = JSON.parse(cookiematch[2]);
			
			if (!recentLikes.includes(value)) {
				recentLikes.push(value);
				let cookiedata: any = JSON.stringify(recentLikes);
				document.cookie = `${cookiename}=${cookiedata}; path=/; max-age=` + 2147483647;

				this.likeProductServicehandler(value);
				
			} else {
				
				const index = recentLikes.indexOf(value);

				if (index > -1) {
					recentLikes.splice(index, 1);
				}

				let cookiedata: any = JSON.stringify(recentLikes);
				
				document.cookie = `${cookiename}=${cookiedata}; path=/; max-age=` + 2147483647;

				this.dislikeProductServicehandler(value);

			}

		}
		
		if (!cookiematch) {
			let cookiedata: any = JSON.stringify([value]);
			
			document.cookie = `${cookiename}=${cookiedata}; path=/; max-age=` + 2147483647;
			this.likeProductServicehandler(value);

		}
		
		this.getAllProducts();
	}

	likingProductsubmissionId: string = '';
	likingProductsubmission: boolean = false;

	likeProductServicehandler(value: any) {
		this.likingProductsubmissionId = value;
		this.likingProductsubmission = true;

		this.isSuccess = false;
        this.hasErrorAlert = false;
		this.__productsService.likeProduct(value).subscribe(
			res => {
				this.isSuccess = true;
				this.successMsg = "Liked product successfully"

				this.likingProductsubmissionId = '';
				this.likingProductsubmission = false;
				
				let count = 0;

				let successIntervalTwo = setInterval(() => {
					if (count > 0) {
						this.isSuccess = false;
						clearInterval(successIntervalTwo);
					}
					count = count + 1;
				}, 2000)
			},
			err => {
				this.isSuccess = true;
				this.successMsg = "Failed to like product!";

				this.likingProductsubmissionId = '';
				this.likingProductsubmission = false;
				
				let count = 0;

				let successIntervalTwo = setInterval(() => {
					if (count > 0) {
						this.isSuccess = false;
						clearInterval(successIntervalTwo);
					}
					count = count + 1;
				}, 2000)
			}
		)
	}

	dislikeProductServicehandler(value: any) {
		this.likingProductsubmissionId = value;
		this.likingProductsubmission = true;

		this.isSuccess = false;
        this.hasErrorAlert = false;
		this.__productsService.dislikeProduct(value).subscribe(
			res => {
				this.isSuccess = true;
				this.successMsg = "Disliked product successfully";

				this.likingProductsubmissionId = '';
				this.likingProductsubmission = false;
				
				let count = 0;

				let successIntervalTwo = setInterval(() => {
					if (count > 0) {
						this.isSuccess = false;
						clearInterval(successIntervalTwo);
					}
					count = count + 1;
				}, 2000)
			},
			err => {
				this.isSuccess = true;
				this.successMsg = "Failed to dislike product";

				this.likingProductsubmissionId = '';
				this.likingProductsubmission = false;
				
				let count = 0;

				let successIntervalTwo = setInterval(() => {
					if (count > 0) {
						this.isSuccess = false;
						clearInterval(successIntervalTwo);
					}
					count = count + 1;
				}, 2000)
			}
		)
	}

	addingToCartSubmission: boolean = false;
	addingToCartSubmissionId: string = '';

	addThisItemToCart(value: any, quantity: any) {
		this.addingToCartSubmission = true;
		this.addingToCartSubmissionId = value;

		if (quantity <= 0) {
			this.isSuccess = false;
			this.hasErrorAlert = true;
			this.hasErrorMsg = "This product is sold out!"
					
			let count = 0;
			
			let successIntervalTwo = setInterval(() => {
				if (count > 0) {
					this.hasErrorAlert = false;
					clearInterval(successIntervalTwo);
				}
				count = count + 1;
			}, 2000);

			return;
		}

		if (quantity > 0) {
			this.checkOutCookieStatus();
			this.__productsService.getProductById(value).subscribe(
				res => {
					
					var cookieid: any = document.cookie.match(new RegExp('(^| )' + '_mycart' + '=([^;]+)'));
					var shopCartId = cookieid[2];
				
					let newProductData = {
						"products": [
							...this.myCart.products
							,{
							"productId": res.data._id,
							"size": "s",
							"number": 1,
							"singlePrice": res.data.productPrice,
							"color": "default",
							"productUniqueness": res.data._id+Math.random()*1000000000000000+(new Date()).toString()
						}]
					}

					
					let quantity_reduce: any = {
						"quantity": 1
					}

					this.__productsService.reduceQuantityInStock(value, quantity_reduce).subscribe(
						res => {
					
							this.__cartService.updateCart( shopCartId , newProductData).subscribe(
								res => {
		
									this.isSuccess = true;
									this.successMsg = "Added default product to cart"
									this.addingToCartSubmission = false;
									this.addingToCartSubmissionId = '';
			
									this.updateCartNumber(1);
									this.getMyShoppingCart(cookieid[2]);
									
									let count = 0;
			
									let successIntervalTwo = setInterval(() => {
										if (count > 0) {
											this.isSuccess = false;
											clearInterval(successIntervalTwo);
										}
			
										count = count + 1;
									}, 2000)
								},
								err => {
									let quantity_increase: any = {
										"quantity": 1
									}

									this.increaseQuantityInStock(value, quantity_increase);
									this.addingToCartSubmission = false;
									this.addingToCartSubmissionId = '';
			
									this.hasErrorAlert = true;
									if (!err.error.message) {
										this.hasErrorMsg = "Something went wrong. Try again!"
									}
									this.hasErrorMsg = err.error.message;
									
									let count = 0;
									
									let successIntervalTwo = setInterval(() => {
										if (count > 0) {
											this.hasErrorAlert = false;
											clearInterval(successIntervalTwo);
										}
										count = count + 1;
									}, 2000)
								}
							)
						},
						err => {
							this.addingToCartSubmission = false;
							this.addingToCartSubmissionId = '';
	
							this.hasErrorAlert = true;
							
							this.hasErrorMsg = err.error.message;
							if (!err.error.message) {
								this.hasErrorMsg = "Something went wrong. Try again!"
							}
							
							let count = 0;
							
							let successIntervalTwo = setInterval(() => {
								if (count > 0) {
									this.hasErrorAlert = false;
									clearInterval(successIntervalTwo);
								}
								count = count + 1;
							}, 2000)
						}
					)
					
				},
				err => {
					this.addingToCartSubmission = false;
					this.addingToCartSubmissionId = '';
	
					this.hasErrorAlert = true;
					this.hasErrorMsg = err.error.message;
					if (!err.error.message) {
						this.hasErrorMsg = "Something went wrong. Try again!"
					}

					let count = 0;
					
					let successIntervalTwo = setInterval(() => {
						if (count > 0) {
							this.hasErrorAlert = false;
							clearInterval(successIntervalTwo);
						}
						count = count + 1;
					}, 2000)
				}
			)
		}
	}
 
	increaseQuantityInStock(value: any, data: any) {
		this.__productsService.increaseQuantityInStock(value, data).subscribe(
			res => {},
			err => {}
		)
	}

	updateCartNumber(value: number) {
		this.__cartService.updateCartNumber(value);
	}

	productsInCartIds: any = [];
	getItemIdToFilterShopping(data: any) {
		data.products.forEach((element: any) => {
			this.productsInCartIds.push(element.productId);
		});
	}

	editInCart() {
		this._router.navigate(['/cart']);
	}

	unableToAddToCart()  {
		this.isSuccess = false;
		this.hasErrorAlert = true;
		this.hasErrorMsg = "This product is sold out!"
				
		let count = 0;
		
		let successIntervalTwo = setInterval(() => {
			if (count > 0) {
				this.hasErrorAlert = false;
				clearInterval(successIntervalTwo);
			}
			count = count + 1;
		}, 2000)
	}

	getSortingAlgorithm(algorithm: string) {
		if (algorithm == 'latest') {
			this.productdata.sort(function(a:any, b:any) {
				return new Date(b.createdAt).valueOf() - new Date(a.createdAt).valueOf();
			});
		}
		
		if (algorithm == 'popular') {
			this.productdata.sort(function(a:any, b:any) {
				return b.productLikes - a.productLikes;
			});
		}
		
		if (algorithm == 'low_high') {
			this.productdata.sort(function(a:any, b:any) {
				return a.productPrice - b.productPrice;
			});
		}

		if (algorithm == 'high_low') {
			this.productdata.sort(function(a:any, b:any) {
				return b.productPrice - a.productPrice;
			});
		}
	}

}
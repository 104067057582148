<div id="entire_admin_login">
    <div class="login_form col-mob-12">
        <div class="header">
            <img (click)="goToClient()" src="../../../assets/app/logo.png" alt="">
            <p>Admin authentication form</p>
        </div>
        <div class="form">
            <div class="alert_container" [ngClass]="{'alert_error': isThereErrors}">
                <p *ngIf="!isThereErrors">Make sure you have the admin credentials.</p>
                <p *ngIf="isThereErrors">{{errors}}</p>
            </div>
            <form #formData = "ngForm" (ngSubmit) = "getAdminData(formData.value)">

                <input type="email" name="email" #email="ngModel" #idEmail ngModel [ngClass] = "{'redbordered': invalidEmail || requiredEmail}" (keyup) = "validateEmail(idEmail.value)" placeholder="Email address" maxlength="80" required>
                <span class="error_input" *ngIf="invalidEmail">Please you must use a valid email.</span>
                <span class="error_input" *ngIf="email.invalid && email.touched || requiredEmail">Email field is required.</span>

                <input type="password" name="password" #password="ngModel" #idPass ngModel [ngClass] = "{'redbordered': invalidPassword || requiredPassword}" (keyup) = "validatePassword(idPass.value)" placeholder="Password key" maxlength="30" required>
                <span class="error_input" *ngIf="invalidPassword">Password must be atleast 6 charachers.</span>
                <span class="error_input" *ngIf="password.invalid && password.touched || requiredPassword">Password field is required.</span>

                <button type="submit" [disabled]="!formData.valid">
                    <svg *ngIf="hasSubmitted" class="spinner_loading" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M18.364 5.636L16.95 7.05A7 7 0 1 0 19 12h2a9 9 0 1 1-2.636-6.364z" fill="rgba(255,255,255,1)"/></svg>
                    <span *ngIf="!hasSubmitted">Login admin panel now</span>
                </button>
            </form>
        </div>
    </div>
</div>
import { Component, OnInit } from '@angular/core';
import { CollectionsService } from 'src/app/services/collections.service';
import { CustomsService } from '../../services/customs.service';
import { environment } from 'src/environments/environment';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
	selector: 'app-singlecollection',
	templateUrl: './singlecollection.component.html',
	styleUrls: ['./singlecollection.component.css']
})
export class SinglecollectionComponent implements OnInit {

	constructor(
		private __customsService: CustomsService,
		private __collectionsService: CollectionsService,
		private activatedRoute: ActivatedRoute, 
		private _router: Router
	) { }

	apiPublic: string = environment.apiPublic;

	_id: string = '';
	collectiondata: any;
	loading: boolean = true;
	isSuccess: boolean = false;
	successMsg: string = '';
	hasErrorAlert: boolean = false;
	hasErrorMsg: string = '';

	ngOnInit(): void {
		this.__customsService.updatePageStatus(false);

		let collectionId: any = this.activatedRoute.snapshot.paramMap.get('collectionname');
		this._id = collectionId;

		this.getCurrentCollection(this._id);
	}

	getCurrentCollection(value: any) {
		this.isSuccess = false;
        this.hasErrorAlert = false;
		this.__collectionsService.getCollectionById(value).subscribe(
			res => {
				this.loading = false;
				this.collectiondata = res.data;
			},
			err => {
				this.hasErrorAlert = true;
				if (err.status == 404) {
					this.hasErrorMsg = "Collection not found!"
				} else {
					this.hasErrorMsg = "Oops! Something went wrong."
				}
				
				let count = 0;

				let successInterval = setInterval(() => {
					if (count > 0) {
						this.hasErrorAlert = false;
						clearInterval(successInterval);	
						this._router.navigate(['/collections']);

					}
					count = count + 1;
				}, 3000)
			}
		)
	}

	viewingPicMedia: boolean = false;
	currentImageBeingViewed: string = '';

	closeAllMediaView() {
		this.viewingPicMedia = false;
		this.currentImageBeingViewed = '';
	}

	viewPicMedia(value: string) {
		this.viewingPicMedia = !this.viewingPicMedia;
		// this.currentImageBeingViewed = this.apiPublic+value;
		this.currentImageBeingViewed = value;
	}

}
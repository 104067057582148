<div id="entire_admin_dashboard">
    <div class="admin_header">
        <h2>Welcome back, {{ username }}</h2>
    </div>
    <div class="dashboard_content">
        <div class="graph_container col-mob-12 col-tab-12 col-large-12">
            <div class="graph">
                <div class="header">
                    <p>Orders & products</p>
                </div>
                <apx-chart
                    #chart
                    [series]="_series"
                    [chart]="_chart"
                    [xaxis]="_xaxis"
                    [yaxis]="_yaxis"
                    [stroke]="_stroke"
                    [tooltip]="_tooltip"
                    [dataLabels]="_dataLabels"
                ></apx-chart>
            </div>
            <div class="graph">
                <div class="header">
                    <p>Users & clients of Inkingi shop</p>
                </div>
                <apx-chart
                    #chart_2
                    [series]="__series"
                    [chart]="__chart"
                    [dataLabels]="__dataLabels"
                    [plotOptions]="__plotOptions"
                    [xaxis]="__xaxis"
                ></apx-chart>
            </div>
        </div>
        <div class="details_container col-mob-12 col-tab-12 col-large-12">

            <div class="status_container" (click)="traverseToNavigation('orders')">
                <div class="header">
                    <p>Number of Orders</p>
                </div>
                <div class="content">
                    <div class="icons">
                        <div class="icon" style="background: #EBF5FB;">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path fill="#5DADE2 " d="M14.5 2a3.5 3.5 0 0 1 3.163 5.001L21 7a1 1 0 0 1 1 1v4a1 1 0 0 1-1 1h-1v8a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-8H3a1 1 0 0 1-1-1V8a1 1 0 0 1 1-1l3.337.001a3.5 3.5 0 0 1 5.664-3.95A3.48 3.48 0 0 1 14.5 2zM18 13H6v7h12v-7zm2-4H4v2h16V9zM9.5 4a1.5 1.5 0 0 0-.144 2.993L9.5 7H11V5.5a1.5 1.5 0 0 0-1.356-1.493L9.5 4zm5 0l-.144.007a1.5 1.5 0 0 0-1.35 1.349L13 5.5V7h1.5l.144-.007a1.5 1.5 0 0 0 0-2.986L14.5 4z"/></svg>
                        </div>
                    </div>
                    <div class="count">
                        <h3>{{ numberOfOrders | number }}</h3>
                    </div>
                </div>
            </div>
            
            <div class="status_container" (click)="traverseToNavigation('pending')">
                <div class="header">
                    <p>Pending orders</p>
                </div>
                <div class="content">
                    <div class="icons">
                        <div class="icon" style="background: #EAF2F8;">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path fill="#5499C7" d="M18.901 10a2.999 2.999 0 0 0 4.075 1.113 3.5 3.5 0 0 1-1.975 3.55L21 21h-7v-2a2 2 0 0 0-1.85-1.995L12 17a2 2 0 0 0-1.995 1.85L10 19v2H3v-6.336a3.5 3.5 0 0 1-1.979-3.553A2.999 2.999 0 0 0 5.098 10h13.803zm-.971 2H6.069l-.076.079c-.431.42-.935.76-1.486 1.002l-.096.039.589.28-.001 5.6 3.002-.001v-.072l.01-.223c.149-2.016 1.78-3.599 3.854-3.698l.208-.005.223.01a4 4 0 0 1 3.699 3.787l.004.201L19 19l.001-5.6.587-.28-.095-.04a5.002 5.002 0 0 1-1.486-1.001L17.93 12zm-.894-9a3.5 3.5 0 0 0 4.446 2.86 3.5 3.5 0 0 1-3.29 3.135L18 9H6a3.5 3.5 0 0 1-3.482-3.14A3.5 3.5 0 0 0 6.964 3h10.072zM15.6 5H8.399a5.507 5.507 0 0 1-1.49 1.816L6.661 7h10.677l-.012-.008a5.518 5.518 0 0 1-1.579-1.722L15.6 5z"/></svg>
                        </div>
                    </div>
                    <div class="count">
                        <h3>{{ numberOfPendingOrders | number }}</h3>
                    </div>
                </div>
            </div>
       
            <div class="status_container" (click)="traverseToNavigation('completed')">
                <div class="header">
                    <p>Completed Orders</p>
                </div>
                <div class="content">
                    <div class="icons">
                        <div class="icon" style="background: #EAFAF1;">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path fill="#2ECC71 " d="M14.5 2a3.5 3.5 0 0 1 3.163 5.001L21 7a1 1 0 0 1 1 1v4a1 1 0 0 1-1 1h-1v8a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-8H3a1 1 0 0 1-1-1V8a1 1 0 0 1 1-1l3.337.001a3.5 3.5 0 0 1 5.664-3.95A3.48 3.48 0 0 1 14.5 2zM18 13H6v7h12v-7zm2-4H4v2h16V9zM9.5 4a1.5 1.5 0 0 0-.144 2.993L9.5 7H11V5.5a1.5 1.5 0 0 0-1.356-1.493L9.5 4zm5 0l-.144.007a1.5 1.5 0 0 0-1.35 1.349L13 5.5V7h1.5l.144-.007a1.5 1.5 0 0 0 0-2.986L14.5 4z"/></svg>
                        </div>
                    </div>
                    <div class="count">
                        <h3>{{ numberOfCompletedOrders | number }}</h3>
                    </div>
                </div>
            </div>
      
            <div class="status_container" (click)="traverseToNavigation('products')">
                <div class="header">
                    <p>Products uploaded</p>
                </div>
                <div class="content">
                    <div class="icons">
                        <div class="icon" style="background: #FEF5E7;">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path fill="#F5B041" d="M21 13.242V20h1v2H2v-2h1v-6.758A4.496 4.496 0 0 1 1 9.5c0-.827.224-1.624.633-2.303L4.345 2.5a1 1 0 0 1 .866-.5H18.79a1 1 0 0 1 .866.5l2.702 4.682A4.496 4.496 0 0 1 21 13.242zm-2 .73a4.496 4.496 0 0 1-3.75-1.36A4.496 4.496 0 0 1 12 14.001a4.496 4.496 0 0 1-3.25-1.387A4.496 4.496 0 0 1 5 13.973V20h14v-6.027zM5.789 4L3.356 8.213a2.5 2.5 0 0 0 4.466 2.216c.335-.837 1.52-.837 1.856 0a2.5 2.5 0 0 0 4.644 0c.335-.837 1.52-.837 1.856 0a2.5 2.5 0 1 0 4.457-2.232L18.21 4H5.79z"/></svg>
                        </div>
                    </div>
                    <div class="count">
                        <h3>{{ numberOfProducts | number }}</h3>
                    </div>
                </div>
            </div>
      
            <div class="status_container" (click)="traverseToNavigation('transactions')">
                <div class="header">
                    <p>Funds earned by inkingi</p>
                </div>
                <div class="content">
                    <div class="icons">
                        <div class="icon" style="background: #E8F6F3;">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path fill="#45B39D" d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-3.5-6H14a.5.5 0 1 0 0-1h-4a2.5 2.5 0 1 1 0-5h1V6h2v2h2.5v2H10a.5.5 0 1 0 0 1h4a2.5 2.5 0 1 1 0 5h-1v2h-2v-2H8.5v-2z"/></svg>
                        </div>
                    </div>
                    <div class="count">
                        <h3>{{ fundEarnedByInkingi | number }}</h3>
                    </div>
                </div>
            </div>
                
            <div class="status_container" (click)="traverseToNavigation('transactions')">
                <div class="header">
                    <p>Failed transactins</p>
                </div>
                <div class="content">
                    <div class="icons">
                        <div class="icon" style="background: #FDEDEC;">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path fill="#E74C3C" d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-1-5h2v2h-2v-2zm2-1.645V14h-2v-1.5a1 1 0 0 1 1-1 1.5 1.5 0 1 0-1.471-1.794l-1.962-.393A3.501 3.501 0 1 1 13 13.355z"/></svg>
                        </div>
                    </div>
                    <div class="count">
                        <h3>{{ numberOfFailedTransactions | number }}</h3>
                    </div>
                </div>
            </div>
      
            <div class="status_container" (click)="traverseToNavigation('carts')">
                <div class="header">
                    <p>Inkingi users & clients</p>
                </div>
                <div class="content">
                    <div class="icons">
                        <div class="icon" style="background: #E9F7EF;">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path fill="#52BE80" d="M9.55 11.5a2.25 2.25 0 1 1 0-4.5 2.25 2.25 0 0 1 0 4.5zm.45 8.248V16.4c0-.488.144-.937.404-1.338a6.473 6.473 0 0 0-5.033 1.417A8.012 8.012 0 0 0 10 19.749zM4.453 14.66A8.462 8.462 0 0 1 9.5 13c1.043 0 2.043.188 2.967.532.878-.343 1.925-.532 3.033-.532 1.66 0 3.185.424 4.206 1.156a8 8 0 1 0-15.253.504zm14.426 1.426C18.486 15.553 17.171 15 15.5 15c-2.006 0-3.5.797-3.5 1.4V20a7.996 7.996 0 0 0 6.88-3.914zM12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm3.5-9.5a2 2 0 1 1 0-4 2 2 0 0 1 0 4z"/></svg>
                        </div>
                    </div>
                    <div class="count">
                        <h3>{{ numberOfCarts | number }}</h3>
                    </div>
                </div>
            </div>
      
            <div class="status_container" (click)="traverseToNavigation('subscribers')">
                <div class="header">
                    <p>Inkingi subscribers</p>
                </div>
                <div class="content">
                    <div class="icons">
                        <div class="icon" style="background: #FDEDEC;">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path fill="#EC7063" d="M22 14h-2V7.238l-7.928 7.1L4 7.216V19h10v2H3a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h18a1 1 0 0 1 1 1v10zM4.511 5l7.55 6.662L19.502 5H4.511zM19 22l-3.536-3.536 1.415-1.414L19 19.172l3.536-3.536 1.414 1.414L19 22z"/></svg>
                        </div>
                    </div>
                    <div class="count">
                        <h3>{{ numberOfSubscribers | number }}</h3>
                    </div>
                </div>
            </div>
      
            <div class="status_container" (click)="traverseToNavigation('dashboard')">
                <div class="header">
                    <p>Notifications rendered</p>
                </div>
                <div class="content">
                    <div class="icons">
                        <div class="icon" style="background: #F4F6F6;">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path fill="#AAB7B8" d="M20 17h2v2H2v-2h2v-7a8 8 0 1 1 16 0v7zm-2 0v-7a6 6 0 1 0-12 0v7h12zm-9 4h6v2H9v-2z"/></svg>
                        </div>
                    </div>
                    <div class="count">
                        <h3>{{ numberOfNotifications | number }}</h3>
                    </div>
                </div>
            </div>
      
            <div class="status_container" (click)="traverseToNavigation('carts')">
                <div class="header">
                    <p>Active carts</p>
                </div>
                <div class="content">
                    <div class="icons">
                        <div class="icon" style="background: #FBEEE6;">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path fill="#DC7633" d="M21 13v7a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1v-7H2v-2l1-5h18l1 5v2h-1zM5 13v6h14v-6H5zm-.96-2h15.92l-.6-3H4.64l-.6 3zM6 14h8v3H6v-3zM3 3h18v2H3V3z"/></svg>
                        </div>
                    </div>
                    <div class="count">
                        <h3>{{ numberOfCarts | number }}</h3>
                    </div>
                </div>
            </div>
      
            <div class="status_container" (click)="traverseToNavigation('sessions')">
                <div class="header">
                    <p>Client sessions</p>
                </div>
                <div class="content">
                    <div class="icons">
                        <div class="icon" style="background: #EBEDEF;">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path fill="#5D6D7E" d="M12 13l6 9H6l6-9zm0 3.6L9.74 20h4.52L12 16.6zm-1.06-6.04a1.5 1.5 0 1 1 2.12-2.12 1.5 1.5 0 0 1-2.12 2.12zM5.281 2.783l1.415 1.415a7.5 7.5 0 0 0 0 10.606l-1.415 1.415a9.5 9.5 0 0 1 0-13.436zm13.436 0a9.5 9.5 0 0 1 0 13.436l-1.415-1.415a7.5 7.5 0 0 0 0-10.606l1.415-1.415zM8.11 5.611l1.414 1.414a3.5 3.5 0 0 0 0 4.95l-1.414 1.414a5.5 5.5 0 0 1 0-7.778zm7.778 0a5.5 5.5 0 0 1 0 7.778l-1.414-1.414a3.5 3.5 0 0 0 0-4.95l1.414-1.414z"/></svg>
                        </div>
                    </div>
                    <div class="count">
                        <h3>{{ numberOfSessions | number}}</h3>
                    </div>
                </div>
            </div>
  
            <div class="status_container" (click)="traverseToNavigation('contact')">
                <div class="header">
                    <p>Messages by contacts</p>
                </div>
                <div class="content">
                    <div class="icons">
                        <div class="icon" style="background: #E8F6F3;">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path fill="#16A085" d="M5.455 15L1 18.5V3a1 1 0 0 1 1-1h15a1 1 0 0 1 1 1v12H5.455zm-.692-2H16V4H3v10.385L4.763 13zM8 17h10.237L20 18.385V8h1a1 1 0 0 1 1 1v13.5L17.545 19H9a1 1 0 0 1-1-1v-1z"/></svg>
                        </div>
                    </div>
                    <div class="count">
                        <h3>{{ numberOfContactMessages | number }}</h3>
                    </div>
                </div>
            </div>
      
        </div>
    </div>
</div>
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  	name: 'phone'
})
export class PhonePipe implements PipeTransform {

	transform(value: any) {

		let phone: any = '';

		if (value.split('')[0] == '+' && value.length == 13) {
			phone = value.substring(0,4).split(' ') + ' ' + value.substring(4,7).split(' ') + ' ' + value.substring(7, 10).split(' ') + ' ' + value.substring(10, 13).split(' ');
		} else {
			phone = value;
		}

		return phone;
	}

}
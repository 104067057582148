import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import jwt_decode from 'jwt-decode';
import { environment } from 'src/environments/environment';

@Injectable({
  	providedIn: 'root'
})
export class AdminService {

	constructor( private _http: HttpClient) { }

	check_auth_token(auth_token: any) {
		return this._http.get<any>(`${environment.apiUrl}admin/checkAuthToken/${auth_token}`);
	}

	getAdminData() {
		let token:any = localStorage.getItem('inkingi_auth_token');
		let adminData:any = jwt_decode(token);
		return this._http.get<any>(`${environment.apiUrl}admin/getOneAdmin/${adminData._id}`);
	}

	updateAdmin(data: any) {
		let token:any = localStorage.getItem('inkingi_auth_token');
		let adminData:any = jwt_decode(token);
		let _id = adminData._id;
		return this._http.put<any>(`${environment.apiUrl}admin/updateAdmin/${_id}`,data);
	}
	
}
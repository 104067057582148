import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  	name: 'getDateIntervals'
})
export class GetDateIntervalsPipe implements PipeTransform {

	transform(value: any) {
		let timeInterval:any = '';
		let date_today: any = new Date().getTime();
		let date_then: any = new Date(value).getTime();
		var hours: any = Math.abs(date_today - date_then) / 36e5;

		hours = hours.toFixed(2)*60;

		if (hours < 1) {
			timeInterval = Math.ceil(hours*60).toString() + ' seconds ago';
		}

		if (hours < 60 && hours > 1) {
			timeInterval = Math.floor(hours).toString() + ' minutes ago';
		}
		
		if (hours > 60) {
			let time: any = Math.ceil(hours/60);
			timeInterval = time.toString() + ' hours ago';
		}
		
		if (hours > 1440) {
			let time: any = (hours/(60*24)).toFixed(1);
			if (time < 1.5) {
				timeInterval = Math.floor(time).toString() + ' days ago';
			}
			
			if (time > 1.5) {
				timeInterval = Math.ceil(time).toString() + ' days ago';
			}
		}

		return timeInterval;
	}

}
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { SwiperOptions} from 'swiper';
import { CollectionsService } from '../services/collections.service';
import { CustomsService } from '../services/customs.service';
import { ProductsService } from '../services/products.service';

interface OnDestroy {
    ngOnDestroy(): void
}

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.css'],
})
export class HomeComponent implements OnInit, OnDestroy {

	constructor( 
        private __customsService: CustomsService, 
        private __collectionsService: CollectionsService, 
        private _router: Router,
        private __productsService: ProductsService 
    ) {
		this.checkOutCookieStatus();
	}

	checkOutCookieStatus() {
		if (!navigator.cookieEnabled) {
			this.cookies_disabled = true;
		}
	}

	cookies_disabled: boolean = false;

	enableBrowserCookies() {
		alert("Press ALT + F and then S to edit your browser settings")
	}

    publicUrl: string = environment.apiPublic;
    loading: boolean = true;

	ngOnInit(): void {

        if (this.cookies_disabled ==  false) {   
            this.getCollectionsChanged();
            this.getWebsiteCustomizations();
            this.getFewProducts();
        }

        window.scroll(0,1);
//         this.carousel();
//         this.moverSlide();
    }

    isSuccess: boolean = false;
    successMsg: string = '';
    hasErrorAlert: boolean = false;
    hasErrorMsg: string = '';
    myIndex: number = 0;
    imageChangeInterval: any;
    imageSliderInterval: any;
    showBtns: boolean = false;
    
//     carousel() {
//         var i;
//         var x: any = document.getElementsByClassName("slide");

//         this.imageChangeInterval = setInterval(() => {
//             for (i = 0; i < x.length; i++) {
//                 x[i].style.display = "none";  
//             }
//             this.myIndex++;
//             if (this.myIndex > x.length) {this.myIndex = 1}    
//             x[this.myIndex-1].style.display = "block"; 
//         }, 3000)
//     }

//     toRemove: number = 0;
//     moverSlide() {
//         this.toRemove = 0;
//         this.imageSliderInterval = setInterval(() => {
//             var carousel: any = document.getElementById('carousel')
//             if (this.toRemove > 287.1) {
//                 this.toRemove = 0;
//                 carousel.style.transition = 'unset';
//                 carousel.style.marginLeft= `${this.toRemove}%`;
//             } else {
//                 carousel.style.transition = '1s ease';
//                 carousel.style.marginLeft= `-${this.toRemove}%`;
//                 this.toRemove = this.toRemove+102.8;
//             }
//         }, 5000)
//     }
    
//     count: number = 0;
//     toRemoveBack: number = 102.8;

//     moverSlidePrevious() {
//         var carousel: any = document.getElementById('carousel');
//         if (this.count == 0) {
//             carousel.style.marginLeft= `-${this.toRemoveBack}%`;
//             this.toRemoveBack = 102.8 * 2;
//             this.count = this.count + 1;
//         } else {
//             carousel.style.marginLeft= `-${this.toRemoveBack}%`;
//             this.toRemoveBack = 102.8;
//             this.count = 0;
//         }
//     }

//     moverSlideForward() {
//         var carousel: any = document.getElementById('carousel');
//         if (this.count == 0) {
//             carousel.style.marginLeft= `-${this.toRemoveBack}%`;
//             this.toRemoveBack = 102.8 * 2;
//             this.count = this.count + 1;
//         } else {
//             carousel.style.marginLeft= `-${this.toRemoveBack}%`;
//             this.toRemoveBack = 102.8;
//             this.count = 0
//         }
//     }

    showBtnFunc() {
        this.showBtns = true;
    }

	viewingVidMedia: boolean = false;
	currentVideoBeingViewed: string = '';
    
	closeAllMediaView() {
		this.viewingVidMedia = false;
		this.currentVideoBeingViewed = '';
	}

	viewVidMedia(value: string) {
		this.viewingVidMedia = true;
		this.currentVideoBeingViewed = value;
	}

    currentCustomizations: any = [];
    playingVideo: boolean = false;

	getWebsiteCustomizations() {
		this.__customsService.getWebsiteCustomizations().subscribe(
			res => {
                this.currentCustomizations = res.data[0];
                this.currentVideoBeingViewed = this.currentCustomizations.videoShots[0];
                this.loading = false;
                setTimeout(() => {
                    this.playingVideo = false;
                    this.pausePlay();
                }, 5000)
			},
			err => {
				this.getWebsiteCustomizations();
			}
		)
	}

    pausePlay() {
        let video: any = document.getElementById('video');
        let video_back: any = document.getElementById('video_back');
        video.muted = true;
        video_back.muted = true;
        if (this.playingVideo) {
            video.pause();
            video_back.pause();
            this.playingVideo = !this.playingVideo;
        } else {
            video.play();
            video_back.play();
            this.playingVideo = !this.playingVideo;
        }
        if (typeof video.loop == 'boolean') {
            video.loop = true;
            video_back.loop = true;
        } else {
            video.addEventListener('ended', function () {
                video.currentTime = 0;
                video.play();
            }, false)
            video_back.addEventListener('ended', function () {
                video_back.currentTime = 0;
                video_back.play();
            }, false);
        }
    }

    config: SwiperOptions = {
        pagination: { 
            el: '.swiper-pagination', 
            clickable: true 
        },
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev'
        },
        spaceBetween: 0,
        observer: true,
        observeParents: true,
        parallax:true,
        loop: true,
        centeredSlides: true,
        slidesPerView: 1,
        autoplay: true,
        simulateTouch: true,
        speed: 1000
    };

    config_2: SwiperOptions = {
        pagination: { 
            el: '.swiper-pagination', 
            clickable: true 
        },
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev'
        },
        spaceBetween: 0,
        observer: true,
        observeParents: true,
        parallax:true,
        loop: true,
        centeredSlides: true,
        slidesPerView: 4,
        autoplay: true,
        simulateTouch: true,
        speed: 1000
    };
    
    ngOnDestroy() {
        clearInterval(this.imageChangeInterval);
        clearInterval(this.imageSliderInterval);
    }

    collectionsArr: any = [];
    len: number = 0;

    getCollectionsChanged() {

        this.__collectionsService.getAllCollections().subscribe(
            res => {
                this.collectionsArr = res.data;
                this.len = this.collectionsArr.length;
            }, err => {
                this.getCollectionsChanged();
            }
        )
        
    }

    getThisCollection(value: any, status: any) {
        this.isSuccess = false;
        this.hasErrorAlert = false;
        if (status == true) {
			this.hasErrorAlert = true;
				this.hasErrorMsg = 'This collection has been deactivated!';

				let count = 0;

				let successInterval = setInterval(() => {
					if (count > 0) {
						this.hasErrorAlert = false;
						clearInterval(successInterval);
					}
					count = count + 1;
				}, 2500)
		} else {
			this._router.navigate(['/collection', value]);
		}
    }

    loadingFewProducts: boolean = true;
    fewProducts: any = [];
    count_getting: number = 0;

    getFewProducts() {
        this.__productsService.getAllProductsLimitedToANumber().subscribe(
            res => {
                this.fewProducts = res.data;
                this.loadingFewProducts = false;

            },
            err => {
                if (this.count_getting < 3) {
                    this.getFewProducts();
                }

                if (this.count_getting > 3) {
                    location.reload();
                }
            }
        )

        this.count_getting++;
    }

    viewSlideProduct(value:any) {
        this._router.navigate(['/product/', value]);
    }

}

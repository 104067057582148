import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http'

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { ClientoutletComponent } from './clientoutlet/clientoutlet.component';
import { MencollectionsComponent } from './components/mencollections/mencollections.component';
import { WomencollectionsComponent } from './components/womencollections/womencollections.component';
import { ShopComponent } from './components/shop/shop.component';
import { CartComponent } from './components/cart/cart.component';
import { ContactComponent } from './components/contact/contact.component';
import { SearchComponent } from './components/search/search.component';
import { ClothingsComponent } from './components/clothings/clothings.component';
import { SingleproductComponent } from './components/singleproduct/singleproduct.component';
import { CheckoutComponent } from './components/checkout/checkout.component';
import { PaymentComponent } from './components/payment/payment.component';
import { AdminoutletComponent } from './adminoutlet/adminoutlet.component';
import { AdmindashboardComponent } from './components/admindashboard/admindashboard.component';
import { AdminallordersComponent } from './components/adminallorders/adminallorders.component';
import { AdmincompleteordersComponent } from './components/admincompleteorders/admincompleteorders.component';
import { AdminallproductsComponent } from './components/adminallproducts/adminallproducts.component';
import { AdminsubscribersComponent } from './components/adminsubscribers/adminsubscribers.component';
import { AdmincontactedComponent } from './components/admincontacted/admincontacted.component';
import { NgApexchartsModule } from 'ng-apexcharts';
import { AdminauthComponent } from './components/adminauth/adminauth.component';
import { AdminaddproductComponent } from './components/adminaddproduct/adminaddproduct.component';

import { AuthGuard } from './common/auth.guard';
import { AuthService } from './services/auth.service';
import { AuthinterceptorService } from './services/authinterceptor.service';
import { LoaderComponent } from './components/loader/loader.component';
import { UnsubscribeComponent } from './components/unsubscribe/unsubscribe.component';

import { NgxUsefulSwiperModule } from 'ngx-useful-swiper';

import { NO_ERRORS_SCHEMA } from '@angular/core';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { AdmincollectionsComponent } from './components/admincollections/admincollections.component';
import { AdmincartsComponent } from './components/admincarts/admincarts.component';
import { AdmintransitionsComponent } from './components/admintransitions/admintransitions.component';
import { AdmincustomizationComponent } from './components/admincustomization/admincustomization.component';
import { AdminsessionsComponent } from './components/adminsessions/adminsessions.component';
import { GeneralDirective } from './directives/general.directive';
import { AdminaddcollectionComponent } from './components/adminaddcollection/adminaddcollection.component';
import { SinglecollectionComponent } from './components/singlecollection/singlecollection.component';

import { CurrenciesPipe } from './pipes/currencies.pipe';
import { ShippingComponent } from './components/shipping/shipping.component';

import { FlutterwaveModule } from "flutterwave-angular-v3";
import { SuccessfullpaymentComponent } from './components/successfullpayment/successfullpayment.component';
import { TrimtipsPipe } from './pipes/trimtips.pipe';
import { AdminpendingordersComponent } from './components/adminpendingorders/adminpendingorders.component';
import { OrdersproductnamefilteringPipe } from './pipes/ordersproductnamefiltering.pipe';
import { GetDateIntervalsPipe } from './pipes/get-date-intervals.pipe';
import { ServiceWorkerModule } from '@angular/service-worker';
import { TimetrimPipe } from './pipes/timetrim.pipe';
import { OrdercolordefinerPipe } from './pipes/ordercolordefiner.pipe';
import { OrdersizedefinerPipe } from './pipes/ordersizedefiner.pipe';
import { PhonePipe } from './pipes/phone.pipe';
import { EditshippingComponent } from './components/editshipping/editshipping.component';
import { AdmineditcollectionComponent } from './components/admineditcollection/admineditcollection.component';
import { AdmineditproductComponent } from './components/admineditproduct/admineditproduct.component';
import { LnglattrimPipe } from './pipes/lnglattrim.pipe';
import { PaymentfailedComponent } from './components/paymentfailed/paymentfailed.component'

@NgModule({
	declarations: [
		AppComponent,
		HomeComponent,
		PageNotFoundComponent,
		ClientoutletComponent,
		MencollectionsComponent,
		WomencollectionsComponent,
		ShopComponent,
		CartComponent,
		ContactComponent,
		SearchComponent,
		ClothingsComponent,
		SingleproductComponent,
		CheckoutComponent,
		PaymentComponent,
		AdminoutletComponent,
		AdmindashboardComponent,
		AdminallordersComponent,
		AdmincompleteordersComponent,
		AdminallproductsComponent,
		AdminsubscribersComponent,
		AdmincontactedComponent,
		AdminauthComponent,
		AdminaddproductComponent,
		LoaderComponent,
		UnsubscribeComponent,
		AdmincollectionsComponent,
		AdmincartsComponent,
		AdmintransitionsComponent,
		AdmincustomizationComponent,
		AdminsessionsComponent,
		GeneralDirective,
		AdminaddcollectionComponent,
		SinglecollectionComponent,
		CurrenciesPipe,
		ShippingComponent,
		SuccessfullpaymentComponent,
		TrimtipsPipe,
		AdminpendingordersComponent,
		OrdersproductnamefilteringPipe,
		GetDateIntervalsPipe,
		TimetrimPipe,
		OrdercolordefinerPipe,
		OrdersizedefinerPipe,
		PhonePipe,
		EditshippingComponent,
		AdmineditcollectionComponent,
		AdmineditproductComponent,
		LnglattrimPipe,
		PaymentfailedComponent
	],
	imports: [
		BrowserModule,
		AppRoutingModule,
		NgApexchartsModule,
		FormsModule,
		ReactiveFormsModule,
		HttpClientModule,
		NgxUsefulSwiperModule,
		FlutterwaveModule,
		ServiceWorkerModule.register('ngsw-worker.js', {
			// enabled: environment.production,
			enabled: true,
			// Register the ServiceWorker as soon as the app is stable
			// or after 30 seconds (whichever comes first).
			registrationStrategy: 'registerWhenStable:30000'
		})
	],
	providers: [
		AuthGuard,
		AuthService,
		{
			provide: HTTP_INTERCEPTORS,
			useClass: AuthinterceptorService,
			multi: true
		}
	],
	schemas: [
        CUSTOM_ELEMENTS_SCHEMA,
        NO_ERRORS_SCHEMA
    ],
	bootstrap: [AppComponent],
})
export class AppModule { }
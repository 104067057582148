import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommunicationService } from 'src/app/services/communication.service';
import { OrdersService } from 'src/app/services/orders.service';
import { TransactionsService } from 'src/app/services/transactions.service';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-paymentfailed',
	templateUrl: './paymentfailed.component.html',
	styleUrls: ['./paymentfailed.component.css']
})
export class PaymentfailedComponent implements OnInit {

	constructor(
		private _router: Router,
		private __tansactionsService: TransactionsService,
		private activatedRoute: ActivatedRoute, 
		private __ordersService: OrdersService,
		private __communService: CommunicationService
	){ 
		let status: any = this.activatedRoute.snapshot.paramMap.get('status');

		this.status = status;
	}

	status: string = '';

	ngOnInit(): void {
		// let paymentsuccessurl: any = location.href.toString();

		// let status: any = paymentsuccessurl.split('?')[1];
		// let tx_ref: any = status.split('&')[1];
		// let transaction_id: any = status.split('&')[2];

		if (this.status == 'success') {	

			localStorage.removeItem('__payment_token');
			localStorage.removeItem('__checkout_credt_id');
			document.cookie = "_mycart= ; expires = Thu, 01 Jan 1970 00:00:00 GMT";

			let date: any = new Date();
			let noteData = {
	
				"notificationTitle": "Transaction failure!",
				"notificationDescription": `There is a transaction that has failed to be stored in you database on ${date}. Consider checking it out quickly.`,
				"notificationCategory": "transactions"
				
			}
	
			this.__communService.sendNotification(noteData).subscribe(
				res => {
					if (res) console.log('Processing . . .');
				}, err => {
					if (err) console.log('Processing . . .');
				}
			)

			this.loading = false;
			
		} else {
			this.hasErrorAlert = true;
			this.hasErrorMsg = "You payment has failed! Try again."
			
			let count = 0;

			let successInterval = setInterval(() => {
				if (count > 0) {
					this.hasErrorAlert = false;
					clearInterval(successInterval);
					this._router.navigate(['/payment']);					
				}
				count = count + 1;
			}, 3000)
		}
	}

	loading: boolean = true;
	isSuccess: boolean = false;
	successMsg: string = '';
	hasErrorAlert: boolean = false;
	hasErrorMsg: string = '';

	navigatetoshop() {
		// this._router.navigate(['/shop']);
		let url: any = `${environment.frontendUrl}shop`;
		window.location.replace(url);
	}

	navigatetohome() {
		this._router.navigate(['/'])
	}

}
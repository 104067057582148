import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProductsService } from '../../services/products.service';
import { CartService } from '../../services/cart.service';
import { environment } from 'src/environments/environment';
import { CustomsService } from 'src/app/services/customs.service';

@Component({
	selector: 'app-singleproduct',
	templateUrl: './singleproduct.component.html',
	styleUrls: ['./singleproduct.component.css']
})
export class SingleproductComponent implements OnInit {

	constructor( 
		private __productsService: ProductsService, 
		private __customsService: CustomsService, 
		private _router: Router, 
		private activatedRoute: ActivatedRoute, 
		private __cartService: CartService 
	) { 
		let prodId: any = this.activatedRoute.snapshot.paramMap.get('prodid');
		this._id = prodId;
		this.checkOutCookieStatus();
	}

	checkOutCookieStatus() {
		if (!navigator.cookieEnabled) {
			this.cookies_disabled = true;
		}
	}

	cookies_disabled: boolean = false;

	enableBrowserCookies() {
		alert("Press ALT + F and then S to edit your browser settings")
	}
	apiPublic: string = environment.apiPublic;

	_id: string = '';

	myCart: any = [];
	shouldCreateCart: any;
	loading: boolean = true;

	ngOnInit(): void {
		this.getCurrentCurrency();
		this.getAllCurrencies();

		this.__customsService.updatePageStatus(false);

		this.__productsService.updateExchangeRates.subscribe(
			(res:any) => {
				this.currency = this.__productsService.getCurrentCurrency();
			}, (err:any) => console.log(err) 
		);
		let prodId: any = this.activatedRoute.snapshot.paramMap.get('prodid');

		this._id = prodId;

		this.getThisProduct();

		this.__cartService.getMyShoppingCart().then(data => {
			this.shouldCreateCart = data;
		
			if (this.shouldCreateCart) {
				this.createNewCart();
			} 
			
			if (!this.shouldCreateCart) {
				var cookieid: any = document.cookie.match(new RegExp('(^| )' + '_mycart' + '=([^;]+)'));
				this.getMyShoppingCart(cookieid[2]);
			}
	
		});
	}

	isSuccess: boolean = false;
	successMsg: string = '';
	hasErrorAlert: boolean = false;
	hasErrorMsg: string = '';

	currency: any = 'rwf';
	exchangeValue: any = 0;
	exchangeRates: any;
	loadingPrice: boolean = true;
	reprocessingcurrencies: number = 0;

	getCurrentCurrency() {
		this.currency = this.__productsService.getCurrentCurrency();
	}

	getAllCurrencies() {

		this.__productsService.getCurrencies().subscribe(
			res => {
				this.exchangeRates = res.data;
				this.exchangeValue = this.exchangeRates;
				this.loadingPrice = false;
			},
			err => {

				if (this.reprocessingcurrencies <= 2) {
					this.getAllCurrencies();
					this.reprocessingcurrencies++;
				} else {
					alert('Having trouble in processing currencies! Reprocessing . . .');
					location.reload();
				}

			})

	}
	
	getMyShoppingCart(value: any) {
		this.checkOutCookieStatus();
		let data = value;

		this.__cartService.getSpecificCart(data).subscribe(
			res => {
				this.myCart = res.data;
			},
			err => { this.createNewCart() }
		)
	}

	createNewCart() {
		this.checkOutCookieStatus();

		let newCartData = {
			"products": []
		};

		this.__cartService.createNewCart(newCartData).subscribe(
			res => {
				document.cookie = `_mycart=${res.data._id}; path=/; max-age=` + 2147483647;
				this.getMyShoppingCart(res.data._id);
			},
			err => {
				this.createNewCart();
			}
		)
	}

	productdata: any;
	loadingData: boolean = true;
	noProductStatus: boolean = false;

	cartNumber: number = 0;
	public numberOfItemsPerItem: any;

	getThisProduct() {
		this.__productsService.getProductById(this._id).subscribe(
			res => {
				this.loadingData = false;
				this.productdata = res.data;

				this.productMainImage = this.productdata.productMainImage;
				this.loading = false;
			},
			err => {
				this._router.navigate(['/shop']);
			}
		)
	}

	productMainImage: string = '';
	clothSize: string = 's';
	clothColor: string = 'default';
	clothNumbers: number = 1;

	changeMainImage(value: any) {
		this.productMainImage = value;
	}

	changeMainImageColor(imagevalue: any, colorvalue: any) {
		this.productMainImage = imagevalue;
		this.clothColor = colorvalue;
		window.scrollTo(0, 0);
	}

	changeClothSize(value: any) {
		this.clothSize = value;
	}

	getNumberOfItems(value: any) {
		this.numberOfItemsPerItem = value;
		this.clothNumbers = parseInt(value);
	}

	addingToChat: boolean = false;

	addToCart(value: any, prod_stock_status: any, productDeleted: boolean) {
		this.addingToChat = true;
		this.checkOutCookieStatus();

		if (this.clothNumbers == undefined || this.clothNumbers == null) {
			this.hasErrorAlert = true;
			this.hasErrorMsg = 'Invalid number';
			
			let count = 0;

			let successInterval = setInterval(() => {
				if (count > 0) {
					this.hasErrorAlert = false;
					clearInterval(successInterval);
					this.addingToChat = false;			
				}
				count = count + 1;
			}, 500)
			return;
		}

		if (productDeleted) {
			this.hasErrorAlert = true;
			this.hasErrorMsg = 'Product currently deactivated';

			let count = 0;

			let successInterval = setInterval(() => {
				if (count > 0) {
					this.hasErrorAlert = false;
					clearInterval(successInterval);
					this.addingToChat = false;			
				}
				count = count + 1;
			}, 500)
			return;
		}
		
		if (this.clothNumbers == 0 || this.clothNumbers < 0) {
			this.hasErrorAlert = true;
			this.hasErrorMsg = 'Quantity cannot be 0 or less!';
			
			let count = 0;

			let successInterval_3 = setInterval(() => {
				if (count > 0) {
					this.hasErrorAlert = false;
					this.addingToChat = false;
					clearInterval(successInterval_3);			
				}
				count = count + 1;
			}, 2000)
			return;
		}

		if (prod_stock_status < this.clothNumbers) {
			this.hasErrorAlert = true;
			this.hasErrorMsg = 'Not enough products in stock!';
			
			let count = 0;

			let successInterval = setInterval(() => {
				if (count > 0) {
					this.hasErrorAlert = false;
					this.addingToChat = false;
					clearInterval(successInterval);			
				}
				count = count + 1;
			}, 2000);
			
			return;
		}

		var quantity_reduce: any = {
			"quantity": this.clothNumbers
		}

		this.__productsService.reduceQuantityInStock(value, quantity_reduce).subscribe(
			res => {
				this.__productsService.getProductById(value).subscribe(
					res => {
						
						var cookieid: any = document.cookie.match(new RegExp('(^| )' + '_mycart' + '=([^;]+)'));
						var shopCartId = cookieid[2];
					
						let newProductData = {
							"products": [
								...this.myCart.products
								,{
								"productId": res.data._id,
								"size": this.clothSize,
								"number": this.clothNumbers,
								"singlePrice": res.data.productPrice,
								"color": this.clothColor,
								"productUniqueness": res.data._id+Math.random()*1000000000000000+(new Date()).toString()
							}]
						}
						this.__cartService.updateCart( shopCartId , newProductData).subscribe(
							res => {
								this.updateCartNumber(this.clothNumbers);
								this._router.navigate(['/cart']);
								this.addingToChat = false;
							},
							err => {
								this.hasErrorAlert = true;
								
								if (err.error.message) {
									this.hasErrorMsg = err.error.message;
								} else if (err.error) {
									this.hasErrorMsg = err.error;
								} else {
									this.hasErrorMsg = "Oops! Something went wrong!"
								}
								
								let count = 0;
		
								let successInterval = setInterval(() => {
									if (count > 0) {
										this.hasErrorAlert = false;
										this.addingToChat = false;
										clearInterval(successInterval);
										this.increaseQuantityInStock(value, quantity_reduce);
									}
									count = count + 1;
								}, 3000)
							}
						)
					},
					err => {
						this.hasErrorAlert = true;
						
						if (err.error.message) {
							this.hasErrorMsg = err.error.message;
						} else if (err.error) {
							this.hasErrorMsg = err.error;
						} else {
							this.hasErrorMsg = "Oops! Something went wrong!"
						}
						
						let count = 0;
		
						let successInterval = setInterval(() => {
							if (count > 0) {
								this.hasErrorAlert = false;
								this.addingToChat = false;
								clearInterval(successInterval);
								this.increaseQuantityInStock(value, quantity_reduce);
							}
							count = count + 1;
						}, 3000)
					}
				)
			}, err => {
				this.addingToChat = false;
				this.hasErrorAlert = true;
				
				this.hasErrorMsg = err.error.message;
				if (!err.error.message) {
					this.hasErrorMsg = "Something went wrong. Try again!"
				}
						
				let count = 0;
				
				let successIntervalTwo = setInterval(() => {
					if (count > 0) {
						this.hasErrorAlert = false;
						clearInterval(successIntervalTwo);
					}
					count = count + 1;
				}, 2000)
			}
		)
	}

	increaseQuantityInStock(value: any, data: any) {
		this.__productsService.increaseQuantityInStock(value, data).subscribe(
			res => {},
			err => {}
		)
	}
 
	updateCartNumber(value: number) {
		this.__cartService.updateCartNumber(value);
	}

}
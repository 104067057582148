import { Component, HostListener, OnInit } from '@angular/core';
import { CartService } from 'src/app/services/cart.service';

@Component({
	selector: 'app-admincarts',
	templateUrl: './admincarts.component.html',
	styleUrls: ['./admincarts.component.css']
})
export class AdmincartsComponent implements OnInit {

	constructor(	
		private __cartService: CartService
	) { }

	ngOnInit(): void {
		this.getAllTransactions();
	}

	isSuccess: boolean = false;
	successMsg: string = '';
	hasSubmitted: boolean = false;
	hasErrorAlert: boolean = false;
	hasErrorMsg: string = '';

	carts: any = [];
	carts_backup: any = [];
	loadingCartsData: boolean = true;

	noCartsStatus: boolean = false;

	getAllTransactions() {
		this.__cartService.getAllCarts().subscribe(
			res => {
				this.carts.push(res.data);
				this.carts_backup.push(res.data);
				
				if (this.carts[0].length == 0) {
					this.noCartsStatus = true;
				}
				this.loadingCartsData = false;		
				
			},
			err => {
				this.hasErrorAlert = true;
				this.hasErrorMsg = "Something went wrong. Reloading . . ."
				
				let count = 0;

				let successIntervalTwo = setInterval(() => {
					if (count > 0) {
						this.hasErrorAlert = false;
						clearInterval(successIntervalTwo);
						location.reload();
					}

					count = count + 1;
				}, 2000)
			}
		)
	}

	currentViewedRow: string = '';
	currentRowView(value: string) {
		this.currentViewedRow = value;
	}

	pageScrollToNavbarDownPosition: boolean = false;

	@HostListener('window:scroll', ['$event']) onScrollEvent(event: any) {
		if (window.pageYOffset > 118) {
			this.pageScrollToNavbarDownPosition = true;
		} else {
			this.pageScrollToNavbarDownPosition = false;
		}  
	}

	searchTermNotFound: boolean = false;
	searching_loading: boolean = false;
	searchAlgorithmActivity(keyword: any) {
		const searchTerm: string = keyword;
		let carts_result_arr: any = [];
		this.searchTermNotFound = false;
		
		if (keyword == '') {
			this.carts = this.carts_backup;
			return;
		}
		this.carts = this.carts_backup;
		this.searching_loading = true;
		let search_results = this.carts[0].filter((val: any) => {
			return val.isCheckedOut == searchTerm ||
			val.totalPrice == searchTerm ||
			val._id == searchTerm
		})
		carts_result_arr.push(search_results);
		this.searching_loading = false;
		this.carts = carts_result_arr;

		if (this.carts[0].length <= 0) {
			this.searchTermNotFound = true;
		}
	}

}
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  	providedIn: 'root'
})

export class OrdersService {

	constructor( private _http: HttpClient ) { }

	getSpecificOrder(value: string) {
		return this._http.get<any>(`${environment.apiUrl}orders/getSpecificOrder/${value}`);
	}
	
	getAllOrders() {
		return this._http.get<any>(`${environment.apiUrl}orders/getAllOrders`);
	}

	getAllOrdersRespectToDates() {
		return this._http.get<any>(`${environment.apiUrl}orders/getAllOrdersRespectToDates`);
	}

	deleteOrder(value: string) {
		return this._http.delete<any>(`${environment.apiUrl}orders/deleteOrder/${value}`);
	}

	markOrderPaid(value: string) {
		return this._http.get<any>(`${environment.apiUrl}orders/markOrderPaid/${value}`);
	}

	markOrderCompleted(value: string) {
		return this._http.get<any>(`${environment.apiUrl}orders/markOrderCompleted/${value}`);
	}

	getPendingOrders() {
		return this._http.get<any>(`${environment.apiUrl}orders/getPendingOrders`);
	}

	getCompletedOrders() {
		return this._http.get<any>(`${environment.apiUrl}orders/getCompletedOrders`);
	}
	
}
<div id="entire_application__search_component">
    
    <div *ngIf="isSuccess || hasErrorAlert" class="success_message">
        <div class="msg_box" [ngClass]="{'isFailed': hasErrorAlert}">
            <p *ngIf="isSuccess">{{ successMsg }}</p>
            <p *ngIf="hasErrorAlert">{{ hasErrorMsg }}</p>
            <svg *ngIf="isSuccess" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-.997-4L6.76 11.757l1.414-1.414 2.829 2.829 5.656-5.657 1.415 1.414L11.003 16z" fill="rgba(255,255,255,1)"/></svg>
            <svg *ngIf="hasErrorAlert" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-9.414l2.828-2.829 1.415 1.415L13.414 12l2.829 2.828-1.415 1.415L12 13.414l-2.828 2.829-1.415-1.415L10.586 12 7.757 9.172l1.415-1.415L12 10.586z" fill="rgba(255,255,255,1)"/></svg>
        </div>
    </div>

    <div class="image_viewer" *ngIf="viewingPicMedia">
        <svg (click)="closeAllMediaView()" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path fill="#ffffff" d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-9.414l2.828-2.829 1.415 1.415L13.414 12l2.829 2.828-1.415 1.415L12 13.414l-2.828 2.829-1.415-1.415L10.586 12 7.757 9.172l1.415-1.415L12 10.586z"/></svg>
        <img [src]="currentImageBeingViewed" alt="">
    </div>

    <div class="cookies_disabled" *ngIf="cookies_disabled">
        <div class="cookie_modal_container col-mob-11">
            <div class="image">
                <img src="../../../assets/app/cookies.png" alt="">
            </div>
            <div class="message">
                <h1>You must enable cookies</h1>
                <p>Inkingi fashion design shop uses browser cookies and yours have been disabled please enable them to continue shopping.</p>
                <button (click)="enableBrowserCookies()">Edit settings by <strong>&nbsp;ALT + F&nbsp;</strong> then <strong>&nbsp;S&nbsp;</strong></button>
            </div>
        </div>
    </div>

    <div class="results_container">
        <div class="search_container">
            <input type="text" #term (input)="getSearchTerm(term.value)" [value]="search_term_2" (keyup.enter)="searchThisTerm()" placeholder="Inkingi powerful search">
            <button [disabled]="searching_loader_2" (click)="searchThisTerm()">
                <span *ngIf="searching_loader_2">
                    <svg class="spinner_loading" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M18.364 5.636L16.95 7.05A7 7 0 1 0 19 12h2a9 9 0 1 1-2.636-6.364z"/></svg>
                </span>
                <span *ngIf="!searching_loader_2">Search</span>
            </button>
        </div>
        <div class="search_results">
            <div class="header">
                <p>{{ totalResults | number }} &nbsp;<span>Related results to '{{ search_term_2 }}'</span></p>
            </div>
            <div class="navigation">
                <div class="nav" (click)="changeSearchCategory('products')" [ngClass]="{'--nav_active': searchedCategory == 'products'}">
                    <p>Products</p>
                </div>
                <div class="nav" (click)="changeSearchCategory('collections')" [ngClass]="{'--nav_active': searchedCategory == 'collections'}">
                    <p>Collections</p>
                </div>
            </div>
            <div class="content" *ngIf="searchedCategory == 'products'">
                
                <div *ngIf="loadingData" class="isLoadingData">
                    <svg class="spinner_loading" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path fill="#000000" d="M18.364 5.636L16.95 7.05A7 7 0 1 0 19 12h2a9 9 0 1 1-2.636-6.364z"/></svg>
                    <p>Searching . . .</p>
                </div>

                <p *ngIf="noProductStatus" style="font-size: 0.88em; opacity: 0.9; margin: 7em auto; display: flex; align-items: center;">
                    <svg style="margin-right: 0.8em;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-1-5h2v2h-2v-2zm0-8h2v6h-2V7z"/></svg>
                    No product related to your search
                </p>

                <div *ngFor="let product of productdata; let i = index" class="product col-mob-12  col-tab-13 col-large-13" [hidden]="product.productDeleted == true" [ngClass]="{'--ng--ser--8934--2nsdn_jsdncd --do_touch_ng_direct_mover': product.productDeleted == true}" [style.display]="product.productDeleted == true ? 'none' : null">
                    <div class="image_design">
                        <img (click)="getProductPage(product._id)" [src]="product.productMainImage" alt="">
                    </div>
                    <div class="image_descript">
                        <svg *ngIf="likingProductsubmission && likingProductsubmissionId == product._id" class="spinner_loading" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path fill="rgba(255,56,52,1)" d="M18.364 5.636L16.95 7.05A7 7 0 1 0 19 12h2a9 9 0 1 1-2.636-6.364z"/></svg>         
                        <svg *ngIf="likingProductsubmissionId != product._id && !likesArr.includes(product._id)" (click)="likeProduct(product._id)" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="22" height="22"><path fill="none" d="M0 0H24V24H0z"/><path d="M12.001 4.529c2.349-2.109 5.979-2.039 8.242.228 2.262 2.268 2.34 5.88.236 8.236l-8.48 8.492-8.478-8.492c-2.104-2.356-2.025-5.974.236-8.236 2.265-2.264 5.888-2.34 8.244-.228zm6.826 1.641c-1.5-1.502-3.92-1.563-5.49-.153l-1.335 1.198-1.336-1.197c-1.575-1.412-3.99-1.35-5.494.154-1.49 1.49-1.565 3.875-.192 5.451L12 18.654l7.02-7.03c1.374-1.577 1.299-3.959-.193-5.454z" fill="rgba(255,56,52,1)"/></svg>
                        <svg *ngIf="likingProductsubmissionId != product._id && likesArr.includes(product._id)" (click)="likeProduct(product._id)" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="22" height="22"><path fill="none" d="M0 0H24V24H0z"/><path d="M12.001 4.529c2.349-2.109 5.979-2.039 8.242.228 2.262 2.268 2.34 5.88.236 8.236l-8.48 8.492-8.478-8.492c-2.104-2.356-2.025-5.974.236-8.236 2.265-2.264 5.888-2.34 8.244-.228z" fill="rgba(255,56,52,1)"/></svg>
                        <p (click)="getProductPage(product._id)">{{ product.productName }}</p>
                        <h3 *ngIf="!loadingPrice"> 
                            <strong *ngIf="currency == 'usd'">$ </strong> 
                            <strong *ngIf="currency == 'gbp'">&pound; </strong> 
                            {{ product.productPrice | currencies:currency:exchangeValue | number }} 
                            <span *ngIf="currency == 'rwf'">RWF</span>
                        </h3>
                        <h3 *ngIf="loadingPrice"><span>loading price . . .</span></h3>
                        <a [disabled]="addingToCartSubmission && addingToCartSubmissionId == product._id" *ngIf="!productsInCartIds.includes(product._id) && product.quantityInStock > 0" (click)="addThisItemToCart(product._id, product.quantityInStock)">
                            <svg *ngIf="addingToCartSubmission && addingToCartSubmissionId == product._id" class="spinner_loading" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M18.364 5.636L16.95 7.05A7 7 0 1 0 19 12h2a9 9 0 1 1-2.636-6.364z" fill="rgba(255,255,255,1)"/></svg>
                            <span *ngIf="!addingToCartSubmission || addingToCartSubmissionId != product._id">Add to cart</span>
                        </a>
                        <a *ngIf="productsInCartIds.includes(product._id)" (click)="editInCart()" class="already_in_cart">
                            Edit in cart
                        </a>
                        <a *ngIf="product.quantityInStock <= 0 && !productsInCartIds.includes(product._id)" (click)="unableToAddToCart()" class="out_of_stock">
                            Sold out
                        </a>
                    </div>
                </div>
        
            </div>
            <div class="content" *ngIf="searchedCategory == 'collections'">

                <div *ngIf="loadingData" class="isLoadingData">
                    <svg class="spinner_loading" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path fill="#000000" d="M18.364 5.636L16.95 7.05A7 7 0 1 0 19 12h2a9 9 0 1 1-2.636-6.364z"/></svg>
                    <p>Searching . . .</p>
                </div>

                <p *ngIf="noCollectionStatus" style="font-size: 0.88em; opacity: 0.9; margin: 7em auto; display: flex; align-items: center;">
                    <svg style="margin-right: 0.8em;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-1-5h2v2h-2v-2zm0-8h2v6h-2V7z"/></svg>
                    No collection related to your search
                </p>

                <div class="collection_content_container" *ngFor="let collectiondata of collectiondata; let i = index"  [hidden]="collectiondata.collectionDeleted == true" [ngClass]="{'--ng--ser--8934--2nsdn_jsdncd --do_touch_ng_direct_mover': collectiondata.collectionDeleted == true}" [style.display]="collectiondata.collectionDeleted == true ? 'none' : null">
                    <div class="collection_main_image_container col-mob-12">
                        <img (click)="viewPicMedia(collectiondata.mainCollectionImage)" [src]="collectiondata.mainCollectionImage" alt="">
                    </div>
                    <div class="collection_description col-mob-12">
                        <div class="collection_title">
                            <h1>{{ collectiondata.collectionName }}</h1>
                        </div>
                        <div class="collection_upload_date">
                            <p><span>Presented On:</span> {{ collectiondata.createdAt | date:'medium' }}</p>
                        </div>
                        <div class="collection_descript">
                            <p>{{ collectiondata.collectionDescription }}</p>
                        </div>
                        <div class="actions">
                            <a [routerLink]="['/collection', collectiondata.collectionName ]">
                                See full collection
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div id="entire_admin_subscribers">

    <div *ngIf="isSuccess || hasErrorAlert" class="success_message">
        <div class="msg_box" [ngClass]="{'isFailed': hasErrorAlert}">
            <p *ngIf="isSuccess">{{ successMsg }}</p>
            <p *ngIf="hasErrorAlert">{{ hasErrorMsg }}</p>
            <svg *ngIf="isSuccess" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-.997-4L6.76 11.757l1.414-1.414 2.829 2.829 5.656-5.657 1.415 1.414L11.003 16z" fill="rgba(255,255,255,1)"/></svg>
            <svg *ngIf="hasErrorAlert" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-9.414l2.828-2.829 1.415 1.415L13.414 12l2.829 2.828-1.415 1.415L12 13.414l-2.828 2.829-1.415-1.415L10.586 12 7.757 9.172l1.415-1.415L12 10.586z" fill="rgba(255,255,255,1)"/></svg>
        </div>
    </div>

    <div class="header">
        <p>Inkingi contact messages</p>
    </div>
    <div class="content col-mob-12 col-tab-12 col-large-12">
        <div class="list col-mob-12 col-tab-12 col-large-12">

            <div *ngIf="loadingData" class="isLoadingData">
                <svg class="spinner_loading" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path fill="#000000" d="M18.364 5.636L16.95 7.05A7 7 0 1 0 19 12h2a9 9 0 1 1-2.636-6.364z"/></svg>
                <p>Loading . . .</p>
            </div>
            <p *ngIf="contacts.length == 0 && !loadingData" style="font-size: 0.83em; opacity: 0.7; margin: 1em 5%">No messages yet!</p>
            <div *ngFor="let contact of contacts; let i = index" class="person col-mob-11 col-tab-11 col-large-11" (click)="pickMessage(contact._id, contact.contactEmail, contact.contactNames)" [ngClass]="{'checked_person': contact._id == currentPicked}">
                <svg (click)="deleteContact(contact._id)" class="delete" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="22" height="22"><path fill="none" d="M0 0h24v24H0z"/><path fill="#f82b27" d="M17 6h5v2h-2v13a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V8H2V6h5V3a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1v3zm1 2H6v12h12V8zm-9 3h2v6H9v-6zm4 0h2v6h-2v-6zM9 4v2h6V4H9z"/></svg>
                <svg class="checked" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm-.997-6l7.07-7.071-1.414-1.414-5.656 5.657-2.829-2.829-1.414 1.414L11.003 16z" fill="rgba(25,188,106,1)"/></svg>
            
                <div class="user">
                    <div class="avatar">
                        <img src="../../../assets/app/client.png" alt="">
                    </div>
                    <div class="details">
                        <p>{{ contact.contactNames }}</p>
                        <a>
                            {{ contact.contactEmail }}
                        </a>
                    </div>
                </div>
                <div class="message"> 
                    <p>
                        {{ contact.contactMessage }}
                    </p>
                </div>
                <p *ngIf="contact.contactAnswered == 'yes'" style="position: absolute; font-size: 0.82em; color: #19BC6A; bottom: 1em; right: 2.5em;">Answered</p>
            </div>

        </div>
        <div class="form col-mob-12 col-tab-12 col-large-12">

            <p>Answer a selected person</p>
            <form class="col-mob-11 col-tab-11 col-large-11">
                <input type="email" placeholder="Enter email" id="emailField" value="">
                <input type="text" #heading (input)="getHeading(heading.value)" id="headingField" placeholder="Enter heading">
                <textarea required #message (input)="getMessage(message.value)" id="messageField" placeholder="Message here . . ."></textarea>
                <button (click)="answerClient()">
                    <svg *ngIf="hasSubmitted" class="spinner_loading" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M18.364 5.636L16.95 7.05A7 7 0 1 0 19 12h2a9 9 0 1 1-2.636-6.364z" fill="rgba(255,255,255,1)"/></svg>
                    <span *ngIf="!hasSubmitted">Reply to this client</span>
                </button>
            </form>
            
        </div>
    </div>
</div>

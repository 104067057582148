import { Component, OnInit } from '@angular/core';
import { CustomsService } from '../services/customs.service';

@Component({
	selector: 'app-page-not-found',
	templateUrl: './page-not-found.component.html',
	styleUrls: ['./page-not-found.component.css']
})
export class PageNotFoundComponent implements OnInit {

	constructor(private __customsService: CustomsService) { }

	ngOnInit(): void {
		this.__customsService.updatePageStatus(false);
	}

}
<div id="entire_application__notfound_component">
    <div class="header">
        <div class="logo">
            <a [routerLink] = "['']">
                <img src="../../assets/app/logo.png" alt="">
            </a>
        </div>
        <div class="others">
            <a [routerLink] = "['../shop']">
                Start Shopping
            </a>
        </div>
    </div>
    <div class="body">
        <img src="../../assets/app/404.png" alt="404 Page Not Found">
        <p>Sorry! we don't find the page you are looking for in the system go back to the main page. <br> And navigate to shop using links provided</p>
        <a [routerLink] = "['']">
            Back To Home
        </a>
    </div>
</div>
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AdminService } from 'src/app/services/admin.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
	selector: 'app-adminauth',
	templateUrl: './adminauth.component.html',
	styleUrls: ['./adminauth.component.css']
})
export class AdminauthComponent implements OnInit {

	constructor( 
		private __authService: AuthService, 
		private _router: Router,
		private __adminService: AdminService
	) { }

	ngOnInit(): void {
		if (this.__authService.loggedIn()) {
			this.__adminService.getAdminData().subscribe(
				res => {
					this._router.navigate(['/admin']);
				}, err => {
					console.trace('Processing redirection . . .')
				}
			)
		}
	}

	regularExpression = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	errors: string = '';
	isThereErrors: boolean = false;
	hasSubmitted: boolean = false;

	requiredEmail: boolean = false;
	requiredPassword: boolean = false;
	invalidEmail: boolean = false;
	invalidPassword: boolean = false;

	
	validatePassword(value: string) {
		if (this.invalidPassword) {
			if (value.length >= 6) {
				this.invalidPassword = false;
			}
		}

		if (this.requiredPassword) {
			if (value.length > 0) {
				this.requiredPassword = false;
			}
		}
	}

	validateEmail(value: any) {
		if (this.invalidEmail) {
			const valid = this.regularExpression.test(String(value).toLowerCase());
			if (!valid) {
				this.invalidEmail = false;
			}
		}

		if (this.requiredEmail) {
			if (value.length > 0) {
				this.requiredEmail = false;
			}
		}
	}

	getAdminData(data: { email: string; password: string; }) {

		if (data.email == "" || data.password == "") {

			if (data.email == "") {
				return this.requiredEmail = true;
			}

			if (data.password == "") {
				return this.requiredPassword = true;
			}
			return;

		}

		if (data.email != "") {
			const valid = this.regularExpression.test(String(data.email).toLowerCase());
			if (!valid) {
				return this.invalidEmail = true;
			}
		}

		if (data.password != "") {
			let password = data.password;
			let len = password.length;

			if (len < 6) {
				return this.invalidPassword = true;
			}
		}

		this.hasSubmitted = true;

		this.__authService.loginAdmin(data).subscribe(
			res => {
				let token = res.data.token;
				localStorage.setItem('inkingi_auth_token', token);
				this._router.navigate(['/admin']);
			},
			err => {
				this.isThereErrors = true;
				this.hasSubmitted = false;
				this.errors = err.error.message;
				if(!err.error.message) {
					this.errors = "Oops! Something went wrong."
				}
			}
		)
	}

	goToClient() {
		this._router.navigate(['/'])
	}

}
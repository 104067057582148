import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
 	name: 'lnglattrim'
})
export class LnglattrimPipe implements PipeTransform {

	transform(value: string) {
		let trimedlnglat = value.split(',')[0]+',  '+value.split(',')[1];
		return trimedlnglat;
	}

}
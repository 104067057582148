import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { CustomsService } from '../../services/customs.service';

@Component({
	selector: 'app-admincustomization',
	templateUrl: './admincustomization.component.html',
	styleUrls: ['./admincustomization.component.css']
})
export class AdmincustomizationComponent implements OnInit {

	constructor( private _router: Router, private __customsService: CustomsService) { }

	publicUrl: string = environment.apiPublic;

	viewingPicMedia: boolean = false;
	viewingVidMedia: boolean = false;
	currentImageBeingViewed: string = '';
	currentVideoBeingViewed: string = '';

	loadingData: boolean = true;

	ngOnInit(): void {
		this.getWebsiteCustomizations();
	}

	currentCustomizations: any;

	getWebsiteCustomizations() {
		this.__customsService.getWebsiteCustomizations().subscribe(
			res => {
				this.currentCustomizations = res.data[0];
				this.loadingData = false;
			},
			err => {
				this.getWebsiteCustomizations();
			}
		)
	}

	hasSubmitted: boolean = false;
	isSuccess: boolean = false;
	successMsg: string = '';
	hasErrors: boolean = false;
	errors: string = '';

	dashprodtitle: string = '';
	dashproddescript: string = '';
	slideShowImages: any = [];
	videoShots: any = [];

	slideShowImagesBlobs: any = [];
	videoShotsBlobs: any = [];

	//status
	slideshowDone: boolean = false;
	videoShotsDone: boolean = false;

	dashTitleRequired: boolean = false;
	dashDescriptionRequired: boolean = false;

	formData: FormData = new FormData();

	getProdDashTitle(value: string) {
		this.dashTitleRequired = false;
		this.dashprodtitle = value;
	}

	getProdDashDescript(value: string) {
		this.dashDescriptionRequired = false;
		this.dashproddescript = value;
	}

	getSlideshowImages(event: any) {
		const slideShowImages: any = Array.from(event.target.files);

		if (slideShowImages) {
			if (slideShowImages.length > 10) {
				this.hasErrors = true;
				this.errors = 'You should upload atmost 10 images';
				let Slideshows:any = document.getElementById('slideshow_images');
				Slideshows.value = '';
				window.scrollTo(0 , 0);
				return;
			}

			this.slideShowImages.push(slideShowImages);
			Array.from(slideShowImages).forEach((item: any) => {
				this.formData.append('mainSlideImages', item);
			})

			this.addSlideImageBlobs();
			
		}
	}

	addSlideImageBlobs() {

		for(let i= 0; i < this.slideShowImages[0].length; i++) {
			let fileReader = new FileReader();
			fileReader.addEventListener('load', () => {
				this.slideShowImagesBlobs.push(fileReader.result);
			})
			
			fileReader.readAsDataURL(this.slideShowImages[0][i]);		
		}

		this.slideshowDone = true;
	}

	getVideoShots(event: any) {
		const videoShots: any = Array.from(event.target.files);

		if (videoShots) {

			if (videoShots.length > 10) {
				this.hasErrors = true;
				this.errors = 'You should upload atmost 10 video shots';
				let Videoshots:any = document.getElementById('video_shots');
				Videoshots.value = '';
				window.scrollTo(0 , 0);
				return;
			}

			this.videoShots.push(videoShots);
			Array.from(videoShots).forEach((item: any) => {
				this.formData.append('videoShots', item);
			})

			this.addVideoShotsBlobs();
			
		}
	}

	addVideoShotsBlobs() {
		for(let i= 0; i < this.videoShots[0].length; i++) {
			let fileReader = new FileReader();
			fileReader.addEventListener('load', () => {
				this.videoShotsBlobs.push(fileReader.result);
			})
			
			fileReader.readAsDataURL(this.videoShots[0][i]);		
		}

		this.videoShotsDone = true;
	}

	saveCustomizations() {
		this.hasErrors = false;
		this.dashTitleRequired = false;
		this.dashDescriptionRequired = false;

		if (this.dashprodtitle == '') {
			this.dashprodtitle = this.currentCustomizations.dashboardProductsTitle;
		}
		
		if (this.dashproddescript == '') {
			this.dashproddescript = this.currentCustomizations.dashboardProductsDescription;
		}

		this.formData.append('dashboardProductsTitle', this.dashprodtitle);
		this.formData.append('dashboardProductsDescription', this.dashproddescript);

		this.hasSubmitted = true;

		this.__customsService.updateWebsiteCustomizations('6661c7511e4b3a3894dab332' , this.formData).subscribe(
			res => {
				this.hasSubmitted = false;
				this.isSuccess = true;
				this.successMsg = 'Customizations successful'

				let count = 0;
				
				let successInterval = setInterval(() => {
					if (count > 0) {
						this.isSuccess = false;						
						clearInterval(successInterval);
						this._router.navigate(['/admin']);
					}

					count = count + 1;
				}, 2000)
			},
			err => {
				window.scrollTo(0 , 0);
				this.hasErrors = true;
				this.hasSubmitted = false;
				if (err.error.message) {
					this.errors = err.error.message;
				} else if(err.error.text) {
					this.errors = err.error.text;
				} else {
					this.errors = err.message
				}
			}
		)

	}

	cancelCustomizations() {
		this._router.navigate(['/admin'])
	}

	closeAllMediaView() {
		this.viewingVidMedia = false;
		this.viewingPicMedia = false;

		this.currentImageBeingViewed = '';
		this.currentVideoBeingViewed = '';
	}

	viewPicMedia(value: string) {
		this.viewingPicMedia = !this.viewingPicMedia;
		this.currentImageBeingViewed = value;
	}

	viewVidMedia(value: string) {
		this.viewingVidMedia = !this.viewingVidMedia;
		this.currentVideoBeingViewed = value;
	}

}
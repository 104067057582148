import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CartService } from '../../services/cart.service';
import { ProductsService } from '../../services/products.service';
import { environment } from 'src/environments/environment';
import { CustomsService } from 'src/app/services/customs.service';
import { PaymentsService } from '../../services/payments.service';
import { OrdersService } from 'src/app/services/orders.service';

@Component({
	selector: 'app-payment',
	templateUrl: './payment.component.html',
	styleUrls: ['./payment.component.css']
})
export class PaymentComponent implements OnInit {

	constructor( 
		private _router: Router, 
		private __customsService: CustomsService, 
		private __cartService: CartService, 
		private __productsService: ProductsService,
		private __paymentsService: PaymentsService,
		private __ordersService: OrdersService 
	) { }

	apiPublic: string = environment.apiPublic;

	ngOnInit(): void {
		
		this.__paymentsService.updateStatus.subscribe(
			(res:any) => {
				let status = this.__paymentsService.getStatus();

				if (status[0] == true) {
					this.hasErrorAlert = true;
					this.hasErrorMsg = status[1]
					
					let count = 0;
		
					let successInterval = setInterval(() => {
						if (count > 0) {
							this.hasErrorAlert = false;
							clearInterval(successInterval);
						}
						count = count + 1;
					}, 3000)
				}

			}, (err:any) => console.log(err) 
		);

		this.getCurrentCurrency();
		this.getAllCurrencies();

		this.__customsService.updatePageStatus(false);

		this.__productsService.updateExchangeRates.subscribe(
			(res:any) => {
				this.currency = this.__productsService.getCurrentCurrency();
			}, (err:any) => console.log(err) 
		);

		let checkouttoken = localStorage.getItem('__checkout_credt_id');
		if (!checkouttoken) {
			this.hasErrorAlert = true;
			this.hasErrorMsg = "Your payment order isn't recognized!"
			
			let count = 0;

			let successInterval = setInterval(() => {
				if (count > 0) {
					this.hasErrorAlert = false;
					clearInterval(successInterval);
					localStorage.removeItem('__payment_token');
					this._router.navigate(['/cart']);					
				}
				count = count + 1;
			}, 3000)
		}	

		if (checkouttoken) {
			this.getOrder(checkouttoken);
			this.makeAwareOfTime()
		}
	}

	makeAwareOfTime() {
		this.isSuccess = false;
        this.hasErrorAlert = false;
		let paymenttoken = localStorage.getItem('__payment_token');
		if (!paymenttoken) {
			this.hasErrorAlert = true;
			this.hasErrorMsg = "Your payment order isn't recognized!"
			
			let count = 0;

			let successInterval = setInterval(() => {
				if (count > 0) {
					this.hasErrorAlert = false;
					clearInterval(successInterval);
					localStorage.removeItem('__checkout_credt_id')
					this._router.navigate(['/checkout']);					
				}
				count = count + 1;
			}, 3000)
		} else {

			this.isSuccess = true;
			this.successMsg = "Make sure payment is complete in 20 minutes";
			
			let count = 0;

			let successInterval = setInterval(() => {
				if (count > 0) {
					this.isSuccess = false;
					clearInterval(successInterval);				
				}
				count = count + 1;
			}, 3000)
		}
	}

	currency: any = 'rwf';
	exchangeValue: any = 0;
	exchangeRates: any;
	loadingPrice: boolean = true;
	reprocessingcurrencies: number = 0;

	getCurrentCurrency() {
		this.currency = this.__productsService.getCurrentCurrency();
	}

	getAllCurrencies() {

		this.__productsService.getCurrencies().subscribe(
			res => {
				this.exchangeRates = res.data;
				this.exchangeValue = this.exchangeRates;
				this.loadingPrice = false;
			},
			err => {

				if (this.reprocessingcurrencies <= 2) {
					this.getAllCurrencies();
					this.reprocessingcurrencies++;
				} else {
					alert('Having trouble in processing currencies! Reprocessing . . .');
					location.reload();
				}

			})

	}

	isSuccess: boolean = false;
	successMsg: string = '';
	hasSubmitted: boolean = false;
	hasErrorAlert: boolean = false;
	hasErrorMsg: string = '';

	loadingData: boolean = true;

	paymentMethodCard: string = 'card';

	changePaymentMethod(value: string) {
		this.paymentMethodCard = value;
	}

	myCart: any = [];
	
	finalProductsInCart: any = [];

	thisItem: any = [];
	firstlevelItems: any = [];
	firstlevelProds: any = [];
	
	async getEachCartItem() {

		const promises = this.myCart.products.map(async (element: any) => {
			this.thisItem = [
				element.size, 
				element.number, 
				element.singlePrice, 
				element.color
			];
			
			this.firstlevelItems = [
				...this.firstlevelItems,
				this.thisItem
			]

			await this.__productsService.getProductById(element.productId).subscribe(
				res => {

					this.firstlevelProds = [
						...this.firstlevelProds,
						res.data
					]
					this.updateFinals();
										
				},
				err => {
					return;
				})
				
			this.thisItem = [];	
		});

		await Promise.all(promises);

	}

	updateFinals() {
	
		if (this.firstlevelProds.length == this.firstlevelItems.length) {
			let count = 0;

			this.firstlevelItems.forEach((element: any) => {
				element = [
					...element,
					this.firstlevelProds[count]
				];
				let item = element;
				this.finalProductsInCart.push(item);
				count++;
			});

			if (this.finalProductsInCart.length == 0) {
				this.hasErrorAlert = true;
				this.hasErrorMsg = "First add items to your cart!"
				
				let count = 0;

				let successInterval = setInterval(() => {
					if (count > 0) {
						this.hasErrorAlert = false;
						clearInterval(successInterval);
						this._router.navigate(['/cart']);			
					}
	
					count = count + 1;
				}, 3000)
			}

			this.loadingData = false;
		}

	}

	orderDetails: any;

	getOrder(token: any) {

		this.__cartService.getOrder(token).subscribe(
			res => {
				this.orderDetails = res.data;
				this.myCart = res.data.cartDetails;
				this.getEachCartItem();
			},
			err => {
				this.hasErrorAlert = true;
				this.hasErrorMsg = "Error recognizing your order."
				
				let count = 0;

				let successInterval = setInterval(() => {
					if (count > 0) {
						this.hasErrorAlert = false;
						location.reload();				
						clearInterval(successInterval);
					}
					count = count + 1;
				}, 3000)
			}
		)
	}

	currenyNotAllowed: boolean = false;

	payForOrder() {
		this.isSuccess = false;
		this.hasErrorAlert = false;
		this.currenyNotAllowed = false;
		let paymenttoken = localStorage.getItem('__payment_token');
		if (!paymenttoken) {
			this.hasErrorAlert = true;
			this.hasErrorMsg = "Your payment order isn't recognized!"
			
			let count = 0;

			let successInterval = setInterval(() => {
				if (count > 0) {
					this.hasErrorAlert = false;
					clearInterval(successInterval);
					localStorage.removeItem('__checkout_credt_id')
					this._router.navigate(['/checkout']);					
				}
				count = count + 1;
			}, 3000)
		} else {

			let valid_token_data: any = {
				'paymentToken': 'Bearer '+paymenttoken
			}

			this.hasSubmitted = true;

			if (this.paymentMethodCard == 'momo') {
				if (this.currency == 'usd' || this.currency == 'gbp') {
					this.hasSubmitted = false;
					this.currenyNotAllowed = true;
					return;
				}
			}

			if (this.paymentMethodCard == 'paypal') {
				if (this.currency == 'rwf') {
					this.hasSubmitted = false;
					this.currenyNotAllowed = true;
					return;
				}
			}

			this.__paymentsService.validatePaymentToken(valid_token_data).subscribe(
				res => {
					
					this.__paymentsService.completeThePayment(this.paymentMethodCard, this.currency, this.exchangeValue);
					let count: number = 0;
					let payingInterval: any = setInterval(() => {
						if (count > 1) {
							this.hasSubmitted = false;
							clearInterval(payingInterval);
						} 
						count++;
					}, 2000)
					
				},
				err => {
					this.hasErrorAlert = true;
					this.hasErrorMsg = err.error.message;

					if (!err.error.message) {
						this.hasErrorMsg = 'Payment token invalid or expired.'
					}
					
					let count = 0;
		
					let successInterval = setInterval(() => {
						if (count > 0) {
							localStorage.removeItem('__payment_token')
							clearInterval(successInterval);
							this.hasSubmitted = false;
							this.hasErrorAlert = false;
							this._router.navigate(['/shipping']);					
						}
						count = count + 1;
					}, 3000)
				}
			)

		}

	}

	removecurrenyNotAllowed() {
		this.currenyNotAllowed = false;
	}

	deletingOrder: boolean = false;
	cancelOrder() {
		let orderId: any = localStorage.getItem('__checkout_credt_id');
		this.hasErrorAlert = false;
		this.hasErrorMsg = '';
		this.successMsg = '';
		this.isSuccess = false;
		this.deletingOrder = true;
		
		this.__ordersService.deleteOrder(orderId).subscribe(
			res => {
				
				this.isSuccess = true;
				this.successMsg = 'Dropped order successfully.';

				let count = 0;
	
				let successInterval = setInterval(() => {
					if (count > 0) {
						this.isSuccess = false;
						this.deletingOrder = false;
						localStorage.removeItem('__payment_token');
						localStorage.removeItem('__checkout_credt_id');
						clearInterval(successInterval);
						this._router.navigate(['/cart']);				
					}
					count = count + 1;
				}, 2000)
			},
			err => {
				this.hasErrorAlert = true;
				this.hasErrorMsg = err.error.message;

				let count = 0;
	
				let successInterval = setInterval(() => {
					if (count > 0) {
						this.deletingOrder = false;
						this.hasErrorAlert = false;
						clearInterval(successInterval);				
					}
					count = count + 1;
				}, 2000)
			}
		)
	}

}
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommunicationService } from 'src/app/services/communication.service';
import { OrdersService } from 'src/app/services/orders.service';
import { PaymentsService } from 'src/app/services/payments.service';
import { TransactionsService } from 'src/app/services/transactions.service';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-successfullpayment',
	templateUrl: './successfullpayment.component.html',
	styleUrls: ['./successfullpayment.component.css']
})

export class SuccessfullpaymentComponent implements OnInit {

	constructor(
		private _router: Router,
		private __tansactionsService: TransactionsService,
		private activatedRoute: ActivatedRoute, 
		private __ordersService: OrdersService,
		private __communService: CommunicationService,
		private __paymentsService: PaymentsService
	) { 
		let status: any = this.activatedRoute.snapshot.paramMap.get('status');
		let tx_ref: any = this.activatedRoute.snapshot.paramMap.get('tx_ref');
		let transaction_id: any = this.activatedRoute.snapshot.paramMap.get('transaction_id');

		this.status = status;
		this.tx_ref = tx_ref;
		this.transaction_id = transaction_id;
	}

	status: string = '';
	tx_ref: string = '';
	transaction_id: string = '';

	ngOnInit(): void {
		
		// let paymentsuccessurl: any = location.href.toString();

		// let status: any = paymentsuccessurl.split('?')[1];
		// let tx_ref: any = status.split('&')[1];
		// let transaction_id: any = status.split('&')[2];

		if (this.status == 'successful' || this.status == 'VERIFIED') {	

			let transaction_lookup_data: any = {
				"transaction_id": this.transaction_id
			}

			this.__tansactionsService.getSpecificTransaction(transaction_lookup_data.transaction_id).subscribe(
				res => {
					
					var customer_names: string = res.data.customer_names;
					if (res.data.transaction_ref == this.tx_ref) {
						if (res.data.transaction_method == 'paypal') {
							let receiptData: any = {
								"email": res.data.customer_email,
								"amount": res.data.transaction_amount
							}

							this.__paymentsService.sendReceipt(receiptData).subscribe(
								res => {
									console.log('Proceeding . . .')
								}, err => {
									console.log('Processing . . .')
								}
							);

							var __id: any = localStorage.getItem('__checkout_credit_id');
			
							this.__ordersService.markOrderPaid(__id).subscribe(
								res => {
									localStorage.removeItem('__payment_token');
									localStorage.removeItem('__checkout_credit_id');
									document.cookie = "_mycart= ; expires = Thu, 01 Jan 1970 00:00:00 GMT";
									console.log('Proceeding . . .');
								},
								err => {
									if (err.error.message == 'Your order is not found.' || err.error.message == 'This order has already completed!' || err.error.message == 'Order is not paid to be completed!') {
									
										this.hasErrorAlert = true;
										if (err.error.message) {
											this.hasErrorMsg = err.error.message;
										} else {
											this.hasErrorMsg = "Oops! Something went wrong!"
										}
										
										let count = 0;

										let successInterval = setInterval(() => {
											if (count > 0) {
												this.hasErrorAlert = false;
												this.loading = false;
												clearInterval(successInterval);
												this._router.navigate(['/shop']);
											}
											count = count + 1;
										}, 2000)

									} else {

										let date: any = new Date();
										let noteData = {
								
											"notificationTitle": "Marking order as paid failure!",
											"notificationDescription": `An order by ${res.data.customer_email} of ${res.data.transaction_currency} ${res.data.transaction_amount} has failed to get marked as paid on ${date}.`,
											"notificationCategory": "transactions"
											
										}
								
										this.__communService.sendNotification(noteData).subscribe(
											res => {
												console.log('Proceeding . . .')
											}, err => {
												console.log('Processing . . .')
											}
										)
										alert("Something went wrong. But don't worry consider contacting our team now to make sure you order was received.")

									}
								}
							)

							let noteData = {
			
								"notificationTitle": "New order arrived!",
								"notificationDescription": `${customer_names} has made an order on inkingi shop system. Pease checkout the order to complete it.`,
								"notificationCategory": "neworder"
								
							}
					
							this.__communService.sendNotification(noteData).subscribe(
								res => {
									if (res) console.log('Processing . . .');
								}, err => {
									if (err) console.log('Processing . . .');
								}
							)

							localStorage.removeItem('__payment_token');
							localStorage.removeItem('__checkout_credit_id');
							document.cookie = "_mycart= ; expires = Thu, 01 Jan 1970 00:00:00 GMT";

							this.loading = false;
						} else {
							localStorage.removeItem('__payment_token');
							localStorage.removeItem('__checkout_credit_id');
							document.cookie = "_mycart= ; expires = Thu, 01 Jan 1970 00:00:00 GMT";
							this.loading = false;
						}
					} else {
						localStorage.removeItem('__payment_token');
						localStorage.removeItem('__checkout_credit_id');
						document.cookie = "_mycart= ; expires = Thu, 01 Jan 1970 00:00:00 GMT";

						this.hasErrorAlert = true;
						this.hasErrorMsg = "Transaction happened with some errors."
						
						let count = 0;

						let successInterval = setInterval(() => {
							if (count > 0) {
								this.hasErrorAlert = false;
								this.loading = false;
								clearInterval(successInterval);
							}
							count = count + 1;
						}, 2000)
					}
				
				},
				err => {
					this.isSuccess = true;
					this.successMsg = "Transaction finishing up . . .";
					
					let count = 0;

					let successInterval = setInterval(() => {
						if (count > 0) {
							this.isSuccess = false;
							this.loading = false;
							clearInterval(successInterval);
						}
						count = count + 1;
					}, 3000)
				}
			)
			
		} else if (this.status == 'PENDING') {
			this.hasErrorAlert = true;
			this.hasErrorMsg = "You payment is pending! Contact our team."
			
			let count = 0;
			
			let successInterval = setInterval(() => {
				if (count > 0) {
					localStorage.removeItem('__payment_token');
					localStorage.removeItem('__checkout_credit_id');
					document.cookie = "_mycart= ; expires = Thu, 01 Jan 1970 00:00:00 GMT";
					this.hasErrorAlert = false;
					clearInterval(successInterval);				
				}
				
				count = count + 1;
			}, 5000)

			this.__tansactionsService.getSpecificTransaction(this.transaction_id).subscribe(
				res => {
					let date: any = new Date();
					let noteData = {
			
						"notificationTitle": "Pending transaction has arrived!",
						"notificationDescription": `Please a client with email = ${res.data.customer_email} just made a pending transaction on ${date} make sure you alert them to clear then as soon as possible.`,
						"notificationCategory": "transactions"
						
					}
			
					this.__communService.sendNotification(noteData).subscribe(
						res => {
							this._router.navigate(['/contact']);
							console.log('Proceeding . . .')
						}, err => {
							this._router.navigate(['/contact']);
							console.log('Processing . . .')
						}
					)
				}, err => {
					let date: any = new Date();
					let noteData = {
			
						"notificationTitle": "Pending transaction has arrived!",
						"notificationDescription": `Please a client just made a pending transaction on ${date} make sure you alert them to clear then as soon as possible. TO KNOW WHO THE CLIENT IS CHECK THE LASTEST TRANSACTIONS WITH PENDING STATUS.`,
						"notificationCategory": "transactions"
						
					}
			
					this.__communService.sendNotification(noteData).subscribe(
						res => {
							this._router.navigate(['/contact']);
							console.log('Proceeding . . .')
						}, err => {
							this._router.navigate(['/contact']);
							console.log('Processing . . .')
						}
					)
				})

		} else {
			this.hasErrorAlert = true;
			this.hasErrorMsg = "You payment has failed!"
			
			let count = 0;

			let successInterval = setInterval(() => {
				if (count > 0) {
					this.hasErrorAlert = false;
					clearInterval(successInterval);
					this._router.navigate(['/payment']);					
				}
				count = count + 1;
			}, 3000)
		}

		window.addEventListener('beforeunload', function () {
			document.cookie = "_mycart= ; expires = Thu, 01 Jan 1970 00:00:00 GMT";	
		});
	}

	loading: boolean = true;
	isSuccess: boolean = false;
	successMsg: string = '';
	hasErrorAlert: boolean = false;
	hasErrorMsg: string = '';

	navigatetoshop() {
		// this._router.navigate(['/shop']);
		document.cookie = "_mycart= ; expires = Thu, 01 Jan 1970 00:00:00 GMT";
		let url: any = `${environment.frontendUrl}shop`;
		window.location.replace(url);
	}

	navigatetohome() {
		this._router.navigate(['/'])
	}

}
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CartService } from 'src/app/services/cart.service';
import { CustomsService } from 'src/app/services/customs.service';
import { ProductsService } from 'src/app/services/products.service';
import { SearchService } from 'src/app/services/search.service';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-search',
	templateUrl: './search.component.html',
	styleUrls: ['./search.component.css']
})
export class SearchComponent implements OnInit {

	constructor(
		private __productsService: ProductsService, 
		private __customsService: CustomsService, 
		private _router: Router, 
		private activatedRoute: ActivatedRoute, 
		private __cartService: CartService,
		private __searchService: SearchService 
	) {
		this.__customsService.updatePageStatus(false);
		this.checkOutCookieStatus();
	}

	checkOutCookieStatus() {
		if (!navigator.cookieEnabled) {
			this.cookies_disabled = true;
		}
	}

	myCart: any = [];
	shouldCreateCart: any;

	cookies_disabled: boolean = false;

	enableBrowserCookies() {
		alert("Press ALT + F and then S to edit your browser settings")
	}

	apiPublic: string = environment.apiPublic;

	isSuccess: boolean = false;
	successMsg: string = '';
	hasSubmitted: boolean = false;
	hasErrorAlert: boolean = false;
	hasErrorMsg: string = '';

	searchTerm: any;

	ngOnInit(): void {
		this.__customsService.updatePageStatus(false);
	
		this.checkOutCookieStatus();
		this.getCurrentCurrency();
		this.getAllCurrencies();

		let searchTerm: any = this.activatedRoute.snapshot.paramMap.get('term');
		this.search_term_2 = searchTerm.toString();
		this.searchTerm = {
			"searchKey": searchTerm.toString()
		}
		this.getSearchResults(this.searchTerm);
		
		this.__productsService.updateExchangeRates.subscribe(
			(res:any) => {
				this.currency = this.__productsService.getCurrentCurrency();
			}, (err:any) => console.log(err) 
		);
	
		this.__cartService.getMyShoppingCart().then(data => {
			this.shouldCreateCart = data;
		
			if (this.shouldCreateCart) {
				this.createNewCart();
			} 
			
			if (!this.shouldCreateCart) {
				var cookieid: any = document.cookie.match(new RegExp('(^| )' + '_mycart' + '=([^;]+)'));
				this.getMyShoppingCart(cookieid[2]);
			}
	
		});

	}

	searchedCategory: string = 'products';

	changeSearchCategory(value: string) {
		this.searchedCategory = value;
	}

	currency: any = 'rwf';
	exchangeValue: any = 0;
	exchangeRates: any;
	loadingPrice: boolean = true;
	reprocessingcurrencies: number = 0;

	getCurrentCurrency() {
		this.currency = this.__productsService.getCurrentCurrency();
	}

	getAllCurrencies() {

		this.__productsService.getCurrencies().subscribe(
			res => {
				this.exchangeRates = res.data;
				this.exchangeValue = this.exchangeRates;

				this.loadingPrice = false;
			},
			err => {

				if (this.reprocessingcurrencies <= 2) {
					this.getAllCurrencies();
					this.reprocessingcurrencies++;
				} else {
					alert('Having trouble in processing currencies! Reprocessing . . .');
					location.reload();
				}
			})

	}

	getMyShoppingCart(value: any) {
		this.checkOutCookieStatus();
		let data = value;

		this.__cartService.getSpecificCart(data).subscribe(
			res => {
				this.myCart = res.data;
				this.getItemIdToFilterShopping(this.myCart);
			},
			err => { this.createNewCart() }
		)
	}

	createNewCart() {
		this.checkOutCookieStatus();
		let newCartData = {
			"products": []
		};

		this.__cartService.createNewCart(newCartData).subscribe(
			res => {
				document.cookie = `_mycart=${res.data._id}; path=/; max-age=` + 2147483647;
				this.getMyShoppingCart(res.data._id)
			},
			err => {
				this.createNewCart();
			}
		)
	}

	productdata: any = [];
	collectiondata: any = [];

	loadingData: boolean = true;
	noProductStatus: boolean = false;
	noCollectionStatus: boolean = false;

	likesArr: any = [];

	totalResults: number = 0;

	search_term_2: string = '';
	searching_loader_2: boolean = false;

	getSearchTerm(value: string) {
		this.search_term_2 = value;
	}

	searchThisTerm() {
		if (this.search_term_2 == '') {
			this.hasErrorAlert = true;
			this.hasErrorMsg = 'Search term is required!';
			let count = 0;

			let successInterval = setInterval(() => {
				if (count > 0) {
					this.hasErrorAlert = false;
					clearInterval(successInterval);
				}

				count = count + 1;
			}, 2000)
			return;
		}

		if (this.search_term_2.length > 20) {
			this.hasErrorAlert = true;
			this.hasErrorMsg = 'Too long search term!';
			let count = 0;

			let successInterval = setInterval(() => {
				if (count > 0) {
					this.hasErrorAlert = false;
					clearInterval(successInterval);
				}

				count = count + 1;
			}, 2000)
			return;
		}

		this.searching_loader_2 = true;
		this.loadingData = true;
		let data: any = {
			"searchKey": this.search_term_2
		}
		this.getSearchResults(data);
	}
	
	getSearchResults(data: any) {
		this.productdata = [];
		this.collectiondata = [];
		this.noProductStatus = false;
		this.noCollectionStatus = false;
		this.totalResults = 0;
		this.loadingData = true;
		this.__searchService.inkingiSearch(data).subscribe(
			res => {
				this.loadingData = false;
				this.searching_loader_2 = false;
	
				if (res.products.length == 0) {
					this.noProductStatus = true;
				}
		
				if (res.collections.length == 0) {
					this.noCollectionStatus = true;
				}
		
				if (res.products.length == 1) {
					if (res.products[0].length == 0) {
						this.noProductStatus = true;
					}
				}

				if (res.collections.length == 1) {
					if (res.collections[0].length == 0) {
						this.noCollectionStatus = true;
					}
				}

				if (this.noCollectionStatus == false) {
					this.collectiondata = res.collections[0];

					this.collectiondata.forEach((element:any )=> {
						if (element.collectionDeleted == false) {
							this.totalResults++;
						} 
					});
				}

				if (this.noProductStatus == false) {

					this.productdata = res.products[0];
	
					let cookiename: string = '_prod_likes'
					var cookiematch: any = document.cookie.match(new RegExp('(^| )' + cookiename + '=([^;]+)'));
	
					if (cookiematch) {
						this.likesArr = JSON.parse(cookiematch[2]);
					}
	
					if (this.productdata.length <= 0) {
						this.noProductStatus = true; 
					} else {
						let countDel: number = 0;
						let countAct: number = 0;
						
						this.productdata.forEach((element:any )=> {
							if (element.productDeleted == true) {
								countDel++;
							} else {
								this.totalResults++;
								countAct++;
							}
						});
	
						if (countDel > 0 && countAct > 0) {
							this.noProductStatus = false;
						} else if (countDel > 0 && countAct == 0) {
							this.noProductStatus = true;
						} else if (countDel == 0 && countAct > 0) {
							this.noProductStatus = false;
						} else {
							this.noProductStatus = false;
						}
						
					}
				}

			},
			err => {
				this.hasErrorAlert = true;
				this.searching_loader_2 = false;
				this.hasErrorMsg = err?.error?.message ?? "Something wrong! Check your search term.";
				
				let count = 0;

				let successIntervalTwo = setInterval(() => {
					if (count > 0) {
						this.hasErrorAlert = false;
						clearInterval(successIntervalTwo);
					}

					count = count + 1;
				}, 2000)
			}
		)
	}
	viewingPicMedia: boolean = false;
	currentImageBeingViewed: string = '';

	closeAllMediaView() {
		this.viewingPicMedia = false;

		this.currentImageBeingViewed = '';
	}

	viewPicMedia(value: string) {
		this.viewingPicMedia = !this.viewingPicMedia;
		// this.currentImageBeingViewed = this.apiPublic+value;
		this.currentImageBeingViewed = value;
	}

	getProductPage(value: any) {
		this._router.navigate(['/product/', value]);
	}

	likeProduct(value: any) {
		this.likingProductsubmissionId = value;
		this.likingProductsubmission = true;

		let cookiename: string = '_prod_likes';
		var cookiematch: any = document.cookie.match(new RegExp('(^| )' + cookiename + '=([^;]+)'));
		
		if (cookiematch) {
			let recentLikes = JSON.parse(cookiematch[2]);
			
			if (!recentLikes.includes(value)) {
				recentLikes.push(value);
				let cookiedata: any = JSON.stringify(recentLikes);
				document.cookie = `${cookiename}=${cookiedata}; path=/; max-age=` + 2147483647;

				this.likeProductServicehandler(value);
				
			} else {
				
				const index = recentLikes.indexOf(value);

				if (index > -1) {
					recentLikes.splice(index, 1);
				}

				let cookiedata: any = JSON.stringify(recentLikes);
				document.cookie = `${cookiename}=${cookiedata}; path=/; max-age=` + 2147483647;
				this.dislikeProductServicehandler(value);
			}

		}
		
		if (!cookiematch) {
			let cookiedata: any = JSON.stringify([value]);
			document.cookie = `${cookiename}=${cookiedata}; path=/; max-age=` + 2147483647;
			this.likeProductServicehandler(value);
		}
		
		this.getSearchResults(this.searchTerm);
	}

	likingProductsubmissionId: string = '';
	likingProductsubmission: boolean = false;

	likeProductServicehandler(value: any) {
		this.likingProductsubmissionId = value;
		this.likingProductsubmission = true;

		this.isSuccess = false;
        this.hasErrorAlert = false;
		this.__productsService.likeProduct(value).subscribe(
			res => {
				
				this.isSuccess = true;
				this.successMsg = "Liked product successfully";

				this.likingProductsubmissionId = '';
				this.likingProductsubmission = false;
				
				let count = 0;

				let successIntervalTwo = setInterval(() => {
					if (count > 0) {
						this.isSuccess = false;
						clearInterval(successIntervalTwo);
					}
					count = count + 1;
				}, 2000)
			},
			err => {
				this.hasErrorAlert = true;
				this.hasErrorMsg = "Failed to like product!";

				this.likingProductsubmissionId = '';
				this.likingProductsubmission = false;
				
				let count = 0;

				let successIntervalTwo = setInterval(() => {
					if (count > 0) {
						this.hasErrorAlert = false;
						clearInterval(successIntervalTwo);
					}
					count = count + 1;
				}, 2000)
			}
		)
	}

	dislikeProductServicehandler(value: any) {
		this.likingProductsubmissionId = value;
		this.likingProductsubmission = true;

		this.isSuccess = false;
        this.hasErrorAlert = false;
		this.__productsService.dislikeProduct(value).subscribe(
			res => {
				this.isSuccess = true;
				this.successMsg = "Disliked product successfully";

				this.likingProductsubmissionId = '';
				this.likingProductsubmission = false;
				
				let count = 0;

				let successIntervalTwo = setInterval(() => {
					if (count > 0) {
						this.isSuccess = false;
						clearInterval(successIntervalTwo);
					}
					count = count + 1;
				}, 2000)
			},
			err => {
				this.hasErrorAlert = true;
				this.hasErrorMsg = "Failed to dislike product";

				this.likingProductsubmissionId = '';
				this.likingProductsubmission = false;
				
				let count = 0;

				let successIntervalTwo = setInterval(() => {
					if (count > 0) {
						this.hasErrorAlert = false;
						clearInterval(successIntervalTwo);
					}
					count = count + 1;
				}, 2000)
			}
		)
	}

	addingToCartSubmission: boolean = false;
	addingToCartSubmissionId: string = '';

	addThisItemToCart(value: any, quantity: any) {
		this.addingToCartSubmission = true;
		this.addingToCartSubmissionId = value;

		if (quantity <= 0) {
			this.isSuccess = false;
			this.hasErrorAlert = true;
			this.hasErrorMsg = "This product is sold out!"
					
			let count = 0;
			
			let successIntervalTwo = setInterval(() => {
				if (count > 0) {
					this.hasErrorAlert = false;
					clearInterval(successIntervalTwo);
				}
				count = count + 1;
			}, 2000)
			
			return;
		}

		if (quantity > 0) {
			this.checkOutCookieStatus();
			this.__productsService.getProductById(value).subscribe(
				res => {
					
					var cookieid: any = document.cookie.match(new RegExp('(^| )' + '_mycart' + '=([^;]+)'));
					var shopCartId = cookieid[2];
				
					let newProductData = {
						"products": [
							...this.myCart.products
							,{
							"productId": res.data._id,
							"size": "s",
							"number": 1,
							"singlePrice": res.data.productPrice,
							"color": "default",
							"productUniqueness": res.data._id+Math.random()*1000000000000000+(new Date()).toString()
						}]
					}
					
					var quantity_reduce: any = {
						"quantity": 1
					}

					this.__productsService.reduceQuantityInStock(value, quantity_reduce).subscribe(
						res => {
					
							this.__cartService.updateCart( shopCartId , newProductData).subscribe(
								res => {
		
									this.isSuccess = true;
									this.successMsg = "Added default product to cart";
									this.addingToCartSubmission = false;
									this.addingToCartSubmissionId = '';
			
									this.updateCartNumber(1);
									this.getMyShoppingCart(cookieid[2]);
									
									let count = 0;
			
									let successIntervalTwo = setInterval(() => {
										if (count > 0) {
											this.isSuccess = false;
											clearInterval(successIntervalTwo);
										}
										count = count + 1;
									}, 2000)
								},
								err => {
									let quantity_increase: any = {
										"quantity": 1
									}

									this.increaseQuantityInStock(value, quantity_increase);
									this.addingToCartSubmission = false;
									this.addingToCartSubmissionId = '';
			
									this.hasErrorAlert = true;
									
									this.hasErrorMsg = err.error.message;
									if (!err.error.message) {
										this.hasErrorMsg = "Something went wrong. Try again!";
									}
													
									let count = 0;
									
									let successIntervalTwo = setInterval(() => {
										if (count > 0) {
											this.hasErrorAlert = false;
											clearInterval(successIntervalTwo);
										}
										count = count + 1;
									}, 2000)
								}
							)
						},
						err => {
							this.addingToCartSubmission = false;
							this.addingToCartSubmissionId = '';
	
							this.hasErrorAlert = true;
							
							this.hasErrorMsg = err.error.message;
							if (!err.error.message) {
								this.hasErrorMsg = "Something went wrong. Try again!";
							}
									
							let count = 0;
							
							let successIntervalTwo = setInterval(() => {
								if (count > 0) {
									this.hasErrorAlert = false;
									clearInterval(successIntervalTwo);
								}
								count = count + 1;
							}, 2000)
						}
					)
					
				},
				err => {
					this.addingToCartSubmission = false;
					this.addingToCartSubmissionId = '';
	
					this.hasErrorAlert = true;
					
					this.hasErrorMsg = err.error.message;
					if (!err.error.message) {
						this.hasErrorMsg = "Something went wrong. Try again!";
					}
					
					let count = 0;
					
					let successIntervalTwo = setInterval(() => {
						if (count > 0) {
							this.hasErrorAlert = false;
							clearInterval(successIntervalTwo);
						}
						count = count + 1;
					}, 2000)
				}
			)
		}
	}
 
	increaseQuantityInStock(value: any, data: any) {
		this.__productsService.increaseQuantityInStock(value, data).subscribe(
			res => {},
			err => {}
		)
	}
 
	updateCartNumber(value: number) {
		this.__cartService.updateCartNumber(value);
	}

	productsInCartIds: any = [];
	getItemIdToFilterShopping(data: any) {
		data.products.forEach((element: any) => {
			this.productsInCartIds.push(element.productId);
		});
	}

	unableToAddToCart()  {
		this.isSuccess = false;
		this.hasErrorAlert = true;
		this.hasErrorMsg = "This product is sold out!";
				
		let count = 0;
		
		let successIntervalTwo = setInterval(() => {
			if (count > 0) {
				this.hasErrorAlert = false;
				clearInterval(successIntervalTwo);
			}

			count = count + 1;
		}, 2000)
	}

	editInCart() {
		this._router.navigate(['/cart']);
	}

}
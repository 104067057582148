import { Component, OnInit, ViewChild } from '@angular/core';
import jwt_decode from 'jwt-decode';
import { ProductsService } from '../../services/products.service';
import { CartService } from '../../services/cart.service';
import { CommunicationService } from '../../services/communication.service';
import { AdminService } from '../../services/admin.service';
import { OrdersService } from '../../services/orders.service';
import { TransactionsService } from '../../services/transactions.service';
import { CustomsService } from '../../services/customs.service';
import { AdminsubscriptionsService } from '../../services/adminsubscriptions.service';

import {
	ChartComponent,
	ApexAxisChartSeries,
	ApexChart,
	ApexXAxis,
	ApexDataLabels,
	ApexTooltip,
	ApexStroke,
	ApexPlotOptions
} from "ng-apexcharts";
import { Router } from '@angular/router';
  
export type ChartOptions = {
	series: ApexAxisChartSeries;
	chart: ApexChart;
	xaxis: ApexXAxis;
	stroke: ApexStroke;
	tooltip: ApexTooltip;
	dataLabels: ApexDataLabels;
	plotOptions: ApexPlotOptions;
};

@Component({
	selector: 'app-admindashboard',
	templateUrl: './admindashboard.component.html',
	styleUrls: ['./admindashboard.component.css']
})
export class AdmindashboardComponent implements OnInit {


	constructor( 
		public __communService: CommunicationService, 
		public __productsService: ProductsService, 
		public __cartService: CartService,
		public __customsService: CustomsService,
		private __adminService: AdminService,
		private __ordersService: OrdersService,
		private __transactionsService: TransactionsService,
		private __adminSubscriptionService: AdminsubscriptionsService,
		private _router: Router	
	) {}
	
	username: string = '';

	ngOnInit(): void {
		let auth_token: any = localStorage.getItem('inkingi_auth_token');

		if (!auth_token || auth_token == null || auth_token == undefined) {
			this._router.navigate(['/adminlogin'])
		}

		let auth_token_data: any = 'Bearer '+auth_token;

		this.__adminService.check_auth_token(auth_token_data).subscribe(
			res => { },
			err => {
				localStorage.removeItem('inkingi_auth_token');
				this._router.navigate(['/adminlogin']);
			}
		)

		let token:any = localStorage.getItem('inkingi_auth_token');
		let adminData:any = jwt_decode(token);

		this.username = adminData.username;

		this.getAllProductsRespectToDates();
		this.getAllOrdersRespectToDates();

		this.getNumberOfOrders();
		this.getNumberPendingOrders();
		this.getNumberCompletedOrders();
		this.getNumberOfProducts();
		this.getNumberOfNotifications();
		this.getNumberOfSubscribers();
		this.getfundEarnedByInkingi();
		this.getNumberOfCarts();
		this.getNumberOfSessions();
		this.getNumberOfContactMessages();
		this.getNumberOfFailedTransactions();

	}

	numberOfOrders: number = 0;
	numberOfPendingOrders: number = 0;
	numberOfProducts: number = 0;
	numberOfNotifications: number = 0;
	numberOfSubscribers: number = 0;
	numberOfQuestions: number = 0;
	numberOfCompletedOrders: number = 0;
	fundEarnedByInkingi: number = 0;
	numberOfCarts: number = 0;
	numberOfSessions:number = 0;
	numberOfContactMessages:number = 0;
	numberOfFailedTransactions: number = 0;

	getNumberOfFailedTransactions() {
		this.__transactionsService.getAllTransactions().subscribe(
			res => {
				res.data.forEach((element: any)=> {
					if (element.transaction_status != 'successful') {
						this.numberOfFailedTransactions++;
					}
				});
			},
			err => {
				this.getNumberOfFailedTransactions();
			}
		)
	}

	getNumberOfContactMessages() {
		this.__adminSubscriptionService.getAllContacts().subscribe(
			res => {
				res.data.forEach(()=> {
					this.numberOfContactMessages++;
				});
			},
			err => {
				this.getNumberOfContactMessages();
			}
		)
	}

	getNumberOfSessions() {
		this.__customsService.getAllSessions().subscribe(
			res => {
				res.data.forEach(()=> {
					this.numberOfSessions++;
				});
			},
			err => {
				this.getNumberOfSessions();
			}
		)
	}

	mnth_1_count: number = 0;
	mnth_2_count: number = 0;
	mnth_3_count: number = 0;
	mnth_4_count: number = 0;
	mnth_5_count: number = 0;
	mnth_6_count: number = 0;
	mnth_7_count: number = 0;
	mnth_8_count: number = 0;
	mnth_9_count: number = 0;
	mnth_10_count: number = 0;
	mnth_11_count: number = 0;
	mnth_12_count: number = 0;

	getNumberOfCarts() {
		this.__cartService.getAllCarts().subscribe(
			res => {
				res.data.forEach(()=> {
					this.numberOfCarts++;
				});

				let today_date: any = new Date();
				let today = new Intl.DateTimeFormat('en-US').format(today_date)
				let this_year: any = today.split('/')[2];


				res.data.forEach((element:any) => {
	
					//2021-09-19T12:23:08.504Z
					
					if (element.createdAt.split('-')[0] == this_year) {

						if (element.createdAt.split('-')[1] == '01') {
							this.mnth_1_count++;
						}

						if (element.createdAt.split('-')[1] == '02') {
							this.mnth_2_count++;
						}
						
						if (element.createdAt.split('-')[1] == '03') {
							this.mnth_3_count++;
						}
						
						if (element.createdAt.split('-')[1] == '04') {
							this.mnth_4_count++;
						}
						
						if (element.createdAt.split('-')[1] == '05') {
							this.mnth_5_count++;
						}
						
						if (element.createdAt.split('-')[1] == '06') {
							this.mnth_6_count++;
						}
						
						if (element.createdAt.split('-')[1] == '07') {
							this.mnth_7_count++;
						}
						
						if (element.createdAt.split('-')[1] == '08') {
							this.mnth_8_count++;
						}
						
						if (element.createdAt.split('-')[1] == '09') {
							this.mnth_9_count++;
						}
						
						if (element.createdAt.split('-')[1] == '10') {
							this.mnth_10_count++;
						}
						
						if (element.createdAt.split('-')[1] == '11') {
							this.mnth_11_count++;
						}
						
						if (element.createdAt.split('-')[1] == '12') {
							this.mnth_12_count++;
						}
						
					}
					
				});
				
				this.chart_2.updateSeries([
					{
						name: "Users & client charts",
						data: [
							this.mnth_1_count,
							this.mnth_2_count,
							this.mnth_3_count,
							this.mnth_4_count,
							this.mnth_5_count,
							this.mnth_6_count,
							this.mnth_7_count,
							this.mnth_8_count,
							this.mnth_9_count,
							this.mnth_10_count,
							this.mnth_11_count,
							this.mnth_12_count
						]
					}
				])
			},
			err => {
				this.getNumberOfCarts();
			}
		)
	}

	getfundEarnedByInkingi() {
		this.__transactionsService.getAllTransactions().subscribe(
			res => {
				res.data.forEach((element: any)=> {
					this.fundEarnedByInkingi+=element.transaction_amount;
				});
			},
			err => {
				this.getfundEarnedByInkingi();
			}
		)
	}

	getNumberOfSubscribers() {
		this.__communService.getAllSubscribers().subscribe(
			res => {
				res.data.forEach(()=> {
					this.numberOfSubscribers++;
				});
			},
			err => {
				this.getNumberOfNotifications();
			}
		)
	}

	getNumberOfNotifications() {
		this.__communService.getAllNotification().subscribe(
			res => {
				res.data.forEach(()=> {
					this.numberOfNotifications++;
				});
			},
			err => {
				this.getNumberOfNotifications();
			}
		)
	}

	getNumberOfOrders() {
		this.__cartService.getAllOrders().subscribe(
			res => {
				res.data.forEach(()=> {
					this.numberOfOrders++;
				});
				
			},
			err => {
				this.getNumberOfOrders();
			}
		)
	}

	getNumberPendingOrders() {
		this.__ordersService.getPendingOrders().subscribe(
			res => {
				res.data.forEach(()=> {
					this.numberOfPendingOrders++;
				});
			},
			err => {
				this.getNumberPendingOrders();
			}
		)
	}

	getNumberCompletedOrders() {
		this.__ordersService.getCompletedOrders().subscribe(
			res => {
				res.data.forEach(()=> {
					this.numberOfCompletedOrders++;
				});
			},
			err => {
				this.getNumberCompletedOrders();
			}
		)
	}

	getNumberOfProducts() {
		this.__productsService.getAllProducts().subscribe(
			res => {
				res.data.forEach(()=> {
					this.numberOfProducts++;
				});
			},
			err => {
				this.getNumberOfProducts();
			}
		)
	}

	dayOneProductCount: number = 0;
	dayTwoProductCount: number = 0;
	dayThreeProductCount: number = 0;
	dayFourProductCount: number = 0;
	dayFiveProductCount: number = 0;
	daySixProductCount: number = 0;
	daySevenProductCount: number = 0;
		
	dayOneOrderCount: number = 0;
	dayTwoOrderCount: number = 0;
	dayThreeOrderCount: number = 0;
	dayFourOrderCount: number = 0;
	dayFiveOrderCount: number = 0;
	daySixOrderCount: number = 0;
	daySevenOrderCount: number = 0;
	

	getAllProductsRespectToDates() {
		this.__productsService.getAllProductsRespectToDates().subscribe(
			res => {
				
				this.dayOneProductCount = res.day_one_products.length;
				this.dayTwoProductCount = res.day_two_products.length
				this.dayThreeProductCount = res.day_three_products.length
				this.dayFourProductCount = res.day_four_products.length
				this.dayFiveProductCount = res.day_five_products.length
				this.daySixProductCount = res.day_six_products.length
				this.daySevenProductCount = res.day_seven_products.length
				// console.log(this.dayOneProductCount.length)
				this.chart.updateSeries([
					{
						name: "Products",
						type: 'column',
						data: [
							this.dayOneProductCount,
							this.dayTwoProductCount,
							this.dayThreeProductCount,
							this.dayFourProductCount,
							this.dayFiveProductCount,
							this.daySixProductCount,
							this.daySevenProductCount
						]
			
					},
					{
						name: "Orders",
						type: 'line',
						data: [
							this.dayOneOrderCount,
							this.dayTwoOrderCount,
							this.dayThreeOrderCount,
							this.dayFourOrderCount,
							this.dayFiveOrderCount,
							this.daySixOrderCount,
							this.daySevenOrderCount
						]
					}
				])
			},
			err => {
				this.getAllProductsRespectToDates();
			}
		)
	}

	getAllOrdersRespectToDates() {
		this.__ordersService.getAllOrdersRespectToDates().subscribe(
			res => {
				
				this.dayOneOrderCount = res.day_one_orders.length;
				this.dayTwoOrderCount = res.day_two_orders.length
				this.dayThreeOrderCount = res.day_three_orders.length
				this.dayFourOrderCount = res.day_four_orders.length
				this.dayFiveOrderCount = res.day_five_orders.length
				this.daySixOrderCount = res.day_six_orders.length
				this.daySevenOrderCount = res.day_seven_orders.length

				this.chart.updateSeries([
					{
						name: "Products",
						type: 'column',
						data: [
							this.dayOneProductCount,
							this.dayTwoProductCount,
							this.dayThreeProductCount,
							this.dayFourProductCount,
							this.dayFiveProductCount,
							this.daySixProductCount,
							this.daySevenProductCount
						]
			
					},
					{
						name: "Orders",
						type: 'line',
						data: [
							this.dayOneOrderCount,
							this.dayTwoOrderCount,
							this.dayThreeOrderCount,
							this.dayFourOrderCount,
							this.dayFiveOrderCount,
							this.daySixOrderCount,
							this.daySevenOrderCount
						]
					}
				])
			},
			err => {
				this.getAllProductsRespectToDates();
			}
		)
	}

	@ViewChild("chart") chart: ChartComponent | any;  
	@ViewChild("chart_2") chart_2: ChartComponent | any;  

	public _series:any = [
		{
			name: "Products",
			type: 'column',
			data: [
				this.dayOneProductCount,
				this.dayTwoProductCount,
				this.dayThreeProductCount,
				this.dayFourProductCount,
				this.dayFiveProductCount,
				this.daySixProductCount,
				this.daySevenProductCount
			]

		},
		{
			name: "Orders",
			type: 'line',
			data: [
				this.dayOneOrderCount,
				this.dayTwoOrderCount,
				this.dayThreeOrderCount,
				this.dayFourOrderCount,
				this.dayFiveOrderCount,
				this.daySixOrderCount,
				this.daySevenOrderCount
			]
		}
	]

	public _chart:any = {
		height: 350,
		type: "line",
		fontFamily: 'Poppins, sans-serif'
	}

	public _dataLabels:any = {
		enabled: true
	}

	public _stroke:any = {
		curve: "smooth",
		width: [0, 4]
	}

	public _xaxis:any = {
		type: "days",
		categories: [
			"D 1",
			"D 2",
			"D 3",
			"D 4",
			"D 5",
			"D 6",
			"D 7",
		]
	}

	public  _yaxis:any = [{
		title: {
		  text: 'Products',
		},
	  
	  }, {
		opposite: true,
		title: {
		  text: 'Orders'
		}
	  }]

	public _tooltip:any = {
		x: {
			format: "dd/MM/yy HH:mm"
		}
	}

	// public generateData(baseval: number, count: number, yrange: { max: number; min: number; }) {
	// 	var i = 0;
	// 	var series = [];
	// 	while (i < count) {
	// 		var x = Math.floor(Math.random() * (750 - 1 + 1)) + 1;
	// 		var y =
	// 		Math.floor(Math.random() * (yrange.max - yrange.min + 1)) + yrange.min;
	// 		var z = Math.floor(Math.random() * (75 - 15 + 1)) + 15;
	
	// 		series.push([x, y, z]);
	// 		baseval += 86400000;
	// 		i++;
	// 	}
	// 	return series;
	// }

	public __series:any = [
		{
			name: "Users & client charts",
			data: [
				this.mnth_1_count,
				this.mnth_2_count,
				this.mnth_3_count,
				this.mnth_4_count,
				this.mnth_5_count,
				this.mnth_6_count,
				this.mnth_7_count,
				this.mnth_8_count,
				this.mnth_9_count,
				this.mnth_10_count,
				this.mnth_11_count,
				this.mnth_12_count
			]
		}
	]

	public __chart:any = {
		type: "bar",
		height: 350,
		fontFamily: 'Poppins, sans-serif'
	}

	public __plotOptions:any = {
		bar: {
			horizontal: false,
			borderRadius: 3,
		}
	}

	public __dataLabels:any = {
		enabled: true
	}

	public __xaxis:any = {
		categories: [
			"Jan",
			"Feb",
			"Mar",
			"Apr",
			"May",
			"Jun",
			"Jul",
			"Aug",
			"Sep",
			"Oct",
			"Nov",
			"Dec"
		]
	}

	//traverse to related page links
	traverseToNavigation(link_name: string) {
		switch(link_name) {
			case 'orders': 
				this._router.navigate(['/admin/allorders']);
				break;
			case 'pending': 
				this._router.navigate(['/admin/pendingorders']);
				break;
			case 'completed': 
				this._router.navigate(['/admin/completeorders']);
				break;
			case 'products': 
				this._router.navigate(['/admin/products']);
				break;
			case 'transactions': 
				this._router.navigate(['/admin/transactions']);
				break;
			case 'carts': 
				this._router.navigate(['/admin/carts']);
				break;
			case 'subscribers': 
				this._router.navigate(['/admin/subscribers']);
				break;
			case 'dashboard': 
				this._router.navigate(['/admin/dashboard']);
				break;
			case 'sessions': 
				this._router.navigate(['/admin/sessions']);
				break;
			case 'contact': 
				this._router.navigate(['/admin/contacted']);
				break;
			default: 
				this._router.navigate(['/admin/dashboard']);
		}
	}

}
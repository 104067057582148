import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
	providedIn: 'root'
})
export class CommunicationService {

	constructor( private _http: HttpClient ) { }

	subscribeNow(data: any) {
		return this._http.post<any>(`${environment.apiUrl}subscribers/addSubscriber`, data);
	}

	sendNotification(data: any) {
		return this._http.post<any>(`${environment.apiUrl}notifications/sendNotification`, data);
	}
	sendMessageToInkingi(data: any) {
		return this._http.post<any>(`${environment.apiUrl}contacts/addMessage`, data);
	}	
	
	unsubscribe(data: any) {
		return this._http.delete<any>(`${environment.apiUrl}subscribers/deleteSubscriber/${data}`);
	}

	getAllNotification() {
		return this._http.get<any>(`${environment.apiUrl}notifications/getAllNotifications`);
	}

	clearAllNotifications() {
		return this._http.get<any>(`${environment.apiUrl}notifications/clearAllNotifications`);
	}

	markNotificationRead(value: any) {
		return this._http.get<any>(`${environment.apiUrl}notifications/markSingleNotificationRead/${value}`);
	}
	
	getAllSubscribers() {
		return this._http.get<any>(`${environment.apiUrl}subscribers/getSubscribers`);
	}
	
	getSessionData() {
		return this._http.get<any>(`${environment.apiUrl}sessions/getSessionData`);
	}
	
	addSession(data: any) {
		return this._http.post<any>(`${environment.apiUrl}sessions/addSession`, data);
	}
	
	emailAboutNewCollection(data: any) {
		return this._http.post<any>(`${environment.apiUrl}subscribers/emailAboutNewCollection`, data);
	}
	
}
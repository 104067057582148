import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ProductsService } from '../../services/products.service';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-adminallproducts',
	templateUrl: './adminallproducts.component.html',
	styleUrls: ['./adminallproducts.component.css']
})
export class AdminallproductsComponent implements OnInit {

	constructor(private _router: Router, private __productsService: ProductsService) { }

	apiPublic: string = environment.apiPublic;

	ngOnInit(): void {
		this.getAllProducts();
	}

	isSuccess: boolean = false;
	successMsg: string = '';
	hasSubmitted: boolean = false;
	hasErrorAlert: boolean = false;
	hasErrorMsg: string = '';

	productdata: any;

	loadingData: boolean = true;
	noProductStatus: boolean = false;

	likesArr: any = [];

	getAllProducts() {
		this.__productsService.getAllProducts().subscribe(
			res => {
				this.productdata = res.data;
				
				if (this.productdata.length == 0) {
					this.noProductStatus = true;
				}
				
				this.loadingData = false;
			},
			err => {
				this.getAllProducts()
			}
		)
	}

	renderAddProduct() {
		this._router.navigate(['admin/addproduct']);
	}

	viewProduct(value: any) {
		const url:any = this._router.createUrlTree(['/product', value]);
		window.open(url.toString(), '_blank');
	}

	proccessingThisProd: boolean = false;
	proccessingThisProdId: string = '';

	deleteThisProduct(value: any) {
		this.proccessingThisProd = true;
		this.proccessingThisProdId = value;
		this.__productsService.deleteDisableProduct(value).subscribe(
			res => {
			
				this.successMsg = 'Deleted product successfully';
				this.isSuccess = true;
				
				let count = 0;

				let successInterval = setInterval(() => {
					if (count > 0) {
						this.isSuccess = false;
						this.proccessingThisProdId = '';
						clearInterval(successInterval);
						this.getAllProducts();
						this.proccessingThisProd = false;
					}

					count = count + 1;
				}, 2000)

			},
			err => {
				this.hasSubmitted = false;
				this.hasErrorAlert = true;
				this.hasErrorMsg = 'Something went wrong try again!'

				let count = 0;

				let successInterval = setInterval(() => {
					if (count > 0) {
						this.proccessingThisProd = false;
						this.proccessingThisProdId = '';
						this.hasErrorAlert = false;
						clearInterval(successInterval);
					}
	
					count = count + 1;
				}, 2000)
			}
		)
	}

	reactiveProduct(value: any) {
		this.proccessingThisProd = true;
		this.proccessingThisProdId = value;
		this.__productsService.reactiveDisabledProduct(value).subscribe(
			res => {
			
				this.successMsg = 'Reactived product successfully';
				this.isSuccess = true;
				
				let count = 0;

				let successInterval = setInterval(() => {
					if (count > 0) {
						this.isSuccess = false;
						this.proccessingThisProdId = '';
						clearInterval(successInterval);
						this.getAllProducts();
						this.proccessingThisProd = false;
					}

					count = count + 1;
				}, 2000)


			},
			err => {
				this.hasSubmitted = false;
				this.hasErrorAlert = true;
				this.hasErrorMsg = 'Something went wrong try again!'

				let count = 0;

				let successInterval = setInterval(() => {
					if (count > 0) {
						this.hasErrorAlert = false;
						this.proccessingThisProd = false;
						this.proccessingThisProdId = '';
						clearInterval(successInterval);
					}
	
					count = count + 1;
				}, 2000)
			}
		)
	}
	
	getSortingAlgorithm(algorithm: string) {
		if (algorithm == 'latest') {
			this.productdata.sort(function(a:any, b:any) {
				return new Date(b.createdAt).valueOf() - new Date(a.createdAt).valueOf();
			});
		}
		
		if (algorithm == 'popular') {
			this.productdata.sort(function(a:any, b:any) {
				return b.productLikes - a.productLikes;
			});
		}
		
		if (algorithm == 'low_high') {
			this.productdata.sort(function(a:any, b:any) {
				return a.productPrice - b.productPrice;
			});
		}

		if (algorithm == 'high_low') {
			this.productdata.sort(function(a:any, b:any) {
				return b.productPrice - a.productPrice;
			});
		}
	}

	editProductNavigation(value: any) {
		this._router.navigate(['/admin/editproduct/', value]);
	}

}
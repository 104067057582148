import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  	providedIn: 'root'
})

export class CartService {

	constructor( private _http: HttpClient ) { }

	updateCartItemsNumber: any = new EventEmitter();

	public numberOfItemsInCart: number = 0;

	getCartNumber() {
		return this.numberOfItemsInCart;
	}
	
	updateCartNumber(value: number) {
		this.numberOfItemsInCart = this.numberOfItemsInCart + value;
		this.updateCartItemsNumber.emit(this.getCartNumber);
	}

	updateCountCart(value: number) {
		this.numberOfItemsInCart = value;
	}

	async getMyShoppingCart() {
		var cookieName = "_mycart";
		var matchCookie = await document.cookie.match(new RegExp('(^| )' + cookieName + '=([^;]+)'));

		if (matchCookie) {
			return false;
		} 
		
		if (!matchCookie){
			return true;
		}
	}

	getAllCarts() {
		return this._http.get<any>(`${environment.apiUrl}cart/getAllCarts`);
	}

	getSpecificCart(value: string) {
		return this._http.get<any>(`${environment.apiUrl}cart/getSpecificCart/${value}`);
	}
	
	createNewCart(data: any) {
		return this._http.post<any>(`${environment.apiUrl}cart/addToCart/`, data);
	}

	updateCart(id: any ,data: any) {
		return this._http.post<any>(`${environment.apiUrl}cart/updateCart/${id}`, data);
	}

	removeItemFromCart(id: any ,data: any) {
		return this._http.post<any>(`${environment.apiUrl}cart/removeItemFromCart/${id}`, data);
	}

	updateCartItemInCart( idcart: any ,idproduct: any ,datanumber: any) {
		return this._http.post<any>(`${environment.apiUrl}cart/updateItemInCartLevel/${idcart}/${idproduct}`, datanumber);
	}

	orderItemsInMyCart( data: any) {
		return this._http.post<any>(`${environment.apiUrl}orders/addOrder/`, data);
	}

	deleteCart( data: any) {
		return this._http.delete<any>(`${environment.apiUrl}cart/deleteCart/${data}`);
	}

	checkoutCart( data: any) {
		return this._http.get<any>(`${environment.apiUrl}cart/checkoutCart/${data}`);
	}

	//all about orders
	getOrder(value: any) {
		return this._http.get<any>(`${environment.apiUrl}orders/getSpecificOrder/${value}`);
	}
	
	getAllOrders() {
		return this._http.get<any>(`${environment.apiUrl}orders/getAllOrders/`);
	}

}
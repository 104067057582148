import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  	providedIn: 'root'
})

export class CustomsService {

    constructor( private _http: HttpClient ) { }

	updateNavbarColors: any = new EventEmitter();

	public onTheHomePage: boolean = true;

	getWhichPageState() {
		return this.onTheHomePage;
	}
	
	updatePageStatus(value: boolean) {
		this.onTheHomePage = value;
		this.updateNavbarColors.emit(this.getWhichPageState);
	}

	updateWhichOneHasGoneTo(value: boolean) {
		this.onTheHomePage = value;
	}

	customizeWebsite(data: any) {
		return this._http.post<any>(`${environment.apiUrl}customs/addCustoms`, data);
	}

	getWebsiteCustomizations() {
		return this._http.get<any>(`${environment.apiUrl}customs/getCustoms`);
	}

	updateWebsiteCustomizations(value: any, data: any) {
		return this._http.put<any>(`${environment.apiUrl}customs/updateCustoms/${value}`, data);
	}

	getAllSessions() {
		return this._http.get<any>(`${environment.apiUrl}sessions/getAllSessions`);
	}
	
}
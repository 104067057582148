<div id="entire_application__cart_component">

    <div *ngIf="loading" class="loader">
        <app-loader></app-loader>
    </div>

    <div *ngIf="isSuccess || hasErrorAlert" class="success_message">
        <div class="msg_box" [ngClass]="{'isFailed': hasErrorAlert}">
            <p *ngIf="isSuccess">{{ successMsg }}</p>
            <p *ngIf="hasErrorAlert">{{ hasErrorMsg }}</p>
            <svg *ngIf="isSuccess" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-.997-4L6.76 11.757l1.414-1.414 2.829 2.829 5.656-5.657 1.415 1.414L11.003 16z" fill="rgba(255,255,255,1)"/></svg>
            <svg *ngIf="hasErrorAlert" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-9.414l2.828-2.829 1.415 1.415L13.414 12l2.829 2.828-1.415 1.415L12 13.414l-2.828 2.829-1.415-1.415L10.586 12 7.757 9.172l1.415-1.415L12 10.586z" fill="rgba(255,255,255,1)"/></svg>
        </div>
    </div>

    <div class="cookies_disabled" *ngIf="cookies_disabled">
        <div class="cookie_modal_container col-mob-11">
            <div class="image">
                <img src="../../../assets/app/cookies.png" alt="">
            </div>
            <div class="message">
                <h1>You must enable cookies</h1>
                <p>Inkingi fashion design shop uses browser cookies and yours have been disabled please enable them to continue shopping.</p>
                <button (click)="enableBrowserCookies()">Edit settings by <strong>&nbsp;ALT + F&nbsp;</strong> then <strong>&nbsp;S&nbsp;</strong></button>
            </div>
        </div>
    </div>

    <div class="cart__container">
        <div class="header">
            <h3>My shopping cart</h3>
        </div>
        <div class="items_in_cart">
            <div class="item_content col-mob-12 col-tab-12 col-large-12">

                <div *ngIf="!loading && myCart.products.length == 0" class="no_cart_items">
                    <h1>Your cart is empty now.</h1>
                    <p>Considering to start shopping . . .</p>
                    <button (click)="goToShop()">Start shopping now</button>
                </div>

                <div *ngIf="loadingData && !loading && myCart.products.length != 0" class="isLoadingData">
                    <svg class="spinner_loading" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path fill="#000000" d="M18.364 5.636L16.95 7.05A7 7 0 1 0 19 12h2a9 9 0 1 1-2.636-6.364z"/></svg>
                    <p>Loading . . .</p>
                </div>    

                <div class="item" *ngFor="let item of finalProductsInCart.slice().reverse(); let i = index"> 
                    <div class="image_sample col-mob-3">
                        <img (click)="seeMoreOneThisProduct(item[5]._id)" [src]="item[5].productMainImage" alt="">
                    </div>
                    <div class="item_content_names_sizes col-mob-9">
                        <h4>{{ item[5].productName }}</h4>
                        <p *ngIf="item[0] == 's'">Small size</p>
                        <p *ngIf="item[0] == 'm'">medium size</p>
                        <p *ngIf="item[0] == 'l'">Large size</p>
                        <p *ngIf="item[0] == 'xl'">Extra large</p>
                        <p *ngIf="item[0] == 'xxl'">Extra extra large</p>
                        <div class="color" *ngIf="item[3] != 'default' " [style.background]="item[3]" style="width: 70%; height: 0.35em; border-radius: 0.3em; margin: 0.2em 10%;"></div>
                        <div class="color" *ngIf="item[3] == 'default' " style="font-size: 0.7em; margin: 0 10%; opacity: 0.4;">Default color</div>
                    </div>
                    <div class="prices col-mob-12">
                        <p *ngIf="!loadingPrice"> 
                            <strong *ngIf="currency == 'usd'">$ </strong> 
                            <strong *ngIf="currency == 'gbp'">&pound; </strong> 
                            {{ item[5].productPrice | currencies:currency:exchangeValue | number }} 
                            <span *ngIf="currency == 'rwf'">RWF</span>
                        </p>
                        <p *ngIf="loadingPrice"><span>pricing . . .</span></p>
                        <input type="number" #number (input)="updateThisItem(item[4], item[5]._id, item[1], item[5].quantityInStock, number.value, i)" value="{{item[1]}}" [disabled]="disableInputs" min="1">
                        <p *ngIf="!loadingPrice"> 
                            <strong *ngIf="currency == 'usd'">$ </strong> 
                            <strong *ngIf="currency == 'gbp'">&pound; </strong> 
                            {{ item[1] * item[5].productPrice | currencies:currency:exchangeValue | number }} 
                            <span *ngIf="currency == 'rwf'">RWF</span>
                        </p>
                        <p *ngIf="loadingPrice"><span>suming . . .</span></p>
                    </div>
                    <div class="remove col-mob-12">
                        <p *ngIf="!removingFromCartSubmission || removingCartSubmissionId != item[4]" (click)="removeItemFromCart( item[4], item[5]._id, item[1] )">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20"><path fill="none" d="M0 0h24v24H0z"/><path d="M17 6h5v2h-2v13a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V8H2V6h5V3a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1v3zm1 2H6v12h12V8zm-9 3h2v6H9v-6zm4 0h2v6h-2v-6zM9 4v2h6V4H9z" fill="rgba(255,56,52,1)"/></svg>
                            Remove
                        </p>
                        <p *ngIf="removingFromCartSubmission && removingCartSubmissionId == item[4]">
                            <svg class="spinner_loading" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path fill="rgba(255,56,52,1)" d="M18.364 5.636L16.95 7.05A7 7 0 1 0 19 12h2a9 9 0 1 1-2.636-6.364z"/></svg>         
                        </p>
                    </div>
                </div>

            
                <button (click)="updateCartSync()">
                    <svg [ngClass]="{'spinning': updatingcart}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20"><path fill="none" d="M0 0h24v24H0z"/><path d="M5.463 4.433A9.961 9.961 0 0 1 12 2c5.523 0 10 4.477 10 10 0 2.136-.67 4.116-1.81 5.74L17 12h3A8 8 0 0 0 6.46 6.228l-.997-1.795zm13.074 15.134A9.961 9.961 0 0 1 12 22C6.477 22 2 17.523 2 12c0-2.136.67-4.116 1.81-5.74L7 12H4a8 8 0 0 0 13.54 5.772l.997 1.795z" fill="rgba(255,255,255,1)"/></svg>
                    Update cart
                </button>

            </div>
            <div class="cart_details col-mob-12 col-tab-12 col-large-12">
                <div class="cart_status_details col-mob-12">
                    <div class="header">
                        <h6>General Cart Totals</h6>
                    </div>
                    <div class="status_container subtotal">
                        <p>Sub total</p>
                        <div class="details">
                            <h2>Subtotal</h2>
                            <p *ngIf="!loadingPrice"> 
                                <strong *ngIf="currency == 'usd'">$ </strong> 
                                <strong *ngIf="currency == 'gbp'">&pound; </strong> 
                                {{ myCart.totalPrice | currencies:currency:exchangeValue | number }} 
                                <span *ngIf="currency == 'rwf'">RWF</span>
                            </p>
                            <p *ngIf="loadingPrice"><span>calculating . . .</span></p>
                        </div>
                    </div>
                    <div class="status_container shipping">
                        <p>Shipping</p>
                        <p>Enter you location to view shipping options.</p>
                        <a [routerLink]="['/checkout']">
                            Calculate shipping 
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="18" height="18"><path fill="none" d="M0 0h24v24H0z"/><path d="M8.965 18a3.5 3.5 0 0 1-6.93 0H1V6a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v2h3l3 4.056V18h-2.035a3.5 3.5 0 0 1-6.93 0h-5.07zM15 7H3v8.05a3.5 3.5 0 0 1 5.663.95h5.674c.168-.353.393-.674.663-.95V7zm2 6h4v-.285L18.992 10H17v3zm.5 6a1.5 1.5 0 1 0 0-3.001 1.5 1.5 0 0 0 0 3.001zM7 17.5a1.5 1.5 0 1 0-3 0 1.5 1.5 0 0 0 3 0z" fill="rgba(50,98,219,1)"/></svg>
                        </a>
                    </div>
                    <div class="status_container total">
                        <p>Total</p>
                        <div class="details">
                            <h2>Total</h2>
                            <p *ngIf="!loadingPrice"> 
                                <strong *ngIf="currency == 'usd'">$ </strong> 
                                <strong *ngIf="currency == 'gbp'">&pound; </strong> 
                                {{ myCart.totalPrice | currencies:currency:exchangeValue | number }} 
                                <span *ngIf="currency == 'rwf'">RWF</span>
                            </p>
                            <p *ngIf="loadingPrice"><span>calculating . . .</span></p>
                        </div>
                    </div>
                    <div class="status_container proceed_shopping">
                        <button (click)="proceedToCheck(myCart.totalPrice)">Proceed To Checkout</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
import { Component, HostListener, OnInit } from '@angular/core';
import { CustomsService } from 'src/app/services/customs.service';

@Component({
	selector: 'app-adminsessions',
	templateUrl: './adminsessions.component.html',
	styleUrls: ['./adminsessions.component.css']
})
export class AdminsessionsComponent implements OnInit {

	constructor(	
		private __customsService: CustomsService
	) { }

	ngOnInit(): void {
		this.getAllTransactions();
	}

	isSuccess: boolean = false;
	successMsg: string = '';
	hasSubmitted: boolean = false;
	hasErrorAlert: boolean = false;
	hasErrorMsg: string = '';

	sessions: any = [];
	sessions_backup: any = [];
	loadingData: boolean = true;

	noSessionStatus: boolean = false;

	getAllTransactions() {
		this.__customsService.getAllSessions().subscribe(
			res => {
				this.loadingData = false;
				this.sessions.push(res.data);
				this.sessions_backup.push(res.data);

				if (this.sessions[0].length == 0) {
					this.noSessionStatus = true;
				}
			},
			err => {
				this.hasErrorAlert = true;
				this.hasErrorMsg = "Something went wrong. Reloading . . ."
				
				let count = 0;

				let successIntervalTwo = setInterval(() => {
					if (count > 0) {
						this.hasErrorAlert = false;
						clearInterval(successIntervalTwo);
						location.reload();
					}
					count = count + 1;
				}, 2000)
			}
		)
	}

	currentViewedRow: string = '';
	currentRowView(value: string) {
		this.currentViewedRow = value;
	}
	
	pageScrollToNavbarDownPosition: boolean = false;

	@HostListener('window:scroll', ['$event']) onScrollEvent(event: any) {
		if (window.pageYOffset > 118) {
			this.pageScrollToNavbarDownPosition = true;
		} else {
			this.pageScrollToNavbarDownPosition = false;
		}  
	}
	
	searchTermNotFound: boolean = false;
	searching_loading: boolean = false;
	searchAlgorithmActivity(keyword: any) {
		const searchTerm: string = keyword;
		let session_result_arr: any = [];
		this.searchTermNotFound = false;
		
		if (keyword == '') {
			this.sessions = this.sessions_backup;
			return;
		}
		this.sessions = this.sessions_backup;
		this.searching_loading = true;
		let search_results = this.sessions[0].filter((val: any) => {
			return val.location == searchTerm ||
			val.ip_address == searchTerm ||
			val.mac_address == searchTerm ||
			val.browser == searchTerm ||
			val.lng_lat == searchTerm ||
			val.data_org == searchTerm ||
			val.operating_system == searchTerm 
		})
		session_result_arr.push(search_results);
		this.searching_loading = false;
		this.sessions = session_result_arr;

		if (this.sessions[0].length <= 0) {
			this.searchTermNotFound = true;
		}

	}

}
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CartService } from 'src/app/services/cart.service';
import { CustomsService } from 'src/app/services/customs.service';
import { OrdersService } from 'src/app/services/orders.service';
import { PaymentsService } from 'src/app/services/payments.service';
import { PostalcodesService } from 'src/app/services/postalcodes.service';
import { ProductsService } from 'src/app/services/products.service';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-editshipping',
	templateUrl: './editshipping.component.html',
	styleUrls: ['./editshipping.component.css']
})

export class EditshippingComponent implements OnInit {

	constructor( 
		private __cartService: CartService, 
		private __customsService: CustomsService, 
		private _router: Router, 
		private __productsService: ProductsService,
		private __postalcodesService: PostalcodesService,
		private __paymentsServices: PaymentsService,
		private __ordersService: OrdersService
	) {
		this.checkOutCookieStatus();
	}

	checkOutCookieStatus() {
		if (!navigator.cookieEnabled) {
			this.cookies_disabled = true;
		}
	}

	cookies_disabled: boolean = false;

	enableBrowserCookies() {
		alert("Press ALT + F and then S to edit your browser settings")
	}

	apiPublic: string = environment.apiPublic;
	loading: boolean = true;

	ngOnInit(): void {
		this.checkOutCookieStatus();
		const checkoutToken:any = localStorage.getItem('__checkout_credt_id');

		if (checkoutToken == null) {
			this._router.navigate(['/checkout']);
		}

		const paymentToken: any = localStorage.getItem('__payment_token');

		if (paymentToken != null) {
			this.hasErrorAlert = true;
			this.hasErrorMsg = "Can not edit destination at payment."
			
			let count = 0;

			let successInterval = setInterval(() => {
				if (count > 0) {
					this.hasErrorAlert = false;
					
					this.isSuccess = true;
					this.successMsg = "Maybe you can cancel the order."
					
					let count = 0;
	
					let successInterval_2 = setInterval(() => {
						if (count > 0) {
							this.isSuccess = false;
							this.loading = false;
							clearInterval(successInterval_2);
							this._router.navigate(['/payment']);
						}
						
						count = count + 1;
					}, 3000)

					clearInterval(successInterval);
				}

				count = count + 1;
			}, 3000)
		}

		this.getCurrentCurrency();
		this.getAllCurrencies();

		this.__customsService.updatePageStatus(false);

		this.__productsService.updateExchangeRates.subscribe(
			(res:any) => {
				this.currency = this.__productsService.getCurrentCurrency();
			}, (err:any) => console.log(err) 
		);

		this.__cartService.getMyShoppingCart().then(data => {
			this.shouldCreateCart = data;
		
			if (this.shouldCreateCart) {
				this.createNewCart();
			} 
			
			if (!this.shouldCreateCart) {
				var cookieid: any = document.cookie.match(new RegExp('(^| )' + '_mycart' + '=([^;]+)'));
				this.getMyShoppingCart(cookieid[2]);
			}
			
		});

		this.getMyOrder();

	}

	getMyOrder() {
		var token: any = localStorage.getItem('__checkout_credt_id');
		this.__ordersService.getSpecificOrder(token).subscribe(
			res => {
								
				this.shippingMethodPickup = res.data.pickupmethod;

				if (this.shippingMethodPickup == 'pick') {
					this.contactinfo = res.data.contactinfo;
					this.firstname = res.data.firstname;
					this.lastname = res.data.lastname
				}

				if (this.shippingMethodPickup == 'ship') {
					this.contactinfo = res.data.contactinfo;
					this.firstname = res.data.firstname;
					this.lastname = res.data.lastname;
					this.apartment = res.data.apartment;
					this.street = res.data.street;
					this.city = res.data.city;
					this.country = res.data.country;
					this.province = res.data.province;
					this.state = res.data.state;
					this.postalcode = res.data.postalcode;
					this.phonenumber = res.data.phonenumber;
				}

				if (this.finalProductsInCart.length == 0) {
					this.loading = false;
				}

			},
			err => {
				this.getMyOrder()
			}
		)
	}
	
	currency: any = 'rwf';
	exchangeValue: any = 0;
	exchangeRates: any;
	loadingPrice: boolean = true;
	reprocessingcurrencies: number = 0;

	getCurrentCurrency() {
		this.currency = this.__productsService.getCurrentCurrency();
	}

	getAllCurrencies() {

		this.__productsService.getCurrencies().subscribe(
			res => {
				this.exchangeRates = res.data;
				this.exchangeValue = this.exchangeRates;
				this.loadingPrice = false;
			},
			err => {

				if (this.reprocessingcurrencies <= 2) {
					this.getAllCurrencies();
					this.reprocessingcurrencies++;
				} else {
					alert('Having trouble in processing currencies! Reprocessing . . .');
					location.reload();
				}

			})

	}

	shippingMethodPickup: string = 'pick';

	changeShippingMethod(value: string) {
		this.shippingMethodPickup = value;
	}

	myCart: any = [];
	shouldCreateCart: any;

	isSuccess: boolean = false;
	successMsg: string = '';
	hasSubmitted: boolean = false;
	hasErrorAlert: boolean = false;
	hasErrorMsg: string = '';

	loadingData: boolean = true;
	
	getMyShoppingCart(value: any) {
		this.checkOutCookieStatus();
		this.myCart = [];
		this.finalProductsInCart = [];
		this.thisItem = [];
		this.firstlevelItems = [];
		this.firstlevelProds = [];

		let data = value;

		this.__cartService.getSpecificCart(data).subscribe(
			res => {
				this.myCart = res.data;
				
				if (this.myCart.length != 0) {
					this.getEachCartItem();
				}

				if (this.myCart.products.length == 0) {
					this.hasErrorAlert = true;
					this.hasErrorMsg = "You cart is empty put some items!"
					
					let count = 0;
	
					let successInterval = setInterval(() => {
						if (count > 0) {
							this.hasErrorAlert = false;
							clearInterval(successInterval);
							this._router.navigate(['/shop']);				
						}
		
						count = count + 1;
					}, 3000)
				}

			},
			err => {
				// this.loading = false;
				this.hasErrorAlert = true;
				this.hasErrorMsg = "You cart is not found!"
				
				let count = 0;

				let successInterval = setInterval(() => {
					if (count > 0) {
						this.hasErrorAlert = false;
						clearInterval(successInterval);
						this.createAlert()					
					}
	
					count = count + 1;
				}, 3000)
			}
		)
	}

	createAlert() {
		this.isSuccess = true;
		this.successMsg = "Don't worry we are creating a new one for you"
		
		let countTwo = 0;

		let successIntervalTwo = setInterval(() => {
			if (countTwo > 0) {
				this.isSuccess = false;
				clearInterval(successIntervalTwo);

				this.createNewCart();
			}

			countTwo = countTwo + 1;
		}, 3000)
	}

	createNewCart() {
		let newCartData = {
			"products": []
		};

		this.__cartService.createNewCart(newCartData).subscribe(
			res => {
				document.cookie = `_mycart=${res.data._id}; path=/; max-age=` + 2147483647;
				this.getMyShoppingCart(res.data._id)
			},
			err => {
				this.createNewCart();
			}
		)
	}

	finalProductsInCart: any = [];

	thisItem: any = [];
	firstlevelItems: any = [];
	firstlevelProds: any = [];
	
	async getEachCartItem() {

		const promises = this.myCart.products.map(async (element: any) => {
			this.thisItem = [
				element.size, 
				element.number, 
				element.singlePrice, 
				element.color,
				element.productUniqueness
			];
			
			this.firstlevelItems = [
				...this.firstlevelItems,
				this.thisItem
			]

			await this.__productsService.getProductById(element.productId).subscribe(
				res => {

					this.firstlevelProds = [
						...this.firstlevelProds,
						res.data
					]

					this.updateFinals();
										
				},
				err => {
					return;
				}
				)
				
			this.thisItem = [];	
		});

		await Promise.all(promises);

	}

	updateFinals() {
	
		if (this.firstlevelProds.length == this.firstlevelItems.length) {
			let count = 0;

			this.firstlevelItems.forEach((element: any) => {
				element = [
					...element,
					this.firstlevelProds[count]
				];
				let item = element;
				this.finalProductsInCart.push(item);
				count++;
			});

			if (this.finalProductsInCart.length == 0) {
				this.hasErrorAlert = true;
				this.hasErrorMsg = "First add items to your cart!"
				
				let count = 0;

				let successInterval = setInterval(() => {
					if (count > 0) {
						this.hasErrorAlert = false;
						clearInterval(successInterval);
						this._router.navigate(['/cart']);
						this.loading = false;				
					}
	
					count = count + 1;
				}, 3000)
			}

			this.loadingData = false;
		}

	}

	hasErrors: boolean = false;
	errors: string = '';

	contactinfo: string = '';

	firstname: string = '';
	lastname: string = '';
	apartment: string = '';
	street: string = '';
	city: string = '';
	country: string = 'Rwanda';
	state: string = '';
	province: string = '';
	postalcode: string = '';
	phonenumber: string = '';

	contactinforequired: boolean = false;
	firstnamerequired: boolean = false;
	lastnamerequired: boolean = false;
	apartmentrequired: boolean = false;
	streetrequired: boolean = false;
	cityrequired: boolean = false;
	staterequired: boolean = false;
	provincerequired: boolean = false;
	countryrequired: boolean = false;
	phonenumberrequired: boolean = false;
	postalcoderequired: boolean = false;

	postalcodeinvalid: boolean = false;

	getContactInfo(value: string) { this.contactinforequired = false; this.contactinfo = value; }
	getFirstname(value: string) { this.firstnamerequired = false; this.firstname = value; }
	getLastname(value: string) { this.lastnamerequired = false; this.lastname = value; }
	getApartment(value: string) { this.apartmentrequired = false; this.apartment = value; }
	getStreet(value: string) { this.streetrequired = false; this.street = value; }
	getCountry(value: string) { this.countryrequired = false; this.country = value; }
	getCity(value: string) { this.cityrequired = false; this.city = value; }
	getPostalcode(value: string) { this.postalcoderequired = false; this.postalcode = value; }
	getPhonenumber(value: string) { this.phonenumberrequired = false; this.phonenumber = value; }
	
	getUsState(value: string) { 
		this.province = ''; 
		this.staterequired = false; 
		this.state = value; 
	}
	getProvince(value: string) { 
		this.state = '';
		this.provincerequired = false; 
		this.province = value; 
	}

	async proceedToPayment() {
		this.hasErrors = false;
		this.contactinforequired = false;
		this.firstnamerequired = false;
		this.lastnamerequired = false;
		this.streetrequired = false;
		this.apartmentrequired = false;
		this.countryrequired = false;
		this.cityrequired = false;
		this.phonenumberrequired = false;
		this.staterequired = false;
		this.provincerequired = false;
		this.postalcoderequired = false;

		if (this.shippingMethodPickup == 'pick') {
		
			if (this.contactinfo == '') {
				this.hasErrors = true;
				this.errors = 'Contact info is required'
				this.contactinforequired = true;
				window.scroll(0, 0);
				return;
			} 

			if (this.firstname == '') {
				this.hasErrors = true;
				this.errors = 'Firstname is required'
				this.firstnamerequired = true;
				window.scroll(0, 0);
				return;
			} 

			if (this.lastname == '') {
				this.hasErrors = true;
				this.errors = 'Lastname is required'
				this.lastnamerequired = true;
				window.scroll(0, 0);
				return;
			} 
			
			var cookieName = "_mycart";
			var matchCookie = await document.cookie.match(new RegExp('(^| )' + cookieName + '=([^;]+)'));

			if (!matchCookie){
				this.hasErrorAlert = true;
				this.hasErrorMsg = "Invalid cart details"
				
				let count = 0;

				let successInterval = setInterval(() => {
					if (count > 0) {
						this.hasErrorAlert = false;
						clearInterval(successInterval);
						this._router.navigate(['/cart']);				
					}
	
					count = count + 1;
				}, 3000)
				return;
			}
			
			var cartid: any = matchCookie[2];

			this.hasSubmitted = true;

			let deliveryAddress: any = {
				"contactinfo": this.contactinfo,
				"firstname": this.firstname,
				"lastname": this.lastname,
				"pickupmethod": 'pick',
				"cartId": cartid
			}

			this.__cartService.orderItemsInMyCart(deliveryAddress).subscribe(
				res => {

					let current_checkout_id: any = localStorage.getItem('__checkout_credt_id');
					localStorage.setItem('__checkout_credt_id', res.data._id);

					this.__ordersService.deleteOrder(current_checkout_id).subscribe(
						res => {}
						,err => {}
					)
					
					this.__cartService.checkoutCart(cartid).subscribe(
						res => {
							var checkout_token: any = localStorage.getItem('__checkout_credt_id')
							
							this.__paymentsServices.authenaticatePayment(checkout_token).subscribe(
								res => {
									this.hasSubmitted = true;
									this.isSuccess = true;
									this.successMsg = "Proceeding to payment . . .";
									
									localStorage.setItem('__payment_token', res.data.token);
					
									let count = 0;
					
									let successInterval = setInterval(() => {
										if (count > 0) {
											this.isSuccess = false;
											clearInterval(successInterval);
											this._router.navigate(['/payment'])				
										}
					
										count = count + 1;
									}, 3000)
								},
								err => {
									this.hasSubmitted = true;
									this.hasErrorAlert = true;
									this.hasErrorMsg = 'Something went wrong! Try again';
									
									let count = 0;
					
									let successInterval = setInterval(() => {
										if (count > 0) {
											this.hasErrorAlert = false;
											clearInterval(successInterval);				
										}
					
										count = count + 1;
									}, 3000)
								}
							)
						},
						err => {
							this.proceedToPayment();
						}
					)

				},
				err => {
					this.hasSubmitted = false;
					this.hasErrors = true;
					this.errors = err.message;
					window.scroll(0, 0);
				}
			) 

		} else {

			if (this.contactinfo == '' || this.firstname == '' || this.lastname == '' || this.apartment == '' || this.street == '' || this.country == '' || this.city == '' || this.phonenumber == '') {
				this.postalcodeinvalid = false;
				if (this.contactinfo == '') {
					this.hasErrors = true;
					this.errors = 'Contact info is required'
					this.contactinforequired = true;
					window.scroll(0, 0);
					return;
				} 

				if (this.firstname == '') {
					this.hasErrors = true;
					this.errors = 'Firstname is required'
					this.firstnamerequired = true;
					window.scroll(0, 0);
					return;
				} 

				if (this.lastname == '') {
					this.hasErrors = true;
					this.errors = 'Lastname is required'
					this.lastnamerequired = true;
					window.scroll(0, 0);
					return;
				} 

				if (this.apartment == '') {
					this.hasErrors = true;
					this.errors = 'Apartment number is required'
					this.apartmentrequired = true;
					window.scroll(0, 0);
					return;
				} 

				if (this.street == '') {
					this.hasErrors = true;
					this.errors = 'Street address is required'
					this.streetrequired = true;
					window.scroll(0, 0);
					return;
				} 

				if (this.city == '') {
					this.hasErrors = true;
					this.errors = 'City is required'
					this.cityrequired = true;
					window.scroll(0, 0);
					return;
				} 

				if (this.country == '') {
					this.hasErrors = true;
					this.errors = 'Country is required'
					this.countryrequired = true;
					window.scroll(0, 0);
					return;
				} 

				if (this.phonenumber == '') {
					this.hasErrors = true;
					this.errors = 'Phone number is required'
					this.phonenumberrequired = true;
					window.scroll(0, 0);
					return;
				} 

				return;			
			}

			if (this.phonenumber.length <= 9 || this.phonenumber.length >= 21) {
				this.hasErrors = true;
				this.errors = 'Phone number should be 10 - 20 characters long'
				this.phonenumberrequired = true;
				window.scroll(0, 0);
				return;	
			}

			if (this.country == 'Canada' || this.country == "South Africa" || this.country == "Kenya" || this.country == 'United States of America' || this.country == 'Germany' || this.country == 'United Kingdom') {
				if (this.postalcode == '') {
					this.hasErrors = true;
					this.errors = 'Postal code is required'
					this.postalcoderequired = true;
					window.scroll(0, 0);
					return;
				}
			}

			if (this.country == 'United States of America' || this.country == 'United Arab Emirates') {
				if (this.state == '') {
					this.hasErrors = true;
					this.errors = 'State is required when shipping to USA or UAE'
					this.staterequired = true;
					window.scroll(0, 0);
					return;
				}
			}

			if (this.country == 'Canada') {
				if (this.province == '') {
					this.hasErrors = true;
					this.errors = 'Province is required when shipping to Canada'
					this.provincerequired = true;
					window.scroll(0, 0);
					return;
				}
			}

			if (this.country == 'Canada' || this.country == "South Africa" || this.country == "Kenya" || this.country == 'United Kingdom' || this.country == 'United States of America' || this.country == 'Germany' || this.country == 'United Arab Emirates') {

				var country: any;
				if (this.country == 'Canada') {country = 'CA'}
				if (this.country == 'United States of America') {country = 'US'}
				if (this.country == 'United Arab Emirates') {
					country = 'AE'; 
					this.postalcode = '00000'
					var postalcode: any = document.getElementById('postalcode');
					postalcode.value = '00000'
				}
				if (this.country == 'Germany') {country = 'DE'}
				if (this.country == 'United Kingdom') {country = 'GB'}
				if (this.country == "South Africa") {country = 'ZA'}
				if (this.country == "Kenya") {country = 'KE'}

				let postaldata: any = {
					"postalcode": this.postalcode,
					"country": country
				}

				this.__postalcodesService.validateworldwidepostalcodes(postaldata).subscribe(
					res => {
						this.postalcodeinvalid = false;
						console.warn('Proceeding . . .');
					},
					err => {
						this.hasSubmitted = false;
						this.hasErrors = true;
						this.errors = err.error.message;
						window.scroll(0, 0);
						this.postalcodeinvalid = true;
						
						return;
					}
				) 
			}

			if (this.postalcodeinvalid == true) {
				return;
			}

			var cookieName = "_mycart";
			var matchCookie = await document.cookie.match(new RegExp('(^| )' + cookieName + '=([^;]+)'));

			if (!matchCookie){
				this.hasErrorAlert = true;
				this.hasErrorMsg = "Invalid cart details"
				
				let count = 0;

				let successInterval = setInterval(() => {
					if (count > 0) {
						this.hasErrorAlert = false;
						clearInterval(successInterval);
						this._router.navigate(['/cart']);				
					}
	
					count = count + 1;
				}, 3000)
				return;
			}
			
			var cartid: any = matchCookie[2];
			this.hasSubmitted = true;

			let deliveryAddress: any = {
				"contactinfo": this.contactinfo,
				"firstname": this.firstname,
				"lastname": this.lastname,
				"apartment": this.apartment,
				"street": this.street,
				"city": this.city,
				"country": this.country,
				"province": this.province,
				"state": this.state,
				"postalcode": this.postalcode,
				"phonenumber": this.phonenumber,
				"pickupmethod": 'ship',
				"cartId": cartid
			}

			this.__cartService.orderItemsInMyCart(deliveryAddress).subscribe(
				res => {
					if (this.postalcodeinvalid == true) {
						return;
					}
					let current_checkout_id: any = localStorage.getItem('__checkout_credt_id');
					localStorage.setItem('__checkout_credt_id', res.data._id);

					this.__ordersService.deleteOrder(current_checkout_id).subscribe(
						res => {}
						,err => {}
					)

					this.__cartService.checkoutCart(cartid).subscribe(
						res => {

							if (this.postalcodeinvalid == true) {
								return;
							}
							this._router.navigate(['/shipping']);
						},
						err => {}
					)
					
				},
				err => {
					this.hasSubmitted = false;
					this.hasErrors = true;
					this.errors = err.message;
					window.scroll(0, 0);
				}
			) 
		}
	}
	
	cancelCheck() {
		this._router.navigate(['/shipping'])
	}

}

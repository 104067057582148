import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  	name: 'currencies'
})
export class CurrenciesPipe implements PipeTransform {

	currency: any = 'rwf';
	currencies: any;

	constructor() {	}

	transform(value: any, currency: any, transformto:any) {
		let currentCurrentValue: any;

		if (currency == 'rwf') {
			currentCurrentValue = parseInt(value) * transformto.RWF;
		}
		
		if (currency == 'usd') {
			currentCurrentValue = parseInt(value) * transformto.USD;
		}
		
		if (currency == 'gbp') {
			currentCurrentValue = parseInt(value) * transformto.GBP;
		}
		
		return Math.ceil(currentCurrentValue);
	}

}
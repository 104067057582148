import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  	providedIn: 'root'
})
export class PostalcodesService {

	constructor( private _http: HttpClient ) { }

	getpostalcodesinstates(data: string) {
		return this._http.post<any>(`${environment.apiUrl}postalcodes/getpostalcodesinstates`, data);
	}
	
	validateworldwidepostalcodes(data: string) {
		return this._http.post<any>(`${environment.apiUrl}postalcodes/validateworldwidepostalcodes`, data);
	}
	
}
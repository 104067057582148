import { Component, OnInit } from '@angular/core';
import { CommunicationService } from 'src/app/services/communication.service';
import { CustomsService } from 'src/app/services/customs.service';

@Component({
	selector: 'app-contact',
	templateUrl: './contact.component.html',
	styleUrls: ['./contact.component.css']
})

export class ContactComponent implements OnInit {

	constructor(private __communService: CommunicationService, private __customsService: CustomsService) { }

	ngOnInit( ): void {
		this.__customsService.updatePageStatus(false);
	}

	hasSubmitted: boolean = false;
	isThereErrors: boolean = false;
	formerrors: string = '';

	namesRequired: boolean = false;
	emailRequired: boolean = false;
	msgRequired: boolean = false;

	successMsg: string = '';
	isSuccess: boolean = false;

	regularExpression = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

	getContactData(formData: any) {
		const data = formData.value

		this.namesRequired = false;
		this.emailRequired = false;
		this.msgRequired = false;
		this.isThereErrors = false;

		if (data.contactNames == "" || data.contactEmail == "" || data.contactMessage == "") {

			if (data.contactNames == "") {
				this.formerrors = "Your full names are required!"
				this.isThereErrors = true;
				return this.namesRequired = true;
			}

			if (data.contactEmail == "") {
				this.formerrors = "Your email address are required!"
				this.isThereErrors = true;
				return this.emailRequired = true;
			}

			if (data.contactMessage == "") {
				this.formerrors = "Message are required!"
				this.isThereErrors = true;
				return this.msgRequired = true;
			}

			
			return;
		}

		const valid = this.regularExpression.test(String(data.contactEmail).toLowerCase());
		if (!valid) {
			this.formerrors = "Invalid email address!"
			this.isThereErrors = true;
			return this.emailRequired = true;
		}

		if (data.contactEmail.length > 80) {
			this.formerrors = "Email address must be 80 characters atmost!"
			this.isThereErrors = true;
			return this.msgRequired = true;
		}

		if (data.contactNames.length > 80) {
			this.formerrors = "Names must be 80 characters atmost!"
			this.isThereErrors = true;
			return this.msgRequired = true;
		}

		if (data.contactMessage.length < 10) {
			this.formerrors = "Message must be atleat 10 characters long!"
			this.isThereErrors = true;
			return this.msgRequired = true;
		}

		this.hasSubmitted = true;
		
		this.__communService.sendMessageToInkingi(data).subscribe(
			res => {

				let date: any = new Date();
				let noteData = {
		
					"notificationTitle": `Someone is looking for support!`,
					"notificationDescription": `${data.contactNames} contacted Inkingi team on ${date}. You can reply via ${data.contactEmail} if not here.`,
					"notificationCategory": "newcontact"
					
				}
		
				this.__communService.sendNotification(noteData).subscribe(
					res => {
						console.log('Proceeding . . .')
					}, err => {
						console.log('Processing . . .')
					}
				)

				let names: any = document.getElementById('names');
				let email: any = document.getElementById('email');
				let msg: any = document.getElementById('msg');

				names.value = '';
				email.value = '';
				msg.value = '';
				formData.resetForm()
				
				this.hasSubmitted = false;
				this.isSuccess = true;
				this.successMsg = res.message;
				let count = 0;

				let successInterval = setInterval(() => {
					if (count > 0) {
						this.isSuccess = false;
						clearInterval(successInterval);
					}
					count = count + 1;
				}, 2000);

				
			},
			err => {
				this.isThereErrors = true;
				this.hasSubmitted = false;
				this.formerrors = err.error.message;
				
				if (!err.error.message) {
					this.formerrors = err.error;
				}
				
			}
		)
	}

}
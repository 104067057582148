import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  	providedIn: 'root'
})
export class AdminsubscriptionsService {

	constructor( private _http: HttpClient ) { }

	getAllSubscriptions() {
		return this._http.get<any>(`${environment.apiUrl}subscribers/getSubscribers`);
	}
	
	getAllContacts() {
		return this._http.get<any>(`${environment.apiUrl}contacts/getAllMessages`);
	}

	deleteContact(data: any) {
		return this._http.delete<any>(`${environment.apiUrl}contacts/deleteMessage/${data}`);
	}
	
	answer(data: any) {
		return this._http.post<any>(`${environment.apiUrl}contacts/answer`, data);
	}

	markAnswered(data: any) {
		return this._http.get<any>(`${environment.apiUrl}contacts/makeAnswered/${data}`);
	}

	writeToAll(data: any) {
		return this._http.post<any>(`${environment.apiUrl}subscribers/writeToSubscribers`, data);
	}

}
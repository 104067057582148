// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  // production: false,
  // apiUrl: 'http://localhost:5000/api/',
  // apiPublic: 'http://localhost:5000/',
  // frontendUrl: 'http://localhost:4200/',
  // flutterPublicApi: 'FLWPUBK-b8e63568ccdf571543a9937f3b9f2601-X',
  // webpush_public_key: "BMD_Zoc1HYVoIrg1AO8ppBE4pzsjA1L0WSyW-oJOt81Bsr4itf0KCeQqNu_R46A0Iie9hgj4_LZ2PG2w4DoC3Mw"
  production: true,
  apiUrl: 'https://api.maisoninkingi.com/api/',
  apiPublic: 'https://api.maisoninkingi.com/',
  frontendUrl: 'https://maisoninkingi.com/',
  flutterPublicApi: 'FLWPUBK_TEST-5e5d39b5228c49e88c44ff9148fb2e0c-X',
  webpush_public_key: "BMD_Zoc1HYVoIrg1AO8ppBE4pzsjA1L0WSyW-oJOt81Bsr4itf0KCeQqNu_R46A0Iie9hgj4_LZ2PG2w4DoC3Mw"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { OrdersService } from './orders.service';
import { TransactionsService } from './transactions.service';
import { CommunicationService } from './communication.service';

import { Flutterwave, InlinePaymentOptions, PaymentSuccessResponse } from "flutterwave-angular-v3";
 
@Injectable({
  	providedIn: 'root'
})

export class PaymentsService {

	constructor( 
		private _http: HttpClient, 
		private __ordersService: OrdersService,
		private __transactionsService: TransactionsService,
		private _router: Router,
		private flutterwave: Flutterwave,
		private __communService: CommunicationService
	) {}

	authenaticatePayment(value: string) {
		return this._http.get<any>(`${environment.apiUrl}payment/authenaticatePayment/${value}`);
	}
	
	validatePaymentToken(token: string) {
		return this._http.post<any>(`${environment.apiUrl}payment/validatePaymentToken`, token);
	}

	payWithPaypal(data: string) {
		return this._http.post<any>(`${environment.apiUrl}payment/payWithPayPal`, data);
	}

	sendReceipt(data: string) {
		return this._http.post<any>(`${environment.apiUrl}payment/sendReceipt`, data);
	}

	paymentAmount: number = 0;
	order_id: string = '';

	updateStatus: any = new EventEmitter();

	public status_failed: boolean = false;
	public status_failed_error_msg: string = '';
	payment_method: string = '';
	transaction_currency: string = 'RWF';

	completeThePayment(method: string, _currency: string, _exchangeValues: any) {
		var checkout_id: any = localStorage.getItem('__checkout_credt_id');

		if (checkout_id == null) {
			this._router.navigate(['/checkout']);
			return;
		} else {

			this.__ordersService.getSpecificOrder(checkout_id).subscribe(
				res => {
					this.order_id = res.data._id;
					if (method == '' || method == undefined || method == null) {
						location.reload();
						return;
					}

					if (method == 'paypal') {
						var cookieName = "_currencynw";
						var matchCookie = document.cookie.match(new RegExp('(^| )' + cookieName + '=([^;]+)'));

						let currency: string = '';

						if (matchCookie) {
							currency = matchCookie[2].toUpperCase();
							this.transaction_currency = matchCookie[2].toUpperCase()
						}
				
						if (!matchCookie) {
							document.cookie = `_currencynw=usd; path=/; max-age=` + 2147483647;
							currency = 'USD';
							this.transaction_currency = 'USD'
						}

						let paypal_payment_data: any = {
							"price": res.data.orderPrice,
							"currency": currency
						}

						this.payWithPaypal(paypal_payment_data).subscribe(
							res => {
								let url: any = res.message.split(' ')[5];
								location.replace(url);
							}, 
							err => {
								this.status_failed = true;
								this.status_failed_error_msg = err.error.message.response.message;
								this.updateStatus.emit(this.getStatus);
							}
						)

					} else if (method == 'card' || method == 'momo') {

						let amount: any = res.data.orderPrice;
						let currency: any = 'rwf';

						if (method == 'card') {
							this.payment_method = 'card';
						} else {
							this.payment_method = 'momo'
						}
									
						if (_currency != '' && _exchangeValues != null && _exchangeValues != undefined) {

							let currentCurrentValue: any;
							currency = _currency;

							if (_currency == 'rwf') {
								currentCurrentValue = parseInt(amount) * _exchangeValues.RWF;
							}
							
							if (_currency == 'usd') {
								currentCurrentValue = parseInt(amount) * _exchangeValues.USD;
							}
							
							if (_currency == 'gbp') {
								currentCurrentValue = parseInt(amount) * _exchangeValues.GBP;
							}
							
							amount = Math.ceil(currentCurrentValue);
						
						}
						
						let date = new Date();
						let randomReference: any = date.getTime().toString() + Math.random()*100000000000000000;

						let publicKey: string = `${environment.flutterPublicApi}`;
	
						let customerDetails: any = { 
							name: res.data.firstname + ' ' + res.data.lastname, 
							email: res.data.contactinfo, 
							phone_number: res.data.phonenumber
						}
						
						let customizations: any = {
							title: 'INKINGI FASHION', 
							description: 'From Ink We Make', 
							logo: `${environment.frontendUrl}assets/app/favicon.png`
						}
						
						// let meta: any = {
						// 	'consumer_id': '7898', 
						// 	'consumer_mac': 'kjs9s8ss7dd'
						// }

						let paymentData: InlinePaymentOptions = {
							public_key: publicKey,
							tx_ref: randomReference,
							amount: amount,
							currency: currency.toUpperCase(),
							payment_options: 'card, banktransfer, mobilemoney',
							// redirect_url: `${environment.frontendUrl}successpayment`,
							// meta: meta,
							customer: customerDetails,
							customizations: customizations,
							callback: this.makePaymentCallback,
							onclose: this.closedPaymentModal,
							callbackContext: this
						} 

						this.makePayment(paymentData);

					} else {
						alert('Unrecognized payment method! Reprocessing . . .');
						let url: any = `${environment.frontendUrl}payment`
						location.replace(url);
					}
				},
				err => {
					alert('Something went wrong! Redirecting . . .');
					localStorage.removeItem('__payment_token');
					this._router.navigate(['/shipping']);
				}
			)
		}
	}

	makePayment(data: any){
		this.flutterwave.inlinePay(data)
	}

	makePaymentCallback(response: PaymentSuccessResponse): void {
		this.registerTransition(response);
	}

	closedPaymentModal() {
		console.warn('Payment processsing stopped . . .');
	}

	registerTransition(data: PaymentSuccessResponse) {
		
		if (data.status == "successful") {

			var __id: any = localStorage.getItem('__checkout_credt_id');
			
			this.__ordersService.markOrderPaid(__id).subscribe(
				res => {
					console.log('Proceeding . . .')
				},
				err => {
					let date: any = new Date();
					let noteData = {
			
						"notificationTitle": "Marking order as paid failure!",
						"notificationDescription": `An order has failed to get marked as paid on ${date}.`,
						"notificationCategory": "transactions"
						
					}
			
					this.__communService.sendNotification(noteData).subscribe(
						res => {
							console.log('Proceeding . . .')
						}, err => {
							console.log('Processing . . .')
						}
					)
					alert("Something went wrong. But don't worry consider contacting our team now to make sure you order was received.")
				}
			)
			
			localStorage.removeItem('__payment_token');
			localStorage.removeItem('__checkout_credt_id');
			document.cookie = "_mycart= ; expires = Thu, 01 Jan 1970 00:00:00 GMT";
			
			let customer: any = data.customer;
			let transactiondata: any = {
				'transaction_id': data.transaction_id?.toString(), 
				'transaction_ref': data.flw_ref, 
				'order_id': this.order_id, 
				'transaction_status': data.status, 
				'transaction_amount': data.amount, 
				'customer_names': customer.name, 
				'customer_email': customer.email, 
				'customer_phonenumber': customer.phone_number,
                'transaction_method': this.payment_method,
				'transaction_currency': this.transaction_currency
			}

			let noteData = {
			
				"notificationTitle": "New order arrived!",
				"notificationDescription": `${customer.name} has made an order on inkingi shop system. Pease checkout the order to complete it.`,
				"notificationCategory": "neworder"
				
			}
	
			this.__communService.sendNotification(noteData).subscribe(
				res => {
					if (res) console.log('Processing . . .');
				}, err => {
					if (err) console.log('Processing . . .');
				}
			)

			this.__transactionsService.addTransaction(transactiondata).subscribe(
				res => {
					this.flutterwave.closePaymentModal();
					// this._router.navigate([`/successpayment`, data.status, data.flw_ref, data.transaction_id]);
					let url: any = `${environment.frontendUrl}successpayment/${data.status}/${data.flw_ref}/${data.transaction_id}`;
					location.replace(url);
				},
				err => {
					let date: any = new Date();
					let noteData = {
			
						"notificationTitle": "Transaction failure!",
						"notificationDescription": `There is a transaction that has failed to be stored in you database on ${date}. Consider checking it out quickly.`,
						"notificationCategory": "transactions"
						
					}
			
					this.__communService.sendNotification(noteData).subscribe(
						res => {
							if (res) console.log('Processing . . .');
						}, err => {
							if (err) console.log('Processing . . .');
						}
					)
				}
			)

		} else {
			this.flutterwave.closePaymentModal();
			this._router.navigate(['/payment']);
			this.status_failed = true;
			this.status_failed_error_msg = 'Transaction failed. Try again.'
			this.updateStatus.emit(this.getStatus);
		}
	
	}

	getStatus() {
		let data: any = [this.status_failed, this.status_failed_error_msg];
		return data;
	}

}
import { Component, HostListener, OnInit } from '@angular/core';
import { TransactionsService } from '../../services/transactions.service';

@Component({
	selector: 'app-admintransitions',
	templateUrl: './admintransitions.component.html',
	styleUrls: ['./admintransitions.component.css']
})
export class AdmintransitionsComponent implements OnInit {

	constructor(	
		private __transactionsService: TransactionsService
	) { }

	ngOnInit(): void {
		this.getAllTransactions();
	}

	isSuccess: boolean = false;
	successMsg: string = '';
	hasSubmitted: boolean = false;
	hasErrorAlert: boolean = false;
	hasErrorMsg: string = '';

	transactions: any = [];
	transactions_backup: any  = [];
	loadingData: boolean = true;

	noTransactionStatus: boolean = false;

	getAllTransactions() {
		this.__transactionsService.getAllTransactions().subscribe(
			res => {
				this.loadingData = false;
				this.transactions.push(res.data);
				this.transactions_backup.push(res.data);

				if (this.transactions[0].length == 0) {
					this.noTransactionStatus = true;
				}
			},
			err => {
				this.hasErrorAlert = true;
				this.hasErrorMsg = "Something went wrong. Reloading . . ."
				
				let count = 0;

				let successIntervalTwo = setInterval(() => {
					if (count > 0) {
						this.hasErrorAlert = false;
						clearInterval(successIntervalTwo);
						location.reload();
					}
					count = count + 1;
				}, 2000)
			}
		)
	}

	currentViewedRow: string = '';
	currentRowView(value: string) {
		this.currentViewedRow = value;
	}

	pageScrollToNavbarDownPosition: boolean = false;

	@HostListener('window:scroll', ['$event']) onScrollEvent(event: any) {
		if (window.pageYOffset > 118) {
			this.pageScrollToNavbarDownPosition = true;
		} else {
			this.pageScrollToNavbarDownPosition = false;
		}  
	}
	
	searchTermNotFound: boolean = false;
	searching_loading: boolean = false;
	searchAlgorithmActivity(keyword: any) {
		const searchTerm: string = keyword;
		let transaction_result_arr: any = [];
		this.searchTermNotFound = false;
		
		if (keyword == '') {
			this.transactions = this.transactions_backup;
			return;
		}
		this.transactions = this.transactions_backup;
		this.searching_loading = true;
		let search_results = this.transactions[0].filter((val: any) => {
			return val.transaction_id == searchTerm ||
			val.order_id == searchTerm ||
			val.transaction_ref == searchTerm ||
			val.transaction_status == searchTerm ||
			val.transaction_amount == searchTerm ||
			val.customer_names == searchTerm ||
			val.customer_email == searchTerm ||
			val.customer_phonenumber == searchTerm ||
			val.transaction_method == searchTerm ||
			val.transaction_currency == searchTerm
		})
		transaction_result_arr.push(search_results);
		this.searching_loading = false;
		this.transactions = transaction_result_arr;

		if (this.transactions[0].length <= 0) {
			this.searchTermNotFound = true;
		}
	}

}
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  	providedIn: 'root'
})

export class NewsletterserviceService {

  	constructor(
		private _http: HttpClient
	) { }

	addPushSubscriber(data: any) {
		return this._http.post<any>(`${environment.apiUrl}newsletter/addNewsletterSubscription`, data);
	}
	
}
 <div id="entire_application__checkout_component">

    <div *ngIf="isSuccess || hasErrorAlert" class="success_message">
        <div class="msg_box" [ngClass]="{'isFailed': hasErrorAlert}">
            <p *ngIf="isSuccess">{{ successMsg }}</p>
            <p *ngIf="hasErrorAlert">{{ hasErrorMsg }}</p>
            <svg *ngIf="isSuccess" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-.997-4L6.76 11.757l1.414-1.414 2.829 2.829 5.656-5.657 1.415 1.414L11.003 16z" fill="rgba(255,255,255,1)"/></svg>
            <svg *ngIf="hasErrorAlert" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-9.414l2.828-2.829 1.415 1.415L13.414 12l2.829 2.828-1.415 1.415L12 13.414l-2.828 2.829-1.415-1.415L10.586 12 7.757 9.172l1.415-1.415L12 10.586z" fill="rgba(255,255,255,1)"/></svg>
        </div>
    </div>

    <div class="cookies_disabled" *ngIf="unableToShipToThisNation">
        <div class="cookie_modal_container col-mob-11">
            <div class="image">
                <img src="../../../assets/app/shippping_failed.png" alt="">
            </div>
            <div class="message">
                <h1>Sorry! We don't ship to this country.</h1>
                <p>Inkingi fashion design is really sorry to be unable to ship to this nation for the meanwhile.</p>
                <button (click)="shipToAnotherCountry()">Ok! ship to another country</button>
            </div>
        </div>
    </div> 
    
    <div class="cookies_disabled" *ngIf="cookies_disabled">
        <div class="cookie_modal_container col-mob-11">
            <div class="image">
                <img src="../../../assets/app/cookies.png" alt="">
            </div>
            <div class="message">
                <h1>You must enable cookies</h1>
                <p>Inkingi fashion design shop uses browser cookies and yours have been disabled please enable them to continue shopping.</p>
                <button (click)="enableBrowserCookies()">Edit settings by <strong>&nbsp;ALT + F&nbsp;</strong> then <strong>&nbsp;S&nbsp;</strong></button>
            </div>
        </div>
    </div>

    <div class="checkout__container">
        <div class="header">
            <h3>Checkout my products</h3>
        </div>
        <div class="check_forms">
            <div class="form_group col-mob-12 col-tab-12 col-large-12">
                <div *ngIf="hasErrors" class="alert_container">
                    <p *ngIf="hasErrors">{{errors}}</p>
                </div>
                <div class="group">    
                    <p>Contact information</p>
                    <input type="text" placeholder="Email or mobile phone number" #contactinfo (input)="getContactInfo(contactinfo.value)" minlength="5" maxlength="100" [ngClass]="{'--red_bordered': contactinforequired}" required>
                </div>
                <div class="group methods">
                    <p class="method_p">Delivery methods</p>
                    
                    <div class="method pick " [ngClass]="{'picked_method': shippingMethodPickup == 'pick'}" (click)="changeShippingMethod('pick')">
                        <div class="method_details">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20"><path fill="none" d="M0 0h24v24H0z"/><path d="M12 2a6 6 0 0 1 6 6v1h4v2h-1.167l-.757 9.083a1 1 0 0 1-.996.917H4.92a1 1 0 0 1-.996-.917L3.166 11H2V9h4V8a6 6 0 0 1 6-6zm6.826 9H5.173l.667 8h12.319l.667-8zM13 13v4h-2v-4h2zm-4 0v4H7v-4h2zm8 0v4h-2v-4h2zm-5-9a4 4 0 0 0-3.995 3.8L8 8v1h8V8a4 4 0 0 0-3.8-3.995L12 4z"/></svg>
                            Pick it myself
                        </div>
                        <div class="check_method">
                            <svg *ngIf=" shippingMethodPickup == 'pick'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm-.997-6l7.07-7.071-1.414-1.414-5.656 5.657-2.829-2.829-1.414 1.414L11.003 16z" fill="rgba(25,188,106,1)"/></svg>
                        </div>
                    </div>

                    <div class="method ship" [ngClass]="{'picked_method': shippingMethodPickup == 'ship'}" (click)="changeShippingMethod('ship')">
                        <div class="method_details">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20"><path fill="none" d="M0 0h24v24H0z"/><path d="M8.965 18a3.5 3.5 0 0 1-6.93 0H1V6a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v2h3l3 4.056V18h-2.035a3.5 3.5 0 0 1-6.93 0h-5.07zM15 7H3v8.05a3.5 3.5 0 0 1 5.663.95h5.674c.168-.353.393-.674.663-.95V7zm2 6h4v-.285L18.992 10H17v3zm.5 6a1.5 1.5 0 1 0 0-3.001 1.5 1.5 0 0 0 0 3.001zM7 17.5a1.5 1.5 0 1 0-3 0 1.5 1.5 0 0 0 3 0z"/></svg>
                            Ship it to me
                        </div>
                        <div class="check_method">
                            <svg *ngIf=" shippingMethodPickup == 'ship'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm-.997-6l7.07-7.071-1.414-1.414-5.656 5.657-2.829-2.829-1.414 1.414L11.003 16z" fill="rgba(25,188,106,1)"/></svg>
                        </div>
                    </div>
                </div>

                <div class="group" *ngIf=" shippingMethodPickup == 'pick'">
                    <p>Personal details</p>
                    <div class="input_group flex_input">
                        <input class="col-mob-12" type="text"  minlength="2" maxlength="100" [ngClass]="{'--red_bordered': firstnamerequired}" #firstname (input)="getFirstname(firstname.value)" placeholder="Firstname">
                        <input class="col-mob-12" type="text" minlength="2" maxlength="100" [ngClass]="{'--red_bordered': lastnamerequired}" #lastname (input)="getLastname(lastname.value)" placeholder="Lastname">
                    </div>
                </div>

                <div class="group" *ngIf=" shippingMethodPickup == 'pick'">
                    <p>Pickup location</p>
                    <div class="pickup_loc">
                        <div class="picked">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-6a4 4 0 1 0 0-8 4 4 0 0 0 0 8z" fill="rgba(25,188,155,1)"/></svg>
                        </div>
                        <div class="place_details">
                            <h4>Kimihurura</h4>
                            <p>Gasabo - Kigali, Rwanda</p>
                        </div>
                        <div class="prices_pickup col-mob-12">
                            <p class="cost">Free</p>
                            <p class="tip">Usually ready in 24 hours</p>
                        </div>
                    </div>
                </div>
                
                <div class="group" *ngIf=" shippingMethodPickup == 'ship'">
                    <p>Pickup location</p>
                    <div class="input_group flex_input">
                        <input class="col-mob-12" type="text" minlength="2" maxlength="100" [ngClass]="{'--red_bordered': firstnamerequired}" #firstname (input)="getFirstname(firstname.value)" placeholder="Firstname">
                        <input class="col-mob-12" type="text" minlength="2" maxlength="100" [ngClass]="{'--red_bordered': lastnamerequired}" #lastname (input)="getLastname(lastname.value)" placeholder="Lastname">
                    </div>
                    <input type="text" id="postalcode" minlength="2" maxlength="100" [ngClass]="{'--red_bordered': apartmentrequired}" #apartment (input)="getApartment(apartment.value)" placeholder="Apartment, suite etc">
                </div>
                
                <div class="group" *ngIf=" shippingMethodPickup == 'ship'">
                    <input type="text"  minlength="1" maxlength="50" [ngClass]="{'--red_bordered': streetrequired}" #street (input)="getStreet(street.value)" placeholder="Street address">
                </div>

                <div class="group" *ngIf=" shippingMethodPickup == 'ship'">
                    <input type="text" id="city" #city (input)="getCity(city.value)" placeholder="City" [ngClass]="{'--red_bordered': cityrequired}" required maxlength="50">
                </div>

                <div class="group flex_input" *ngIf=" shippingMethodPickup == 'ship'">
                    <select class="col-mob-12" #country (input)="getCountry(country.value)" [ngClass]="{'--red_bordered': countryrequired}" required>
                        <option selected data-code="RW" value="Rwanda">Rwanda</option>
                        <option data-code="CA" value="Canada">Canada</option>
                        <option data-code="AE" value="United Arab Emirates">United Arab Emirates</option>
                        <option data-code="DE" value="Germany">Germany</option>
                        <option data-code="US" value="United States of America">United States of America</option>
                        <option data-code="GB" value="United Kingdom">United Kingdom</option>
                        <option data-code="ZA" value="South Africa">South Africa</option>
                        <option data-code="KE" value="Kenya">Kenya</option>
                        <option data-code="UG" value="Uganda">Uganda</option>
                        <option data-code="TZ" value="Tanzania">Tanzania</option>
                        <option data-code="BI" value="Burundi">Burundi</option>
                        
                        <option disabled>-------------------</option>

                        <option data-code="AF" value="Afghanistan">Afghanistan</option>
                        <option data-code="AX" value="Aland Islands">Åland Islands</option>
                        <option data-code="AL" value="Albania">Albania</option>
                        <option data-code="DZ" value="Algeria">Algeria</option>
                        <option data-code="AD" value="Andorra">Andorra</option>
                        <option data-code="AO" value="Angola">Angola</option>
                        <option data-code="AI" value="Anguilla">Anguilla</option>
                        <option data-code="AG" value="Antigua And Barbuda">Antigua &amp; Barbuda</option>
                        <option data-code="AR" value="Argentina">Argentina</option>
                        <option data-code="AM" value="Armenia">Armenia</option>
                        <option data-code="AW" value="Aruba">Aruba</option>
                        <option data-code="AC" value="Ascension Island">Ascension Island</option>
                        <option data-code="AU" value="Australia">Australia</option>
                        <option data-code="AT" value="Austria">Austria</option>
                        <option data-code="AZ" value="Azerbaijan">Azerbaijan</option>
                        <option data-code="BS" value="Bahamas">Bahamas</option>
                        <option data-code="BH" value="Bahrain">Bahrain</option>
                        <option data-code="BD" value="Bangladesh">Bangladesh</option>
                        <option data-code="BB" value="Barbados">Barbados</option>
                        <option data-code="BY" value="Belarus">Belarus</option>
                        <option data-code="BE" value="Belgium">Belgium</option>
                        <option data-code="BZ" value="Belize">Belize</option>
                        <option data-code="BJ" value="Benin">Benin</option>
                        <option data-code="BM" value="Bermuda">Bermuda</option>
                        <option data-code="BT" value="Bhutan">Bhutan</option>
                        <option data-code="BO" value="Bolivia">Bolivia</option>
                        <option data-code="BA" value="Bosnia And Herzegovina">Bosnia &amp; Herzegovina</option>
                        <option data-code="BW" value="Botswana">Botswana</option>
                        <option data-code="BR" value="Brazil">Brazil</option>
                        <option data-code="IO" value="British Indian Ocean Territory">British Indian Ocean Territory</option>
                        <option data-code="VG" value="Virgin Islands, British">British Virgin Islands</option>
                        <option data-code="BN" value="Brunei">Brunei</option>
                        <option data-code="BG" value="Bulgaria">Bulgaria</option>
                        <option data-code="BF" value="Burkina Faso">Burkina Faso</option>
                        <option data-code="BI" value="Burundi">Burundi</option>
                        <option data-code="KH" value="Cambodia">Cambodia</option>
                        <option data-code="CM" value="Republic of Cameroon">Cameroon</option>
                        <option data-code="CA" value="Canada">Canada</option>
                        <option data-code="CV" value="Cape Verde">Cape Verde</option>
                        <option data-code="BQ" value="Caribbean Netherlands">Caribbean Netherlands</option>
                        <option data-code="KY" value="Cayman Islands">Cayman Islands</option>
                        <option data-code="CF" value="Central African Republic">Central African Republic</option>
                        <option data-code="TD" value="Chad">Chad</option>
                        <option data-code="CL" value="Chile">Chile</option>
                        <option data-code="CN" value="China">China</option>
                        <option data-code="CX" value="Christmas Island">Christmas Island</option>
                        <option data-code="CC" value="Cocos (Keeling) Islands">Cocos (Keeling) Islands</option>
                        <option data-code="CO" value="Colombia">Colombia</option>
                        <option data-code="KM" value="Comoros">Comoros</option>
                        <option data-code="CG" value="Congo">Congo - Brazzaville</option>
                        <option data-code="CD" value="Congo, The Democratic Republic Of The">Congo - Kinshasa</option>
                        <option data-code="CK" value="Cook Islands">Cook Islands</option>
                        <option data-code="CR" value="Costa Rica">Costa Rica</option>
                        <option data-code="HR" value="Croatia">Croatia</option>
                        <option data-code="CW" value="Curaçao">Curaçao</option>
                        <option data-code="CY" value="Cyprus">Cyprus</option>
                        <option data-code="CZ" value="Czech Republic">Czechia</option>
                        <option data-code="CI" value="Côte d'Ivoire">Côte d’Ivoire</option>
                        <option data-code="DK" value="Denmark">Denmark</option>
                        <option data-code="DJ" value="Djibouti">Djibouti</option>
                        <option data-code="DM" value="Dominica">Dominica</option>
                        <option data-code="DO" value="Dominican Republic">Dominican Republic</option>
                        <option data-code="EC" value="Ecuador">Ecuador</option>
                        <option data-code="EG" value="Egypt">Egypt</option>
                        <option data-code="SV" value="El Salvador">El Salvador</option>
                        <option data-code="GQ" value="Equatorial Guinea">Equatorial Guinea</option>
                        <option data-code="ER" value="Eritrea">Eritrea</option>
                        <option data-code="EE" value="Estonia">Estonia</option>
                        <option data-code="SZ" value="Eswatini">Eswatini</option>
                        <option data-code="ET" value="Ethiopia">Ethiopia</option>
                        <option data-code="FK" value="Falkland Islands (Malvinas)">Falkland Islands</option>
                        <option data-code="FO" value="Faroe Islands">Faroe Islands</option>
                        <option data-code="FJ" value="Fiji">Fiji</option>
                        <option data-code="FI" value="Finland">Finland</option>
                        <option data-code="FR" value="France">France</option>
                        <option data-code="GF" value="French Guiana">French Guiana</option>
                        <option data-code="PF" value="French Polynesia">French Polynesia</option>
                        <option data-code="TF" value="French Southern Territories">French Southern Territories</option>
                        <option data-code="GA" value="Gabon">Gabon</option>
                        <option data-code="GM" value="Gambia">Gambia</option>
                        <option data-code="GE" value="Georgia">Georgia</option>
                        <option data-code="DE" value="Germany">Germany</option>
                        <option data-code="GH" value="Ghana">Ghana</option>
                        <option data-code="GI" value="Gibraltar">Gibraltar</option>
                        <option data-code="GR" value="Greece">Greece</option>
                        <option data-code="GL" value="Greenland">Greenland</option>
                        <option data-code="GD" value="Grenada">Grenada</option>
                        <option data-code="GP" value="Guadeloupe">Guadeloupe</option>
                        <option data-code="GT" value="Guatemala">Guatemala</option>
                        <option data-code="GG" value="Guernsey">Guernsey</option>
                        <option data-code="GN" value="Guinea">Guinea</option>
                        <option data-code="GW" value="Guinea Bissau">Guinea-Bissau</option>
                        <option data-code="GY" value="Guyana">Guyana</option>
                        <option data-code="HT" value="Haiti">Haiti</option>
                        <option data-code="HN" value="Honduras">Honduras</option>
                        <option data-code="HK" value="Hong Kong">Hong Kong SAR</option>
                        <option data-code="HU" value="Hungary">Hungary</option>
                        <option data-code="IS" value="Iceland">Iceland</option>
                        <option data-code="IN" value="India">India</option>
                        <option data-code="ID" value="Indonesia">Indonesia</option>
                        <option data-code="IQ" value="Iraq">Iraq</option>
                        <option data-code="IE" value="Ireland">Ireland</option>
                        <option data-code="IM" value="Isle Of Man">Isle of Man</option>
                        <option data-code="IL" value="Israel">Israel</option>
                        <option data-code="IT" value="Italy">Italy</option>
                        <option data-code="JM" value="Jamaica">Jamaica</option>
                        <option data-code="JP" value="Japan">Japan</option>
                        <option data-code="JE" value="Jersey">Jersey</option>
                        <option data-code="JO" value="Jordan">Jordan</option>
                        <option data-code="KZ" value="Kazakhstan">Kazakhstan</option>
                        <option data-code="KE" value="Kenya">Kenya</option>
                        <option data-code="KI" value="Kiribati">Kiribati</option>
                        <option data-code="XK" value="Kosovo">Kosovo</option>
                        <option data-code="KW" value="Kuwait">Kuwait</option>
                        <option data-code="KG" value="Kyrgyzstan">Kyrgyzstan</option>
                        <option data-code="LA" value="Lao People's Democratic Republic">Laos</option>
                        <option data-code="LV" value="Latvia">Latvia</option>
                        <option data-code="LB" value="Lebanon">Lebanon</option>
                        <option data-code="LS" value="Lesotho">Lesotho</option>
                        <option data-code="LR" value="Liberia">Liberia</option>
                        <option data-code="LY" value="Libyan Arab Jamahiriya">Libya</option>
                        <option data-code="LI" value="Liechtenstein">Liechtenstein</option>
                        <option data-code="LT" value="Lithuania">Lithuania</option>
                        <option data-code="LU" value="Luxembourg">Luxembourg</option>
                        <option data-code="MO" value="Macao">Macao SAR</option>
                        <option data-code="MG" value="Madagascar">Madagascar</option>
                        <option data-code="MW" value="Malawi">Malawi</option>
                        <option data-code="MY" value="Malaysia">Malaysia</option>
                        <option data-code="MV" value="Maldives">Maldives</option>
                        <option data-code="ML" value="Mali">Mali</option>
                        <option data-code="MT" value="Malta">Malta</option>
                        <option data-code="MQ" value="Martinique">Martinique</option>
                        <option data-code="MR" value="Mauritania">Mauritania</option>
                        <option data-code="MU" value="Mauritius">Mauritius</option>
                        <option data-code="YT" value="Mayotte">Mayotte</option>
                        <option data-code="MX" value="Mexico">Mexico</option>
                        <option data-code="MD" value="Moldova, Republic of">Moldova</option>
                        <option data-code="MC" value="Monaco">Monaco</option>
                        <option data-code="MN" value="Mongolia">Mongolia</option>
                        <option data-code="ME" value="Montenegro">Montenegro</option>
                        <option data-code="MS" value="Montserrat">Montserrat</option>
                        <option data-code="MA" value="Morocco">Morocco</option>
                        <option data-code="MZ" value="Mozambique">Mozambique</option>
                        <option data-code="MM" value="Myanmar">Myanmar (Burma)</option>
                        <option data-code="NA" value="Namibia">Namibia</option>
                        <option data-code="NR" value="Nauru">Nauru</option>
                        <option data-code="NP" value="Nepal">Nepal</option>
                        <option data-code="NL" value="Netherlands">Netherlands</option>
                        <option data-code="NC" value="New Caledonia">New Caledonia</option>
                        <option data-code="NZ" value="New Zealand">New Zealand</option>
                        <option data-code="NI" value="Nicaragua">Nicaragua</option>
                        <option data-code="NE" value="Niger">Niger</option>
                        <option data-code="NG" value="Nigeria">Nigeria</option>
                        <option data-code="NU" value="Niue">Niue</option>
                        <option data-code="NF" value="Norfolk Island">Norfolk Island</option>
                        <option data-code="MK" value="North Macedonia">North Macedonia</option>
                        <option data-code="NO" value="Norway">Norway</option>
                        <option data-code="OM" value="Oman">Oman</option>
                        <option data-code="PK" value="Pakistan">Pakistan</option>
                        <option data-code="PS" value="Palestinian Territory, Occupied">Palestinian Territories</option>
                        <option data-code="PA" value="Panama">Panama</option>
                        <option data-code="PG" value="Papua New Guinea">Papua New Guinea</option>
                        <option data-code="PY" value="Paraguay">Paraguay</option>
                        <option data-code="PE" value="Peru">Peru</option>
                        <option data-code="PH" value="Philippines">Philippines</option>
                        <option data-code="PN" value="Pitcairn">Pitcairn Islands</option>
                        <option data-code="PL" value="Poland">Poland</option>
                        <option data-code="PT" value="Portugal">Portugal</option>
                        <option data-code="QA" value="Qatar">Qatar</option>
                        <option data-code="RE" value="Reunion">Réunion</option>
                        <option data-code="RO" value="Romania">Romania</option>
                        <option data-code="RU" value="Russia">Russia</option>
                        <option data-code="RW" value="Rwanda">Rwanda</option>
                        <option data-code="WS" value="Samoa">Samoa</option>
                        <option data-code="SM" value="San Marino">San Marino</option>
                        <option data-code="ST" value="Sao Tome And Principe">São Tomé &amp; Príncipe</option>
                        <option data-code="SA" value="Saudi Arabia">Saudi Arabia</option>
                        <option data-code="SN" value="Senegal">Senegal</option>
                        <option data-code="RS" value="Serbia">Serbia</option>
                        <option data-code="SC" value="Seychelles">Seychelles</option>
                        <option data-code="SL" value="Sierra Leone">Sierra Leone</option>
                        <option data-code="SG" value="Singapore">Singapore</option>
                        <option data-code="SX" value="Sint Maarten">Sint Maarten</option>
                        <option data-code="SK" value="Slovakia">Slovakia</option>
                        <option data-code="SI" value="Slovenia">Slovenia</option>
                        <option data-code="SB" value="Solomon Islands">Solomon Islands</option>
                        <option data-code="SO" value="Somalia">Somalia</option>
                        <option data-code="ZA" value="South Africa">South Africa</option>
                        <option data-code="GS" value="South Georgia And The South Sandwich Islands">South Georgia &amp; South Sandwich Islands</option>
                        <option data-code="KR" value="South Korea">South Korea</option>
                        <option data-code="SS" value="South Sudan">South Sudan</option>
                        <option data-code="ES" value="Spain">Spain</option>
                        <option data-code="LK" value="Sri Lanka">Sri Lanka</option>
                        <option data-code="BL" value="Saint Barthélemy">St. Barthélemy</option>
                        <option data-code="SH" value="Saint Helena">St. Helena</option>
                        <option data-code="KN" value="Saint Kitts And Nevis">St. Kitts &amp; Nevis</option>
                        <option data-code="LC" value="Saint Lucia">St. Lucia</option>
                        <option data-code="MF" value="Saint Martin">St. Martin</option>
                        <option data-code="PM" value="Saint Pierre And Miquelon">St. Pierre &amp; Miquelon</option>
                        <option data-code="VC" value="St. Vincent">St. Vincent &amp; Grenadines</option>
                        <option data-code="SD" value="Sudan">Sudan</option>
                        <option data-code="SR" value="Suriname">Suriname</option>
                        <option data-code="SJ" value="Svalbard And Jan Mayen">Svalbard &amp; Jan Mayen</option>
                        <option data-code="SE" value="Sweden">Sweden</option>
                        <option data-code="CH" value="Switzerland">Switzerland</option>
                        <option data-code="TW" value="Taiwan">Taiwan</option>
                        <option data-code="TJ" value="Tajikistan">Tajikistan</option>
                        <option data-code="TZ" value="Tanzania, United Republic Of Tanzania">Tanzania</option>
                        <option data-code="TH" value="Thailand">Thailand</option>
                        <option data-code="TL" value="Timor Leste">Timor-Leste</option>
                        <option data-code="TG" value="Togo">Togo</option>
                        <option data-code="TK" value="Tokelau">Tokelau</option>
                        <option data-code="TO" value="Tonga">Tonga</option>
                        <option data-code="TT" value="Trinidad and Tobago">Trinidad &amp; Tobago</option>
                        <option data-code="TA" value="Tristan da Cunha">Tristan da Cunha</option>
                        <option data-code="TN" value="Tunisia">Tunisia</option>
                        <option data-code="TR" value="Turkey">Turkey</option>
                        <option data-code="TM" value="Turkmenistan">Turkmenistan</option>
                        <option data-code="TC" value="Turks and Caicos Islands">Turks &amp; Caicos Islands</option>
                        <option data-code="TV" value="Tuvalu">Tuvalu</option>
                        <option data-code="UM" value="United States Minor Outlying Islands">U.S. Outlying Islands</option>
                        <option data-code="UG" value="Uganda">Uganda</option>
                        <option data-code="UA" value="Ukraine">Ukraine</option>
                        <option data-code="AE" value="United Arab Emirates">United Arab Emirates</option>
                        <option data-code="GB" value="United Kingdom">United Kingdom</option>
                        <option data-code="US" value="United States of America">United States of America</option>
                        <option data-code="UY" value="Uruguay">Uruguay</option>
                        <option data-code="UZ" value="Uzbekistan">Uzbekistan</option>
                        <option data-code="VU" value="Vanuatu">Vanuatu</option>
                        <option data-code="VA" value="Holy See (Vatican City State)">Vatican City</option>
                        <option data-code="VE" value="Venezuela">Venezuela</option>
                        <option data-code="VN" value="Vietnam">Vietnam</option>
                        <option data-code="WF" value="Wallis And Futuna">Wallis &amp; Futuna</option>
                        <option data-code="EH" value="Western Sahara">Western Sahara</option>
                        <option data-code="YE" value="Yemen">Yemen</option>
                        <option data-code="ZM" value="Zambia">Zambia</option>
                        <option data-code="ZW" value="Zimbabwe">Zimbabwe</option>
                    </select>
                    <input class="col-mob-12" type="text" #postalcode (input)="getPostalcode(postalcode.value)" [ngClass]="{'--red_bordered': postalcoderequired}" placeholder="Postal code">
                </div>

                <div class="group" *ngIf=" shippingMethodPickup == 'ship' && country == 'United States of America'">
                    <select class="col-mob-12" #state (input)="getUsState(state.value)" [ngClass]="{'--red_bordered': staterequired}">
                        <option selected value="">State</option>
                        <option data-alternate-values="[&quot;Alabama&quot;]" value="Alabama">Alabama</option>
                        <option data-alternate-values="[&quot;Alaska&quot;]" value="Alaska">Alaska</option>
                        <option data-alternate-values="[&quot;American Samoa&quot;]" value="American Samoa">American Samoa</option>
                        <option data-alternate-values="[&quot;Arizona&quot;]" value="Arizona">Arizona</option>
                        <option data-alternate-values="[&quot;Arkansas&quot;]" value="Arkansas">Arkansas</option>
                        <option data-alternate-values="[&quot;California&quot;]" value="California">California</option>
                        <option data-alternate-values="[&quot;Colorado&quot;]" value="Colorado">Colorado</option>
                        <option data-alternate-values="[&quot;Connecticut&quot;]" value="Connecticut">Connecticut</option>
                        <option data-alternate-values="[&quot;Delaware&quot;]" value="Delaware">Delaware</option>
                        <option data-alternate-values="[&quot;Florida&quot;]" value="Florida">Florida</option>
                        <option data-alternate-values="[&quot;Georgia&quot;]" value="Georgia">Georgia</option>
                        <option data-alternate-values="[&quot;Guam&quot;]" value="Guam">Guam</option>
                        <option data-alternate-values="[&quot;Hawaii&quot;]" value="Hawaii">Hawaii</option>
                        <option data-alternate-values="[&quot;Idaho&quot;]" value="Idaho">Idaho</option>
                        <option data-alternate-values="[&quot;Illinois&quot;]" value="Illinois">Illinois</option>
                        <option data-alternate-values="[&quot;Indiana&quot;]" value="Indiana">Indiana</option>
                        <option data-alternate-values="[&quot;Iowa&quot;]" value="Iowa">Iowa</option>
                        <option data-alternate-values="[&quot;Kansas&quot;]" value="Kansas">Kansas</option>
                        <option data-alternate-values="[&quot;Kentucky&quot;]" value="Kentucky">Kentucky</option>
                        <option data-alternate-values="[&quot;Louisiana&quot;]" value="Louisiana">Louisiana</option>
                        <option data-alternate-values="[&quot;Maine&quot;]" value="Maine">Maine</option>
                        <option data-alternate-values="[&quot;Marshall Islands&quot;]" value="Marshall Islands">Marshall Islands</option>
                        <option data-alternate-values="[&quot;Maryland&quot;]" value="Maryland">Maryland</option>
                        <option data-alternate-values="[&quot;Massachusetts&quot;]" value="Massachusetts">Massachusetts</option>
                        <option data-alternate-values="[&quot;Michigan&quot;]" value="Michigan">Michigan</option>
                        <option data-alternate-values="[&quot;Federated States of Micronesia&quot;]" value="Micronesia">Micronesia</option>
                        <option data-alternate-values="[&quot;Minnesota&quot;]" value="Minnesota">Minnesota</option>
                        <option data-alternate-values="[&quot;Mississippi&quot;]" value="Mississippi">Mississippi</option>
                        <option data-alternate-values="[&quot;Missouri&quot;]" value="Missouri">Missouri</option>
                        <option data-alternate-values="[&quot;Montana&quot;]" value="Montana">Montana</option>
                        <option data-alternate-values="[&quot;Nebraska&quot;]" value="Nebraska">Nebraska</option>
                        <option data-alternate-values="[&quot;Nevada&quot;]" value="Nevada">Nevada</option>
                        <option data-alternate-values="[&quot;New Hampshire&quot;]" value="New Hampshire">New Hampshire</option>
                        <option data-alternate-values="[&quot;New Jersey&quot;]" value="New Jersey">New Jersey</option>
                        <option data-alternate-values="[&quot;New Mexico&quot;]" value="New Mexico">New Mexico</option>
                        <option data-alternate-values="[&quot;New York&quot;]" value="New York">New York</option>
                        <option data-alternate-values="[&quot;North Carolina&quot;]" value="North Carolina">North Carolina</option>
                        <option data-alternate-values="[&quot;North Dakota&quot;]" value="North Dakota">North Dakota</option>
                        <option data-alternate-values="[&quot;Northern Mariana Islands&quot;]" value="Northern Mariana Islands">Northern Mariana Islands</option>
                        <option data-alternate-values="[&quot;Ohio&quot;]" value="Ohio">Ohio</option>
                        <option data-alternate-values="[&quot;Oklahoma&quot;]" value="Oklahoma">Oklahoma</option>
                        <option data-alternate-values="[&quot;Oregon&quot;]" value="Oregon">Oregon</option>
                        <option data-alternate-values="[&quot;Palau&quot;]" value="Palau">Palau</option>
                        <option data-alternate-values="[&quot;Pennsylvania&quot;]" value="Pennsylvania">Pennsylvania</option>
                        <option data-alternate-values="[&quot;Puerto Rico&quot;]" value="Puerto Rico">Puerto Rico</option>
                        <option data-alternate-values="[&quot;Rhode Island&quot;]" value="Rhode Island">Rhode Island</option>
                        <option data-alternate-values="[&quot;South Carolina&quot;]" value="South Carolina">South Carolina</option>
                        <option data-alternate-values="[&quot;South Dakota&quot;]" value="South Dakota">South Dakota</option>
                        <option data-alternate-values="[&quot;Tennessee&quot;]" value="Tennessee">Tennessee</option>
                        <option data-alternate-values="[&quot;Texas&quot;]" value="Texas">Texas</option>
                        <option data-alternate-values="[&quot;Virgin Islands&quot;]" value="U.S. Virgin Islands">U.S. Virgin Islands</option>
                        <option data-alternate-values="[&quot;Utah&quot;]" value="Utah">Utah</option>
                        <option data-alternate-values="[&quot;Vermont&quot;]" value="Vermont">Vermont</option>
                        <option data-alternate-values="[&quot;Virginia&quot;]" value="Virginia">Virginia</option>
                        <option data-alternate-values="[&quot;Washington&quot;]" value="Washington">Washington</option>
                        <option data-alternate-values="[&quot;District of Columbia&quot;]" value="Washington DC">Washington DC</option>
                        <option data-alternate-values="[&quot;West Virginia&quot;]" value="West Virginia">West Virginia</option>
                        <option data-alternate-values="[&quot;Wisconsin&quot;]" value="Wisconsin">Wisconsin</option>
                        <option data-alternate-values="[&quot;Wyoming&quot;]" value="Wyoming">Wyoming</option>
                        <option data-alternate-values="[&quot;Armed Forces Americas&quot;]" value="Armed Forces Americas">Armed Forces Americas</option>
                        <option data-alternate-values="[&quot;Armed Forces Europe&quot;]" value="Armed Forces Europe">Armed Forces Europe</option>
                        <option data-alternate-values="[&quot;Armed Forces Pacific&quot;]" value="Armed Forces Pacific">Armed Forces Pacific</option>
                    </select>
                </div>
                <div class="group" *ngIf=" shippingMethodPickup == 'ship' && country == 'United Arab Emirates'">
                    <select class="col-mob-12" #state (input)="getUsState(state.value)" [ngClass]="{'--red_bordered': staterequired}">
                        <option selected value="">City / State</option>
                        <option value="Abu Dhabi">Abu Dhabi</option>
                        <option value="Dubai">Dubai</option>
                        <option value="Sharjah">Sharjah</option>
                        <option value="Ajman">Ajman</option>
                        <option value="Umm Al-Quwain">Umm Al-Quwain</option>
                        <option value="Fujairah">Fujairah</option>
                        <option value="Ras Al Khaimah">Ras Al Khaimah</option>
                    </select>
                </div>

                <div class="group" *ngIf=" shippingMethodPickup == 'ship'">
                    <input type="text" class="with_span"  minlength="10" maxlength="20" [ngClass]="{'--red_bordered': phonenumberrequired}" #phonenumber (input)="getPhonenumber(phonenumber.value)" placeholder="Phone number with country code">
                    <br><span>This will be used to contact you about your order</span>
                </div>

                <div class="group flex_input btn_group">
                    <button *ngIf=" shippingMethodPickup == 'ship'" (click)="proceedToPayment()" class="continue col-mob-12">
                        <svg *ngIf="hasSubmitted" class="spinner_loading" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M18.364 5.636L16.95 7.05A7 7 0 1 0 19 12h2a9 9 0 1 1-2.636-6.364z" fill="rgba(255,255,255,1)"/></svg>
                        <span *ngIf="!hasSubmitted">Continue to shipping</span>
                    </button>
                    <button [disabled]="hasSubmitted" *ngIf=" shippingMethodPickup == 'pick'" (click)="proceedToPayment()" class="continue col-mob-12">
                        <svg *ngIf="hasSubmitted" class="spinner_loading" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M18.364 5.636L16.95 7.05A7 7 0 1 0 19 12h2a9 9 0 1 1-2.636-6.364z" fill="rgba(255,255,255,1)"/></svg>
                        <span *ngIf="!hasSubmitted">Continue to payment</span>    
                    </button>
                    <button (click)="cancelCheck()" class="cancel col-mob-12">Cancel order</button>
                </div>
            </div>
            <div class="shopping_details col-mob-12 col-tab-12 col-large-12">
                <div class="cart_items">
                    <div *ngIf="loadingData" class="isLoadingData">
                        <svg class="spinner_loading" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path fill="#000000" d="M18.364 5.636L16.95 7.05A7 7 0 1 0 19 12h2a9 9 0 1 1-2.636-6.364z"/></svg>
                        <p>Loading . . .</p>
                    </div>
                    <div *ngFor="let item of finalProductsInCart.slice().reverse(); let i = index" class="item">
                        <div class="image">
                            <img [src]="item[5].productMainImage" alt="">
                            <div class="number">{{ item[1] }}</div>
                        </div>
                        <div class="name">
                            <p>{{ item[5].productName }}</p>
                            <span *ngIf="item[0] == 's'">Small size</span>
                            <span *ngIf="item[0] == 'm'">medium size</span>
                            <span *ngIf="item[0] == 'l'">Large size</span>
                            <span *ngIf="item[0] == 'xl'">Extra large</span>
                            <span *ngIf="item[0] == 'xxl'">Extra extra large</span>
                            <div class="color" *ngIf="item[3] != 'default' " [style.background]="item[3]" style="width: 70%; height: 0.35em; border-radius: 0.3em; margin: 1% 0;"></div>
                            <div class="color" *ngIf="item[3] == 'default' " style="font-size: 0.7em; margin: 1% 0; opacity: 0.4;">Default color</div>
                        </div>
                        <div class="price">
                            <p *ngIf="!loadingPrice"> 
                                <strong *ngIf="currency == 'usd'">$ </strong> 
                                <strong *ngIf="currency == 'gbp'">&pound; </strong> 
                                {{ item[2] * item[1]  | currencies:currency:exchangeValue | number }} 
                                <span *ngIf="currency == 'rwf'">RWF</span>
                            </p>
                            <p *ngIf="loadingPrice"><span>Calculating . . .</span></p>
                        </div>
                    </div>
                </div>
                <div class="sub_totals">
                    <div class="subttl">
                        <div class="details">
                            <p>Subtotal</p>
                            <p>Pickup price</p>
                        </div>
                        <div class="costs">
                            <p *ngIf="!loadingPrice"> 
                                <strong *ngIf="currency == 'usd'">$ </strong> 
                                <strong *ngIf="currency == 'gbp'">&pound; </strong> 
                                {{ myCart.totalPrice  | currencies:currency:exchangeValue | number }} 
                                <span *ngIf="currency == 'rwf'">RWF</span>
                            </p>
                            <p *ngIf="loadingPrice"><span>Calculating . . .</span></p>
                            <p *ngIf=" shippingMethodPickup == 'pick'">Free</p>
                            <p *ngIf=" shippingMethodPickup == 'ship'" style="color: #104cba; font-size: 0.8em;">Continue to calculate</p>
                        </div>
                    </div>
                </div>
                <div class="final_totals">
                    <div class="subttl">
                        <div class="details">
                            <p class="final_total">Total</p>
                        </div>
                        <div class="costs">
                            <p class="final_cost" *ngIf="!loadingPrice"> 
                                <strong *ngIf="currency == 'usd'">$ </strong> 
                                <strong *ngIf="currency == 'gbp'">&pound; </strong> 
                                {{ myCart.totalPrice  | currencies:currency:exchangeValue | number }} 
                                <span *ngIf="currency == 'rwf'">RWF</span>
                            </p>
                            <p class="final_cost" *ngIf="loadingPrice"><span>Calculating . . .</span></p>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    </div>
</div>
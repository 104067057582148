<div id="entire_admin_orders">
    <div *ngIf="isSuccess || hasErrorAlert" class="success_message">
        <div class="msg_box" [ngClass]="{'isFailed': hasErrorAlert}">
            <p *ngIf="isSuccess">{{ successMsg }}</p>
            <p *ngIf="hasErrorAlert">{{ hasErrorMsg }}</p>
            <svg *ngIf="isSuccess" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-.997-4L6.76 11.757l1.414-1.414 2.829 2.829 5.656-5.657 1.415 1.414L11.003 16z" fill="rgba(255,255,255,1)"/></svg>
            <svg *ngIf="hasErrorAlert" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-9.414l2.828-2.829 1.415 1.415L13.414 12l2.829 2.828-1.415 1.415L12 13.414l-2.828 2.829-1.415-1.415L10.586 12 7.757 9.172l1.415-1.415L12 10.586z" fill="rgba(255,255,255,1)"/></svg>
        </div>
    </div>
    
    <div class="order_in_details" (click)="toggleOrderDetails($event)" *ngIf="isOrderDetailsOpen">
        <div class="details_content">
            <div class="header">
                <h1>Order details</h1>
                <svg (click)="toggleOrderDetails($event)" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-9.414l2.828-2.829 1.415 1.415L13.414 12l2.829 2.828-1.415 1.415L12 13.414l-2.828 2.829-1.415-1.415L10.586 12 7.757 9.172l1.415-1.415L12 10.586z"/></svg>
            </div>
            <div class="content">
                <div class="written_details col-mob-12 col-tab-12 col-large-12">
                    <div class="data"><p><span>Owner names:</span>&nbsp; {{ orderBeingViewInDetails.firstname }} {{ orderBeingViewInDetails.lastname }}</p></div>
                    <div class="data"><p><span>Owner email:</span>&nbsp; {{ orderBeingViewInDetails.contactinfo }}</p></div>
                    <div class="data"><p><span>Owner phonenumber:</span>&nbsp; {{ orderBeingViewInDetails.phonenumber | phone }}</p></div>
                    <div class="data"><p class="red_text" [ngClass]="{'--success_text_data': orderBeingViewInDetails.paid == 'yes'}"><span>Order paid:</span>&nbsp; {{ orderBeingViewInDetails.paid }}</p></div>
                    <div class="data"><p class="red_text" [ngClass]="{'--success_text_data': orderBeingViewInDetails.ordercompleted == true}"><span>Order completed:</span>&nbsp; {{ orderBeingViewInDetails.ordercompleted }}</p></div>
                    <div class="data"><p><span>Total price:</span>&nbsp; RF {{ orderBeingViewInDetails.orderPrice | number }}</p></div>
                    <div class="data"><p><span>Pickup method:</span>&nbsp; {{ orderBeingViewInDetails.pickupmethod }}</p></div>
                    <div class="data"><p><span>Pickup price:</span>&nbsp; RF {{ orderBeingViewInDetails.pickupprice | number }}</p></div>
                    <div class="data"><p><span>CartId:</span>&nbsp; {{ orderBeingViewInDetails.cartId }}</p></div>
                    <div class="data"><p><span>Created:</span>&nbsp; {{ orderBeingViewInDetails.createdAt | timetrim }}</p></div>
                    <div class="data"><p><span>Last Updated:</span>&nbsp; {{ orderBeingViewInDetails.updatedAt | timetrim }}</p></div>
                </div>
                <div class="products col-mob-12 col-tab-12 col-large-12">
                    <div *ngIf="loadingItems" class="isLoadingData">
                        <svg class="spinner_loading" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path fill="#000000" d="M18.364 5.636L16.95 7.05A7 7 0 1 0 19 12h2a9 9 0 1 1-2.636-6.364z"/></svg>
                        <p>Loading . . .</p>
                    </div>

                    <div *ngFor="let item of itemsInCurrentOrder.slice().reverse(); let i = index" class="item">
                        <div class="image">
                            <img (click)="getItem(item[2])" [src]="item[6].productMainImage" alt="">
                            <div class="number">{{ item[1] }}</div>
                        </div>
                        <div class="name">
                            <p  (click)="getItem(item[2])">{{ item[6].productName }}</p>
                            <span *ngIf="item[4] == 's'">Small size</span>
                            <span *ngIf="item[4] == 'm'">medium size</span>
                            <span *ngIf="item[4] == 'l'">Large size</span>
                            <span *ngIf="item[4] == 'xl'">Extra large</span>
                            <span *ngIf="item[4] == 'xxl'">Extra extra large</span>
                            <div class="color" *ngIf="item[0] != 'default' " [style.background]="item[0]" style="width: 70%; height: 0.35em; border-radius: 0.3em; margin: 1% 0;"></div>
                            <div class="color" *ngIf="item[0] == 'default' " style="font-size: 0.7em; margin: 1% 0; opacity: 0.4;">Default color</div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="header">
        <div class="title col-mob-12 col-tab-12 col-large-12">
            <p>Pending orders to inkingi shop</p>
        </div>
        <div class="sort col-mob-12 col-tab-12 col-large-12">
            <select class="pickup" #sort_algorithm (input)="getSortingPickup(sort_algorithm.value)">
                <option selected>deliver sort</option>
                <option value="pick">Pickup themselve</option>
                <option value="ship">Ship to them</option>
            </select>
            <select class="timing" #sort_algorithm_2 (input)="getSortingPrice(sort_algorithm_2.value)">
                <option selected>sort price</option>
                <option value="low_high">low - high</option>
                <option value="high_low">high - low</option>
            </select>
            <!-- <select class="status_payment">
                <option selected>Paid</option>
                <option>Unpaid</option>
            </select> -->
            <!-- <input type="number" value="10"> -->
        </div>
    </div>
    <div class="content">

        <div *ngIf="loadingData" class="isLoadingData">
            <svg class="spinner_loading" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path fill="#000000" d="M18.364 5.636L16.95 7.05A7 7 0 1 0 19 12h2a9 9 0 1 1-2.636-6.364z"/></svg>
            <p>Loading . . .</p>
        </div>

        <p *ngIf="noItemsInPending" style="font-size: 0.88em; opacity: .5; text-align: center; margin-top: 10%;">No pending orders</p>

        <div class="order" *ngFor="let order of allOrders[0]; let i = index">

            <div class="numbering col-mob-12 col-tab-12 col-large-12">
                <h2 *ngIf="i <= 8">0{{ i+1 }}.</h2>
                <h2 *ngIf="i > 8">{{ i + 1 }}.</h2>
            </div>

            <div class="details col-mob-12 col-tab-12 col-large-12">
                <div class="info">
                    <p> 
                        <span class="date">Ordered on {{ order.createdAt | date:'medium' }}</span>
                        <span *ngIf="order.paid == 'yes'" ngClass="paid_class"> &nbsp; <strong>.</strong> &nbsp; Completely paid</span>
                        <span *ngIf="order.paid == 'no'" ngClass="unpaid_class"> &nbsp; <strong>.</strong> &nbsp; Unpaid</span>
                    </p>
                    <h1 *ngIf="order.pickupmethod == 'ship'
                        && order.country != 'United State of America' 
                        && order.country != 'Canada' 
                        && order.country != 'United Arab Emirates'">
                            <span> Order to </span> {{ order.apartment }}, &nbsp; {{ order.street }}, &nbsp; {{ order.city }}, &nbsp; {{ order.city }} - {{ order.country }}
                    </h1>
                    <h1 *ngIf="order.pickupmethod == 'ship' && order.country == 'United State of America' || order.country == 'Canada' || order.country == 'United Arab Emirates'"><span> Order to </span> {{ order.apartment }}, &nbsp; {{ order.street }}, &nbsp; {{ order.state }}{{ order.province }}, &nbsp; {{ order.city }} - {{ order.country }}</h1>
                    <h1 *ngIf="order.pickupmethod == 'pick'"><span> Order </span>to be picked by owner</h1>
                </div>

                <div class="products col-mob-12 col-tab-12 col-large-12">
                    <p>This order has &nbsp;<strong>{{ order.cartDetails.products | ordersproductnamefiltering | number }}</strong>&nbsp; items in total.</p>
                </div>
                
                <div class="colors col-mob-12 col-tab-12 col-large-12">
                    <p>{{ order.cartDetails.products | ordercolordefiner }} <span>colors included</span></p>
                    <p>{{ order.cartDetails.products | ordersizedefiner }} <span>sizes included</span></p>

                    <h3 *ngIf="order.pickupmethod == 'pick'">{{ order.contactinfo }}</h3>
                    <h3 *ngIf="order.pickupmethod == 'ship'">{{ order.phonenumber | phone }}</h3>
                </div>

            </div>

            <div class="actions_status col-mob-12 col-tab-12 col-large-12">
                <div class="price">
                    <h1>{{ order.orderPrice | number }} <span>RWF</span></h1>
                </div>
                <div class="status">
                    <button (click)="markOrderCompleted(order._id)" *ngIf="!order.ordercompleted" [ngClass]="{'_order_not_complete': !order.ordercompleted}">
                        <span *ngIf="hasSubmitted && order._id == processingSubmissionId">
                            <svg class="spinner_loading" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M18.364 5.636L16.95 7.05A7 7 0 1 0 19 12h2a9 9 0 1 1-2.636-6.364z"/></svg>
                        </span>
                        <span *ngIf="order._id != processingSubmissionId">Mark complete</span> 
                    </button>
                    <button *ngIf="order.ordercompleted" [ngClass]="{'_order_complete': order.ordercompleted}">Order completed</button>
                    <a (click)="showOrderDetails(order)">
                        See details
                    </a>
                </div>
            </div>

        </div>
    </div>
</div>
import { Component, OnInit } from '@angular/core';
import { CommunicationService } from 'src/app/services/communication.service';
import { AdminsubscriptionsService } from '../../services/adminsubscriptions.service';

@Component({
	selector: 'app-adminsubscribers',
	templateUrl: './adminsubscribers.component.html',
	styleUrls: ['./adminsubscribers.component.css']
})
export class AdminsubscribersComponent implements OnInit {

	constructor( 
		private __adminSubscriptions: AdminsubscriptionsService,
		private __communService: CommunicationService
	) { }

	ngOnInit(): void {
		this.getAllSubscriptions();
	}

	isSuccess: boolean = false;
	successMsg: string = '';
	hasSubmitted: boolean = false;
	hasErrorAlert: boolean = false;
	hasErrorMsg: string = '';

	subscriptions: any = [];
	loadingData: boolean = true;

	getAllSubscriptions() {
		this.__adminSubscriptions.getAllSubscriptions().subscribe(
			res => {
				this.subscriptions = res.data;
				this.loadingData = false;
			},
			err => {
				this.getAllSubscriptions();
			}
		)
	}

	newsubject: string = '';
	newMessage: string = '';
	newLink: string = '';

	getSubject(value: any) {
		this.newsubject = value;
	}

	getMessage(value: any) {
		this.newMessage = value;
	}
	
	getLink(value: any) {
		this.newLink = value;
	}

	writeToClients() {
		var data = {
			"subject": `${this.newsubject}`,
			"message": `${this.newMessage}`,
			"link": `${this.newLink}`
		}

		this.hasSubmitted = true;

		this.__adminSubscriptions.writeToAll(data).subscribe(
			res => {
				this.successMsg = 'Mail sent successfully!';
				this.isSuccess = true;
				this.hasSubmitted = false;
				
				let message: any = document.getElementById('message');
				let subject: any = document.getElementById('subject');
				let link: any = document.getElementById('link');

				message.value = '';
				subject.value = '';
				link.value = '';

				let count = 0;
				
				let successInterval = setInterval(() => {
					if (count > 0) {
						this.isSuccess = false;
						clearInterval(successInterval);
					}
					count = count + 1;
				}, 2000)
			},
			err => {
				this.hasSubmitted = false;
				this.hasErrorAlert = true;
				if (err.message && err.error) {
					if (err.error.message) {
						this.hasErrorMsg = err.error.message;
					} else if (err.error) {
						this.hasErrorMsg = err.error;
					} else {
						this.hasErrorMsg = "Oops! Something went wrong!"
					}
				} else if (err.message) {
					this.hasErrorMsg = err.message;
				} else if (err.error) {
					this.hasErrorMsg = err.error;
				} else {
					this.hasErrorMsg = "Oops! Something went wrong!"
				}

				let count = 0;

				let successInterval = setInterval(() => {
					if (count > 0) {
						this.hasErrorAlert = false;
						clearInterval(successInterval);
					}
					count = count + 1;
				}, 2000)
			}
		)
	}

	deletingSub: boolean = false;
	subBeingDeleted: string = '';

	deleteSubscriber(value: any, sub_id: any) {
		this.deletingSub = true;
		this.subBeingDeleted = value;
		this.__communService.unsubscribe(value).subscribe(
			res => {
				this.successMsg = 'Subscriber deleted successfully';
				this.isSuccess = true;
				
				let count = 0;
				
				let successInterval = setInterval(() => {
					if (count > 0) {
						this.deletingSub = false;
						this.subBeingDeleted = '';
						this.isSuccess = false;
						this.subscriptions.splice(sub_id, 1);
						clearInterval(successInterval);
					}
					count = count + 1;
				}, 2000)

			},
			err => {
	
				this.hasErrorAlert = true;
				this.hasErrorMsg = "Something isn't right! Try again";

				let count = 0;
				
				let successInterval = setInterval(() => {
					if (count > 0) {
						this.hasErrorAlert = false;
						this.deletingSub = false;
						this.subBeingDeleted = '';
						clearInterval(successInterval);
					}
					count = count + 1;
				}, 2000)
			}
		)
	}
}
import { Component, HostListener, OnInit } from '@angular/core';
import { CommunicationService } from '../services/communication.service';
import { CartService } from '../services/cart.service';
import { ProductsService } from '../services/products.service';
import { Router } from '@angular/router';
import { CustomsService } from '../services/customs.service';
import { SearchService } from '../services/search.service';

import axios from 'axios';

@Component({
	selector: 'app-clientoutlet',
	templateUrl: './clientoutlet.component.html',
	styleUrls: ['./clientoutlet.component.css']
})
export class ClientoutletComponent implements OnInit {

	constructor( 
		private __communService: CommunicationService, 
		private __customsService: CustomsService, 
		private _router: Router, 
		private __cartService: CartService, 
		private __productsService: ProductsService,
		private __searchService: SearchService,
	) { 
		this._router.events.subscribe((event) => {
			this.__customsService.updatePageStatus(true);
		});
	}

	currency: any = 'rwf'

	shouldCreateCart: any;
	myCart: any = [];

	onTheHomePage: boolean = true;
	macaddress: any;

	ngOnInit(): void {

		// axios.get('https://iplist.cc/api').then(res => {
		axios.get('https://ipinfo.io/json?token=6d2b23a3d49bf4').then(res => {
				
				let browser: string = '';
				if((navigator.userAgent.indexOf("Opera") || navigator.userAgent.indexOf('OPR')) != -1 ) {
					browser = 'Opera'
				} else if(navigator.userAgent.indexOf("Chrome") != -1 ) {
					browser = 'Chrome'
				} else if(navigator.userAgent.indexOf("Safari") != -1) {
					browser = 'Safari'
				} else if(navigator.userAgent.indexOf("Firefox") != -1 )  {
					browser = 'Firefox'
				} else if(navigator.userAgent.indexOf("MSIE") != -1 ) {
						browser = 'IE or Edge'
				} else {
					browser = 'Unknown'
				}

				let random_mac_holder = Math.random()*1000000000000000+(new Date()).toString();
				let macAddress = random_mac_holder;

				let session_data: any = {
					"location": res.data.city+' - '+res.data.country,
					"browser": browser,
					"ip_address": res.data.ip,
					"mac_address": macAddress,
					"operating_system": navigator.userAgent.split('(')[1].split(';')[0],
					"data_org": res.data.org,
					"lng_lat": res.data.loc
				}

				this.__communService.addSession(session_data).subscribe(
					res => {},
					err => {})

		}).catch(err => {
			console.warn('Session crupting . . .');
		})

		// this.itemsInCart = this.__cartService.getCartNumber();

		this.__cartService.updateCartItemsNumber.subscribe(
			(res:any) => {
			  this.itemsInCart = this.__cartService.getCartNumber();
			}
		);

		this.__customsService.updateNavbarColors.subscribe(
			(res:any) => {
				this.onTheHomePage = this.__customsService.getWhichPageState(); 
			}, (err:any) => console.log(err) 
		);

		this.__cartService.getMyShoppingCart().then(data => {
			this.shouldCreateCart = data;
		
			if (this.shouldCreateCart) {
				this.createNewCart();
			} 
			
			if (!this.shouldCreateCart) {
				var cookieid: any = document.cookie.match(new RegExp('(^| )' + '_mycart' + '=([^;]+)'));
				this.getMyShoppingCart(cookieid[2]);
			}
	
		});

		this.getCurrentCurrency()
	}

	getMyShoppingCart(value: any) {
		let data = value;

		this.__cartService.getSpecificCart(data).subscribe(
			res => {
				this.myCart = res.data;

				this.myCart.products.forEach((element: any) => {
					this.itemsInCart = this.itemsInCart+parseInt(element.number);
				});

				this.__cartService.updateCountCart(this.itemsInCart);
			},
			err => { this.createNewCart() }
		)
	}

	createNewCart() {
		let newCartData = {
			"products": []
		};

		this.__cartService.createNewCart(newCartData).subscribe(
			res => {
				document.cookie = `_mycart=${res.data._id}; path=/; max-age=` + 2147483647;
				this.getMyShoppingCart(res.data._id)
			},
			err => {
				this.createNewCart();
			}
		)
	}

	public isNavbarDown: boolean = false;
	public itemsInCart: number = 0;
	public searchToggled: boolean = false;
	public expandedMenu: boolean = false;
	isSuccess: boolean = false;
	successMsg: string = '';
	hasSubmitted: boolean = false;
	hasErrorAlert: boolean = false;
	hasErrorMsg: string = '';

	@HostListener('window:scroll', ['$event']) onScrollEvent(event: any){
		if (window.pageYOffset > 88) {
			this.isNavbarDown = true;
		} else {
			this.isNavbarDown = false;
		}  
	}

	subscriberEmail: string = '';
	regularExpression = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

	toggleSearch(event: any) {
		if (event.target !== event.currentTarget) return;
		this.searchToggled = !this.searchToggled;
	}

	expandMenu() {
		this.expandedMenu = !this.expandedMenu;
	}

	expandMenuFalsey() {
		this.expandedMenu = false;
	}

	getSubscriberEmail(value: string) {
		this.subscriberEmail = value;
	}

	subscriptionActivity() {
		this.hasErrorAlert = false;
		this.isSuccess = false;
		this.successMsg = '';
		this.hasErrorMsg = '';

		if (this.subscriberEmail.length < 5) {
			this.hasErrorAlert = true;
			this.hasErrorMsg = "Your email must be above 5 characters";

			let count = 0;

			let successInterval = setInterval(() => {
				if (count > 0) {
					this.hasErrorAlert = false;
					clearInterval(successInterval);
				}

				count = count + 1;
			}, 2000)

			return;
		}
	
		if (this.subscriberEmail.length > 80) {
			this.hasErrorAlert = true;
			this.hasErrorMsg = "Your email must be less 80 characters";

			let count = 0;

			let successInterval = setInterval(() => {
				if (count > 0) {
					this.hasErrorAlert = false;
					clearInterval(successInterval);
				}

				count = count + 1;
			}, 2000)

			return;
		}

		if (this.subscriberEmail != "") {
			const valid = this.regularExpression.test(String(this.subscriberEmail).toLowerCase());
			if (!valid) {
				this.hasErrorAlert = true;
				this.hasErrorMsg = "Invalid email address!"
				
				let count = 0;

				let successInterval = setInterval(() => {
					if (count > 0) {
						this.hasErrorAlert = false;
						clearInterval(successInterval);
					}
	
					count = count + 1;
				}, 2000)
	
				return;
			}
		} else {
			return;
		}

		this.hasSubmitted = true;
		let formData = { "subscriberEmail": this.subscriberEmail };
		
		this.__communService.subscribeNow(formData).subscribe(
			res => {
				this.hasSubmitted = false;
				this.sendNotificaton(this.subscriberEmail);
				this.subscriberEmail = '';
				var email: any = document.getElementById('email');
				email.value = '';
				

				this.successMsg = 'Subscribed inkingi successfully';
				this.isSuccess = true;
				
				let count = 0;

				let successInterval = setInterval(() => {
					if (count > 0) {
						this.isSuccess = false;
						clearInterval(successInterval);
					}

					count = count + 1;
				}, 2000)

			},
			err => {
				this.hasSubmitted = false;
				this.hasErrorAlert = true;
				if (err.message && err.error) {
					if (err.error.message) {
						this.hasErrorMsg = err.error.message;
					} else if (err.error) {
						this.hasErrorMsg = err.error;
					} else {
						this.hasErrorMsg = "Oops! Something went wrong!"
					}
				} else if (err.message) {
					this.hasErrorMsg = err.message;
				} else if (err.error) {
					this.hasErrorMsg = err.error;
				} else {
					this.hasErrorMsg = "Oops! Something went wrong!"
				}

				let count = 0;

				let successInterval = setInterval(() => {
					if (count > 0) {
						this.hasErrorAlert = false;
						clearInterval(successInterval);
					}
	
					count = count + 1;
				}, 2000)
			}
		)
	}

	sendNotificaton(value: any) {

		let noteData = {
			
			"notificationTitle": "New inkingi subscriber",
			"notificationDescription": `A client with email - ${value} has successfully subscribed to inkingi daily updated and newsletter.`,
			"notificationCategory": "newsubscriber"
			
		}

		this.__communService.sendNotification(noteData).subscribe(
			res => {
				if (res) return;
			}, err => {
				if (err) return;
			}
		)
	}
	
	changeCurrency(value: any) {
		this.__productsService.setCurrentCurrency(value);
		this.getCurrentCurrency();
	}

	getCurrentCurrency() {
		this.currency = this.__productsService.getCurrentCurrency();
	}

	searchKey: string = '';
	loadingSearch: boolean = false;

	getSearchKey(value: string) {
		this.searchKey = value;
	}

	year: any  = new Date().getFullYear()

	searchOnInkingi() {
		this.expandMenuFalsey();
		this.loadingSearch = true;

		if (this.searchKey == '') {
			this.hasErrorAlert = true;
			this.hasErrorMsg = 'Search term is required!'
			let count = 0;

			let successInterval = setInterval(() => {
				if (count > 0) {
					this.hasErrorAlert = false;
					this.loadingSearch = false;
					clearInterval(successInterval);
				}

				count = count + 1;
			}, 2000)
			return;
		}

		if (this.searchKey.length > 30) {
			this.hasErrorAlert = true;
			this.hasErrorMsg = 'Too long search term!'
			let count = 0;

			let successInterval = setInterval(() => {
				if (count > 0) {
					this.hasErrorAlert = false;
					this.loadingSearch = false;
					clearInterval(successInterval);
				}

				count = count + 1;
			}, 2000)
			return;
		}

		this._router.navigate(['search', this.searchKey])

		this.searchToggled = false;
		this.loadingSearch = false;
		this.searchKey = "";
	}
	
}
<div id="entire_application__shipping_component">

    <div *ngIf="loading" class="loader">
        <app-loader></app-loader>
    </div>
    
    <div *ngIf="isSuccess || hasErrorAlert" class="success_message">
        <div class="msg_box" [ngClass]="{'isFailed': hasErrorAlert}">
            <p *ngIf="isSuccess">{{ successMsg }}</p>
            <p *ngIf="hasErrorAlert">{{ hasErrorMsg }}</p>
            <svg *ngIf="isSuccess" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-.997-4L6.76 11.757l1.414-1.414 2.829 2.829 5.656-5.657 1.415 1.414L11.003 16z" fill="rgba(255,255,255,1)"/></svg>
            <svg *ngIf="hasErrorAlert" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-9.414l2.828-2.829 1.415 1.415L13.414 12l2.829 2.828-1.415 1.415L12 13.414l-2.828 2.829-1.415-1.415L10.586 12 7.757 9.172l1.415-1.415L12 10.586z" fill="rgba(255,255,255,1)"/></svg>
        </div>
    </div>

    <div class="shipping__container">
        <div class="header">
            <h3>Shipping my products</h3>
        </div>
        <div class="check_forms">
            <div class="form_group col-mob-12 col-tab-12 col-large-12">
        
                <div class="group methods">
                    <p class="method_p">Shipping info & client contacts</p>
                    
                    <div class="method pick">
                        <div class="method_details col-mob-12">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20"><path fill="none" d="M0 0h24v24H0z"/><path d="M9.366 10.682a10.556 10.556 0 0 0 3.952 3.952l.884-1.238a1 1 0 0 1 1.294-.296 11.422 11.422 0 0 0 4.583 1.364 1 1 0 0 1 .921.997v4.462a1 1 0 0 1-.898.995c-.53.055-1.064.082-1.602.082C9.94 21 3 14.06 3 5.5c0-.538.027-1.072.082-1.602A1 1 0 0 1 4.077 3h4.462a1 1 0 0 1 .997.921A11.422 11.422 0 0 0 10.9 8.504a1 1 0 0 1-.296 1.294l-1.238.884zm-2.522-.657l1.9-1.357A13.41 13.41 0 0 1 7.647 5H5.01c-.006.166-.009.333-.009.5C5 12.956 11.044 19 18.5 19c.167 0 .334-.003.5-.01v-2.637a13.41 13.41 0 0 1-3.668-1.097l-1.357 1.9a12.442 12.442 0 0 1-1.588-.75l-.058-.033a12.556 12.556 0 0 1-4.702-4.702l-.033-.058a12.442 12.442 0 0 1-.75-1.588z"/></svg>
                            <p>{{ myorderdata.contactinfo }} <strong>&nbsp; & &nbsp;</strong> {{ myorderdata.phonenumber | phone }}</p>
                        </div>
                        <div class="check_method col-mob-12">
                            <a [routerLink]="['/editshipping']">
                                change
                            </a>
                        </div>
                    </div>

                    <div class="method ship">
                        <div class="method_details col-mob-12">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20"><path fill="none" d="M0 0h24v24H0z"/><path d="M12 20.9l4.95-4.95a7 7 0 1 0-9.9 0L12 20.9zm0 2.828l-6.364-6.364a9 9 0 1 1 12.728 0L12 23.728zM12 13a2 2 0 1 0 0-4 2 2 0 0 0 0 4zm0 2a4 4 0 1 1 0-8 4 4 0 0 1 0 8z"/></svg>
                            <p *ngIf="myorderdata.country == 'United State of America' || myorderdata.country == 'Canada' || myorderdata.country == 'United Arab Emirates'">{{ myorderdata.apartment }} - {{ myorderdata.street }}, {{ myorderdata.postalcode }} {{ myorderdata.state }}{{ myorderdata.province }}, {{ myorderdata.country }}</p>
                            <p *ngIf="myorderdata.country != 'United State of America' && myorderdata.country != 'Canada' && myorderdata.country != 'United Arab Emirates'">{{ myorderdata.apartment }} - {{ myorderdata.street }}, {{ myorderdata.postalcode }} {{ myorderdata.city }}, {{ myorderdata.country }}</p>
                        </div>
                        <div class="check_method col-mob-12">
                            <a [routerLink]="['/editshipping']">
                                change
                            </a>
                        </div>
                    </div>
                </div>

                <div class="group" *ngIf=" shippingMethodPickup == 'pick'">
                    <p>Shipping methods available</p>
                    <div class="pickup_loc">
                        <div class="picked">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-6a4 4 0 1 0 0-8 4 4 0 0 0 0 8z" fill="rgba(25,188,155,1)"/></svg>
                        </div>
                        <div class="place_details">
                            <h4>DHL Express</h4>
                        </div>
                        <div class="prices_pickup">
                            <p *ngIf="!loadingPrice" class="cost"> 
                                <strong *ngIf="currency == 'usd'">$ </strong> 
                                <strong *ngIf="currency == 'gbp'">&pound; </strong> 
                                {{ myorderdata.pickupprice | currencies:currency:exchangeValue | number }} 
                                <span *ngIf="currency == 'rwf'" style="opacity: 0.7; font-size: 0.8em;">RWF</span>
                            </p>
                            <p class="cost" *ngIf="loadingPrice"><span style="opacity: 0.7; font-size: 0.8em;">Calculating . . .</span></p>
                        </div>
                    </div>
                </div>

                <div class="group flex_input btn_group">
                    <button [disabled]="hasSubmitted" (click)="proceedToPayment()" class="continue col-mob-12">
                        <svg *ngIf="hasSubmitted" class="spinner_loading" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M18.364 5.636L16.95 7.05A7 7 0 1 0 19 12h2a9 9 0 1 1-2.636-6.364z" fill="rgba(255,255,255,1)"/></svg>
                        <span *ngIf="!hasSubmitted">Continue to payment</span>    
                    </button>
                    <button (click)="cancelCheck()" class="cancel col-mob-12">
                        <svg *ngIf="deletingOrder" class="spinner_loading" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M18.364 5.636L16.95 7.05A7 7 0 1 0 19 12h2a9 9 0 1 1-2.636-6.364z" fill="#000000"/></svg>
                        <span *ngIf="!deletingOrder">Cancel & restore cart</span>     
                    </button>
                </div>
            </div>
            <div class="shopping_details col-mob-12 col-tab-12 col-large-12">
                <div class="cart_items">
                    <div *ngIf="loadingData" class="isLoadingData">
                        <svg class="spinner_loading" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path fill="#000000" d="M18.364 5.636L16.95 7.05A7 7 0 1 0 19 12h2a9 9 0 1 1-2.636-6.364z"/></svg>
                        <p>Loading . . .</p>
                    </div>
                    <div *ngFor="let item of finalProductsInCart.slice().reverse(); let i = index" class="item">
                        <div class="image">
                            <img [src]="item[5].productMainImage" alt="">
                            <div class="number">{{ item[1] }}</div>
                        </div>
                        <div class="name">
                            <p>{{ item[5].productName }}</p>
                            <span *ngIf="item[0] == 's'">Small size</span>
                            <span *ngIf="item[0] == 'm'">medium size</span>
                            <span *ngIf="item[0] == 'l'">Large size</span>
                            <span *ngIf="item[0] == 'xl'">Extra large</span>
                            <span *ngIf="item[0] == 'xxl'">Extra extra large</span>
                            <div class="color" *ngIf="item[3] != 'default' " [style.background]="item[3]" style="width: 70%; height: 0.35em; border-radius: 0.3em; margin: 1% 0;"></div>
                            <div class="color" *ngIf="item[3] == 'default' " style="font-size: 0.7em; margin: 1% 0; opacity: 0.4;">Default color</div>
                        </div>
                        <div class="price">
                            <p *ngIf="!loadingPrice"> 
                                <strong *ngIf="currency == 'usd'">$ </strong> 
                                <strong *ngIf="currency == 'gbp'">&pound; </strong> 
                                {{ item[2] * item[1]  | currencies:currency:exchangeValue | number }} 
                                <span *ngIf="currency == 'rwf'">RWF</span>
                            </p>
                            <p *ngIf="loadingPrice"><span>Calculating . . .</span></p>
                        </div>
                    </div>
                </div>
                <div class="sub_totals">
                    <div class="subttl">
                        <div class="details">
                            <p>Subtotal</p>
                            <p>Pickup price</p>
                        </div>
                        <div class="costs">
                            <p *ngIf="!loadingPrice"> 
                                <strong *ngIf="currency == 'usd'">$ </strong> 
                                <strong *ngIf="currency == 'gbp'">&pound; </strong> 
                                {{ myCart.totalPrice  | currencies:currency:exchangeValue | number }} 
                                <span *ngIf="currency == 'rwf'">RWF</span>
                            </p>
                            <p *ngIf="loadingPrice"><span>Calculating . . .</span></p>
                           
                            <p *ngIf="!loadingPrice && shippingMethodPickup == 'pick'"> 
                                <strong *ngIf="currency == 'usd'">$ </strong> 
                                <strong *ngIf="currency == 'gbp'">&pound; </strong> 
                                {{ myorderdata.pickupprice  | currencies:currency:exchangeValue | number }} 
                                <span *ngIf="currency == 'rwf'">RWF</span>
                            </p>
                            <p *ngIf="loadingPrice && shippingMethodPickup == 'pick'"><span>Calculating . . .</span></p>
                            <!-- <p *ngIf=" shippingMethodPickup == 'ship'" style="color: #104cba; font-size: 0.8em;">Continue to calculate</p> -->
                        </div>
                    </div>
                </div>
                <div class="final_totals">
                    <div class="subttl">
                        <div class="details">
                            <p class="final_total">Total</p>
                        </div>
                        <div class="costs">
                            <p class="final_cost" *ngIf="!loadingPrice"> 
                                <strong *ngIf="currency == 'usd'">$ </strong> 
                                <strong *ngIf="currency == 'gbp'">&pound; </strong> 
                                {{ myorderdata.orderPrice | currencies:currency:exchangeValue | number }} 
                                <span *ngIf="currency == 'rwf'">RWF</span>
                            </p>
                            <p class="final_cost" *ngIf="loadingPrice"><span>Calculating . . .</span></p>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    </div>
</div>
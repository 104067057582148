import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CollectionsService } from 'src/app/services/collections.service';
import { CustomsService } from 'src/app/services/customs.service';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-clothings',
	templateUrl: './clothings.component.html',
	styleUrls: ['./clothings.component.css']
})

export class ClothingsComponent implements OnInit {

	constructor(private __customsService: CustomsService,
		private __collectionsService: CollectionsService,
		private _router: Router
	) { }

	apiPublic: string = environment.apiPublic;

	_id: string = '';
	collectiondata: any = [];

	loading: boolean = true;

	isSuccess: boolean = false;
	successMsg: string = '';
	hasErrorAlert: boolean = false;
	hasErrorMsg: string = '';

	loadingData: boolean = true;
	noCollectionStatus: boolean = false;

	ngOnInit(): void {
		this.__customsService.updatePageStatus(false);
		this.getCurrentCollection();

	}

	getCurrentCollection() {
		this.__collectionsService.getAllCollections().subscribe(
			res => {
				this.loading = false;
				this.loadingData = false;
				this.collectiondata = res.data;

				if (this.collectiondata.length == 0) {
					this.noCollectionStatus = true;
				}
			},
			err => {
				this.hasErrorAlert = true;
				if (err.status == 404) {
					this.hasErrorMsg = "Collections not found!"
				} else {
					this.hasErrorMsg = "Oops! Something went wrong."
				}
				
				let count = 0;

				let successInterval = setInterval(() => {
					if (count > 0) {
						this.hasErrorAlert = false;
						clearInterval(successInterval);	
						this._router.navigate(['/collections']);
					}
					count = count + 1;
				}, 3000)
			}
		)
	}

	viewingPicMedia: boolean = false;
	currentImageBeingViewed: string = '';

	closeAllMediaView() {
		this.viewingPicMedia = false;

		this.currentImageBeingViewed = '';
	}

	viewPicMedia(value: string) {
		this.viewingPicMedia = !this.viewingPicMedia;
		// this.currentImageBeingViewed = this.apiPublic+value;
		this.currentImageBeingViewed = value;
	}

	seeSingleCollection(value: any) {	
		this._router.navigate(['/collection', value ])
	}

}